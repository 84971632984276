import { useEffect, useState } from 'react';

import { useGetCustomerReferralCreditBalance } from 'client/dist/generated/alloy';

import useCart from 'modules/shared/hooks/useCart';
import useTotalProductPrices from 'modules/shared/hooks/useTotalProductPrices';

import { getCartTotals } from 'modules/shared/lib/cart';
import { getPriceForCombinedProducts } from 'modules/shared/lib/product';
import { isConsultCart } from 'modules/shared/sub-modules/checkout/lib/cart';
import {
  buildDataForCalculation,
  calculateTaxes,
} from 'modules/shared/sub-modules/checkout/lib/tax';

import { useAppSelector } from 'shared/store/reducers';

export default function useTotalsState() {
  const { cart, updateCart } = useCart();

  const [isLoading, setIsLoading] = useState(false);

  const { data: referralCreditBalance } = useGetCustomerReferralCreditBalance();

  const customer = useAppSelector((state) => state.alloy.customer!);

  const isConsult = isConsultCart(cart);

  const { unbundledTotal: unbundledSubtotal } = useTotalProductPrices({
    products: cart.products.flatMap((gcp) => gcp.alloyProduct.parent),
    dependencies: [JSON.stringify(cart.products)],
  });
  const { total, subtotal, discount, referralCreditDiscount, tax, shipping } = getCartTotals(cart);

  const { shippingAddressLineOne, city, zip } = customer;
  const isShippingComplete = [shippingAddressLineOne, city, zip].every(
    (val) => val && val !== '' && !val.includes('_'),
  );

  // Just need to update whenever promotion code is added/removed or shipping
  // is added/updated
  useEffect(() => {
    getTax();
  }, [JSON.stringify(cart.promotionCode), isShippingComplete]);

  useEffect(() => {
    // < 0 b/c the balance comes back as a negative number from stripe.
    // We tack the Abs value when we calculate the discount
    if (!!referralCreditBalance && referralCreditBalance < 0) {
      updateCart({ referralCreditBalance });
    }
  }, [referralCreditBalance]);

  /**
   * Update the carts tax anytime there is a change to the cart (product select, promo code, shipping, etc)
   */
  const getTax = async () => {
    if (cart.products.length !== 0 && !isConsult && isShippingComplete) {
      setIsLoading(true);

      const productsPriceInCents = cart.products.map((gcp) => getPriceForCombinedProducts(gcp));

      const taxesShipping = buildDataForCalculation(
        discount,
        shipping,
        productsPriceInCents,
        customer,
      );

      const taxAmountToCollect = await calculateTaxes(taxesShipping);

      updateCart({
        taxAmountToCollect,
      });

      setIsLoading(false);
    }
  };

  return {
    isLoading,

    cart,
    isConsult,
    isShippingComplete,

    unbundledSubtotal,
    subtotal,
    discount,
    referralCreditDiscount,
    tax,
    shipping,
    total,
  };
}
