import classNames from 'classnames';
import { ReactNode } from 'react';
import { Drawer } from 'rsuite';

import arrowLeft from 'shared/assets/svg/arrows/arrow-left-bold.svg';
import closeIcon from 'shared/assets/svg/common/btn-close.svg';
import logo from 'shared/assets/svg/logos/logo.svg';

interface Props {
  title: string;
  drawerClass?: string;
  open: boolean;
  onClose: () => void;
  disableClosing?: boolean;
  onBack?: () => void;
  children: ReactNode;
}

export default function AlloyDrawer({
  title,
  drawerClass,
  open,
  onClose,
  disableClosing = false,
  onBack,
  children,
}: Props) {
  return (
    <Drawer
      open={open}
      size='sm'
      className={classNames('alloy-drawer', open ? 'drawer-open' : 'drawer-closed', drawerClass)}
      onClose={!disableClosing ? onClose : undefined}
    >
      {open && (
        <Drawer.Body>
          <div className='drawer-header'>
            {onBack && (
              <button className='header-button' onClick={onBack}>
                <img src={arrowLeft} alt='arrow left icon' className='button-icon' />
              </button>
            )}

            <p className='header-title'>{title}</p>

            <img className='header-logo' src={logo} alt='alloy logo' />

            <button onClick={onClose} className='header-btn-close'>
              <img src={closeIcon} alt='close icon' className='close-icon' />
            </button>
          </div>

          {/* drawer-body and drawer-footer go here */}
          {children}
        </Drawer.Body>
      )}
    </Drawer>
  );
}
