import { isEmpty } from 'lodash';

import { featuresWithLightIcons } from 'modules/shared/data/features';
import { SELECTIONS } from 'modules/shared/data/relief-type';

import FeaturesBlock from 'modules/checkout-experience/ui/blocks/Features';
import ImprovementBlock from 'modules/checkout-experience/ui/blocks/Improvement';

import CheckoutExperienceSection from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceSection';

import Form from 'shared/components/core/Form';
import BottomBar from 'shared/components/wrappers/BottomBar';

import useReliefTypeState from '../../hooks/useReliefTypeState';
import { FieldsGroup } from '../content/FieldsGroup';

export default function ReliefTypeFormWrapper() {
  const { firstName, selectedId, setSelectedId, onSubmit } = useReliefTypeState();

  return (
    <Form onSubmit={onSubmit}>
      <CheckoutExperienceSection colClass='col-12 col-md-10'>
        <h1 className='content-title'>
          {isEmpty(firstName)
            ? 'What brings you to Alloy today?'
            : `Hi ${firstName}, what brings you to Alloy today?`}
        </h1>

        <FieldsGroup selectedId={selectedId} onSelect={setSelectedId} options={SELECTIONS} />

        <div className='enhancers-wrapper'>
          <FeaturesBlock data={featuresWithLightIcons} customBg='dark-bg' />

          <ImprovementBlock
            title='95% of women '
            text='will see improvement in symptoms within 4-12 weeks of starting treatment'
          />
        </div>
      </CheckoutExperienceSection>

      <BottomBar>
        <button className='primary-button' type='submit' disabled={!selectedId}>
          Next
        </button>
      </BottomBar>
    </Form>
  );
}
