import { Uploader } from 'rsuite';

interface Props {
  name: string;
  classes?: string;
  accept?: string;
  onChange: (blobs: Blob[]) => void;
}

export const UploadField = ({
  name,
  classes = '',
  accept = '.jpg,.jpeg,.png,.webp,.gif,.tiff,.heic',
  onChange,
}: Props) => (
  <Uploader
    action=''
    name={name}
    multiple={false}
    type='button'
    accept={accept}
    autoUpload={false}
    fileListVisible={false}
    draggable
    className={classes}
    onChange={(event: { blobFile?: Blob }[]) => {
      const blobs: Blob[] = [];

      event.forEach((e) => {
        if (e.blobFile) {
          blobs.push(e.blobFile);
        }
      });

      onChange(blobs);
    }}
  />
);
