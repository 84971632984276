var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getAllProductFrequencies, getAllShippingMethods, getDiscountBundles, } from '../generated/alloy';
import { ProductRegistry, PRODUCT_IDS_PER_TYPE, M4_PRODUCT_ID, OMAZING_PRODUCT_ID, SYNBIOTIC_PRODUCT_ID, TRETINOIN_ID, VAGINAL_PRODUCT_ID, } from 'common/dist/products/productRegistry';
import { getContentfulProducts } from '../contentful/client';
import _, { partition } from 'lodash';
export const shippingMethodTranslator = (sm) => {
    // Spreading and recombining the properties here so I can cast the stripePrice
    // as Stripe.Price. Something seems to get lost with the Stripe types when
    // generating the client that this works around.
    const { stripePrice } = sm, shippingMethod = __rest(sm, ["stripePrice"]);
    return Object.assign(Object.assign({}, shippingMethod), { stripePrice: stripePrice });
};
export class DomProductRegistry extends ProductRegistry {
    constructor(fetcher) {
        super(fetcher);
        /**
         * @param type 'specific' | 'mht' | 'upsell'
         *    specific - allows for passing product ids that can be used to fetch a parent / contentful product
         * @param specificDeepProductIds if we want to filter for specific deep products ids
         * @returns ParentContentfulProduct[]
         */
        this.getIndividualGroupedContentfulProductsFor = (type, specificDeepProductIds = []) => __awaiter(this, void 0, void 0, function* () {
            const upsellProductIds = process.env.REACT_APP_SHOW_NEW_SKINCARE === 'true'
                ? PRODUCT_IDS_PER_TYPE['upsell']
                : [
                    M4_PRODUCT_ID,
                    TRETINOIN_ID,
                    OMAZING_PRODUCT_ID,
                    SYNBIOTIC_PRODUCT_ID,
                    VAGINAL_PRODUCT_ID,
                ];
            const typeWithProductIds = {
                specific: (yield this.alloyProducts).filter((ap) => specificDeepProductIds.includes(ap.id)),
                mht: yield this.getDefaultProductsByIds(PRODUCT_IDS_PER_TYPE['mht']),
                upsell: yield this.getDefaultProductsByIds(upsellProductIds),
            };
            const productFrequencies = typeWithProductIds[type];
            // makes sure it gets unbundled price for every product
            return this.sortGroupedContentfulProducts((yield Promise.all(productFrequencies.map((pf) => this.getRecurringProductsForV2([pf])))).flat());
        });
        /**
         * Filters products that are not mht and returns those for using them as upsell products for the customer
         *
         * @returns ContentfulProduct[]
         */
        this.getUpsellContentfulProducts = () => __awaiter(this, void 0, void 0, function* () {
            return (yield this.contentfulProducts).filter((cp) => !cp.fields.isMht);
        });
        /**
         * Given purchase product ids, not purchased product ids and the upsell product ids coming from FE
         * transform and decide which products should we show in the "Round out your routine" section
         * also, check if parent of bundled product is in the context of purchased products
         * and get the discounted price to show there if that's the case.
         *
         * @param purchasedProducts
         * @param notPurchasedProducts
         * @param upsellProducts
         * @returns GroupedContentfulProduct[]
         */
        this.getNewUpsellContentfulProducts = (purchasedProducts, notPurchasedProducts, upsellProducts) => __awaiter(this, void 0, void 0, function* () {
            const allUpsellProductFrequencies = _.uniqBy([...upsellProducts, ...purchasedProducts, ...notPurchasedProducts], 'id');
            const allUpsellProducts = (yield this.getRecurringProductsForV2(allUpsellProductFrequencies)).flat();
            const upsellProductsContentfulIds = (yield this.getUpsellContentfulProducts()).map((ucp) => ucp.sys.id);
            return allUpsellProducts
                .filter((aup) => {
                const isNotPurchased = aup.alloyProduct.parent.some((p) => !purchasedProducts.map((pp) => pp.productId).includes(p.productId));
                const isUpsellContentfulProduct = aup.contentfulProduct
                    ? upsellProductsContentfulIds.includes(aup.contentfulProduct.sys.id)
                    : false;
                return isNotPurchased && isUpsellContentfulProduct;
            })
                .sort((a, b) => a.contentfulProduct.fields.order - b.contentfulProduct.fields.order);
        });
        this.contentfulProducts = fetcher.fetchContentfulProducts();
    }
    /**
     * Given one deep product, we check if it's a switchable products or not
     * (meaning: if it has another product with the same product id but different dosages AND/OR form factors)
     *
     * todo: maybe we can move this to product registry common and use in the BE code too
     *
     * @param deepProduct
     * @returns
     */
    isSwitchableProduct(deepProduct) {
        return __awaiter(this, void 0, void 0, function* () {
            const allProducts = yield this.alloyProducts;
            return allProducts.some((ap) => ap.productId === deepProduct.productId &&
                (ap.dosageId !== deepProduct.dosageId || ap.formFactorId !== deepProduct.formFactorId));
        });
    }
    /**
     * fetch all the switchable products we currently have in our database
     *
     * todo: maybe we can move this to product registry common and use in the BE code too
     *
     * @returns
     */
    getSwitchableProducts() {
        return __awaiter(this, void 0, void 0, function* () {
            const allProducts = yield this.alloyProducts;
            const switchable = allProducts.filter((p) => {
                if (!!allProducts.some((dp) => dp.productId === p.productId &&
                    (p.dosageId !== dp.dosageId || p.formFactorId !== dp.formFactorId))) {
                    return p;
                }
            });
            return switchable;
        });
    }
    /**
     * Given a GroupedProduct, this function find its ContentfulProduct equivalent,
     * if the product is non splitabble.
     *
     * @param contentfulProducts
     * @param alloyProduct
     * @returns ContentfulProduct
     */
    getNonSplittableContentfulProduct(contentfulProducts, alloyProduct) {
        return contentfulProducts
            .filter((cp) => cp.fields.groupingProductIds)
            .find((cp) => {
            var _a, _b;
            const grouped = cp.fields.groupingProductIds;
            return (((_a = grouped['applicableMhtParent']) === null || _a === void 0 ? void 0 : _a.some((id) => alloyProduct.parent.map((pf) => pf.productId).includes(id))) &&
                ((_b = grouped['applicableMhtChild']) === null || _b === void 0 ? void 0 : _b.some((id) => (alloyProduct.child || []).map((pf) => pf.productId).includes(id))) &&
                alloyProduct.parent.some((pf) => pf.formFactorId === grouped['formFactorId']));
        });
    }
    /**
     * Given a GroupedContentfultProduct 2D array,
     * this function returns the same array
     * but ordered by its Contentful order.
     *
     * @param groupedContentfulProducts
     * @returns GroupedContentfulProduct[][]
     */
    sortGroupedContentfulProducts(groupedContentfulProducts) {
        return groupedContentfulProducts
            .map((gcpList) => gcpList.sort((a, b) => a.contentfulProduct.fields.order - b.contentfulProduct.fields.order))
            .sort((a, b) => a[0].contentfulProduct.fields.order - b[0].contentfulProduct.fields.order);
    }
    /**
     * Given a list of productIds, it returns their equivalent GroupedContentfulProduct array.
     * The selected product frequencies will be determined by their prices,
     * which may vary if a product is part of a bundle.
     * If any of the products are present in a bundle that can't be split, they will be together
     * in a single GroupedContentfulProduct with children.
     *
     * @param productIds
     * @returns Promise<GroupedContentfulProduct[]>
     */
    getRecurringProductsForV2(productFrequencies) {
        return __awaiter(this, void 0, void 0, function* () {
            const [bundles, contentfulProducts, prices] = yield Promise.all([
                this.discountBundles,
                this.contentfulProducts,
                this.getPricesFor(productFrequencies),
            ]);
            const groupedContentfulProducts = prices.flatMap((bundle) => {
                const [child, parent] = partition(bundle, (b) => bundles[b.productId]);
                const isBundleSplittableByParents = this.isBundleSplittableByParents(bundle) && parent.length && child.length;
                if (isBundleSplittableByParents) {
                    return parent.map((pf) => {
                        const alloyProduct = { parent: [pf], child };
                        const contentfulProduct = this.getNonSplittableContentfulProduct(contentfulProducts, alloyProduct);
                        return [{ alloyProduct, contentfulProduct }];
                    });
                }
                return [
                    bundle.map((pf) => ({
                        alloyProduct: { parent: [pf] },
                        contentfulProduct: contentfulProducts.find((cp) => cp.sys.id === pf.contentfulProductId),
                    })),
                ];
            });
            return this.sortGroupedContentfulProducts(groupedContentfulProducts);
        });
    }
    static getInstance(fetcher) {
        if (!DomProductRegistry.domInstance) {
            DomProductRegistry.domInstance = new DomProductRegistry(fetcher);
        }
        return DomProductRegistry.domInstance;
    }
}
/**
 * Use this primarily, because it's way easier to mock with sinon
 */
const get = () => DomProductRegistry.getInstance({
    fetch: () => __awaiter(void 0, void 0, void 0, function* () { return yield getAllProductFrequencies(); }),
    fetchShippingMethods: () => __awaiter(void 0, void 0, void 0, function* () { return (yield getAllShippingMethods()).map(shippingMethodTranslator); }),
    fetchContentfulProducts: () => __awaiter(void 0, void 0, void 0, function* () {
        return yield getContentfulProducts();
    }),
    fetchDiscountBundles: () => __awaiter(void 0, void 0, void 0, function* () {
        return yield getDiscountBundles();
    }),
});
export default { get };
