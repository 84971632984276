import './ProductsList.scss';

import { Skeleton } from '@mui/material';
import classNames from 'classnames';

import {
  DeepProduct,
  ProductFrequencyRenewal,
  SubscriptionWithRenewal,
  useGetAllSubscriptionsForCustomer,
} from 'client/dist/generated/alloy';

import { getDeepProductIdsFrom, getPriceForCombinedProducts } from 'modules/shared/lib/product';
import { getProductNameWithDosage } from 'modules/shared/lib/product/name';

import alloyProductFiller from 'shared/assets/images/alloy-product-filler.png';

import useGroupedProducts from 'shared/hooks/product/useGroupedProducts';

import { convertCentsToDollars } from 'shared/lib/convert';

import ProductListCardBlock from '../blocks/ProductListCard';

interface Props {
  subscriptions?: SubscriptionWithRenewal[];
  productsWithRenewal: ProductFrequencyRenewal[];
  showDosage?: boolean;
  shouldGroup?: boolean;
  onEdit?: (productIds: DeepProduct['id'][]) => void;
}

export default function ProductsListWrapper({
  subscriptions = [],
  productsWithRenewal,
  showDosage = false,
  shouldGroup = true,
  onEdit,
}: Props) {
  const { isLoading: isLoadingSubs } = useGetAllSubscriptionsForCustomer();
  const { products, isLoading } = useGroupedProducts({
    deepProducts: productsWithRenewal.map((pfr) => pfr.product),
    dependencies: [isLoadingSubs, JSON.stringify(subscriptions)],
  });

  const disableList =
    !!subscriptions.length && subscriptions.some((sub) => sub.status !== 'ACTIVE');

  if (isLoading) {
    return (
      <div className='products-list-wrapper'>
        <div className='product-block'>
          <div className='product-img-wrapper'>
            <Skeleton variant='rectangular' width={100} height={100} />
          </div>

          <div className='product-content-wrapper'>
            <Skeleton variant='text' width={150} />

            <Skeleton variant='text' width={100} />
          </div>
        </div>
      </div>
    );
  }

  if (!shouldGroup) {
    return (
      <div className={classNames('products-list-wrapper', disableList && 'disable')}>
        {productsWithRenewal.map((pfr, index) => {
          const prescription = pfr.prescription;

          const foundSubscription = subscriptions.find((sub) =>
            sub.products.find((p) => pfr.product.id === p.product.id),
          );

          const prescriptionActiveSub = !!prescription && foundSubscription?.status === 'ACTIVE';

          return (
            <div className='product-block' key={index}>
              <div className='product-img-content-wrapper'>
                <div className='product-img-wrapper'>
                  <img src={alloyProductFiller} alt={pfr.product.name} className='product-img' />
                </div>

                <div className='product-content-wrapper'>
                  <p className='content-title'>{pfr.product.name}</p>

                  <p className='content-text'>${convertCentsToDollars(pfr.product.priceInCents)}</p>

                  {prescriptionActiveSub && (
                    <p className='content-text'>
                      {prescription.fillsRemaining} refill
                      {prescription.fillsRemaining !== 1 && 's'} left
                    </p>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={classNames('products-list-wrapper', disableList && 'disable')}>
      {products.map((gcpList, listIndex) =>
        gcpList.map((gcp, index) => (
          <ProductListCardBlock
            key={`${listIndex}-${index}`}
            product={gcp}
            parent={gcpList.length > 1 ? gcpList[gcpList.length - index - 1] : undefined}
            showDosage={showDosage}
            onEdit={onEdit}
          />
        )),
      )}
    </div>
  );
}
