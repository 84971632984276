import DashboardContainer from 'modules/dashboard/ui/containers/DashboardContainer';

import OrderConfirmationInfoWrapper from 'modules/checkout-experience/sub-modules/order-confirmation/ui/wrappers/OrderConfirmationInfo';

export default function OrderConfirmation() {
  return (
    <DashboardContainer title='Order Confirmation | Alloy' desc=''>
      <OrderConfirmationInfoWrapper />
    </DashboardContainer>
  );
}
