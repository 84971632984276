import DashboardContainer from 'modules/dashboard/ui/containers/DashboardContainer';

import OrderHistoryListWrapper from 'modules/dashboard/sub-modules/account/sub-modules/order-history/ui/wrappers/OrderHistoryList';
import AccountContainer from 'modules/dashboard/sub-modules/account/ui/containers/AccountContainer';

import useMarkNotificationsAsRead from 'shared/hooks/useMarkNotificationsAsRead';

export default function OrderHistory() {
  // mark notifications as read once on everytime going to page OR reloading
  useMarkNotificationsAsRead(['ORDER_SHIPPED']);

  return (
    <DashboardContainer title='Order History | Alloy' desc='' currentPage='account'>
      <AccountContainer title='Your Account' currentPage='order history'>
        <OrderHistoryListWrapper />
      </AccountContainer>
    </DashboardContainer>
  );
}
