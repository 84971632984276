import { Skeleton } from '@mui/material';
import { useState } from 'react';

import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import drMennImg from 'modules/shared/assets/images/dr-menn.png';

import useCart from 'modules/shared/hooks/useCart';
import useFlowProducts from 'modules/shared/sub-modules/checkout/hooks/useFlowProducts';

import { cleanGroupedPurchasableProducts } from 'modules/request-experience/lib/flow';
import { getDeepProductsFromGroupedProducts } from 'modules/shared/lib/product';
import { sortProducts } from 'modules/shared/lib/product/sort';
import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';
import { isConsultCart } from 'modules/shared/sub-modules/checkout/lib/cart';

import DoctorConsultViewableProduct from 'modules/shared/ui/blocks/DoctorConsultViewableProduct';
import ListViewableProducts from 'modules/shared/ui/blocks/ListViewableProducts';

import EditProductsDrawer from 'modules/shared/ui/drawers/EditProductsDrawer';

export default function CartWrapper() {
  const { cart, updateCart } = useCart();

  const [isEditing, setIsEditing] = useState(false);

  const isConsultCheckout = isConsultCart(cart);

  const { data: subscriptions = [], isLoading: isLoadingSubscriptions } =
    useGetAllSubscriptionsForCustomer();

  const { flowProducts, shouldShowEdit, isLoading: isFlowLoading } = useFlowProducts();

  const isLoading = isLoadingSubscriptions || isFlowLoading;

  const onSave = async (gcps: GroupedContentfulProduct[][]) => {
    const products = getDeepProductsFromGroupedProducts(gcps.flat());
    const { activeSubs, pausedSubs } = getSubscriptionsWithStatus(subscriptions);

    const subscribedProducts = [...activeSubs, ...pausedSubs].flatMap((sub) =>
      sub.products.map((p) => p.product),
    );

    const cleanedProducts = await cleanGroupedPurchasableProducts(products, subscribedProducts);

    updateCart({ products: cleanedProducts.flat() });

    setIsEditing(false);
  };

  if (isLoading) {
    return (
      <div className='mb-3'>
        <Skeleton variant='rectangular' height={150} />
      </div>
    );
  }

  if (isConsultCheckout) {
    return (
      <DoctorConsultViewableProduct
        topImg={drMennImg}
        title='Doctor Consultation'
        subtitle='$49.00 (1-time cost)'
      />
    );
  }

  return (
    <div>
      {shouldShowEdit && !isFlowLoading && (
        <div className='text-left text-md-right mb-3'>
          <button className='secondary-border-button' onClick={() => setIsEditing(true)}>
            Edit cart
          </button>
        </div>
      )}

      <ListViewableProducts
        products={[sortProducts(cart.products)]}
        subscriptions={subscriptions}
        hideDetails
      />

      {shouldShowEdit && (
        <EditProductsDrawer
          open={isEditing}
          setOpen={setIsEditing}
          onSave={onSave}
          productsWithType={{ specific: flowProducts }}
          prevSelectedProducts={sortProducts(cart.products)}
        />
      )}
    </div>
  );
}
