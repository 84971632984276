import { bindActionCreators } from '@reduxjs/toolkit';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  IntakeCategory,
  useGenerateSetupIntent,
  useGetCustomerReferralCreditBalance,
  useGetPaymentMethods,
} from 'client/dist/generated/alloy';

import { useExperience } from 'modules/shared/context/experience';

import { subscribeNewsletter } from 'modules/checkout-experience/sub-modules/checkout/lib/subscribe-newsletter';
import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';
import { getCheckoutType, isConsultCart } from 'modules/shared/sub-modules/checkout/lib/cart';
import { posthogCapture } from 'modules/tracking/lib/posthog';
import sendAbandonEvent from 'modules/tracking/lib/sendAbandonEvent';

import { processCheckout } from 'modules/shared/sub-modules/checkout/store/actions';

import { useAppSelector } from 'shared/store/reducers';

export default function useCheckoutState() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { onBack: rootOnBack } = useExperience();

  const { cart } = useAppSelector((state) => state.experience);
  const customer = useAppSelector((state) => state.alloy.customer!);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkoutStep, setCheckoutStep] = useState<'shipping' | 'payment'>('shipping');

  const { isLoading: isLoadingReferralCredits } = useGetCustomerReferralCreditBalance();
  const { isLoading: isLoadingPaymentMethods } = useGetPaymentMethods();
  const { data: setupIntent, isLoading: isLoadingSetupIntent } = useGenerateSetupIntent();

  const isAnyLoading =
    isLoading || isLoadingReferralCredits || isLoadingPaymentMethods || isLoadingSetupIntent;

  const dispatchProcessCheckout = bindActionCreators(processCheckout, dispatch);

  const onBack = isAnyLoading
    ? undefined
    : checkoutStep === 'payment'
      ? () => setCheckoutStep('shipping')
      : rootOnBack;

  const onNextStep = () => setCheckoutStep(checkoutStep === 'shipping' ? 'payment' : 'shipping');

  const onPlaceOrder = async () => {
    window.scrollTo(0, 0);

    try {
      setIsLoading(true);

      const categories = retrieveCategoriesFromUrl(location);

      const filteredIntakeCategories = categories.filter(
        (c) => c !== 'gut-health',
      ) as IntakeCategory[];

      const isConsult = isConsultCart(cart);
      const isOtcOnly = getCheckoutType(cart, filteredIntakeCategories) === 'OTC';

      await dispatchProcessCheckout(categories, 'checkout');

      sendAbandonEvent({
        event: 'CHECKOUT_COMPLETED',
        categories,
        experience: 'checkout',
      });

      subscribeNewsletter(customer.email, 'opted_in');

      const searchParams = new URLSearchParams(location.search);

      if (isOtcOnly) {
        searchParams.append('otc-checkout', 'true');
      } else if (isConsult) {
        searchParams.append('consult-checkout', 'true');
      }

      posthogCapture('purchase', categories);

      navigate({
        pathname: '/order-confirmation',
        search: `?${searchParams.toString()}`,
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  return { isAnyLoading, setupIntent, checkoutStep, onBack, onNextStep, onPlaceOrder };
}
