import { useLocation } from 'react-router-dom';

import { getRelevantFact } from 'modules/ab-tests/sub-modules/checkout-review/lib/relevant-fact';
import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';

import TPReviewBlock from 'modules/ab-tests/sub-modules/checkout-review/ui/blocks/TPReview';

import ImprovementBlock from 'modules/checkout-experience/ui/blocks/Improvement';

interface Props {
  reviewPage?: boolean;
}

export default function StatReviewWrapper({ reviewPage }: Props) {
  const location = useLocation();
  const categories = retrieveCategoriesFromUrl(location);

  // which widget is most relevant to the user's category and place in the checkout flow
  const showBoth = categories.includes('mht');
  const showStatOnly = reviewPage && categories.includes('hair-health');

  // what wording should we use in the widget(s), based on category and page
  const isStat = reviewPage || categories.includes('hair-health') || categories.includes('mht');
  const relevantFact = getRelevantFact({
    categories: categories,
    isStat
  });

  if (showStatOnly) {
    return <ImprovementBlock title={relevantFact.title} text={relevantFact.content} />;
  }

  return (
    <>
      {showBoth && <ImprovementBlock title={relevantFact.title} text={relevantFact.content} />}
      <TPReviewBlock />
    </>
  );
}
