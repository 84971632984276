import { upperCase } from 'lodash';

import { useCancelContext } from 'modules/dashboard/sub-modules/manage-subscription/context/cancel';
import { useProductContext } from 'modules/dashboard/sub-modules/manage-subscription/context/product';

import { formatProductNames } from 'modules/shared/lib/product/name';

import { EmailUs } from 'shared/components/content/Buttons';

import { useQueryParams } from 'shared/context/url/query';

import SubscriberBenefitsBlock from '../content/cancel/SubscriberBenefits';

const ConfirmationContent = () => {
  const { reasonType } = useCancelContext();
  const { product } = useProductContext();
  const { getParam } = useQueryParams();

  const outcome = upperCase(getParam('outcome') || '');

  if (!outcome) return <></>;

  switch (outcome) {
    case 'INVOICE':
      return (
        <>
          <h4 className='content-title'>Your invoice has been downloaded.</h4>

          <p className='content-text'>
            It can be submitted to your insurance company as a claim. Reach out to{' '}
            <EmailUs btnClass='text-underline' /> if you have any questions.
          </p>
        </>
      );

    case 'CANCEL':
      if (!!reasonType && ['BAD_EXPERIENCE', 'OTHER'].includes(reasonType)) {
        return (
          <>
            <h4 className='content-title'>Thank you for sharing your experience.</h4>

            <p className='content-text'>
              You have successfully canceled your subscription. We're sad to see you go! If you ever
              change your mind, just log back into your account and resume your subscription to{' '}
              {formatProductNames([product])}.
            </p>
          </>
        );
      } else {
        return (
          <>
            <h4 className='content-title'>You've successfully canceled your subscription.</h4>

            <p className='content-text'>
              We're sad to see you go! If you ever change your mind, just log back into your account
              and resume your subscription to {formatProductNames([product])}.
            </p>
          </>
        );
      }

    case 'RETENTION':
      return (
        <>
          <h4 className='content-title'>
            We've paused your subscription and shared your responses with your doctor.
          </h4>

          <p className='content-text'>
            Your doctor will be in touch with you soon to suggest next steps and options.
          </p>
        </>
      );

    case 'KEEP':
      return (
        <>
          <h4 className='content-title'>You've made a great choice!</h4>

          <p className='content-text'>
            We're so glad you're a part of the Alloy community, where free, unlimited messaging with
            your doctor is included in your subscription.
          </p>

          <SubscriberBenefitsBlock />
        </>
      );
  }

  return <></>;
};

export default function ConfirmationBlock() {
  return (
    <div className='cancel-confirmation-block'>
      <div className='content-block'>
        <ConfirmationContent />
      </div>
    </div>
  );
}
