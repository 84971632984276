import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useExperience } from 'modules/shared/context/experience';

import { fadeQuestions } from 'modules/shared/lib/fade-scroll';

import { ProductPreference } from 'modules/ab-tests/sub-modules/known-preferences/models/preferences';

import { updateLocalPreCustomer } from 'modules/shared/store/local-pre-customer';

import { useAppSelector } from 'shared/store/reducers';

export default function useKnownPreferencesState() {
  const dispatch = useDispatch();
  const { onNext } = useExperience();

  const dispatchUpdateLocalPreCustomer = bindActionCreators(updateLocalPreCustomer, dispatch);
  const {
    localPreCustomer: { productPreferences },
  } = useAppSelector((state) => state.experience);

  const [selectedId, setSelectedId] = useState<ProductPreference | undefined>(productPreferences);
  const selectionWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fadeQuestions('fade-in-wrapper', selectionWrapper);
  }, []);

  const onChange = (ids: string[]) => {
    if (!ids.length) {
      setSelectedId(undefined);
      dispatchUpdateLocalPreCustomer({
        productPreferences: undefined,
      });
      return;
    }
    const singleId: string = ids[ids.length - 1];
    setSelectedId(singleId as ProductPreference);
    fadeQuestions('fade-out-wrapper', selectionWrapper);

    dispatchUpdateLocalPreCustomer({
      productPreferences: singleId as ProductPreference,
    });

    setTimeout(() => onNext(), 400);
  };

  const onSubmit = () => {
    dispatchUpdateLocalPreCustomer({
      productPreferences: selectedId,
    });

    onNext();
  };

  return {
    selectionWrapper,

    selectedId,

    onChange,
    onSubmit,
  };
}
