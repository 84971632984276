import {
  M4_EYE_SERUM_PRODUCT_ID,
  M4_FACE_SERUM_PRODUCT_ID,
  M4_PRODUCT_ID,
  OMAZING_PRODUCT_ID,
  ORAL_MINOXIDIL_ID,
  SYNBIOTIC_PRODUCT_ID,
  TRETINOIN_ID,
  VAGINAL_PRODUCT_ID,
} from 'common/dist/products/productRegistry';
import hairHealthQuestionnaire from 'common/dist/questionnaire/hair-health.json';
import mhtQuestionnaire from 'common/dist/questionnaire/mht.json';
import sexualHealthQuestionnaire from 'common/dist/questionnaire/sexual-health.json';
import { SKIN_QUESTIONNAIRE } from 'common/dist/questionnaire/skin-health';
import vaginalHealthQuestionnaire from 'common/dist/questionnaire/vaginal-health.json';

import { ExperienceValidationKey } from 'modules/checkout-experience/lib/validation';

import { ExperienceFlowStep, ExperienceFlowsDict } from 'modules/shared/models/experience';

import CheckoutPage from 'pages/request-experience/Checkout';
import Intake from 'pages/request-experience/Intake';
import Review from 'pages/shared/Review';
import UploadVideoPage from 'pages/shared/UploadVideo';
import VerifyIdentityPage from 'pages/shared/VerifyIdentity';

export const mhtSteps: ExperienceFlowStep[] = [
  {
    validationKey: ExperienceValidationKey.intake,
    component: Intake,
    path: 'intake',
    routeType: 'PRIVATE',
    hasNested: true,
  },
];

export const requestSteps: ExperienceFlowStep[] = [
  {
    validationKey: ExperienceValidationKey.intake,
    component: Intake,
    path: 'intake',
    routeType: 'PRIVATE',
    hasNested: true,
  },
  {
    validationKey: ExperienceValidationKey.uploadId,
    component: VerifyIdentityPage,
    path: 'verify-identity',
    routeType: 'PRIVATE',
  },
  {
    validationKey: ExperienceValidationKey.uploadVideo,
    component: UploadVideoPage,
    path: 'upload-video',
    routeType: 'PRIVATE',
  },
  {
    validationKey: ExperienceValidationKey.checkout,
    component: CheckoutPage,
    path: 'checkout',
    routeType: 'PRIVATE',
  },
];

export const requestStepsWithReview: ExperienceFlowStep[] = [
  {
    validationKey: ExperienceValidationKey.intake,
    component: Intake,
    path: 'intake',
    routeType: 'PRIVATE',
    hasNested: true,
  },
  {
    validationKey: ExperienceValidationKey.uploadId,
    component: VerifyIdentityPage,
    path: 'verify-identity',
    routeType: 'PRIVATE',
  },
  {
    validationKey: ExperienceValidationKey.uploadVideo,
    component: UploadVideoPage,
    path: 'upload-video',
    routeType: 'PRIVATE',
  },
  {
    validationKey: ExperienceValidationKey.review,
    component: Review,
    path: 'review',
    routeType: 'PRIVATE',
  },
  {
    validationKey: ExperienceValidationKey.checkout,
    component: CheckoutPage,
    path: 'checkout',
    routeType: 'PRIVATE',
  },
];

export const requestFlows: ExperienceFlowsDict = {
  mht: {
    intakeQuestions: mhtQuestionnaire.questions,
    steps: mhtSteps,
    productIds: [],
  },
  'sexual-health': {
    intakeQuestions: sexualHealthQuestionnaire.questions,
    steps: requestStepsWithReview,
    productIds: [OMAZING_PRODUCT_ID],
  },
  'skin-health': {
    intakeQuestions: SKIN_QUESTIONNAIRE.questions,
    steps: requestStepsWithReview,
    productIds:
      process.env.REACT_APP_SHOW_NEW_SKINCARE === 'true'
        ? [M4_PRODUCT_ID, TRETINOIN_ID, M4_FACE_SERUM_PRODUCT_ID, M4_EYE_SERUM_PRODUCT_ID]
        : [M4_PRODUCT_ID, TRETINOIN_ID],
  },
  'gut-health': {
    steps: requestSteps,
    productIds: [SYNBIOTIC_PRODUCT_ID],
  },
  'vaginal-health': {
    intakeQuestions: vaginalHealthQuestionnaire.questions,
    steps: requestStepsWithReview,
    productIds: [VAGINAL_PRODUCT_ID],
  },
  'hair-health': {
    intakeQuestions: hairHealthQuestionnaire.questions,
    steps: requestSteps,
    productIds: [ORAL_MINOXIDIL_ID],
  },
};
