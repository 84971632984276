import './HowItWorks.scss';

import classNames from 'classnames';

import { HowItWorksItem } from '../../models/how-it-works';

interface Props {
  data: HowItWorksItem[];
  showInfoBG?: boolean;
}

export default function HowItWorksBlock({ data, showInfoBG = true }: Props) {
  return (
    <div className='how-it-works-block'>
      {data.map((item, index) => {
        const isStart = index === 0;
        const isEnd = index === data.length - 1;

        return (
          <div className='hiw-container-wrapper' key={index}>
            <div className='hiw-progress-wrapper'>
              <p className='progress-tag'>{index + 1}</p>

              {(isStart || !isEnd) && <div className='progress-line' />}
            </div>

            <div className={classNames(showInfoBG && 'info-bg', 'hiw-info-wrapper')}>
              <p className='info-title'>{item.title}</p>

              {item.image && <img src={item.image} alt={item.title} className='info-img' />}

              <p className='info-text'>{item.text}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
