import { knownPreferenceSelections } from 'modules/ab-tests/sub-modules/treatment-interests/data/treatment-interests';

import CheckoutExperienceSection from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceSection';

import Form from 'shared/components/core/Form';
import { SelectFieldsGroup } from 'shared/components/fields/SelectFieldsGroup';
import BottomBar from 'shared/components/wrappers/BottomBar';

import useKnownPreferencesState from '../../hooks/useKnownPreferencesState';

export default function KnownPreferencesFormWrapper() {
  const {
    selectionWrapper,

    selectedId,

    onChange,
    onSubmit,
  } = useKnownPreferencesState();

  return (
    <Form onSubmit={onSubmit}>
      <div className='questionnaire-wrapper' ref={selectionWrapper}>
        <CheckoutExperienceSection>
          <h1 className='content-title'>
            Do you have any specific treatments you are interested in?
          </h1>

          <SelectFieldsGroup
            selectedIds={[selectedId] as string[]}
            onChange={onChange}
            options={knownPreferenceSelections}
            isSingleSelect
          />
        </CheckoutExperienceSection>
      </div>

      <BottomBar>
        <button className='primary-button' type='submit' disabled={selectedId === undefined}>
          Next
        </button>
      </BottomBar>
    </Form>
  );
}
