import { useEffect, useState } from 'react';

import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { isProductSwitchable } from 'modules/shared/lib/product/switch';

interface Props {
  product: GroupedContentfulProduct;
}

interface ReturnsProps {
  isSwitchable: boolean;
}

/**
 * hook allows to fetch whether a product is switchable and makes it really easy to call in any component
 * that has a product. this makes it better to not have to rewrite all the fetching at the top of the component
 * and moving business/functional logic out of components
 *
 * @param Props
 * @returns ReturnProps
 */
export default function useSwitchable({ product }: Props): ReturnsProps {
  const [isSwitchable, setIsSwitchable] = useState(false);

  useEffect(() => {
    const getIsSwitchable = async () => {
      const isSwitchable = await isProductSwitchable(product);

      setIsSwitchable(isSwitchable);
    };

    getIsSwitchable();
  }, []);

  return {
    isSwitchable
  };
}
