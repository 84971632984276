import './RequestDrawer.scss';

import classNames from 'classnames';
import { first, uniq } from 'lodash';
import { useEffect, useState } from 'react';

import {
  DeepProduct,
  SubscriptionWithRenewal,
  createDosageSwitchReason,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import {
  ProductFrequenciesWithHasPrescription,
  getSwitchableFormFactorProducts,
} from 'modules/request-experience/lib/switch';
import { getDeepProductIdsFrom } from 'modules/shared/lib/product';
import {
  getProductsWithAltDoses,
  getProductsWithAltFormFactors,
} from 'modules/shared/lib/product/switch';

import { ProductRenewalPrescription } from 'modules/dashboard/sub-modules/treatment-plan/models/product-renewal-prescription';

import Loader from 'shared/components/content/Loader';
import AlloyDrawer from 'shared/components/core/AlloyDrawer';

import ConfirmChangeDrawer from './ConfirmChangeDrawer';

import { DoseForm } from '../../models/dose-form';
import { ProductSwitchChangeType } from '../../models/product-switch-type';
import DosageWrapper from '../wrappers/Dosage';
import HeaderWrapper from '../wrappers/Header';
import TreatmentWrapper from '../wrappers/Treatment';

interface Props {
  open: boolean;
  onClose: () => void;
  product: ProductRenewalPrescription;
  subscription: SubscriptionWithRenewal;
}

export default function RequestDrawer({ open, onClose, product, subscription }: Props) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [dosageSwitchReasonId, setDosageSwitchReasonId] = useState<number | undefined>(undefined);

  const [selectedType, setSelectedType] = useState<ProductSwitchChangeType | undefined>(undefined);

  const INIT_FORM: DoseForm = {
    reason: '',
    agreedTerms: false,
  };

  const [altDoseProducts, setAltDoseProducts] = useState<DeepProduct[]>([]);
  const [altFormFactorProducts, setAltFormFactorProducts] = useState<DeepProduct[]>([]);

  const [doseForm, setDoseForm] = useState<DoseForm>(INIT_FORM);

  const [products, setProducts] = useState<ProductFrequenciesWithHasPrescription[]>([]);
  const [groupedProducts, setGroupedProducts] = useState<GroupedContentfulProduct[]>([]);

  const [selectedProduct, setSelectedProduct] = useState<GroupedContentfulProduct>({
    alloyProduct: product.alloyProduct,
    contentfulProduct: product.contentfulProduct,
  });

  const { data: treatmentPlan, isLoading: isLoadingTreatmentPlan } = useGetTreatmentPlan();

  const hasAltDoses = altDoseProducts.length > 0;
  const hasAltFormFactors = altFormFactorProducts.length > 0;

  const onlyDosageSwitchAvailable = hasAltDoses && !hasAltFormFactors;
  const onlyFormFactorSwitchAvailable = hasAltFormFactors && !hasAltDoses;

  useEffect(() => {
    // retrieve all the data needed for displaying alt doses and form factors
    const getData = async () => {
      const [altDoses, altFormFactors] = await Promise.all([
        getProductsWithAltDoses(product),
        getProductsWithAltFormFactors(product),
      ]);

      if (open && !isLoadingTreatmentPlan) {
        const activePrescriptions = treatmentPlan?.prescriptionProducts ?? [];

        // todo: confirm this still works if we have a child with multiple form factors in the future
        const parentPf = first(product.alloyProduct.parent)!;
        const childrenPfs = product.alloyProduct.child ?? [];

        const switchableProducts = await ProductRegistry.get().getSwitchableProducts();

        const filteredProducts = await getSwitchableFormFactorProducts(
          parentPf,
          childrenPfs,
          activePrescriptions,
          uniq(switchableProducts.map((dp) => dp.productId)).filter((id) =>
            altFormFactors.map((p) => p.productId).includes(id),
          ),
        );

        const groupedContentful = (
          await ProductRegistry.get().getRecurringProductsForV2(
            filteredProducts.flatMap((fmp) => fmp.productFrequencies),
          )
        ).flat();

        setProducts(filteredProducts);
        setGroupedProducts(groupedContentful);
      }

      setAltDoseProducts(altDoses);
      setAltFormFactorProducts(altFormFactors);
    };

    getData();
  }, [open, isLoadingTreatmentPlan]);

  useEffect(() => {
    // in order to display the correct dose or form factor switch IF ONLY 1 is available,
    // we need to do on init / once stuff gotten
    // if the product has other dosages avaiable but NOT other form factors, it can only go for a dosage switch
    if (onlyDosageSwitchAvailable) {
      return setSelectedType('CUSTOMER_DOSAGE_CHANGE');
    }

    // if the product has other form factors avaiable but NOT other dosages, it can only go for a form factor switch
    if (onlyFormFactorSwitchAvailable) {
      return setSelectedType('CUSTOMER_FORM_FACTOR_CHANGE');
    }
  }, [altDoseProducts, altFormFactorProducts]);

  const hasPrescriptionFor = (pfId: number) =>
    products.some(
      (pfp) => pfp.productFrequencies.some((pf) => pf.id === pfId) && pfp.hasPrescription,
    );

  const onOpenConfirm = (gcp: GroupedContentfulProduct) => {
    setSelectedProduct(gcp);

    setIsConfirmOpen(true);
  };

  const onRequestDose = async () => {
    const currentPfIds = getDeepProductIdsFrom(product);

    const dosageSwitchReason = await createDosageSwitchReason({
      reason: doseForm.reason,
      currentPfIds,
    });

    setDosageSwitchReasonId(dosageSwitchReason.id);
    setIsConfirmOpen(true);
  };

  return (
    <>
      <AlloyDrawer
        title='Request a change'
        drawerClass='request-drawer'
        open={open}
        onClose={onClose}
        disableClosing={isLoadingTreatmentPlan}
      >
        {isLoadingTreatmentPlan ? (
          <Loader size='lg' />
        ) : (
          <>
            <div
              className={classNames(
                'drawer-body',
                selectedType === 'CUSTOMER_DOSAGE_CHANGE' && 'dosage',
              )}
            >
              <HeaderWrapper
                product={product}
                subscription={subscription}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                onlyDosageSwitch={onlyDosageSwitchAvailable}
                onlyFormFactorSwitch={onlyFormFactorSwitchAvailable}
              />

              <div className='drawer-divider' />

              {selectedType === 'CUSTOMER_FORM_FACTOR_CHANGE' && (
                <TreatmentWrapper
                  hasAltFormFactors={hasAltFormFactors}
                  groupedProducts={groupedProducts}
                  hasPrescriptionFor={hasPrescriptionFor}
                  onOpenConfirm={onOpenConfirm}
                />
              )}

              {selectedType === 'CUSTOMER_DOSAGE_CHANGE' && (
                <DosageWrapper
                  hasAltDoses={hasAltDoses}
                  doseForm={doseForm}
                  setDoseForm={setDoseForm}
                />
              )}
            </div>

            {selectedType === 'CUSTOMER_DOSAGE_CHANGE' && hasAltDoses && (
              <div className='drawer-footer'>
                <button
                  className='primary-button full-width-button'
                  onClick={onRequestDose}
                  disabled={doseForm.reason === '' || !doseForm.agreedTerms}
                >
                  Continue
                </button>
              </div>
            )}
          </>
        )}
      </AlloyDrawer>

      {selectedType && (
        <ConfirmChangeDrawer
          currentProduct={product}
          requestedProduct={selectedProduct}
          open={isConfirmOpen}
          setOpen={setIsConfirmOpen}
          selectedType={selectedType}
          hasPrescription={hasPrescriptionFor(first(selectedProduct.alloyProduct.parent)!.id)}
          subscription={subscription}
          dosageSwitchReasonId={dosageSwitchReasonId}
        />
      )}
    </>
  );
}
