import './Payment.scss';

import { Elements } from '@stripe/react-stripe-js';
import { useState } from 'react';

import { StripePaymentMethod } from 'client/dist/generated/alloy';

import PaymentDrawer from 'modules/shared/ui/drawers/PaymentDrawer';

import chevronDownIcon from 'shared/assets/svg/common/chevron-down.svg';

import CardIcon from 'shared/components/content/CardIcon';

import { stripePromise } from 'shared/lib/stripe-promise';

interface Props {
  paymentMethods: StripePaymentMethod[];
  stripeClientSecret: string;
}

/**
 * Component that handles showing the user their payments details, used within <OrderSummaryBlock />
 * Also has a drawer that can be opened to edit the users primary payment method
 */
export default function PaymentBlock({ paymentMethods, stripeClientSecret }: Props) {
  const defaultPaymentMethod = paymentMethods.find((pm) => pm.is_primary)!;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <div className='tp-payment-block' onClick={onOpen}>
        <div className='payment-content'>
          <p className='payment-title'>Payment Method</p>
          {defaultPaymentMethod ? (
            <p className='payment-text'>
              <CardIcon brand={defaultPaymentMethod.card!.brand} />
              •••• •••• •••• {defaultPaymentMethod.card!.last4}
            </p>
          ) : (
            <>
              <p className='payment-text'>No default payment method found.</p>
              <p className='payment-text'>Click here to add one.</p>
            </>
          )}
        </div>

        <div className=''>
          <img src={chevronDownIcon} alt='chevron right' className='payment-chevron' />
        </div>
      </div>

      <Elements stripe={stripePromise} options={{ clientSecret: stripeClientSecret }}>
        <PaymentDrawer open={isOpen} setOpen={setIsOpen} />
      </Elements>
    </>
  );
}
