import './Totals.scss';

import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Loader } from 'rsuite';

import useCart from 'modules/shared/hooks/useCart';
import useTotalProductPrices from 'modules/shared/hooks/useTotalProductPrices';

import { getGroupedCartTotals } from 'modules/shared/lib/cart';
import {
  buildDataForCalculation,
  calculateTaxes,
} from 'modules/shared/sub-modules/checkout/lib/tax';

import { updateLoadingTotals } from 'modules/dashboard/sub-modules/treatment-plan/sub-modules/checkout/store/actions';

import TotalPriceContent from 'modules/dashboard/ui/content/TotalPrice';

import { useAppSelector } from 'shared/store/reducers';

interface Props {
  onPlaceOrder: () => void;
  showOrderButton?: boolean;
}

const TotalsFooter = ({
  isSynbioticOnly,
  isSingleSupplyOnly,
}: {
  isSynbioticOnly: boolean;
  isSingleSupplyOnly: boolean;
}) => {
  return (
    <p className='summary-text'>
      {!isSynbioticOnly && !isSingleSupplyOnly && (
        <>
          Your prescription is valid for 1 year. Your treatment plan will automatically ship every 3
          months until there are no remaining refills.
        </>
      )}

      {isSingleSupplyOnly && (
        <>
          Your prescription is valid for 3 months. You will need to reach out to your doctor once
          you've received a mammogram to receive a year long prescription and continue your
          treatment.
        </>
      )}
    </p>
  );
};

/**
 * Component that handles showing the totals and handling the place order button, used within <OrderSummaryBlock />
 */
export default function TotalsBlock({ onPlaceOrder, showOrderButton = false }: Props) {
  const dispatch = useDispatch();

  const { cart, updateCart } = useCart();

  const { isLoadingTotals } = useAppSelector((state) => state.treatmentPlan);

  const customer = useAppSelector((state) => state.alloy.customer!);

  const isSynbioticOnly = cart.products.every((p) =>
    [...p.alloyProduct.parent, ...(p.alloyProduct.child ?? [])].every(
      (pf) => pf.category === 'gut-health',
    ),
  );

  const isSingleSupplyOnly = cart.products.every((p) => {
    // don't need to check child since we don't have mixed recurrence between parent/child
    return p.alloyProduct.parent.every((pa) => pa.recurrenceType === 'ONE_TIME');
  });

  // Update the treatment plan cart

  const dispatchUpdateLoadingTotals = bindActionCreators(updateLoadingTotals, dispatch);

  useEffect(() => {
    getTax();
  }, [JSON.stringify(cart)]);

  const { total, discount, tax, shipping } = getGroupedCartTotals(cart);
  const { unbundledTotal: unbundledSubtotal, bundledTotal: bundledSubtotal } =
    useTotalProductPrices({
      products: cart.products.flatMap((gcp) => gcp.alloyProduct.parent),
      dependencies: [JSON.stringify(cart.products)],
    });

  /**
   * Update the carts tax anytime there is a change to the cart (product select, promo code, shipping, etc)
   */
  const getTax = async () => {
    if (cart.products.length !== 0) {
      dispatchUpdateLoadingTotals(true);

      const taxesShipping = buildDataForCalculation(
        discount,
        shipping,
        cart.products
          .flatMap((gcp) => gcp.alloyProduct.parent.concat(gcp.alloyProduct.child ?? []))
          .map((pf) => pf.priceInCents),
        customer,
      );

      const taxAmountToCollect = await calculateTaxes(taxesShipping);

      updateCart({
        taxAmountToCollect,
      });

      dispatchUpdateLoadingTotals(false);
    }
  };

  /**
   * If no products, then do not show anything
   */
  if (cart.products.length === 0) {
    return (
      <>
        <div className='tp-total-wrapper top-padding'>
          <p className='total-title'>Order Total</p>
          <p className='total-amount'>-</p>
        </div>

        <button
          className='primary-button full-width-button d-block d-md-none'
          onClick={onPlaceOrder}
          disabled
        >
          Place order
        </button>
      </>
    );
  }

  /**
   * If loading then show loader and disabled button
   */
  if (isLoadingTotals) {
    return (
      <>
        <div className='tp-total-wrapper with-loader'>
          <Loader size='md' />
        </div>

        {showOrderButton && (
          <button
            className='primary-button full-width-button d-none d-md-block'
            onClick={onPlaceOrder}
            disabled
          >
            Place order
          </button>
        )}

        <TotalsFooter isSynbioticOnly={isSynbioticOnly} isSingleSupplyOnly={isSingleSupplyOnly} />
      </>
    );
  }

  return (
    <>
      <div className='tp-total-summary-wrapper'>
        <div className='total-summary-row'>
          <p className='total-summary-title-bold'>
            Subtotal <span>(3-month supply)</span>
          </p>

          <TotalPriceContent unbundledTotal={unbundledSubtotal} bundledTotal={bundledSubtotal} />
        </div>

        <div className='total-summary-row'>
          <p className='total-summary-title'>
            Discounts{discount !== 0 ? ` (${cart.promotionCode.name})` : ''}
          </p>

          <p className='total-summary-content'>
            {discount !== 0 ? `-$${discount.toFixed(2)}` : '-'}
          </p>
        </div>

        <div className='total-summary-row'>
          <p className='total-summary-title'>Tax</p>

          <p className='total-summary-content-tax'>${tax.toFixed(2)}</p>
        </div>

        <div className='total-summary-row'>
          <p className='total-summary-title'>Shipping</p>

          <p className='total-summary-content'>
            {shipping === 0 ? 'FREE' : `$${shipping.toFixed(2)}`}
          </p>
        </div>
      </div>

      <div className='tp-total-wrapper'>
        <p className='total-title'>Order Total</p>

        <p className='total-amount'>${total.toFixed(2)}</p>
      </div>

      {showOrderButton && (
        <button
          className='primary-button full-width-button d-none d-md-block'
          onClick={onPlaceOrder}
          disabled={cart.products.length === 0}
        >
          Place order
        </button>
      )}

      <TotalsFooter isSynbioticOnly={isSynbioticOnly} isSingleSupplyOnly={isSingleSupplyOnly} />
    </>
  );
}
