import './HeaderProductsCard.scss';

import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

import {
  SubscriptionWithRenewal,
  useGetPendingSwitch,
  useIsProcessing,
} from 'client/dist/generated/alloy';
import DomProductRegistry from 'client/dist/product/productRegistry';

import useTotalProductPrices from 'modules/shared/hooks/useTotalProductPrices';

import { isSubscriptionProcessing } from 'modules/shared/lib/subscriptions';

import { convertCentsToDollars } from 'shared/lib/convert';

import ProductsListWrapper from '../../../../ui/wrappers/ProductsList';
import SubscriptionsAction from '../content/SubscriptionsAction';

interface Props {
  subscription: SubscriptionWithRenewal;
  setIsLoadingUpdates: (isLoading: boolean) => void;
}

export default function HeaderProductsCardBlock({ subscription, setIsLoadingUpdates }: Props) {
  const [switchableProductIds, setSwitchableProductIds] = useState<number[]>([]);

  const { data: isProcessing = false, isLoading: isLoadingProcessing } = useIsProcessing(
    subscription.stripeSubscriptionId,
  );
  const { data: pendingSwitch, isLoading: isLoadingPendingSwitch } = useGetPendingSwitch();

  const { unbundledTotal, bundledTotal } = useTotalProductPrices({
    products: subscription.products.map((pfr) => pfr.product),
  });

  const isBundled = subscription.status === 'ACTIVE' && unbundledTotal - bundledTotal !== 0;

  useEffect(() => {
    const fetchSwitchableProducts = async () => {
      const switchable = await DomProductRegistry.get().getSwitchableProducts();

      setSwitchableProductIds(switchable.map((sp) => sp.id));
    };

    fetchSwitchableProducts();
  }, []);

  const getTitle = () => {
    const status = subscription.status;

    switch (true) {
      case status === 'INACTIVE':
        return 'Cancelled';
      case status === 'PAUSED':
        return 'Paused';
      case isSubscriptionProcessing(
        isProcessing,
        subscription,
        switchableProductIds,
        pendingSwitch,
      ):
        return 'Processing';
      default:
        return `Processes ${format(subscription.nextRecurrenceOn, 'MM/dd')}`;
    }
  };

  return (
    <div className='header-products-card-block'>
      <div className='header-content-wrapper'>
        {isLoadingProcessing || isLoadingPendingSwitch ? (
          <Skeleton variant='rectangular' height={75} />
        ) : (
          <>
            <div className='content-block'>
              <p
                className={classNames(
                  'content-title',
                  subscription.status !== 'ACTIVE' && 'disable',
                )}
              >
                {getTitle()}
              </p>

              {!isSubscriptionProcessing(
                isProcessing,
                subscription,
                switchableProductIds,
                pendingSwitch,
              ) && (
                <div className='d-none d-md-block'>
                  <SubscriptionsAction
                    subscription={subscription}
                    setLoading={setIsLoadingUpdates}
                  />
                </div>
              )}
            </div>

            {!!subscription.upcomingInvoice && (
              <div className='content-block d-block d-md-flex mt-3'>
                <p className='content-text'>
                  <span className='text-bold'>Total</span> $
                  {convertCentsToDollars(subscription.upcomingInvoice.total)}
                </p>
              </div>
            )}

            {isBundled && (
              <span className='bundled-text-hug'>
                Saving ${(unbundledTotal - bundledTotal).toFixed(2)}
              </span>
            )}
          </>
        )}
      </div>

      <ProductsListWrapper
        subscriptions={[subscription]}
        productsWithRenewal={subscription.products}
        showDosage
      />

      {!isSubscriptionProcessing(
        isProcessing,
        subscription,
        switchableProductIds,
        pendingSwitch,
      ) && (
        <div className='d-block d-md-none'>
          <SubscriptionsAction subscription={subscription} setLoading={setIsLoadingUpdates} />
        </div>
      )}
    </div>
  );
}
