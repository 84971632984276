import posthog from 'posthog-js';

//  "KnownPreferences" CRO Test | testing allowing the user to skip the "add-on preferences" step of the checkout flow if
//  they do not already know what they want
export const showKnownPreferencesPage = () => {
  const knownPreferencesStatus = posthog.getFeatureFlag('known-preferences');
  console.log('Retrieved result of known-preferences is:', knownPreferencesStatus);

  return knownPreferencesStatus === 'test';
};
