import './BundledSavings.scss';

import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import useTotalProductPrices from 'modules/shared/hooks/useTotalProductPrices';

interface Props {
  products: GroupedContentfulProduct[][];
}

export default function BundledSavingsBlock({ products }: Props) {
  const { unbundledTotal, bundledTotal } = useTotalProductPrices({
    products: products.flatMap((gcpList) => gcpList.flatMap((gcp) => gcp.alloyProduct.parent)),
    dependencies: [JSON.stringify(products)],
  });

  const canBeDiscounted = unbundledTotal - bundledTotal !== 0;

  if (!canBeDiscounted) return <></>;

  return (
    <div className='bundled-savings-block'>
      <p className='bundled-savings-text'>
        * You are saving ${(unbundledTotal - bundledTotal).toFixed(2)}
      </p>
    </div>
  );
}
