import DashboardContainer from 'modules/dashboard/ui/containers/DashboardContainer';

import RequestConfirmationInfoWrapper from 'modules/request-experience/sub-modules/request-confirmation/ui/wrappers/RequestConfirmationInfo';

export default function RequestConfirmation() {
  return (
    <DashboardContainer title='Request Confirmation | Alloy' desc=''>
      <RequestConfirmationInfoWrapper />
    </DashboardContainer>
  );
}
