import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { handleNextPath } from 'modules/checkout-experience/lib/handle-path';

import { showErrorNotification } from 'shared/components/core/Notification';

import { resendVerificationCode } from 'shared/lib/aws/aws-auth';

import { LoginForm } from '../models/login-form';
import { login } from '../store/actions';

export default function useLoginState() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const INIT_FORM_VALUE = {
    email: '',
    password: '',
  };

  const [isLoading, setIsLoading] = useState(false);
  const [loginForm, setLoginForm] = useState<LoginForm>(INIT_FORM_VALUE);

  const dispatchLogin = bindActionCreators(login, dispatch);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const wasLoggedOut = searchParams.get('loggedOut');

    if (wasLoggedOut) {
      showErrorNotification("You've been logged out, please login again to continue");
    }
  }, [location.search]);

  // Submit the login and if needed to change password, submit the new password changed
  const onSubmit = async () => {
    window.scrollTo(0, 0);

    const { email, password } = loginForm;

    try {
      setIsLoading(true);

      /**
       * Reason for awaiting here is if any errors pop up in login then they will be added to the catch
       * even though await doesn't really have much of an effect except that...
       */
      await dispatchLogin(email, password, location);

      handleNextPath(navigate, location);
    } catch (error: any) {
      setIsLoading(false);

      if (
        error.message &&
        (error.message.includes('PreAuthentication failed with error request failed.') ||
          error.message.includes('Bad credentials'))
      ) {
        showErrorNotification('The provided email address or password are incorrect.');
      } else if (error.message && error.message.includes('Password reset required for the user')) {
        navigate(
          {
            pathname: '/reset',
            search: location.search,
          },
          {
            state: {
              email,
              resetReason: 'REQUIRED',
            },
          },
        );
      } else if (error.message && error.message.includes('User is not confirmed')) {
        setIsLoading(true);
        showErrorNotification('Account verification required. Verification code resent.');

        await resendVerificationCode(email);

        navigate(
          {
            pathname: '/standalone-verification',
            search: location.search,
          },
          {
            state: {
              email,
              password,
              context: 'UNCONFIRMED LOGIN',
            },
          },
        );

        setIsLoading(false);
      } else {
        showErrorNotification(error.message);
      }
    }
  };

  return {
    isLoading,

    loginForm,
    setLoginForm,

    onSubmit,
  };
}
