import React from 'react';

interface EmailUsModel {
  btnClass?: string;
}

export const EmailUs: React.FC<EmailUsModel> = ({ btnClass = '' }) => {
  return (
    <a className={btnClass} href='mailto:support@myalloy.com'>
      support@myalloy.com
    </a>
  );
};
