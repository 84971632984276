import './Improvement.scss';

interface Props {
  tag?: string;
  title: string;
  text: string;
}

export default function ImprovementBlock({ tag, title, text }: Props) {
  return (
    <div className='improvement-block'>
      {tag && <p className='improvement-tag'>{tag}</p>}

      <p className='improvement-title'>{title}</p>
      <p className='improvement-text'>{text}</p>
    </div>
  );
}
