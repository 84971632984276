import { Dispatch } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';

import { brazeLogout } from 'modules/tracking/lib/braze';
import { posthogLogout } from 'modules/tracking/lib/posthog';
import { sendExceptionToSentry } from 'modules/tracking/lib/sentry';

import { signOut } from 'shared/lib/aws/aws-auth';

import {
  CUSTOMER,
  IS_AUTHENTICATED,
  PRESCRIBING_DOCTOR,
  SYMPTOMS,
} from 'shared/store/actions/GlobalTypes';

const logout = (navigate: NavigateFunction) => {
  return async (dispatch: Dispatch) => {
    try {
      await signOut();

      brazeLogout();
      posthogLogout();

      dispatch({ type: IS_AUTHENTICATED, payload: false });
      dispatch({ type: CUSTOMER, payload: undefined });

      dispatch({ type: SYMPTOMS, payload: [] });
      dispatch({ type: PRESCRIBING_DOCTOR, payload: undefined });

      navigate('/login');
    } catch (error) {
      sendExceptionToSentry(error as Error);
      throw error;
    }
  };
};

export { logout };
