import './ManageSubscriptionInfo.scss';

import { Skeleton } from '@mui/material';

import ProductsListWrapper from 'modules/dashboard/ui/wrappers/ProductsList';

import useManageSubscriptionState from '../../hooks/useManageSubscriptionState';
import HeaderInfoCardBlock from '../blocks/HeaderInfoCard';
import OrderSummaryCardBlock from '../blocks/OrderSummaryCard';
import ManageDrawer from '../drawers/ManageDrawer';

export default function ManageSubscriptionInfoWrapper() {
  const {
    isLoadingData,

    subscription,

    selectedDeepProductIds,

    manageType,
    manageLevel,
    isManageOpen,
    onClose,

    onRescheduleSub,
    onShipNowSub,

    onEditProduct,
  } = useManageSubscriptionState();

  if (isLoadingData) {
    return (
      <>
        <Skeleton variant='rectangular' height={200} className='mb-3' />
        <Skeleton variant='rectangular' height={200} />
      </>
    );
  }

  if (!!subscription) {
    return (
      <>
        <div className='row'>
          <div className='col-12'>
            <div className='subscription-container'>
              <div className='subscription-content-wrapper'>
                <HeaderInfoCardBlock onReschedule={onRescheduleSub} onShipNow={onShipNowSub} />
              </div>

              <div className='subscription-content-wrapper'>
                <div className='products-card-block'>
                  <h3 className='card-title'>
                    Your Treatments <span className='title-hug'>Every 3 months</span>
                  </h3>

                  <ProductsListWrapper
                    productsWithRenewal={subscription.products}
                    subscriptions={[subscription]}
                    showDosage
                    onEdit={onEditProduct}
                  />
                </div>
              </div>

              <div className='subscription-content-wrapper'>
                <OrderSummaryCardBlock subscription={subscription} />
              </div>
            </div>
          </div>
        </div>

        {!!selectedDeepProductIds.length && (
          <ManageDrawer
            selectedDeepProductIds={selectedDeepProductIds}
            selectedType={manageType}
            manageLevel={manageLevel}
            open={isManageOpen}
            onClose={onClose}
          />
        )}
      </>
    );
  }

  return <></>;
}
