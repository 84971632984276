import { SubscriptionWithRenewal } from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { DeepProduct } from 'common/dist/products/productFrequency';
import { getDeepProductIdsFrom } from '../product';

/**
 * Checks whether the customer is on their last "grouped" (for mht purposes) product
 * in their account on active subs
 *
 * @param activeSubs SubscriptionWithRenewal[]
 * @param groupedProduct GroupedContentfulProduct
 * @returns boolean
 */
export const isLastGroupedProductIn = (
  activeSubscriptions: SubscriptionWithRenewal[],
  groupedProduct: GroupedContentfulProduct
): boolean => {
  // Check if there is only one active subscription
  if (activeSubscriptions.length !== 1) return false;

  return isLastDeepProductsIn(activeSubscriptions[0], getDeepProductIdsFrom(groupedProduct));
};

/**
 *
 * @param subscription SubscriptionWithRenewal
 * @param deepProductIds DeepProduct['id'][]
 * @param ignoreOtc boolean - some instances we don't care for otc being last
 * @returns boolean
 */
export const isLastDeepProductsIn = (
  subscription: SubscriptionWithRenewal,
  deepProductIds: DeepProduct['id'][],
  ignoreOtc: boolean = false
) => {
  const groupedProductIds = new Set(deepProductIds);

  const remainingProducts = subscription.products.filter(
    (subProduct) => !groupedProductIds.has(subProduct.product.id)
  );

  const onlyOtcRemaining =
    !ignoreOtc && remainingProducts.every((pfr) => pfr.product.type === 'OTC');

  // if there are only otc product(s) remaining then we know this is the last non
  // otc product remaining
  return onlyOtcRemaining || remainingProducts.length === 0;
};

export const extractCategories = (groupedProduct: GroupedContentfulProduct) => {
  const parentCategories = groupedProduct.alloyProduct.parent.map((dp) => dp.category);
  const childCategories = (groupedProduct.alloyProduct.child || []).map((dp) => dp.category);

  return [...parentCategories, ...childCategories];
};
