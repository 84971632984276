import { StripePaymentMethod } from 'client/dist/generated/alloy';

import StatReviewWrapper from 'modules/ab-tests/sub-modules/checkout-review/ui/wrappers/StartReview';

import { useAppSelector } from 'shared/store/reducers';

import AccountWrapper from '../wrappers/Account';
import BlurbWrapper from '../wrappers/Blurb';
import PaymentWrapper from '../wrappers/Payment';
import ShippingWrapper from '../wrappers/Shipping';
import SummaryWrapper from '../wrappers/Summary';

interface Props {
  onPlaceOrder: () => void;
  onSelectPaymentMethod: (pm: StripePaymentMethod | null, isAllInputsComplete: boolean) => void;
  isPaymentError: boolean;
  disableContinue: boolean;
}

export default function SingleStepWrapper({
  onPlaceOrder,
  onSelectPaymentMethod,
  isPaymentError,
  disableContinue,
}: Props) {
  const { isEditingShipping } = useAppSelector((state) => state.experience);

  return (
    <>
      <BlurbWrapper />
      <AccountWrapper />
      <ShippingWrapper />

      {!isEditingShipping && (
        <>
          <PaymentWrapper onSelectPaymentMethod={onSelectPaymentMethod} isError={isPaymentError} />
          <SummaryWrapper onPlaceOrder={onPlaceOrder} shouldDisableContinue={disableContinue} />
        </>
      )}

      <div className='d-md-none'>
        <StatReviewWrapper />
      </div>
    </>
  );
}
