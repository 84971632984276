import { isEmpty } from 'lodash';
import { useState } from 'react';

import { useCancelContext } from 'modules/dashboard/sub-modules/manage-subscription/context/cancel';
import { useProductContext } from 'modules/dashboard/sub-modules/manage-subscription/context/product';

import { isOtcProduct } from 'modules/shared/lib/product';

import { TextAreaField } from 'shared/components/fields/TextAreaField';

export default function SideEffectsContent() {
  const [inputText, setInputText] = useState('');

  const { onRetentionSave, onCancel, onSaveSubmission } = useCancelContext();
  const { product } = useProductContext();

  const isOtc = isOtcProduct(product);

  const onSubmitRetention = async () => {
    const id = await onSaveSubmission({ 'cancel-explanation': inputText });

    await onRetentionSave(id);
  };

  const onSubmitCancel = async () => {
    await onSaveSubmission({ 'cancel-explanation': inputText });

    await onCancel();
  };

  return (
    <div className='cancel-content-wrapper'>
      <div className='content-block'>
        <h4 className='content-title'>We're sorry to hear that.</h4>
        <p className='content-text'>
          {isOtc
            ? 'What side effects are you experiencing?'
            : 'Our medical experts are here to work with you. What side effects are you experiencing?'}
        </p>
      </div>

      <form>
        <TextAreaField
          name='side-effects-field'
          value={inputText}
          onChange={setInputText}
          placeholder='Type here'
        />
      </form>

      <div className='btns-stack-block'>
        {!isOtc && (
          <button
            className='primary-button'
            disabled={isEmpty(inputText)}
            onClick={onSubmitRetention}
          >
            Send to my doctor to find a better fit
          </button>
        )}

        {!isOtc ? (
          <button className='primary-link' disabled={isEmpty(inputText)} onClick={onSubmitCancel}>
            I want to cancel instead
          </button>
        ) : (
          <button className='primary-button' disabled={isEmpty(inputText)} onClick={onSubmitCancel}>
            Cancel subscription
          </button>
        )}
      </div>

      <p className='content-blurb'>Note: If this is an emergency, please call 911</p>
    </div>
  );
}
