import classNames from 'classnames';
import { ReactNode } from 'react';

import closeIcon from 'shared/assets/svg/common/btn-close.svg';

interface Props {
  hugText?: string;
  isRequired?: boolean;
  isImportant?: boolean;
  title: string;
  description: string;
  icon?: string;
  children?: ReactNode;
  isClosable?: boolean;
  onClose?: () => void;
}

/**
 * Reusable card that can be used to display notifications
 */
export default function NotificationCardBlock({
  hugText,
  isRequired = false,
  isImportant = false,
  title,
  description,
  icon,
  children,
  isClosable = false,
  onClose,
}: Props) {
  return (
    <div className={classNames('header-state-block', isRequired && 'highlight-border')}>
      <div className='state-content-wrapper'>
        {!!hugText && (
          <div className={classNames('content-hug-wrapper', isRequired && 'hug-required')}>
            <span className='hug-text'>{hugText}</span>
            {isImportant && <div className='hug-alert-icon' />}
          </div>
        )}

        <h3 className='content-title'>{title}</h3>
        <p className='content-text'>{description}</p>

        {children}
      </div>

      <div className='state-icon-wrapper'>
        {!!icon && <img src={icon} alt='' className='content-icon' />}
      </div>

      {isClosable && onClose && (
        <button className='state-close-btn' onClick={onClose}>
          <img src={closeIcon} alt='close icon' className='close-icon' />
        </button>
      )}
    </div>
  );
}
