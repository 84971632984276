import { store } from 'shared/store';

import { Checkout, Customer, patchCustomer } from 'client/dist/generated/alloy';
import { ExperienceCategory } from 'common/dist/models/experience';

import { getCartTotals, getGroupedCartTotals } from 'modules/shared/lib/cart';
import { getReadablePrice } from 'modules/shared/lib/product';
import { sendExceptionToSentry, sendMessageToSentry } from 'modules/tracking/lib/sentry';

import { AlloyCart } from 'modules/shared/models/cart';

import { RootState } from 'shared/store/reducers';

import { FreshpaintEvent } from '../models/freshpaint-event';

const getFreshpaintCategory = (checkoutCategory: ExperienceCategory) => {
  switch (checkoutCategory) {
    case 'mht':
      return 1;
    case 'sexual-health':
      return 2;
    case 'skin-health':
      return 3;
    case 'vaginal-health':
      return 4;
    case 'gut-health':
      return 5;
    case 'hair-health':
      return 6;
    default:
      throw Error(`Checkout category ${checkoutCategory} invalid or doesn't exist.`);
  }
};

const waitForThenDo = (funct: () => void, trigger: () => boolean): void => {
  let retries = 0;
  const interval = setInterval(() => {
    if (retries > 100) {
      clearInterval(interval);
      sendMessageToSentry('Exhausted retries trying to communicate with the freshpaint instance.');
      return;
    }
    if (!trigger()) {
      retries++;
      return;
    } else {
      funct();
      clearInterval(interval);
    }
  }, 100);
};

const identifyFreshpaint = (customer: Customer) =>
  waitForThenDo(
    () =>
      window.freshpaint.identify(customer.patientId, {
        email: customer.email,
      }),
    () => window.freshpaint?.identify,
  );

const sendFreshpaintEvent = (event: FreshpaintEvent, body?: Record<string, unknown>) => {
  window.freshpaint.track(event, body);
};

const cartRevenue = (subtotal: number, discount: number, referralCreditDiscount: number) =>
  parseFloat((subtotal - discount - referralCreditDiscount).toFixed(2));

const getCategories = (categories?: ExperienceCategory[]) =>
  !categories ? '' : categories.map((c) => `${getFreshpaintCategory(c)}`).join(',');

const getCustomer = (customer: Customer) => {
  return {
    'First Name': customer.firstName,
    'Last Name': customer.lastName,
    Email: customer.email,
  };
};

const getItems = (cart: AlloyCart) => {
  return cart.products.flatMap((gcp) =>
    gcp.alloyProduct.parent.map((pf) => ({
      id: pf.name,
      name: pf.name,
      price: getReadablePrice(pf.priceInCents),
    })),
  );
};

const getCouponData = (cart: AlloyCart) => {
  return {
    'Coupon Code': cart.promotionCode.id !== '' ? cart.promotionCode.name : undefined,
    'Coupon Discount': cart.promotionCode.id !== '' ? cart.promotionCode.discountAmount : undefined,
  };
};

const getTreatmentPlanPayload = async (customer: Customer) => {
  const state = store.getState() as RootState;
  const { cart } = state.experience;

  const { subtotal, discount, referralCreditDiscount } = getGroupedCartTotals(cart);

  return {
    Items: getItems(cart),
    Revenue: cartRevenue(subtotal, discount, referralCreditDiscount),
    ...getCouponData(cart),
    ...getCustomer(customer),
    ...getParamsFromFreshpaintCookie(),
  };
};

const getCheckoutPayload = async (
  customer: Customer,
  checkout?: Checkout,
  categories?: ExperienceCategory[],
) => {
  const state = store.getState() as RootState;
  const { cart } = state.experience;

  const { subtotal, discount, referralCreditDiscount } = getCartTotals(cart);

  return {
    'Order ID': checkout?.id,
    Items: getItems(cart),
    Revenue: cartRevenue(subtotal, discount, referralCreditDiscount),
    'Flow ID': getCategories(categories),
    ...getCouponData(cart),
    ...checkout,
    ...getCustomer(customer),
  };
};

// gathers the parameters based on event type so we can send
// the event to Freshpaint with tracking and analytics data
// that's as useful as possible
const trackFreshpaint = async (
  event: FreshpaintEvent,
  customer: Customer,
  checkout?: Checkout,
  categories?: ExperienceCategory[],
) => {
  try {
    if (checkout && !categories) {
      throw new Error('Cannot send event to Freshpaint; categories are required');
    }
    if (event === 'CHECKOUT_CONFIRMATION') {
      const payload = await getCheckoutPayload(customer, checkout, categories);
      sendFreshpaintEvent(event, payload);
      //NOTE: recording a temporary event during anonymization of FB event, see shortcut story 19290
      sendFreshpaintEvent('CHECKOUT_CONFIRMATION2', payload);
    } else if (event === 'TP_CHECKOUT_CONFIRMATION') {
      const payload = await getTreatmentPlanPayload(customer);
      sendFreshpaintEvent(event, payload);
    } else {
      sendFreshpaintEvent(event, getCustomer(customer));
    }
  } catch (e: any) {
    sendExceptionToSentry(e);
  }
};

const correlateFreshpaintToCustomer = () =>
  waitForThenDo(
    async () => {
      const freshpaintId = window.freshpaint.get_property('$device_id');
      try {
        await patchCustomer({ freshpaintId });
      } catch (e: any) {
        sendExceptionToSentry(e);
      }
    },
    () => window.freshpaint?.get_property,
  );

/**
 * Reference: https://documentation.freshpaint.io/faqs/what-data-does-freshpaint-collect/autotrack#a-dvertising-click-parameters
 *
 * If anything goes wrong, we swallow the exception
 *
 * @returns clickId, UtmTerm and UtmSource from freshpaint
 */
const getParamsFromFreshpaintCookie = () => {
  try {
    const cookieProps = window.freshpaint?.cookie?.props?.__user_props;
    const freshpaintClickId =
      window.freshpaint?.cookie?.props?.$gclid ??
      window.freshpaint?.cookie?.props?.$fbclid ??
      window.freshpaint?.cookie?.props?.$msclkid ??
      window.freshpaint?.cookie?.props?.$twclid ??
      window.freshpaint?.cookie?.props?.$ttclid;
    const freshpaintUtmTerm = cookieProps.utm_term;
    const freshpaintUtmSource = cookieProps.utm_source;
    const freshpaintUtmCampaign = cookieProps.utm_campaign;
    const freshpaintUtmMedium = cookieProps.utm_medium;
    const freshpaintUtmContent = cookieProps.utm_content;
    return {
      freshpaintClickId: freshpaintClickId,
      freshpaintUtmTerm: freshpaintUtmTerm,
      freshpaintUtmSource: freshpaintUtmSource,
      freshpaintUtmCampaign: freshpaintUtmCampaign,
      freshpaintUtmMedium: freshpaintUtmMedium,
      freshpaintUtmContent: freshpaintUtmContent,
    };
  } catch (e) {
    console.error('Could not fetch freshpaint details');
    console.error(e);
    return {};
  }
};

export {
  correlateFreshpaintToCustomer,
  getParamsFromFreshpaintCookie,
  identifyFreshpaint,
  trackFreshpaint,
};
