import { useEffect, useState } from 'react';

import {
  PendingSwitch,
  ProductFrequencyRenewal,
  SubscriptionWithRenewal,
  useGetAllSubscriptionsForCustomer,
  useGetPendingSwitch,
  useGetTreatmentPlan
} from 'client/dist/generated/alloy';
import { default as ProductRegistry } from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { isMht, isPrescription } from 'modules/shared/lib/contentful';
import {
  getSeparatedGroupedContentfulProducts,
  isSameParentProduct
} from 'modules/shared/lib/product';
import { filterSingleSupplyProducts } from 'modules/shared/lib/product/single-supply';
import { isProductSwitchable } from 'modules/shared/lib/product/switch';
import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';

interface ReturnProps {
  activeProducts: ActiveProduct[];

  requestedProduct: GroupedContentfulProduct | undefined;
  pendingSwitch: PendingSwitch | undefined;

  isLoading: boolean;
}

type ActiveProduct = {
  gcp: GroupedContentfulProduct;
  subscription?: SubscriptionWithRenewal;
  productInSubscription?: ProductFrequencyRenewal;
  prescriptionProduct?: ProductFrequencyRenewal;
  foundSwitch?: boolean;
};

export default function useActiveState(): ReturnProps {
  const [activeProducts, setActiveProducts] = useState<ActiveProduct[]>([]);
  const [requestedProduct, setRequestedProduct] = useState<GroupedContentfulProduct | undefined>(
    undefined
  );

  const [isLoading, setIsLoading] = useState(true);

  const { data: treatmentPlan } = useGetTreatmentPlan();
  const { data: pendingSwitch } = useGetPendingSwitch();
  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const { activeSubs } = getSubscriptionsWithStatus(subscriptions);

  const singleSupplyProducts = filterSingleSupplyProducts(
    subscriptions,
    treatmentPlan,
    'EXPIRING_SOON'
  );

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setIsLoading(true);

    const purchased = [
      ...activeSubs.flatMap((sub) => sub.products.map((pfr) => pfr.product)),
      ...singleSupplyProducts.map((pfr) => pfr.product)
    ];

    const purchasedProducts = (await getSeparatedGroupedContentfulProducts(purchased)).sort(
      (a, b) =>
        Number(isMht(b.contentfulProduct)) - Number(isMht(a.contentfulProduct)) ||
        Number(isPrescription(b.contentfulProduct)) - Number(isPrescription(a.contentfulProduct))
    ); // only for active products that the customer has in sub / one time, we need to sort how we do everywhere
    // else with the mht / prescriptions at the top and then everything else toward the bottom!

    if (pendingSwitch && pendingSwitch.requestedProducts.length > 0) {
      const requestedGroupedContentful = (
        await ProductRegistry.get().getRecurringProductsForV2(pendingSwitch.requestedProducts)
      ).flat();

      setRequestedProduct(requestedGroupedContentful[0]);
    }

    const result = await Promise.all(
      purchasedProducts.map(async (gcp) => {
        const subscription = activeSubs.find((sub) =>
          gcp.alloyProduct.parent.some((dp) =>
            sub.products
              .map((p) => p.product)
              .find((i) => ProductRegistry.get().areProductsEquivalent([dp, i]))
          )
        );

        // product in the subscription, just used for renewal and prescription information
        const productInSubscription = subscription
          ? subscription.products.find((p) =>
              gcp.alloyProduct.parent.find((i) =>
                ProductRegistry.get().areProductsEquivalent([p.product, i])
              )
            )
          : undefined;

        // for one time products, we just need the prescription product for renewal and prescription information
        const prescriptionProduct = treatmentPlan!.prescriptionProducts.find((pp) =>
          ProductRegistry.get().areProductsEquivalent([pp.product, ...gcp.alloyProduct.parent])
        );

        const foundProductInSwitchableProducts = await isProductSwitchable(gcp);
        const foundProductInSwitchProcess = isSameParentProduct(gcp, requestedProduct);

        // potential in switch in progress
        const foundSwitch =
          (pendingSwitch?.isSwitchFormFactor || pendingSwitch?.isSwitchDose) &&
          foundProductInSwitchableProducts &&
          foundProductInSwitchProcess;

        return {
          gcp,
          subscription,
          productInSubscription,
          prescriptionProduct,
          foundSwitch
        };
      })
    );

    setActiveProducts(result);
    setIsLoading(false);
  };

  return {
    activeProducts,

    requestedProduct,
    pendingSwitch,

    isLoading
  };
}
