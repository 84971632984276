import { useCancelContext } from 'modules/dashboard/sub-modules/manage-subscription/context/cancel';

import { CANCEL_SAVE } from 'modules/dashboard/sub-modules/manage-subscription/data/reason';

import { useQueryParams } from 'shared/context/url/query';

export default function MovingContent() {
  const { navigateWithParams } = useQueryParams();

  const { onCancel, onSaveSubmission } = useCancelContext();

  const onUpdateAddress = async () => {
    await onSaveSubmission({ 'cancel-saved': CANCEL_SAVE.MOVING });

    navigateWithParams('/account/profile'); // TODO: think about params maybe later on profile
  };

  const onSubmitCancel = async () => {
    await onSaveSubmission();

    await onCancel();
  };

  return (
    <div className='cancel-content-wrapper'>
      <div className='content-block'>
        <h4 className='content-title'>Did you know Alloy is available in all 50 states?</h4>
        <p className='content-text'>
          Your treatments can be sent to your new address! Just update your shipping address.
        </p>
      </div>

      <div className='btns-stack-block'>
        <button className='primary-button' onClick={onUpdateAddress}>
          Update my address
        </button>

        <button className='primary-link' onClick={onSubmitCancel}>
          I want to cancel
        </button>
      </div>
    </div>
  );
}
