import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { DeepProduct } from 'common/dist/products/productFrequency';

const getParent = (product: GroupedContentfulProduct) => product.alloyProduct.parent[0];

const getPriority = (parent: DeepProduct): number => {
  if (parent.isBundledParent && !parent.isBundledChild) return 0;
  if (parent.isBundledChild) return 1;
  return 2; // Others
};

/**
 * specifically for skin atm, we need to sort products a certain way for the team
 * bundled parent products + (Z-A) go first
 * bundled child products + (A-Z) go last
 *
 * this is definitely something that could be completely removed with:
 * https://myalloy.slack.com/archives/C03465ESVQT/p1736443323571959
 *
 * @param products GroupedContentfulProduct[]
 * @returns GroupedContentfulProduct[]
 */
export const sortProducts = (products: GroupedContentfulProduct[]): GroupedContentfulProduct[] => {
  return products.sort((a, b) => {
    const aParent = getParent(a);
    const bParent = getParent(b);

    const priorityA = getPriority(aParent);
    const priorityB = getPriority(bParent);

    if (priorityA !== priorityB) return priorityA - priorityB;

    if (priorityA === 0) {
      // Z-A for non-child parents
      return bParent.name.localeCompare(aParent.name);
    }

    if (priorityA === 1) {
      // A-Z for child parents
      return aParent.name.localeCompare(bParent.name);
    }

    return 0; // Default case
  });
};
