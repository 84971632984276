import RequestExperienceContextProvider from 'modules/request-experience/context/request-experience';
import ExperienceContextProvider from 'modules/shared/context/experience';

import RequestExperienceRoutesWrapper from 'modules/request-experience/ui/wrappers/RequestExperienceRoutes';

export default function RequestExperience() {
  return (
    <RequestExperienceContextProvider>
      <ExperienceContextProvider>
        <RequestExperienceRoutesWrapper />
      </ExperienceContextProvider>
    </RequestExperienceContextProvider>
  );
}
