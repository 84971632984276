import { Elements } from '@stripe/react-stripe-js';

import CheckoutSectionWrapper from 'modules/shared/sub-modules/checkout/ui/wrappers/CheckoutSection';

import Loader from 'shared/components/content/Loader';

import { stripePromise } from 'shared/lib/stripe-promise';

import useCheckoutState from '../../hooks/useCheckoutState';
import useInitCheckout from '../../hooks/useInitCheckout';

export default function CheckoutFormWrapper() {
  // initiate the checkout
  useInitCheckout();

  const { isAnyLoading, setupIntent, onPlaceOrder } = useCheckoutState();

  if (isAnyLoading || !setupIntent) return <Loader />;

  return (
    <Elements stripe={stripePromise} options={{ clientSecret: setupIntent.client_secret }}>
      <CheckoutSectionWrapper onPlaceOrder={onPlaceOrder} />
    </Elements>
  );
}
