import {
  PreSubmission,
  SubmissionAnswer,
  postSubmission,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';
import getIntakesByCategory from 'common/dist/intake/getIntakesByCategory';
import usePrescribingDoctor from 'modules/shared/hooks/usePrescribingDoctor';
import { fillAnswer } from 'modules/questionnaire/lib/fillAnswer';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useDoctorSurveyState() {
  const navigate = useNavigate();

  const dateStarted = new Date();

  const [isLoading, setIsLoading] = useState(true);
  const [encounterId, setEncounterId] = useState<undefined | number>(undefined);

  const questions = getIntakesByCategory(['doctor-survey']);

  const { data: treatmentPlan, isLoading: isLoadingTreatmentPlan } = useGetTreatmentPlan();

  const { doctor, isLoading: isLoadingDoctor } = usePrescribingDoctor();

  const isAllLoading = isLoading || isLoadingDoctor || isLoadingTreatmentPlan;

  const onBack = () => navigate(-1);

  useEffect(() => {
    retrieveDoctor();
  }, [treatmentPlan]);

  const retrieveDoctor = async () => {
    if (treatmentPlan) {
      const prescriptions = treatmentPlan.prescriptionProducts
        .filter((pp) => pp.prescription)
        .map((pp) => pp.prescription!);

      if (prescriptions.length > 0) {
        const mostRecentPrescription = prescriptions[prescriptions.length - 1];
        const mostRecentEncounterId = mostRecentPrescription.encounterId;

        if (mostRecentEncounterId) {
          setEncounterId(mostRecentEncounterId);
        }
      }

      setIsLoading(false);
    }
  };

  const onSubmit = async (answers: SubmissionAnswer[]) => {
    let updatedAnswers: SubmissionAnswer[] = [];
    try {
      setIsLoading(true);

      const doctorNameAnswer = fillAnswer(
        questions,
        answers,
        'doctor-name',
        doctor?.fields.name || 'DOCTOR_NOT_FOUND'
      );

      const encounterIdAnswer = fillAnswer(
        questions,
        answers,
        'encounter-id',
        encounterId?.toString() || 'ENCOUNTER_NOT_FOUND'
      );

      updatedAnswers = [...answers, doctorNameAnswer, encounterIdAnswer];

      const submission: PreSubmission = {
        categories: ['doctor-survey'],
        started: dateStarted,
        answers: updatedAnswers,
      };

      await postSubmission(submission);

      navigate('/doctor-survey-confirmation');
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    isLoading: isAllLoading,

    doctor,
    questions,

    onBack,
    onSubmit,
  };
}
