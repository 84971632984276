import { uniq } from 'lodash';

import ProductRegistry from 'client/dist/product/productRegistry';
import { SharedLocation } from 'common/dist/models/shared/location';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { DeepProduct } from 'common/dist/products/productFrequency';

import { isGroupedProductInProductsList } from 'modules/shared/lib/product';

export const retrieveProductIdsFromUrl = (location: SharedLocation): number[] => {
  const searchParams = new URLSearchParams(location.search);

  const productIds: string | null = searchParams.get('productIds[]');

  const splitProductIds = productIds ? productIds.split(',') : [];

  const parsedProductIds = splitProductIds.map((pid) => parseInt(pid));

  return parsedProductIds;
};

/**
 * Return 2D array of requested products using products from active subs to get
 * their correct bundled product config.
 *
 * For v1 of request experience, we might run into an instance where a customer (for bundle)
 * has purchased m4 before BUT never had tret before as a prescription. for this, we need to get
 * them the bundled pf in order for them to see the cheaper price since they have m4 active
 * With work around cleaning split stuff, TODO: mikhail to target this for a bit of a better functional
 * approach but this is so we can get it rolling for v1
 *
 * @param requestedProducts
 * @param activeSubsProducts
 * @returns Promise<GroupedContentfulProduct[][]>
 */
export const cleanGroupedPurchasableProducts = async (
  requestedProducts: DeepProduct[],
  activeSubsProducts: DeepProduct[],
): Promise<GroupedContentfulProduct[][]> => {
  // just all product ids from cart (requested products) and active subs
  const collated = uniq([...requestedProducts, ...activeSubsProducts]);

  const products = await ProductRegistry.get().getRecurringProductsForV2(collated);

  // filter out against active subs that way no current purchased products get in and return them
  return products
    .map((gcpList) =>
      gcpList.filter((gcp) => !isGroupedProductInProductsList(activeSubsProducts, gcp)),
    )
    .filter((gcpList) => gcpList.length);
};

/**
 * Same as cleanGroupedPurchasableProducts but in this case
 * we return a 1D array for when having an 2D array
 * is not necessary.
 *
 * @param requestedProducts
 * @param activeSubsProducts
 * @returns Promise<GroupedContentfulProduct[]>
 */
export const cleanPurchasableProducts = async (
  requestedProducts: DeepProduct[],
  activeSubsProducts: DeepProduct[],
): Promise<GroupedContentfulProduct[]> => {
  return (await cleanGroupedPurchasableProducts(requestedProducts, activeSubsProducts)).flat();
};
