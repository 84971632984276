import classNames from 'classnames';

import { ProductCategory } from 'common/dist/products/product';

interface Props {
  unbundledPrice: number;
  bundledPrice: number;
  canBeDiscounted: boolean;
  isParentSelected: boolean;
  discountedParentName: string;
  categories: ProductCategory[];
}

export default function ProductPricingBlock({
  unbundledPrice,
  bundledPrice,
  canBeDiscounted,
  isParentSelected,
  discountedParentName,
  categories,
}: Props) {
  // for getting out new skin, we need to hide some standard fields even tho it isn't the prettiest atm
  const isSkin = categories.includes('skin-health');

  const differenceInPrice = unbundledPrice - bundledPrice;
  const isSamePrice = bundledPrice === unbundledPrice;

  return (
    <>
      {canBeDiscounted && !isSkin && (
        <p className='header-blurb'>
          ${Number(differenceInPrice.toFixed(2))} off with purchase of {discountedParentName}
        </p>
      )}

      <p className='header-text'>
        {canBeDiscounted && isParentSelected && !isSamePrice && (
          <span className='text-discounted-price'>${bundledPrice.toFixed(2)} </span>
        )}
        <span
          className={classNames(
            'text-price',
            canBeDiscounted && isParentSelected && !isSamePrice && 'price-strikethrough',
          )}
        >
          ${unbundledPrice.toFixed(2)}
        </span>{' '}
        (3-month supply)
      </p>
    </>
  );
}
