import HeaderBlock from 'modules/dashboard/sub-modules/treatment-plan/ui/blocks/Header';
import ProgressBlock from 'modules/dashboard/sub-modules/treatment-plan/ui/blocks/Progress';
import ReferralBlock from 'modules/dashboard/sub-modules/treatment-plan/ui/blocks/Referral';

import TopBlogBlock from 'modules/dashboard/ui/blocks/TopBlog';
import ViewableProduct from 'modules/shared/ui/blocks/ViewableProduct';

import InfoSection from 'modules/dashboard/ui/wrappers/InfoSection';
import TestimonialsSection from 'modules/dashboard/ui/wrappers/TestimonialsSection';

import { EmailUs } from 'shared/components/content/Buttons';
import SectionWrapper from 'shared/components/wrappers/Section';

import { useAppSelector } from 'shared/store/reducers';

import useRequestConfirmationState from '../../hooks/useRequestConfirmationState';

export default function RequestConfirmationInfoWrapper() {
  const customer = useAppSelector((state) => state.alloy.customer!);
  const { cart } = useAppSelector((state) => state.experience);
  const { checkout } = useAppSelector((state) => state.experience);

  const {
    hasPrescription,
    nextRecurrence,
    recurrenceDaysDiff,
    shipmentProducts,

    getProductHug,
  } = useRequestConfirmationState();

  return (
    <>
      <HeaderBlock
        title={hasPrescription ? 'Your treatment change is' : 'Your request has been'}
        fancyTitle={hasPrescription ? 'confirmed' : 'received'}
        backgroundType='secondary'
      />

      <SectionWrapper sectionClasses='order-confirmation-section'>
        {checkout && <div className='fp-checkout-id' data-checkout-id={checkout.id} />}

        <div className='col-12 col-md-7'>
          {hasPrescription && nextRecurrence && (
            <p className='confirmation-text text-bold'>
              Your order will be charged on {nextRecurrence} and ships in {recurrenceDaysDiff} days.
            </p>
          )}

          {cart.products.map((gcp, index) => (
            <ViewableProduct
              key={index}
              groupedProduct={gcp}
              showDosage={cart.checkoutType === 'CUSTOMER_FORM_FACTOR_CHANGE'}
              hideDetails
              hideProductInformation
              hidePrice
            >
              <span className='product-hug'>{getProductHug()}</span>
            </ViewableProduct>
          ))}

          {hasPrescription &&
            shipmentProducts
              .flat()
              .map((gcp, index) => (
                <ViewableProduct
                  key={index}
                  groupedProduct={gcp}
                  showDosage={cart.checkoutType === 'CUSTOMER_FORM_FACTOR_CHANGE'}
                  hideDetails
                  hideProductInformation
                  hidePrice={cart.checkoutType === 'CUSTOMER_DOSAGE_CHANGE'}
                />
              ))}

          {hasPrescription ? (
            <>
              <p className='confirmation-text'>
                Thank you for your order! You'll receive an order confirmation email shortly at{' '}
                <span>{customer.email}</span>.
              </p>

              <p className='confirmation-text'>
                Any questions about your order at all, email us at{' '}
                <EmailUs btnClass='text-underline' />. We're here for you!
              </p>
            </>
          ) : (
            <ProgressBlock
              title="What's next."
              description='Your doctor will review your request and, once approved, your new product will ship with the rest of your normal shipment.'
              barTitles={['Request', 'Doctor review', 'Shipped']}
              currentIndex={1}
              hideButton
            >
              <p className='progress-text'>
                Any questions about your order at all, email us at{' '}
                <EmailUs btnClass='text-underline' />. We're here for you!
              </p>
            </ProgressBlock>
          )}
        </div>

        <div className='col-12 col-md-5'>
          <ReferralBlock />
          <TopBlogBlock />
        </div>
      </SectionWrapper>

      <InfoSection />
      <TestimonialsSection />
    </>
  );
}
