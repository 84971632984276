import { useEffect, useState } from 'react';

import { DeepProduct } from 'common/dist/products/productFrequency';

import { getProductPrice } from '../lib/product/pricing';

interface Props {
  products: DeepProduct[];
  dependencies?: (string | boolean)[];
}

export default function useTotalProductPrices({ products, dependencies = [] }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [unbundledTotal, setUnbundledTotal] = useState(0);
  const [bundledTotal, setBundledTotal] = useState(0);

  useEffect(() => {
    getTotals();
  }, dependencies);

  const getTotals = async () => {
    setIsLoading(true);

    const prices = await Promise.all(
      products.map(async (dp) => {
        return {
          unbundled: await getProductPrice(dp),
          bundled: await getProductPrice(dp, products),
        };
      }),
    );

    setUnbundledTotal(prices.map((p) => p.unbundled).reduce((a, p) => a + p, 0) / 100);
    setBundledTotal(prices.map((p) => p.bundled).reduce((a, p) => a + p, 0) / 100);

    setIsLoading(false);
  };

  return {
    isLoading,

    unbundledTotal,
    bundledTotal,
  };
}
