import { bindActionCreators } from '@reduxjs/toolkit';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useExperience } from 'modules/shared/context/experience';

import { getPersonalizedContent } from 'modules/checkout-experience/lib/personalized-content';
import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';
import { shouldSkipCategorySelect } from 'modules/shared/lib/url';
import { brazeSetPartial } from 'modules/tracking/lib/braze';

import { updateLocalPreCustomer } from 'modules/shared/store/local-pre-customer';

import { useAppSelector } from 'shared/store/reducers';

import { NameStateForm } from '../models/name-state-form';

export default function useNameState() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { onNext } = useExperience();

  const customer = useAppSelector((state) => state.alloy.customer);
  const localPreCustomer = useAppSelector((state) => state.experience.localPreCustomer);

  const skipCategorySelect = shouldSkipCategorySelect(location);

  const defaultFirstName: string =
    (customer ? customer.firstName : localPreCustomer.firstName) ?? '';

  const defaultState: string = (customer ? customer.stateAbbr : localPreCustomer.state) ?? '';

  const categories = retrieveCategoriesFromUrl(location);

  const content = getPersonalizedContent(categories, 'name');

  const INIT_FORM = {
    firstName: defaultFirstName,
    state: defaultState,
  };

  const [customerForm, setCustomerForm] = useState<NameStateForm>(INIT_FORM);

  const dispatchUpdateLocalPreCustomer = bindActionCreators(updateLocalPreCustomer, dispatch);

  const onSubmit = async () => {
    dispatchUpdateLocalPreCustomer(customerForm);

    // we'll only have first name and state at this point and we only send firstName to braze
    brazeSetPartial(customerForm);

    onNext();
  };

  return {
    content,
    skipCategorySelect,

    customerForm,
    setCustomerForm,

    onSubmit,
  };
}
