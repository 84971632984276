import { bindActionCreators } from '@reduxjs/toolkit';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { uploadIdentificationPhoto } from 'client/dist/generated/alloy';

import { useExperience } from 'modules/shared/context/experience';

import { updateLicenseExists } from 'modules/shared/store/customer';

import { b64toBlob } from 'shared/lib/file';

export default function useVerifyIdentityState() {
  const dispatch = useDispatch();
  const { onNext } = useExperience();

  const [fileBlob, setFileBlob] = useState<Blob | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPhotoModal, setShowPhotoModal] = useState(false);

  const dispatchUpdateLicenseExists = bindActionCreators(updateLicenseExists, dispatch);

  const onSubmit = async () => {
    try {
      if (fileBlob) {
        setIsLoading(true);

        await uploadIdentificationPhoto({
          file: fileBlob,
        });

        // Update the redux value to true since we uploaded their id / license
        dispatchUpdateLicenseExists(true);

        onNext();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onRemove = () => {
    setFileBlob(null);
  };

  const onClose = (photo?: string) => {
    setShowPhotoModal(false);

    if (photo) {
      const blob = b64toBlob(photo);
      setFileBlob(blob);
    }
  };

  return {
    isLoading,

    fileBlob,
    setFileBlob,

    showPhotoModal,
    setShowPhotoModal,

    onClose,
    onRemove,
    onSubmit,
  };
}
