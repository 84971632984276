import { formatExperienceURL } from 'modules/shared/lib/url';
import { sendExceptionToSentry } from 'modules/tracking/lib/sentry';
import { AbandonEvent } from '../models/abandon-event';
import { brazeSendAbandonEvent } from './braze';

/**
 * Send abandon events at certain points through the funnel
 */
const sendAbandonEvent = async ({
  event,
  categories,
  experience,
  avRequiredState,
  products,
}: AbandonEvent) => {
  try {
    // since this event is used for the funnel, we only have 2 funnels atm which are (checkout || request)-experience(s)
    // we can build the base url this way and then pass along to the braze abandon event
    const experienceBase =
      experience === 'request' ? '/request-experience' : '/checkout-experience';

    const endOfUrl = formatExperienceURL(experienceBase, window.location, categories);

    // for example if we had mht as a category, this would give us: `https://secure.myalloy.com/checkout-experience?categories[]=mht`
    // which when a customer comes back it will direct them appropriately
    const url = window.location.origin + endOfUrl;

    await brazeSendAbandonEvent(event, categories, avRequiredState, products, url);
  } catch (e) {
    sendExceptionToSentry(e as Error);
  }
};

export default sendAbandonEvent;
