import { useGetAllUnreadNotifications } from 'client/dist/generated/alloy';

import alarm from 'modules/notifications/assets/svg/alarm-icon.svg';

export default function NotificationNavLink() {
  const { data: notifications = [] } = useGetAllUnreadNotifications();
  const isUnread = notifications.length > 0;
  return (
    <div className='notification-link'>
      <img src={alarm} alt='notifications' className='notification' />
      {isUnread && <span className='notification-dot' />}
    </div>
  );
}
