import {
  useGetAllSubscriptionsForCustomer,
  useGetNonCustomerUpsellProducts,
  useGetPendingSwitch,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import useGroupedProducts from 'shared/hooks/product/useGroupedProducts';

interface ReturnProps {
  customerApprovalProducts: GroupedContentfulProduct[][];
  customerRetentionProducts: GroupedContentfulProduct[][];
  isLoadingGroupedProducts: boolean;
  isLoading: boolean;
}

export default function usePendingData(): ReturnProps {
  /**
   * Data loading for treatment plan, subscriptions, and upsell products
   */
  const { data: treatmentPlan, isLoading: isLoadingTreatmentPlan } = useGetTreatmentPlan();
  const { isLoading: isLoadingPendingSwitch } = useGetPendingSwitch();
  const { isLoading: isLoadingSubscriptions } = useGetAllSubscriptionsForCustomer();
  const { isLoading: isLoadingUpsellProducts } = useGetNonCustomerUpsellProducts();

  // customer approval
  const { products: customerApprovalProducts, isLoading: isLoadingApproval } = useGroupedProducts({
    deepProducts: treatmentPlan?.pendingCharges.consultApproval.flatMap((pc) => pc.products) || [],
    cachedProducts: undefined,
  });

  // customer retention
  const { products: customerRetentionProducts, isLoading: isLoadingRetention } = useGroupedProducts(
    {
      deepProducts:
        treatmentPlan?.pendingCharges.cancelRetentionApproval.flatMap((pc) => pc.products) || [],
      cachedProducts: undefined,
    }
  );

  const isLoading =
    isLoadingTreatmentPlan ||
    isLoadingPendingSwitch ||
    isLoadingSubscriptions ||
    isLoadingUpsellProducts;

  return {
    customerApprovalProducts,
    customerRetentionProducts,
    isLoadingGroupedProducts: isLoadingApproval || isLoadingRetention,
    isLoading,
  };
}
