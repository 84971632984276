import { isEmpty } from 'lodash';
import { Link, useLocation } from 'react-router-dom';

import { formatExperienceURL } from 'modules/shared/lib/url';

import CheckoutExperienceSection from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceSection';

import arrowRight from 'shared/assets/svg/arrows/arrow-right-white.svg';

import Loader from 'shared/components/content/Loader';
import Form from 'shared/components/core/Form';
import { EmailField } from 'shared/components/fields/EmailField';
import { PasswordField } from 'shared/components/fields/PasswordField';

import { useQueryParams } from 'shared/context/url/query';

import useLoginState from '../../hooks/useLoginState';

export default function LoginFormWrapper() {
  const location = useLocation();
  const { buildPathWith } = useQueryParams();
  const {
    isLoading,

    loginForm,
    setLoginForm,

    onSubmit,
  } = useLoginState();

  if (isLoading) return <Loader />;

  return (
    <CheckoutExperienceSection>
      <Form onSubmit={onSubmit}>
        <h1 className='content-title'>Login</h1>

        <p className='content-text'>
          New here?{' '}
          <Link to={formatExperienceURL('/checkout-experience', location)} className='content-link'>
            Get started
          </Link>
        </p>

        <EmailField
          label='Email'
          name='email'
          value={loginForm.email}
          placeholder='Enter your email address'
          onChange={(value) => setLoginForm({ ...loginForm, email: value })}
        />

        <PasswordField
          label='Password'
          name='password'
          value={loginForm.password}
          placeholder='Enter your password'
          hideChecksSucceeded
          hideVisibleIcon
          onChange={(_, password) => setLoginForm({ ...loginForm, password })}
        />

        <p className='content-text'>
          <Link to={buildPathWith('/forgot')} className='content-link'>
            Forgot password?
          </Link>
        </p>

        <button
          className='primary-button full-width-button'
          type='submit'
          disabled={isEmpty(loginForm.email) || isEmpty(loginForm.password)}
        >
          Login
          <img src={arrowRight} alt='arrow right' className='btn-arrow' />
        </button>
      </Form>
    </CheckoutExperienceSection>
  );
}
