import classNames from 'classnames';
import { useState } from 'react';

import checkedBox from 'shared/assets/svg/common/checked-box-icon.svg';
import uncheckedBox from 'shared/assets/svg/common/unchecked-box-icon.svg';

interface Props {
  content: any;
  isPrevSelected: boolean;
  onChange: (isSelected: boolean) => void;
  isOnErrorState?: boolean;
}
export default function CheckboxField({
  isPrevSelected,
  content,
  onChange,
  isOnErrorState,
}: Props) {
  const [isSelected, setIsSelected] = useState(isPrevSelected);

  const onSelect = () => {
    const updatedIsSelected = !isSelected;

    setIsSelected(updatedIsSelected);
    onChange(updatedIsSelected);
  };

  return (
    <div className='input-field-wrapper'>
      <div className='field-checkbox checkbox-single' onClick={onSelect} tabIndex={0}>
        <img
          src={isSelected ? checkedBox : uncheckedBox}
          alt='selectable box'
          className={classNames(
            'checkbox-select-icon',
            isOnErrorState && 'checkbox-select-icon error'
          )}
        />

        <p className={classNames('checkbox-text', isOnErrorState && 'checkbox-text error')}>
          {content}
        </p>
      </div>
    </div>
  );
}
