import { uniq } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { DeepProduct } from 'common/dist/products/productFrequency';

import useCart from 'modules/shared/hooks/useCart';

import CheckoutExperienceSection from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceSection';

import { EmailUs } from 'shared/components/content/Buttons';
import Layout from 'shared/components/core/Layout';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

interface Props {
  products: DeepProduct[];
}

export default function Disqualified({ products }: Props) {
  const navigate = useNavigate();

  const { clearCart } = useCart();

  const productNames = uniq(products.map((pf) => pf.name));

  const onViewTreatmentPlan = () => {
    clearCart();

    navigate('/', { replace: true });
  };

  return (
    <Layout title='Intake - Alloy' desc='' noBars>
      <TopBannerWithProgress />

      <CheckoutExperienceSection colClass='col-md-6'>
        <h1 className='content-title'>Looks like this isn't the best treatment for you</h1>
        <p className='content-text'>
          {productNames.length === 1
            ? productNames[0] + " isn't"
            : "Your requested treatments aren't"}{' '}
          a great fit for you based on your responses. If you have any questions, please reach out
          to <EmailUs btnClass='text-underline' />. Want to try something else? Head back to your
          dashboard.
        </p>

        <button className='primary-button full-width-button' onClick={onViewTreatmentPlan}>
          Back to your treatment plan
        </button>
      </CheckoutExperienceSection>
    </Layout>
  );
}
