import skincareBundle from 'modules/shared/assets/images/skincare-bundle-no-background.png';
import synbiotic from 'modules/shared/assets/images/synbiotic-no-background.png';
import vaginalBundleSmall from 'modules/shared/assets/images/vaginal-bundle-small.png';
import vaginalBundle from 'modules/shared/assets/images/vaginal-bundle.png';

import {
  PREFERENCE_KNOWN,
  PREFERENCE_UNKNOWN,
  PreferenceKnowledgeOption,
} from 'modules/ab-tests/sub-modules/known-preferences/models/preferences';

import { MultiSelectOption } from '../models/multi-select-option';

export const treatmentInterestsSelections: MultiSelectOption[] = [
  {
    id: 'none',
    text: 'None at this time',
    categories: [],
  },
  {
    id: 'skin-health',
    text: 'Skincare',
    categories: ['skin-health'],
    imageConfig: {
      alt: 'Skincare bundle',
      src: skincareBundle,
      backgroundClassNames: 'background blue-white-gradient',
      imageClassNames: 'bottom',
      includeBadge: true,
    },
  },
  {
    id: 'vaginal-health',
    text: 'Vaginal and sexual health',
    categories: ['vaginal-health', 'sexual-health'],
    imageConfig: {
      alt: 'Vaginal care rx bundle',
      src: vaginalBundle,
      mobileSrc: vaginalBundleSmall,
      backgroundClassNames: 'background',
      imageClassNames: 'fullWidth',
      includeBadge: true,
    },
  },
  {
    id: 'gut-health',
    text: 'Gut health',
    categories: ['gut-health'],
    imageConfig: {
      alt: 'Synbiotic bottle',
      src: synbiotic,
      backgroundClassNames: 'background blue-white-gradient',
      imageClassNames: 'bottom',
    },
  },
];

export const knownPreferenceSelections: PreferenceKnowledgeOption[] = [
  {
    title: 'Yes, I know what I want',
    text: 'I know what menopause treatments I am interested in getting through a doctor consult with Alloy',
    id: PREFERENCE_KNOWN,
  },
  {
    title: 'No, I’m not sure',
    text: 'I do not know what treatments I am interested in; I would like the doctor to make a recommendation',
    id: PREFERENCE_UNKNOWN,
  },
];
