import OrderButton from 'modules/dashboard/sub-modules/treatment-plan/sub-modules/checkout/ui/content/OrderButton';
import BlurbWrapper from 'modules/shared/sub-modules/checkout/ui/wrappers/Blurb';

import ListViewableProducts from 'modules/shared/ui/blocks/ListViewableProducts';

import Loader from 'shared/components/content/Loader';
import Layout from 'shared/components/core/Layout';
import SectionWrapper from 'shared/components/wrappers/Section';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

import PaymentWrapper from './Payment';
import ShippingWrapper from './Shipping';
import SummaryWrapper from './Summary';

import useCheckoutState from '../../../../hooks/useCheckoutState';

export default function CheckoutFormWrapper() {
  const {
    isLoading,

    isAddOnTreatmentPlan,

    cartProducts,
    subscriptions,

    setupIntent,

    onPlaceOrder
  } = useCheckoutState();

  if (isLoading || !setupIntent) {
    return (
      <Layout title='Checkout | Alloy' desc='' noBars>
        <TopBannerWithProgress />

        <Loader />
      </Layout>
    );
  }

  return (
    <Layout title='Checkout | Alloy' desc='' noBars>
      <TopBannerWithProgress />

      <SectionWrapper sectionClasses='dashboard-treatment-plan-section'>
        <div className='col-12'>
          <h1 className='tp-checkout-title'>Checkout</h1>
        </div>

        <div className='col-12 col-md-7 order-2 order-md-1 checkout-col-container'>
          {isAddOnTreatmentPlan && <BlurbWrapper isAddOn />}

          <ShippingWrapper isAddOnTreatmentPlan={isAddOnTreatmentPlan} />

          <PaymentWrapper stripeClientSecret={setupIntent.client_secret} />

          <SummaryWrapper onPlaceOrder={onPlaceOrder} />
        </div>

        <div className='col-12 col-md-5 order-1 order-md-12'>
          <ListViewableProducts
            products={cartProducts}
            subscriptions={subscriptions}
            showDosage
            hideDetails
          />
        </div>
      </SectionWrapper>

      <OrderButton onPlaceOrder={onPlaceOrder} />
    </Layout>
  );
}
