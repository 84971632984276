import { capitalize } from 'lodash';
import { useState } from 'react';

import { useExperience } from 'modules/shared/context/experience';

import { SELECTIONS } from 'modules/shared/data/relief-type';

import { posthogCapture } from 'modules/tracking/lib/posthog';

import { useAppSelector } from 'shared/store/reducers';

export default function useReliefTypeState() {
  const { onNext } = useExperience();

  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);

  const customer = useAppSelector((state) => state.alloy.customer);
  const localPreCustomer = useAppSelector((state) => state.experience.localPreCustomer);
  const firstName = capitalize(customer ? customer.firstName : localPreCustomer.firstName) ?? '';

  const onSubmit = () => {
    if (selectedId) {
      const foundSelection = SELECTIONS.find((s) => s.id === selectedId);

      if (foundSelection) {
        posthogCapture('reliefTypesSelected', foundSelection.categories);

        onNext(foundSelection.categories);
      }
    }
  };

  return { firstName, selectedId, setSelectedId, onSubmit };
}
