import { Dispatch } from '@reduxjs/toolkit';

import { setItem } from 'client/dist/localstorage';

import { LOCAL_USER } from 'shared/store/actions/GlobalTypes';
import { RootState } from 'shared/store/reducers';
import { LocalPreCustomer } from 'shared/store/reducers/ExperienceReducer';

const updateLocalPreCustomer = (updatedLocalPreCustomer: Partial<LocalPreCustomer>) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const localPreCustomer = {
      ...getState().experience.localPreCustomer,
      ...updatedLocalPreCustomer,
    };

    setItem('localPreCustomer', localPreCustomer, true);

    dispatch({ type: LOCAL_USER, payload: localPreCustomer });
  };
};

export { updateLocalPreCustomer };
