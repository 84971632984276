import TotalPriceContent from 'modules/dashboard/ui/content/TotalPrice';

import useTotalsState from '../../hooks/useTotalsState';

/**
 * Component that handles showing the totals and handling the place order button, used within <OrderSummaryBlock />
 */
export default function TotalsBlock() {
  const {
    isLoading,

    cart,
    isConsult,
    isShippingComplete,

    unbundledSubtotal,
    subtotal,
    discount,
    referralCreditDiscount,
    tax,
    shipping,
    total,
  } = useTotalsState();

  return (
    <div className='ce-totals-block'>
      <div className='ce-total-summary-wrapper'>
        <div className='total-summary-row'>
          <p className='total-summary-title-bold'>
            Subtotal <span>(3-month supply)</span>
          </p>

          <TotalPriceContent unbundledTotal={unbundledSubtotal} bundledTotal={subtotal} />
        </div>

        <div className='total-summary-row'>
          <p className='total-summary-title'>
            Discounts{discount !== 0 ? ` (${cart.promotionCode.name})` : ''}
          </p>

          <p className='total-summary-content'>
            {discount !== 0 ? `-$${discount.toFixed(2)}` : '-'}
          </p>
        </div>

        {referralCreditDiscount > 0 && (
          <div className='total-summary-row'>
            <p className='total-summary-title'>Referral Credits</p>

            <p className='total-summary-content'>-${referralCreditDiscount.toFixed(2)}</p>
          </div>
        )}

        <div className='total-summary-row'>
          <p className='total-summary-title'>Tax</p>

          <p className='total-summary-content-tax'>
            {!isShippingComplete || isLoading ? '-' : isConsult ? '$0.00' : `$${tax.toFixed(2)}`}
          </p>
        </div>

        <div className='total-summary-row'>
          <p className='total-summary-title'>Shipping</p>

          <p className='total-summary-content'>
            {isConsult || isLoading ? '-' : shipping === 0 ? 'FREE' : `$${shipping.toFixed(2)}`}
          </p>
        </div>
      </div>

      <div className='ce-total-wrapper'>
        <p className='total-title'>Order Total</p>

        <p className='total-amount'>${total.toFixed(2)}</p>
      </div>
    </div>
  );
}
