import './EditProductsDrawer.scss';

import { xorWith } from 'lodash';
import { useEffect, useState } from 'react';

import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import {
  getDeepProductsFromGroupedProducts,
  getProductsToBeBundledWith,
  isGroupedProductInProductsList,
} from 'modules/shared/lib/product';
import { sortProducts } from 'modules/shared/lib/product/sort';
import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';
import { isConsultCart } from 'modules/shared/sub-modules/checkout/lib/cart';

import AlloyDrawer from 'shared/components/core/AlloyDrawer';

import { useAppSelector } from 'shared/store/reducers';

import BundleSelectableProduct from '../blocks/BundleSelectableProduct';
import SelectableProduct from '../blocks/SelectableProduct';

type ProductsWithType = {
  [key: string]: GroupedContentfulProduct[][];
};

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSave: (products: GroupedContentfulProduct[][]) => void;
  productsWithType: ProductsWithType;
  prevSelectedProducts: GroupedContentfulProduct[];
}

export default function EditProductsDrawer({
  open,
  setOpen,
  onSave,
  productsWithType,
  prevSelectedProducts,
}: Props) {
  const isAuthenticated = useAppSelector((state) => state.alloy.isAuthenticated);
  const { cart } = useAppSelector((state) => state.experience);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer({
    swr: { enabled: isAuthenticated },
  });
  const { activeSubs, pausedSubs } = getSubscriptionsWithStatus(subscriptions);

  const subscribedProducts = [...activeSubs, ...pausedSubs].flatMap((sub) =>
    sub.products.map((p) => p.product),
  );

  const [selectedProducts, setSelectedProducts] = useState(
    getDeepProductsFromGroupedProducts(prevSelectedProducts),
  );
  const [specificProducts, setSpecificProducts] = useState<GroupedContentfulProduct[][]>([]);

  const mhtProducts = productsWithType['mht'] ?? [];
  const upsellProducts = productsWithType['upsell'] ?? [];
  const specificProductsWithoutSub = specificProducts
    .map((gcpList) =>
      gcpList.filter(
        (gcp) =>
          !subscribedProducts.some((sp) =>
            gcp.alloyProduct.parent.every((pf) =>
              ProductRegistry.get().areProductsEquivalent([pf, sp]),
            ),
          ),
      ),
    )
    .filter((gcpList) => gcpList.length);
  const onlyConsultOnCart = isConsultCart(cart);

  useEffect(() => {
    fetchSpecificProducts();
  }, [JSON.stringify(specificProducts), cart.shipNow]);

  /**
   * Here we need to refetch specific products together with products from user active subs.
   * This is because we should filter out all active sub products from Edit Drawer
   * keeping all bundle config any product has with them.
   *
   * Example: if Tretinoin is in drawer and m4 is in an active sub, we should only show
   * Tretinoin in drawer but with bundled price and informing that its parent is m4.
   *
   * TODO: since we need to consider active sub products in various places, we should refactor
   * this logic in the future and think of how to reutilize the active sub logic everywhere it's needed.
   */
  const fetchSpecificProducts = async () => {
    const specificProducts = productsWithType['specific'] ?? [];

    if (specificProducts.length !== 0 && subscribedProducts.length > 0) {
      const products = getDeepProductsFromGroupedProducts(specificProducts.flat());
      // if ship now is selected for request experience, we should filter out subscribed products so we can get the unbundled
      const productsWithoutSubs = products.filter(
        (pf) =>
          !subscribedProducts.some((sp) => ProductRegistry.get().areProductsEquivalent([pf, sp])),
      );
      const productsWithActiveSubs = cart.shipNow
        ? await ProductRegistry.get().getRecurringProductsForV2(productsWithoutSubs)
        : await ProductRegistry.get().getRecurringProductsForV2([
            ...products,
            ...subscribedProducts,
          ]);

      setSpecificProducts(productsWithActiveSubs);
    } else {
      setSpecificProducts(specificProducts);
    }
  };

  const onSelectProduct = (product: GroupedContentfulProduct) => {
    setSelectedProducts(
      xorWith(selectedProducts, product.alloyProduct.parent, (productA, productB) =>
        ProductRegistry.get().areProductsEquivalent([productA, productB]),
      ),
    );
  };

  const areParentsSelected = (parents: GroupedContentfulProduct[]) => {
    return [...selectedProducts, ...subscribedProducts].some((p) =>
      parents.some((gcp) =>
        gcp.alloyProduct.parent.every((pf) => ProductRegistry.get().areProductsEquivalent([pf, p])),
      ),
    );
  };

  const onSaveAndClose = () => {
    const products = Object.values(productsWithType).flatMap((gcp) => gcp);

    const filteredSelectedProducts = products
      .map((gcpList) =>
        gcpList.filter((gcp) => isGroupedProductInProductsList(selectedProducts, gcp)),
      )
      .filter((gcpList) => gcpList.length);

    onSave(filteredSelectedProducts);
  };

  return (
    <AlloyDrawer
      title='Edit selections'
      drawerClass='edit-drawer'
      open={open}
      onClose={() => setOpen(false)}
    >
      <div className='drawer-body'>
        {mhtProducts.length !== 0 && (
          <div className='edit-products-wrapper'>
            {mhtProducts.map((gcpList, listIndex) => {
              return gcpList.map((gcp, index) => {
                const isSelected = isGroupedProductInProductsList(selectedProducts, gcp);

                return (
                  <SelectableProduct
                    key={`${listIndex}-${index}`}
                    groupedProduct={gcp}
                    isSelected={isSelected}
                    onSelect={onSelectProduct}
                    multiSelect
                    hideDetails
                    showProgDetails
                  />
                );
              });
            })}

            <div className='edit-divider' />
          </div>
        )}

        {upsellProducts.length !== 0 && (
          <div className='edit-products-wrapper'>
            <p className='edit-text'>Round out your routine</p>

            {upsellProducts.map((gcpList, listIndex) => {
              return sortProducts(gcpList).map((gcp, index) => {
                const isSelected = isGroupedProductInProductsList(selectedProducts, gcp);

                return (
                  <SelectableProduct
                    key={`${listIndex}-${index}`}
                    groupedProduct={gcp}
                    isSelected={isSelected}
                    onSelect={onSelectProduct}
                    multiSelect
                    hideDetails
                  />
                );
              });
            })}
          </div>
        )}

        {specificProductsWithoutSub.length !== 0 && (
          <div className='edit-products-wrapper'>
            {specificProductsWithoutSub.map((gcpList, listIndex) => {
              return sortProducts(gcpList).map((gcp, index) => {
                const isSelected = isGroupedProductInProductsList(selectedProducts, gcp);
                const parents = getProductsToBeBundledWith(
                  gcp,
                  cart.shipNow && specificProducts.flat().length === 1 ? [] : specificProducts,
                );

                return !!parents.length ? (
                  <BundleSelectableProduct
                    key={`${listIndex}-${index}`}
                    groupedProduct={gcp}
                    isSelected={isSelected}
                    onSelect={onSelectProduct}
                    isParentSelected={areParentsSelected(parents)}
                    parents={parents}
                    hideDetails
                  />
                ) : (
                  <SelectableProduct
                    key={`${listIndex}-${index}`}
                    groupedProduct={gcp}
                    isSelected={isSelected}
                    onSelect={onSelectProduct}
                    multiSelect
                    hideDetails
                  />
                );
              });
            })}
          </div>
        )}
      </div>

      <div className='drawer-footer'>
        <button
          className='primary-button'
          disabled={selectedProducts.length === 0 && !onlyConsultOnCart}
          onClick={onSaveAndClose}
        >
          Save {selectedProducts.length} selection
          {selectedProducts.length !== 1 && 's'}
        </button>
      </div>
    </AlloyDrawer>
  );
}
