import ForgotPage from 'pages/authentication/Forgot';
import LoginPage from 'pages/authentication/Login';
import ResetRequiredPage from 'pages/authentication/ResetRequired';
import StandaloneVerificationPage from 'pages/authentication/StandaloneVerification';
import CheckoutExperiencePage from 'pages/checkout-experience/CheckoutExperience';
import OrderConfirmationPage from 'pages/checkout-experience/OrderConfirmation';
import HomePage from 'pages/dashboard/Home';
import MessagesPage from 'pages/dashboard/Messages';
import OrderHistoryPage from 'pages/dashboard/account/OrderHistory';
import ProfilePage from 'pages/dashboard/account/Profile';
import ReferralsPage from 'pages/dashboard/account/Referrals';
import ManageSubscriptionPage from 'pages/dashboard/subscriptions/ManageSubscription';
import SubscriptionsPage from 'pages/dashboard/subscriptions/Subscriptions';
import CheckoutPage from 'pages/dashboard/treatment-plan/Checkout';
import TreatmentPlanPage from 'pages/dashboard/treatment-plan/TreatmentPlan';
import DataSubjectAccessRequestPage from 'pages/other/DataSubjectAccessRequest';
import GenericThankYouPage from 'pages/other/GenericThankYou';
import MaintenancePage from 'pages/other/Maintenance';
import RequestConfirmationPage from 'pages/request-experience/RequestConfirmation';
import RequestExperiencePage from 'pages/request-experience/RequestExperience';
import DoctorSurveyPage from 'pages/surveys/DoctorSurvey';
import DoctorSurveyConfirmationPage from 'pages/surveys/DoctorSurveyConfirmation';
import SymptomSurveyPage from 'pages/surveys/SymptomSurvey';

import { Route } from '../models/route';

/**
 * private routes are required for customers to be authenticated/logged in
 * in order for them to be accessed
 */
export const PRIVATE_ROUTES: Route[] = [
  {
    path: '/',
    component: process.env.REACT_APP_SHOW_V3_HOME === 'true' ? HomePage : TreatmentPlanPage,
  },

  // conditionally allow setting up home page as a route based on flag
  ...(process.env.REACT_APP_SHOW_V3_HOME === 'true'
    ? [
        {
          path: '/home',
          component: HomePage,
        },
      ]
    : []),

  {
    path: '/treatment-plan',
    component: TreatmentPlanPage,
  },
  {
    path: '/treatment-plan/checkout',
    component: CheckoutPage,
  },

  {
    path: '/messages',
    component: MessagesPage,
  },

  {
    path: '/subscriptions',
    component: SubscriptionsPage,
  },
  {
    path: '/subscriptions/:id',
    component: ManageSubscriptionPage,
  },

  // Account
  {
    path: '/account/profile',
    component: ProfilePage,
  },
  {
    path: '/account/order-history',
    component: OrderHistoryPage,
  },
  {
    path: '/account/referrals',
    component: ReferralsPage,
  },

  // Confirmation stuffs
  {
    path: '/order-confirmation',
    component: OrderConfirmationPage,
  },
  {
    path: '/request-confirmation',
    component: RequestConfirmationPage,
  },
  {
    path: '/doctor-survey-confirmation',
    component: DoctorSurveyConfirmationPage,
  },
  // Doctor survey
  {
    path: '/doctor-survey/*',
    component: DoctorSurveyPage,
  },
];

/**
 * public routes can be accessed by either authenticated or unauthenticated users
 */
export const PUBLIC_ROUTES: Route[] = [
  {
    path: '/checkout-experience/*',
    component: CheckoutExperiencePage,
  },
  {
    path: '/request-experience/*',
    component: RequestExperiencePage,
  },

  // Symptom survey
  {
    path: '/symptomsurvey/*',
    component: SymptomSurveyPage,
  },

  // DSAR
  {
    path: '/dsar',
    component: DataSubjectAccessRequestPage,
  },

  // Waitlist
  {
    path: '/waitlist',
    component: GenericThankYouPage,
  },

  // Maintenace + auth/standalone verification
  {
    path: '/maintenance',
    component: MaintenancePage,
  },
  {
    path: '/standalone-verification',
    component: StandaloneVerificationPage,
  },
];

/**
 * authentication routes can only be accessed when a customer is not authenticated / logged in,
 * these are usually those point of entry routes
 */
export const AUTHENTICATION_ROUTES: Route[] = [
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/forgot',
    component: ForgotPage,
  },
  {
    path: '/reset',
    component: ResetRequiredPage,
  },
];
