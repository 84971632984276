import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  showErrorNotification,
  showSuccessNotification,
} from 'shared/components/core/Notification';

import {
  confirmSignUpWithCode,
  requestForgotPasswordCode,
  resendVerificationCode,
  updatePasswordWithCode,
} from 'shared/lib/aws/aws-auth';

import { ForgotForm } from '../models/forgot-form';

const INIT_FORGOT_FORM: ForgotForm = {
  email: '',
  code: '',
  password: '',
  confirmedChecksSucceeded: false,
};

export default function useForgotState() {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setLoading] = useState(false);
  const [forgotForm, setForgotForm] = useState<ForgotForm>(INIT_FORGOT_FORM);
  const [sentCode, setSentCode] = useState(false);
  const [unconfirmedUser, setUnconfirmedUser] = useState(false);

  const onSendVerificationCode = async () => {
    const { email } = forgotForm;

    try {
      setLoading(true);

      await requestForgotPasswordCode(email);

      setSentCode(true);
      setLoading(false);

      showSuccessNotification(
        "Verification code sent, check your email (don't forget your spam folders)",
      );
    } catch (error: any) {
      if (
        error.message &&
        error.message.includes(
          'Cannot reset password for the user as there is no registered/verified email or phone_number',
        )
      ) {
        setLoading(true);

        setUnconfirmedUser(true);
        setSentCode(true);
        showErrorNotification('Account verification required. Verification code resent.');

        await resendVerificationCode(email);

        setLoading(false);
      } else {
        setLoading(false);
        showErrorNotification(error.message);
      }
    }
  };

  const onConfirmAccount = async () => {
    try {
      const { email, code } = forgotForm;

      setLoading(true);

      await confirmSignUpWithCode(email, code);

      await requestForgotPasswordCode(email);

      setUnconfirmedUser(false);

      setForgotForm({ ...forgotForm, code: '' });
      setSentCode(true);
      setLoading(false);

      showSuccessNotification('Account successfully confirmed. Verification code resent');
    } catch (error: any) {
      setLoading(false);
      showErrorNotification(error.message);
    }
  };

  const onChangePassword = async () => {
    try {
      const { email, password: newPassword, code } = forgotForm;

      setLoading(true);

      await updatePasswordWithCode(email, code, newPassword);

      navigate({ pathname: '/login', search: location.search });

      setSentCode(false);
      setLoading(false);

      showSuccessNotification('Successfully changed password, please login!');
    } catch (error: any) {
      setLoading(false);
      showErrorNotification(error.message);
    }
  };

  return {
    isLoading,

    sentCode,
    unconfirmedUser,

    forgotForm,
    setForgotForm,

    onSendVerificationCode,
    onConfirmAccount,
    onChangePassword,
  };
}
