import { useCancelContext } from 'modules/dashboard/sub-modules/manage-subscription/context/cancel';

import { CANCEL_SAVE } from 'modules/dashboard/sub-modules/manage-subscription/data/reason';

import { useQueryParams } from 'shared/context/url/query';

export default function TooMuchProductContent() {
  const { setParam } = useQueryParams();

  const { onCancel, onSaveSubmission } = useCancelContext();

  const onReschedule = async () => {
    await onSaveSubmission({ 'cancel-saved': CANCEL_SAVE.RESCHEDULE });

    setParam('manage', 'reschedule');
  };

  const onSubmitCancel = async () => {
    await onSaveSubmission();

    await onCancel();
  };

  return (
    <div className='cancel-content-wrapper'>
      <div className='content-block'>
        <h4 className='content-title'>
          Too much product? We can ship your next refill at a later date.
        </h4>
        <p className='content-text'>
          With Alloy, you can easily reschedule your treatments to ship at a later date without
          losing your prescription or access to your doctor. Would you like to reschedule your
          shipment of this treatment?
        </p>
      </div>

      <div className='btns-stack-block'>
        <button className='primary-button' onClick={onReschedule}>
          Yes, reschedule
        </button>

        <button className='primary-link' onClick={onSubmitCancel}>
          No, I want to cancel
        </button>
      </div>
    </div>
  );
}
