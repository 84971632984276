import { useExperience } from 'modules/shared/context/experience';

import VideoBlock from 'modules/shared/sub-modules/upload-video/ui/blocks/Video';

import CheckoutExperienceSection from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceSection';

export default function UploadVideoInfoWrapper() {
  const { onNext } = useExperience();

  return (
    <CheckoutExperienceSection>
      <h1 className='content-title'>Upload a short video</h1>
      <p className='content-text'>
        Telehealth laws in your state require that for additional verification, you record a video
        of yourself. If you'd like, you can upload it now. Otherwise, you can do it later on in your
        dashboard. All of your information will be kept confidential.
      </p>

      <VideoBlock onNext={onNext} />
    </CheckoutExperienceSection>
  );
}
