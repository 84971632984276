import './Shipping.scss';

import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';

import checkmarkBlack from 'modules/dashboard/sub-modules/treatment-plan/sub-modules/checkout/assets/images/checkmark-black.png';

import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';
import { getNumberOfDays } from 'modules/shared/sub-modules/checkout/lib/shipping';

import ShipOptionBlock from 'modules/request-experience/ui/blocks/ShipOptionBlock';

import { useAppSelector } from 'shared/store/reducers';

import AddressBlock from '../blocks/Address';
import ShippingMethodBlock from '../blocks/ShippingMethod';

interface Props {
  isAddOnTreatmentPlan: boolean;
}

export default function ShippingWrapper({ isAddOnTreatmentPlan }: Props) {
  const { cart } = useAppSelector((state) => state.experience);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const { activeSubs } = getSubscriptionsWithStatus(subscriptions);

  return (
    <div className='tp-checkout-ship-wrapper'>
      <p className='checkout-ship-title'>Shipment information</p>

      <p className='checkout-ship-text bottom-padding'>
        <img src={checkmarkBlack} alt='checkmark' className='checkout-ship-checkmark' />
        Your order will ship in {getNumberOfDays(cart.shipping)}
      </p>

      <AddressBlock />

      {isAddOnTreatmentPlan && !!activeSubs.length ? <ShipOptionBlock /> : <ShippingMethodBlock />}
    </div>
  );
}
