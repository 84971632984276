import { Checkout, IntakeCategory, Suggestions } from 'client/dist/generated/alloy';
import { ExperienceCategory } from 'common/dist/models/experience';

import {
  ProductPreference,
  SelectableCategory,
} from 'modules/ab-tests/sub-modules/known-preferences/models/preferences';
import { AlloyCart } from 'modules/shared/models/cart';

import { Address } from 'shared/models/customer-address';

import {
  ALLOY_CART,
  BILLING_ADDRESS,
  CHECKOUT,
  CHECKOUT_EDITING_SHIPPING,
  COGNITO_USER,
  LICENSE_EXISTS,
  LOCAL_USER,
  REQUESTING_PRODUCTS,
} from 'shared/store/actions/GlobalTypes';

export type RequestingDeepProductIds = number[];

export type ExperienceValidation = {
  categories: ExperienceCategory[];
  date: string;
  stepsViewed: string[];
};

export type LocalPreCustomer = Partial<{
  firstName: string;
  state: string;

  marketingEmailConsent: boolean;

  dateOfBirth: string;

  alloySubmissionId: string;
  previousCategoriesForSubmission: string[];
  recentSubmissionDate: string;

  experienceValidation: ExperienceValidation;
  productPreferences: ProductPreference;
  treatmentInterests: SelectableCategory[];
}>;

export interface CognitoUser {
  email: string;
  password: string;
}

export interface ExperienceState {
  cart: AlloyCart;

  requestingDeepProductIds: RequestingDeepProductIds;

  licenseExists: boolean;

  cognitoUser?: CognitoUser; // TODO does this need to be in redux..?
  // note - localPreCustomer is stored and updated both in memory
  // and in localstorage.
  // ONLY used in checkout experience
  localPreCustomer: LocalPreCustomer;

  checkout?: Checkout;

  isEditingShipping: boolean;
  billingAddress: Address;
}

const INIT_STATE: ExperienceState = {
  cart: {
    products: [],
    taxAmountToCollect: 0,
    promotionCode: {
      id: '',
      name: '',
      discountAmount: 0,
      isReferral: false,
    },
    shipping: undefined,
    referralCreditBalance: 0,
    isPurchased: false,
    shipNow: false,
    agreement: {
      isAgreedTerms: false,
      isOptedSmsMarketing: true,
    },
  },

  requestingDeepProductIds: [],

  licenseExists: false,

  cognitoUser: undefined,
  localPreCustomer: {},

  isEditingShipping: false,
  billingAddress: {},
};

// Create reducer
export default function ExperienceReducer(
  state = INIT_STATE,
  action: { payload: Partial<ExperienceState>; type: string },
) {
  switch (action.type) {
    case ALLOY_CART: {
      return { ...state, cart: action.payload };
    }

    case REQUESTING_PRODUCTS: {
      return { ...state, requestingDeepProductIds: action.payload };
    }

    case LICENSE_EXISTS: {
      return { ...state, licenseExists: action.payload };
    }

    case COGNITO_USER: {
      return { ...state, isAuthenticated: false, cognitoUser: action.payload };
    }

    // MARK: This is staying for right now.
    case LOCAL_USER: {
      return { ...state, localPreCustomer: action.payload };
    }

    case CHECKOUT_EDITING_SHIPPING: {
      return { ...state, isEditingShipping: action.payload };
    }

    case BILLING_ADDRESS: {
      return { ...state, billingAddress: action.payload };
    }

    case CHECKOUT: {
      return { ...state, checkout: action.payload };
    }

    default:
      return state;
  }
}
