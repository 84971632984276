import './PasswordFieldsGroup.scss';

import classNames from 'classnames';
import { useState } from 'react';
import { Form } from 'rsuite';

import completeIcon from 'shared/assets/images/complete-icon.png';

import { PasswordField } from './PasswordField';

interface Props {
  onChange: (confirmedChecksSucceeded: boolean, password: string) => void;
  useConfirm?: boolean;
  passwordReset?: boolean;
  hideVisibleAndPlaceholder?: boolean;
}

/**
 * Check whether the pass and the confirmation match, in addition to the single checks
 *
 * @param pass
 * @param confirm
 * @returns {[boolean,string][]}
 */
const checkWithConfirm = (pass: string, confirm: string) => {
  const singleChecks = check(pass);

  return [...singleChecks, [pass === confirm && pass !== '', 'Passwords must match']];
};

/**
 * For every check, test and return the result per test
 *
 * @param p the password
 * @returns {[boolean,string][]} - list of test results with their message
 */
const check = (p: any) => {
  return checks.map(({ test, message }) => [test(p), message]);
};

/**
 * A list of checks to determine whether a password is valid.
 *
 * @type {[{test: (function(*): boolean), message: string}]}
 */
const checks = [
  {
    test: (p: any) => p.length >= 8,
    message: 'At least 8 characters long',
  },
  {
    test: (p: any) => /[a-z]/.test(p),
    message: '1 lowercase letter',
  },
  {
    test: (p: any) => /[A-Z]/.test(p),
    message: '1 uppercase letter',
  },
  {
    test: (p: any) => /\d/.test(p),
    message: '1 number',
  },
  {
    test: (p: any) => /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(p),
    message: '1 special character',
  },
];

/**
 * Standard password widget. Show one or two password fields, along with a list
 * of necessary checks that guarantees a password that fits the usual guidelines.
 *
 * @param onChange - pass a callback to listen to underlying changes. emits {checksSucceeded, password}
 * @param useConfirm - do we need a password confirmation?
 */
const PasswordFieldsGroup = ({
  onChange,
  useConfirm,
  passwordReset,
  hideVisibleAndPlaceholder = false,
}: Props) => {
  const [passwordInfo, setPasswordInfo] = useState({
    password: '',
    passwordConfirm: '',
  });

  const { password, passwordConfirm } = passwordInfo;

  const passwordChecks = useConfirm ? checkWithConfirm(password, passwordConfirm) : check(password);
  const checksSucceeded = passwordChecks.every(([checkPassed]) => checkPassed);

  return (
    <Form.Group className={classNames(!checksSucceeded && 'has-error', 'password-widget')}>
      <PasswordField
        name='password'
        label={passwordReset ? 'Confirm New Password' : 'Confirm Password'}
        hideChecksSucceeded
        hideVisibleIcon={hideVisibleAndPlaceholder}
        placeholder={!hideVisibleAndPlaceholder ? 'Enter your new password' : undefined}
        onChange={(_, password) => {
          setPasswordInfo({ ...passwordInfo, password });

          const confirmedpasswordCheck = useConfirm
            ? checkWithConfirm(password, passwordConfirm)
            : check(password);
          const confirmedChecksSucceeded = confirmedpasswordCheck.every(
            ([checkPassed]) => checkPassed,
          );

          onChange(confirmedChecksSucceeded, password);
        }}
      />

      {useConfirm && (
        <div className='confirm-password'>
          <PasswordField
            name='confirm-password'
            label={passwordReset ? 'Confirm New Password' : 'Confirm Password'}
            placeholder={!hideVisibleAndPlaceholder ? 'Re-enter your new password' : undefined}
            hideChecksSucceeded
            hideVisibleIcon={hideVisibleAndPlaceholder}
            onChange={(_, passwordConfirm) => {
              setPasswordInfo({ ...passwordInfo, passwordConfirm });
              const confirmedpasswordCheck = checkWithConfirm(password, passwordConfirm);
              const confirmedChecksSucceeded = confirmedpasswordCheck.every(
                ([checkPassed]) => checkPassed,
              );
              onChange(confirmedChecksSucceeded, passwordConfirm);
            }}
          />
        </div>
      )}

      <ul className='custom-password'>
        {passwordChecks.map(([passed, message], row) => (
          <li key={row}>
            {passed ? <img src={completeIcon} alt={'Complete icon'} /> : '• '}
            {message}
          </li>
        ))}
      </ul>
    </Form.Group>
  );
};

export default PasswordFieldsGroup;
