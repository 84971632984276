import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getMdiWidget, useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';

import { sendExceptionToSentry } from 'modules/tracking/lib/sentry';

import { updateMdiWidget } from 'modules/shared/store/customer';

import { showErrorNotification } from 'shared/components/core/Notification';

import useMarkNotificationsAsRead from 'shared/hooks/useMarkNotificationsAsRead';

import { useAppSelector } from 'shared/store/reducers';

export default function useMessagesState() {
  // mark notifications as read once on everytime going to page OR reloading
  useMarkNotificationsAsRead(['NEW_DOCTOR_MESSAGE']);

  const dispatch = useDispatch();

  const [isLoadingWidget, setIsLoadingWidget] = useState(true);
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const [isLoadingValidation, setIsLoadingValidation] = useState(true);
  const [isError, setIsError] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const customer = useAppSelector((state) => state.alloy.customer!);
  const mdiWidget = useAppSelector((state) => state.alloy.mdiWidget);

  const { data: subscriptions = [], isLoading: isLoadingSubs } =
    useGetAllSubscriptionsForCustomer();

  const dispatchUpdateMdiWidget = bindActionCreators(updateMdiWidget, dispatch);

  const isLoading = isLoadingSubs || isLoadingWidget;

  useEffect(() => {
    const fetchWidget = async () => {
      try {
        const widget = await getMdiWidget();

        dispatchUpdateMdiWidget(widget);
        setIsLoadingWidget(false);
      } catch (e) {
        dispatchUpdateMdiWidget({ status: 'PENDING' });
        setIsLoadingWidget(false);
      }
    };

    fetchWidget();
  }, []);

  useEffect(() => {
    if (isLoadingIframe) {
      return;
    }

    window.addEventListener('message', validateIframe);
    return () => window.removeEventListener('message', validateIframe);
  }, [isLoadingIframe]);

  const validateIframe = (e: MessageEvent) => {
    // if mdi is not the origin, we just return since we're just checking against them
    // OR
    // return early if there's no code in the payload (pre-deploy)
    if (!e.origin.includes('mdintegrations') || !mdiWidget.code) {
      return;
    }

    const event = e.data?.event;

    // lifecycle is...
    // 1. we ask api for code
    // 2. we wait for message to come in via window
    // 3. if we receive "otp_ready" we send the otp code
    // 4. we receive "otp_success" BUT don't take action yet - because that only varifies 2FA and not widget loading
    // 5. when we receive "load" we know the widget is ready (not just the code entry screen, which we'd rather suppress)
    if (event === 'otp_ready') {
      // post the otp message payload from here
      iframeRef.current?.contentWindow?.postMessage({ message: 'otp', data: mdiWidget.code }, '*');
      setIsError(false);
    }

    if (event === 'load') {
      setIsLoadingValidation(false);
    }

    // error occurred and need to display to customer
    if (event === 'otp_failed' || mdiWidget.code?.length !== 6) {
      setIsError(true);
      const errorMessage =
        'Doctor messaging is down temporarily. Please reach out to support@myalloy.com with any questions.';

      showErrorNotification(errorMessage);
      sendExceptionToSentry(new Error(errorMessage));

      setIsLoadingValidation(false);
    }
  };

  return {
    isLoading,

    isLoadingValidation,
    setIsLoadingIframe,

    isError,

    mdiWidget,
    customer,
    subscriptions,
    iframeRef
  };
}
