import DashboardNavBar from 'modules/dashboard/ui/containers/DashboardNavBar';
import { useEffect } from 'react';

import SEO from 'shared/components/core/SEO';

import Footer from './Footer';

interface Props {
  children: any;
  title: string;
  desc: string;
  noBars?: boolean;
}

export default function Layout({ children, title, desc = '', noBars = false }: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO title={title} description={desc} />

      {!noBars && (
        <header className='dashboard-header-wrapper'>
          <DashboardNavBar />
        </header>
      )}

      <main className='bg-content' role='main'>
        {children}
      </main>

      {!noBars && <Footer />}
    </>
  );
}
