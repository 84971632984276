import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'shared/components/core/Notification';
import { requestForgotPasswordCode, updatePasswordWithCode } from 'shared/lib/aws/aws-auth';

import { ResetForm } from '../models/reset-form';
import { LocationState } from 'shared/models/location-state';

export default function useResetRequiredState() {
  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state as LocationState | null;

  const INIT_FORGOT_FORM: ResetForm = {
    email: state && state.email ? state.email : '',
    code: '',
    password: '',
    confirmedChecksSucceeded: false,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [resetForm, setResetForm] = useState<ResetForm>(INIT_FORGOT_FORM);
  const [sentCode, setSentCode] = useState(false);

  useEffect(() => {
    onSendVerificationCode();
  }, []);

  const onSendVerificationCode = async () => {
    try {
      setIsLoading(true);

      if (resetForm.email !== '') {
        await requestForgotPasswordCode(resetForm.email);

        showSuccessNotification(
          "Verification code sent, check your email (don't forget your spam folders)"
        );

        setSentCode(true);
      }

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      showErrorNotification(error.message);
    }
  };

  const onChangePassword = async () => {
    const { password: newPassword, code } = resetForm;

    try {
      setIsLoading(true);

      await updatePasswordWithCode(resetForm.email, code, newPassword);

      showSuccessNotification('Successfully changed password, please login!');

      navigate({ pathname: '/login', search: location.search });

      setSentCode(false);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      showErrorNotification(error.message);
    }
  };

  return {
    isLoading,

    sentCode,

    resetForm,
    setResetForm,

    onSendVerificationCode,
    onChangePassword,
  };
}
