import { Alert, Box, ThemeProvider, createTheme } from '@mui/material';
import { SnackbarContent, SnackbarKey, useSnackbar } from 'notistack';
import { forwardRef, useCallback } from 'react';

import greenCheckmark from 'shared/assets/svg/notifications/checkmark-green.svg';
import errorIcon from 'shared/assets/svg/notifications/error-icon.svg';
import infoIcon from 'shared/assets/svg/notifications/info-circle-icon.svg';
import warningIcon from 'shared/assets/svg/notifications/warning-icon.svg';

interface Props {
  id: SnackbarKey;
  message: string;
  variant: 'error' | 'info' | 'success' | 'warning';
}

const baseStyles = {
  bgcolor: '#fff',
  border: 'none',
  boxShadow: 'rgba(51, 62, 69, 0.18) 0px 12px 24px',
  width: '400px',
};

const GreenCheckmark = () => (
  <img src={greenCheckmark} alt='checkmark icon' className='alert-icon' />
);
const InfoIcon = () => <img src={infoIcon} alt='info icon' className='alert-icon' />;
const ErrorIcon = () => <img src={errorIcon} alt='error icon' className='alert-icon' />;
const WarningIcon = () => <img src={warningIcon} alt='error icon' className='alert-icon' />;

const theme = createTheme({
  components: {
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          success: <GreenCheckmark />,
          info: <InfoIcon />,
          error: <ErrorIcon />,
          warning: <WarningIcon />,
        },
      },
    },
  },
});

export const AlloySnackbar = forwardRef<HTMLDivElement, Props>(({ id, ...props }, ref) => {
  const { message, variant } = props;

  const { closeSnackbar } = useSnackbar();
  const handleCloseSnackbar = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarContent ref={ref}>
        <Alert onClose={handleCloseSnackbar} severity={variant} variant='outlined' sx={baseStyles}>
          <Box fontSize={18}>{message}</Box>
        </Alert>
      </SnackbarContent>
    </ThemeProvider>
  );
});
