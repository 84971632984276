import { isEmpty } from 'lodash';
import { useState } from 'react';

import { DSARequestPayload, addDSARequest } from 'client/dist/generated/alloy';

import { sendExceptionToSentry } from 'modules/tracking/lib/sentry';

const INIT_FORM: DSARequestPayload = {
  fullName: '',
  stateAbbr: '',
  customerEmail: '',
  customerCity: '',
  customerZip: '',
  customerPhone: '',
  customerAddress: '',
  interaction: '',
  rightToRequest: false,
  rightToCorrect: false,
  rightToDelete: false,
  rightToConfirm: false,
  rightToAppeal: false,
};

export default function useConsentFormState() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [consentForm, setConsentForm] = useState<DSARequestPayload>(INIT_FORM);

  //unlikely someone's interaction with us will contain underscores, but you never know
  const isFormIncomplete =
    Object.entries(consentForm).filter(
      ([key, value]) =>
        typeof value === 'string' && // Only process string values
        (value === '' ||
          (value.includes('_') && (key === 'customerZip' || key === 'customerPhone')))
    ).length > 0;

  const isStateSelected = !isEmpty(consentForm.stateAbbr) && consentForm.stateAbbr !== 'OTHER';
  const isStateSelectedCalifornia = consentForm.stateAbbr === 'CA';

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      await addDSARequest(consentForm);

      setIsSuccess(true);
    } catch (error) {
      sendExceptionToSentry(error as Error);
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    isSuccess,
    isFormIncomplete,

    isStateSelected,
    isStateSelectedCalifornia,

    consentForm,
    setConsentForm,

    onSubmit,
  };
}
