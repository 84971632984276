import { ExperienceCategory } from 'common/dist/models/experience';

import {
  CheckoutReviews,
  TrustpilotReview,
} from 'modules/checkout-experience/data/content/trustpilot-reviews';

// When given a list of categories, return a review related to that category
// Category mht takes precedence over others, otherwise, return the first match

export const getRelevantReview = (
  categories: ExperienceCategory[],
): TrustpilotReview | undefined => {
  if (categories.includes('mht')) {
    return CheckoutReviews.find((review) => review.categories.includes('mht'));
  }

  return CheckoutReviews.find((review) =>
    categories.some((category) => review.categories.includes(category)),
  );
};

// When the category is 'mht', we display the existing dark blue "Stat" element
//  as well as a light-themed relevant review card

export const getReviewTheme = (categories: ExperienceCategory[]) =>
  categories.includes('mht') ? 'light' : 'dark';
