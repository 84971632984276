import { first } from 'lodash';
import { Route, Routes, useLocation } from 'react-router-dom';

import { useCheckoutExperience } from 'modules/checkout-experience/context/checkout-experience';
import { useExperience } from 'modules/shared/context/experience';

import { getAllCheckoutExperienceSteps } from 'modules/checkout-experience/data/steps';

import { retrieveFlowFromUrl } from 'modules/shared/lib/experience/experience-flow';

import { AuthFlowRoute, PrivateFlowRoute } from 'modules/routes/ui/content/HandleExperience';

import Loader from 'shared/components/content/Loader';

export default function CheckoutExperienceRoutesWrapper() {
  const location = useLocation();

  const { isLoading, BASE_PATH } = useCheckoutExperience();
  const { onBack, onNext } = useExperience();

  if (isLoading) return <Loader />;

  const retrievedFlow = retrieveFlowFromUrl(location);
  const firstStep = first(retrievedFlow.steps);

  return (
    <Routes>
      {getAllCheckoutExperienceSteps().map((step, index) => {
        // for pages that are the FIRST in the list of their desginated steps
        // we need to not display an onBack button in the ui otherwise it could end in
        // going back and forth with the history since history in onBack above
        // goes by push and then goBack so doing what we had with Name initially,
        // this will always hide the first step back button!!
        const handleOnBack =
          firstStep && firstStep.validationKey === step.validationKey ? undefined : onBack;

        const path = step.path + (!!step.hasNested ? '/*' : '');

        switch (step.routeType) {
          case 'AUTH':
            return (
              <Route
                key={index}
                path={path}
                element={
                  <AuthFlowRoute
                    component={step.component}
                    basePath={BASE_PATH}
                    onNext={onNext}
                    onBack={handleOnBack}
                    location={location}
                  />
                }
              />
            );

          case 'PRIVATE':
            return (
              <Route
                key={index}
                path={path}
                element={
                  <PrivateFlowRoute
                    component={step.component}
                    basePath={BASE_PATH}
                    onNext={onNext}
                    onBack={handleOnBack}
                    location={location}
                  />
                }
              />
            );

          default:
            return (
              <Route
                key={index}
                path={path}
                element={
                  <step.component
                    component={step.component}
                    onNext={onNext}
                    onBack={handleOnBack}
                  />
                }
              />
            );
        }
      })}
    </Routes>
  );
}
