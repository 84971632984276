import { Link } from 'react-router-dom';

import NotificationCardBlock from './NotificationCard';

export default function NoActiveSubsCardBlock() {
  return (
    <NotificationCardBlock
      title="You don't have any active treatments."
      description='Your treatments are inactive. They can be renewed in your subscriptions.'
    >
      <Link to='/subscriptions' className='primary-button mt-3'>
        View subscriptions
      </Link>
    </NotificationCardBlock>
  );
}
