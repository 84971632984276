import { Customer, CustomerStatus, MdiWidget } from 'client/dist/generated/alloy';

import { ContentfulBanner } from 'shared/models/contentful/banner';

import {
  CUSTOMER,
  IS_APP_LOADED,
  IS_AUTHENTICATED,
  MDI_WIDGET,
  MESSAGES_BANNER,
  TOP_ALERT_BANNER,
} from 'shared/store/actions/GlobalTypes';

export interface AlloyState {
  isAppLoaded: boolean;

  isAuthenticated: boolean;
  customer?: Customer & { status: CustomerStatus };

  topAlertBanner: ContentfulBanner | null;
  messagesBanner: ContentfulBanner | null;

  mdiWidget: MdiWidget;
}

const INIT_STATE: AlloyState = {
  isAppLoaded: false,

  isAuthenticated: false,
  customer: undefined,

  topAlertBanner: null,
  messagesBanner: null,

  mdiWidget: {
    status: 'INACTIVE',
  },
};

export default function AccountReducer(
  state = INIT_STATE,
  action: { payload: Partial<AlloyState>; type: string },
) {
  switch (action.type) {
    case IS_APP_LOADED: {
      return { ...state, isAppLoaded: true };
    }

    case IS_AUTHENTICATED: {
      return { ...state, isAuthenticated: action.payload };
    }

    case CUSTOMER: {
      return { ...state, customer: action.payload };
    }

    case TOP_ALERT_BANNER: {
      return { ...state, topAlertBanner: action.payload };
    }

    case MESSAGES_BANNER: {
      return { ...state, messagesBanner: action.payload };
    }

    case MDI_WIDGET: {
      return { ...state, mdiWidget: action.payload };
    }

    default: {
      return state;
    }
  }
}
