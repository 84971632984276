import './InfoSection.scss';

import infinityIcon from 'modules/dashboard/assets/svg/infinity-icon.svg';
import mobileIcon from 'modules/dashboard/assets/svg/mobile-icon.svg';
import shippingIcon from 'modules/dashboard/assets/svg/shipping-icon.svg';

import SectionWrapper from 'shared/components/wrappers/Section';

const infoItems = [
  {
    icon: shippingIcon,
    text: 'Free shipping, cancel anytime',
    alt: 'shipping icon',
  },
  {
    icon: infinityIcon,
    text: 'Unlimited messaging with your care team; commited to helping you get the right dose.',
    alt: 'infinity icon',
  },
  {
    icon: mobileIcon,
    text: 'Community events and support groups ',
    alt: 'mobile icon',
  },
];

/**
 * Info section is used within the entirety of the treatment plan page in order to display quick info items!
 */
export default function InfoSectionWrapper() {
  return (
    <SectionWrapper sectionClasses='dashboard-tp-info-section'>
      <div className='col-12'>
        <ul className='info-list'>
          {infoItems.map((info, index) => (
            <li className='list-item' key={index}>
              <img src={info.icon} alt={info.alt} /> <p>{info.text}</p>
            </li>
          ))}
        </ul>
      </div>
    </SectionWrapper>
  );
}
