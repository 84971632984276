import { SubmissionAnswer } from 'client/dist/generated/alloy';
import { Question } from 'common/dist/models/questionnaire';
import { Navigate, Route, Routes } from 'react-router-dom';

import { RelevantProduct } from 'modules/shared/models/experience';
import { QuestionnaireAnswers } from 'modules/questionnaire/models/questionnaire-answers';
import useQuestionnaire from 'modules/questionnaire/hooks/useQuestionnaire';
import QuestionsWrapper from './Questions';

interface Props {
  questions: Question[];
  previousResults: QuestionnaireAnswers;
  onSubmit: (results: SubmissionAnswer[]) => void;
  onBack?: () => void;
  hideProgressBar?: boolean;
  metaTitle?: string;
  relevantProducts?: RelevantProduct[];
  introduction?: JSX.Element;
  basePath: string;
}

export default function QuestionnaireWrapper({
  questions,
  previousResults,
  onSubmit,
  onBack: rootOnBack,
  hideProgressBar,
  metaTitle = 'Intake - Alloy',
  relevantProducts = [],
  introduction,
  basePath,
}: Props) {
  const {
    pages,
    slugs,
    answers,
    categories,
    parentPath,

    getInitialRoute,

    onNext,
    onBack,
  } = useQuestionnaire({
    questions,
    previousResults,
    onSubmit,
    onBack: rootOnBack,
    relevantProducts,
    basePath,
  });

  return (
    <Routes>
      <Route path='/' element={<Navigate to={getInitialRoute()} replace />} />

      {pages.map((pageQuestions, index) => (
        <Route
          key={slugs[index]}
          path={slugs[index]}
          element={
            <QuestionsWrapper
              questions={pageQuestions}
              allAnswers={answers}
              onNext={onNext}
              // there doesn't need to be a back button for the request experience since it is the first page
              // TODO: go over a better approach around universal usage - for mikhail
              onBack={
                (index === 0 && parentPath.includes('request-experience')) ||
                (!onBack && index === 0)
                  ? undefined
                  : onBack
              }
              hideProgressBar={hideProgressBar}
              metaTitle={metaTitle}
              relevantProducts={relevantProducts}
              introduction={introduction}
              type={categories.length === 0 ? 'survey' : 'intake'}
              isLastQuestion={pages.length - 1 === index}
            />
          }
        />
      ))}

      <Route path='*' element={<Navigate to={getInitialRoute()} replace />} />
    </Routes>
  );
}
