import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { SubscriptionWithRenewal } from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { getBundledProductsFrom } from 'modules/shared/lib/product';

import BundleViewableProduct from 'modules/shared/ui/blocks/BundleViewableProduct';
import ViewableProduct from 'modules/shared/ui/blocks/ViewableProduct';

import { ProductSwitchChangeType } from '../../models/product-switch-type';

interface Props {
  product: GroupedContentfulProduct;
  subscription: SubscriptionWithRenewal;
  selectedType: ProductSwitchChangeType | undefined;
  setSelectedType: (type: ProductSwitchChangeType) => void;
  onlyDosageSwitch: boolean;
  onlyFormFactorSwitch: boolean;
}

export default function HeaderWrapper({
  product,
  subscription,
  selectedType,
  setSelectedType,
  onlyDosageSwitch,
  onlyFormFactorSwitch,
}: Props) {
  const [bundledProducts, setBundledProducts] = useState<GroupedContentfulProduct[]>([]);

  const subProducts =
    subscription && subscription.status === 'ACTIVE'
      ? subscription.products.map((p) => p.product)
      : [];

  useEffect(() => {
    const fetchProducts = async () => {
      const bundled = await getBundledProductsFrom(subProducts, product);

      setBundledProducts(bundled);
    };

    fetchProducts();
  }, []);

  return (
    <div className='request-content-wrapper'>
      <p className='content-title'>Your current treatment</p>

      <div className='request-products-wrapper'>
        {!!bundledProducts.length ? (
          <BundleViewableProduct
            groupedProduct={product}
            isParentSelected
            parents={bundledProducts}
            showDosage
            hideProductInformation
            hideDetails
          />
        ) : (
          <ViewableProduct groupedProduct={product} showDosage hideProductInformation hideDetails />
        )}
      </div>

      {!onlyDosageSwitch && !onlyFormFactorSwitch && (
        <div className='request-products-buttons'>
          <button
            className={classNames(
              'secondary-border-button',
              selectedType === 'CUSTOMER_FORM_FACTOR_CHANGE' && 'selected-button',
            )}
            onClick={() => setSelectedType('CUSTOMER_FORM_FACTOR_CHANGE')}
          >
            Change treatment
          </button>

          <button
            className={classNames(
              'secondary-border-button',
              selectedType === 'CUSTOMER_DOSAGE_CHANGE' && 'selected-button',
            )}
            onClick={() => setSelectedType('CUSTOMER_DOSAGE_CHANGE')}
          >
            Change dosage
          </button>
        </div>
      )}
    </div>
  );
}
