import './TopBannerWithProgress.scss';

import classNames from 'classnames';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getPercentComplete } from 'modules/checkout-experience/lib/progress';

import arrowLeft from 'shared/assets/svg/arrows/arrow-left.svg';
import helpIcon from 'shared/assets/svg/common/help-icon.svg';

import { EmailUs } from 'shared/components/content/Buttons';
import Banner from 'shared/components/wrappers/Banner';

import AlloyModal from '../core/AlloyModal';

interface Props {
  onBack?: () => void;
  hideProgress?: boolean;
}

export default function TopBannerWithProgress({ onBack, hideProgress }: Props) {
  const location = useLocation();

  const [isHelpOpen, setIsHelpOpen] = useState(false);

  const percentComplete = getPercentComplete(location);

  return (
    <div className='top-banner-container'>
      <section className='top-banner-section'>
        <div className='banner-left-content'>
          <button
            className={classNames('content-back-button', !onBack && 'hide-content')}
            onClick={onBack}
            disabled={!onBack}
          >
            <img src={arrowLeft} alt='Back icon' className='back-icon' />
            <span className='back-text'>Back</span>
          </button>
        </div>

        <a href={`${process.env.REACT_APP_MARKETING_URL}`} className='banner-center-button'>
          <div className='banner-icon' />
        </a>

        <button className='banner-help-button' onClick={() => setIsHelpOpen(true)}>
          <span className='help-text'>Help</span>
          <img src={helpIcon} alt='help icon' className='help-icon' />
        </button>
      </section>

      {!hideProgress && percentComplete !== 0 && (
        <div className='progress-bar-wrapper'>
          <div className='progress-bar-inner' style={{ width: `${percentComplete}%` }} />
        </div>
      )}

      <Banner />

      <AlloyModal isOpen={isHelpOpen} onClose={() => setIsHelpOpen(false)}>
        <div className='modal-body'>
          <p className='modal-title'>
            Need help? Find answers{' '}
            <a
              className='text-underline'
              href='https://myalloy.zendesk.com/hc/en-us'
              target='_blank'
              rel='noreferrer'
            >
              here
            </a>
            .
          </p>
          <p className='modal-text help-desc'>
            You can also contact our support team by texting 888-660-1816 or emailing{' '}
            <EmailUs btnClass='anchor-link' />.
          </p>
        </div>
      </AlloyModal>
    </div>
  );
}
