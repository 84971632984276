import './RenewalConfirmationInfo.scss';

import { useNavigate } from 'react-router-dom';

import { EmailUs } from 'shared/components/content/Buttons';

import { useAppSelector } from 'shared/store/reducers';

export default function RenewalConfirmationInfoWrapper() {
  const navigate = useNavigate();

  const onComplete = () => navigate('/');

  const checkout = useAppSelector((state) => state.experience.checkout);

  return (
    <section className='auth-section'>
      {checkout && <div className='fp-checkout-id' data-checkout-id={checkout.id} />}
      <div className='container'>
        <div className='row align-items-center mt-4 mt-md-0'>
          <div className='col-12 text-center'>
            <p className='assessment-top-title'>Renewal confirmation</p>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-7'>
            <div className='assessment-wrapper'>
              <div className='assessment-group text-center'>
                <h5 className='assessment-title pb-2'>Thanks for completing your renewal!</h5>
                <p className='assessment-desc light'>
                  Your doctor will review any updated information, and, assuming no changes need to
                  be made, your prescription will come in the mail as usual.
                  <br />
                  <br />
                  Loving Alloy? Leave us a review by emailing <EmailUs /> & we'll send you a
                  referral code to share with friends and save!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='row btn-row'>
          <div className='col-12 text-center'>
            <button className='primary-button' onClick={onComplete}>
              Back to your dashboard
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
