import { ExperienceValidationKey } from 'modules/checkout-experience/lib/validation';

import { ExperienceFlowStep } from 'modules/shared/models/experience';

import RegisterPage from 'pages/authentication/Register';
import VerificationPage from 'pages/authentication/Verification';
import AddOnPreferencesPage from 'pages/checkout-experience/AddOnPreferences';
import CheckoutPage from 'pages/checkout-experience/Checkout';
import ExpectationsPage from 'pages/checkout-experience/Expectations';
import IntakePage from 'pages/checkout-experience/Intake';
import KnownPreferencesPage from 'pages/checkout-experience/KnownPreferences';
import MhtPreferencesPage from 'pages/checkout-experience/MhtPreferences';
import NamePage from 'pages/checkout-experience/Name';
import ReliefTypePage from 'pages/checkout-experience/ReliefType';
import TreatmentInterestsPage from 'pages/checkout-experience/TreatmentInterests';
import ReviewPage from 'pages/shared/Review';
import UploadVideoPage from 'pages/shared/UploadVideo';
import VerifyIdentityPage from 'pages/shared/VerifyIdentity';

export const mhtSteps: ExperienceFlowStep[] = [
  {
    validationKey: ExperienceValidationKey.name,
    component: NamePage,
    path: 'name',
    routeType: 'PUBLIC',
  },
  {
    validationKey: ExperienceValidationKey.reliefType,
    component: ReliefTypePage,
    path: 'relief-type',
    routeType: 'PUBLIC',
  },
  {
    validationKey: ExperienceValidationKey.expectations,
    component: ExpectationsPage,
    path: 'expectations',
    routeType: 'PUBLIC',
  },
  {
    validationKey: ExperienceValidationKey.knownPreferences,
    component: KnownPreferencesPage,
    path: 'known-preferences',
    routeType: 'PUBLIC',
  },
  {
    validationKey: ExperienceValidationKey.treatmentInterests,
    component: TreatmentInterestsPage,
    path: 'treatment-interests',
    routeType: 'PUBLIC',
  },
  {
    validationKey: ExperienceValidationKey.mhtPreferences,
    component: MhtPreferencesPage,
    path: 'mht-preferences',
    routeType: 'PUBLIC',
  },
  {
    validationKey: ExperienceValidationKey.addOnPreferences,
    component: AddOnPreferencesPage,
    path: 'add-on-preferences',
    routeType: 'PUBLIC',
  },
  {
    validationKey: ExperienceValidationKey.intake,
    component: IntakePage,
    path: 'intake',
    routeType: 'PUBLIC',
    hasNested: true,
  },

  {
    validationKey: ExperienceValidationKey.review,
    component: ReviewPage,
    path: 'review',
    routeType: 'PUBLIC',
  },

  {
    validationKey: ExperienceValidationKey.register,
    component: RegisterPage,
    path: 'register',
    routeType: 'AUTH',
  },
  {
    validationKey: ExperienceValidationKey.verification,
    component: VerificationPage,
    path: 'verification',
    routeType: 'AUTH',
  },

  {
    validationKey: ExperienceValidationKey.uploadId,
    component: VerifyIdentityPage,
    path: 'verify-identity',
    routeType: 'PRIVATE',
  },
  {
    validationKey: ExperienceValidationKey.uploadVideo,
    component: UploadVideoPage,
    path: 'upload-video',
    routeType: 'PRIVATE',
  },

  {
    validationKey: ExperienceValidationKey.checkout,
    component: CheckoutPage,
    path: 'checkout',
    routeType: 'PRIVATE',
  },
];
