import './SubscriptionsList.scss';

import { Skeleton } from '@mui/material';

import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';

import EmptyState from 'modules/shared/ui/content/EmptyState';

import useSubscriptionsState from '../../hooks/useSubscriptionsState';
import HeaderProductsCard from '../blocks/HeaderProductsCard';
import BundleDrawer from '../drawers/BundleDrawer';

export default function SubscriptionsListWrapper() {
  const {
    isLoading,

    customer,
    subscriptions,
    filteredSubs,

    isDrawerOpen,
    setIsDrawerOpen,

    setIsLoadingUpdates,

    canBundleSubs,
    didBundle,

    onBundled,
  } = useSubscriptionsState();

  const { activeSubs, pausedSubs, cancelledSubs } = getSubscriptionsWithStatus(subscriptions);

  if (isLoading) {
    return (
      <>
        <Skeleton variant='rectangular' height={200} className='mb-3' />
        <Skeleton variant='rectangular' height={200} />
      </>
    );
  }

  if (customer.status === 'NEW') {
    return <EmptyState type='NEW' />;
  } else if (customer.status === 'PENDING') {
    return <EmptyState type='PENDING' />;
  } else if (subscriptions.length === 0) {
    return (
      <EmptyState
        type='CONSULT'
        title="Ready for relief? Let's make it happen."
        desc="Oops! You don't have any upcoming shipments. Once you have active Alloy shipments, they will appear here."
        btnTitle='Start consult'
      />
    );
  }

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          {canBundleSubs && (
            <div className='subscriptions-bundle-container'>
              <div className='bundle-content-wrapper'>
                <p className='content-title'>Get fewer shipments</p>
                <p className='content-text'>
                  Bundle your orders together, and get all of your treatments in the same shipment.
                </p>
              </div>

              <button className='primary-button' onClick={() => setIsDrawerOpen(true)}>
                Bundle your shipments
              </button>
            </div>
          )}

          {didBundle && (
            <div className='subscriptions-bundle-container'>
              <div className='bundle-content-wrapper'>
                <p className='content-title'>Thanks for bundling your orders!</p>
              </div>
            </div>
          )}

          {!!activeSubs.length && (
            <div className='subscriptions-container'>
              {activeSubs.map((subscription, index) => (
                <div className='subscription-wrapper' key={index}>
                  <HeaderProductsCard
                    subscription={subscription}
                    setIsLoadingUpdates={setIsLoadingUpdates}
                  />
                </div>
              ))}
            </div>
          )}

          {(!!pausedSubs.length || !!cancelledSubs.length) && (
            <div className='subscriptions-container'>
              {!!activeSubs.length && <h3 className='container-title'>Inactive</h3>}

              {[...pausedSubs, ...cancelledSubs].map((subscription, index) => (
                <div className='subscription-wrapper' key={index}>
                  <HeaderProductsCard
                    subscription={subscription}
                    setIsLoadingUpdates={setIsLoadingUpdates}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {activeSubs.length > 1 && (
        <BundleDrawer
          open={isDrawerOpen}
          setOpen={setIsDrawerOpen}
          bundableSubscriptions={filteredSubs}
          setDidBundle={onBundled}
        />
      )}
    </>
  );
}
