import './ActiveProduct.scss';

import { format } from 'date-fns';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { SubscriptionWithRenewal } from 'client/dist/generated/alloy';

import useActiveProductState from 'modules/dashboard/sub-modules/treatment-plan/sub-modules/home/hooks/useActiveProductState';

import { ProductRenewalPrescription } from 'modules/dashboard/sub-modules/treatment-plan/models/product-renewal-prescription';

import RequestDrawer from 'modules/dashboard/sub-modules/request-switch/ui/drawers/RequestDrawer';

import ProductNameDosage from 'modules/shared/ui/blocks/ProductNameDosage';

import ProductInfoDrawer from 'modules/shared/ui/drawers/ProductInfoDrawer';

import arrowRightIcon from 'shared/assets/svg/arrows/arrow-right.svg';

interface Props {
  product: ProductRenewalPrescription;
  nextRecurrenceOn?: string;
  subscription?: SubscriptionWithRenewal;
  isProcessing?: boolean;
  showDosage?: boolean;
  children?: ReactNode;
  customHug?: string;
}

export default function ActiveProduct({
  product,
  nextRecurrenceOn,
  subscription,
  isProcessing = false,
  showDosage = true,
  children,
  customHug,
}: Props) {
  const {
    isRx,
    productsCleanNameAndDosage,
    showFills,
    showNextShipment,
    isSynbioticProduct,
    showExpiration,
    showSwitch,
    renewalCompletedDate,
    isOneTimeProduct,
    needsRenewed,

    isInfoOpen,
    setIsInfoOpen,

    isSwitchOpen,

    onOpenInfo,
    onOpenSwitch,
    onCloseSwitch,
    onRenew,
  } = useActiveProductState({ product, subscription, isProcessing, showDosage });

  const { prescription, contentfulProduct, renewal: renewalInfo } = product;

  return (
    <div className='active-product'>
      {isRx && <span className='product-rx-tag'>Rx</span>}

      <div className='product-header'>
        <div className='header-content'>
          <img
            src={contentfulProduct?.fields.photo.fields.file.url}
            alt={contentfulProduct?.fields.photo.fields.title}
            className='header-img'
          />

          <div className='header-text-wrapper'>
            {productsCleanNameAndDosage.map(({ cleanName, dosage }, index) => (
              <ProductNameDosage key={index} cleanName={cleanName} dosage={dosage} />
            ))}

            <p className='header-text mt-2'>
              {showFills && (
                <span>
                  {prescription?.fillsRemaining === 0
                    ? 'No refills'
                    : `Refills: ${prescription?.fillsRemaining}`}
                </span>
              )}

              {showNextShipment && !!nextRecurrenceOn && !renewalCompletedDate && (
                <Link to='/subscriptions' className='product-link'>
                  <span className='link-underline'>
                    Next {isSynbioticProduct ? 'shipment' : 'refill'}:{' '}
                    {format(new Date(nextRecurrenceOn), 'MM/dd/yyyy')}
                  </span>

                  <img src={arrowRightIcon} alt='arrow right' className='link-arrow' />
                </Link>
              )}

              {showExpiration && (
                <span className='header-tag'>
                  Expires in {renewalInfo?.daysRemaining} day
                  {renewalInfo?.daysRemaining !== 1 && 's'}
                </span>
              )}

              {renewalCompletedDate && (
                <div className='header-tag d-sm-block d-md-block d-lg-inline d-xl-inline'>
                  Renewal Completed {format(renewalCompletedDate, 'MM/dd/yy')}
                </div>
              )}

              {renewalInfo?.hasExpired && <span className='header-tag'>Expired</span>}
            </p>

            {!isProcessing && (
              <button className='product-link mt-2' type='button' onClick={onOpenInfo}>
                <span className='link-underline'>Product information</span>
              </button>
            )}
          </div>
        </div>

        {showSwitch && (
          <div className='header-action d-none d-md-flex'>
            <button className='product-link' onClick={onOpenSwitch}>
              <span className='link-underline'>Request a change</span>
            </button>
          </div>
        )}

        {customHug && (
          <p className='m-0 p-0'>
            <span className='product-hug'>{customHug}</span>
          </p>
        )}
      </div>

      {isOneTimeProduct && (
        <>
          <p className='product-special-blurb'>
            Next step: Get an updated mammogram & message your doctor
          </p>

          <Link to='/messages' className='secondary-border-button mt-3'>
            Message your doctor
          </Link>
        </>
      )}

      {needsRenewed && !renewalCompletedDate && !isOneTimeProduct && (
        <button className='primary-button mt-3' onClick={onRenew}>
          Renew your prescription
        </button>
      )}

      {showSwitch && (
        <div className='header-action d-block d-md-none'>
          <button className='product-link' onClick={onOpenSwitch}>
            <span className='link-underline'>Request a change</span>
          </button>
        </div>
      )}

      <div>{children}</div>

      <ProductInfoDrawer open={isInfoOpen} setOpen={setIsInfoOpen} product={contentfulProduct} />

      {showSwitch && (
        <RequestDrawer
          open={isSwitchOpen}
          onClose={onCloseSwitch}
          product={product}
          subscription={subscription!}
        />
      )}
    </div>
  );
}
