import { uploadVideo } from 'client/dist/generated/alloy';
import { videoProblemMessage } from 'common/dist/license-uploads/isTooBig';
import { isFileValid } from 'shared/lib/file';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'shared/components/core/Notification';
import { useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { Form, Loader, Modal } from 'rsuite';
import { UploadField } from 'shared/components/fields/UploadField';

const videoConstraints = {
  width: 400,
  facingMode: 'user',
};

interface Props {
  open: boolean;
  onClose: (isUploaded: boolean) => void;
}

// TODO: Sometime later clean this up to follow modal pattern
export default function VideoModal({ open, onClose }: Props) {
  const [isRecordOpen, setIsRecordOpen] = useState<boolean>(false);
  const [hasCameraAccess, setHasCameraAccess] = useState<boolean>(true);

  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);

  const webcamRef = useRef<any>(null);
  const mediaRecorderRef = useRef<any>(null);
  const [isCapturing, setIsCapturing] = useState<boolean>(false);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const mediaOptions = getMediaOptions();

    if (!mediaOptions) {
      setHasCameraAccess(false);
    }
  }, []);

  const getMediaOptions = () => {
    if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
      return { mimeType: 'video/webm; codecs=vp9' };
    } else if (MediaRecorder.isTypeSupported('video/webm')) {
      return { mimeType: 'video/webm' };
    } else if (MediaRecorder.isTypeSupported('video/mp4')) {
      return { mimeType: 'video/mp4', videoBitsPerSecond: 160000 };
    } else {
      return null;
    }
  };

  const handleDataAvailable = useCallback(
    ({ data }: { data: Blob }) => {
      if (data.size > 0) {
        const chunks = recordedChunks.concat(data);
        setRecordedChunks(chunks);

        const mediaOptions = getMediaOptions();

        const updatedType =
          mediaOptions && mediaOptions.mimeType && mediaOptions.mimeType.includes('video/mp4')
            ? 'video/mp4'
            : 'video/webm';

        const blob = new Blob(chunks, {
          type: updatedType,
        });

        setVideoBlob(blob);
        setIsRecordOpen(false);
        setRecordedChunks([]);
      }
    },
    [setRecordedChunks]
  );

  const onStartCaptureClick = useCallback(() => {
    setIsCapturing(true);

    const mediaOptions = getMediaOptions();

    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, mediaOptions!);

    mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);

    mediaRecorderRef.current.start();
  }, [webcamRef, setIsCapturing, mediaRecorderRef]);

  const onStopCaptureClick = useCallback(() => {
    setIsCapturing(false);

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  }, [mediaRecorderRef, setIsCapturing]);

  const onRemove = () => {
    setVideoBlob(null);
  };

  const onSubmit = async () => {
    try {
      if (videoBlob) {
        setIsLoading(true);

        await uploadVideo({
          file: videoBlob,
        });

        showSuccessNotification('Successfully uploaded video!');

        setIsLoading(false);
        onClose(true);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      overflow={true}
      size='lg'
      className='modal-light video-modal'
      onClose={() => {
        if (!isLoading) {
          setVideoBlob(null);
          setIsRecordOpen(false);

          onClose(false);
        }
      }}
    >
      <Modal.Header />

      <Modal.Body>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-7'>
              {isLoading ? (
                <div className='video-modal-block-loader'>
                  <Loader size='md' />
                </div>
              ) : (
                <div className='video-modal-block'>
                  {videoBlob && (
                    <>
                      <h4 className='video-title'>Video ready to submit</h4>
                      <p className='video-text'>
                        Telehealth laws in your state require that for additional verification, you
                        record a video of yourself. All of your information will be kept
                        confidential.
                      </p>

                      <div className='video-example-wrapper sml'>
                        <video controls>
                          <source src={URL.createObjectURL(videoBlob)} type='video/mp4' />
                          Your browser does not support videos
                        </video>
                      </div>

                      <div className='video-btn-block'>
                        <button className='btn-video btn-video-primary' onClick={onSubmit}>
                          Submit
                        </button>

                        <button className='btn-video btn-video-link' onClick={onRemove}>
                          Upload or take new video
                        </button>
                      </div>
                    </>
                  )}

                  {isRecordOpen && !videoBlob && (
                    <>
                      <h4 className='video-title'>Record a video</h4>
                      <p className='video-text'>
                        Telehealth laws in your state require that for additional verification, you
                        record a video of yourself. All of your information will be kept
                        confidential.
                      </p>

                      <div className='video-webcam-wrapper'>
                        {!videoBlob && hasCameraAccess ? (
                          <Webcam
                            className='video-webcam'
                            audio
                            muted
                            width={400}
                            height={300}
                            ref={webcamRef}
                            videoConstraints={videoConstraints}
                            onUserMediaError={(error) => {
                              if (error) {
                                setHasCameraAccess(false);
                              }
                            }}
                          />
                        ) : (
                          <p className='video-text bold'>
                            You have not authorized your webcam to record a video on Alloy.
                            Authorize your webcam for {window.location.host} in browser settings.
                          </p>
                        )}
                      </div>

                      <div className='video-btn-block'>
                        {hasCameraAccess && (
                          <>
                            {isCapturing ? (
                              <button
                                className='btn-video btn-video-primary'
                                onClick={onStopCaptureClick}
                              >
                                Finish recording
                              </button>
                            ) : (
                              <button
                                className='btn-video btn-video-primary'
                                onClick={onStartCaptureClick}
                              >
                                Start recording
                              </button>
                            )}
                          </>
                        )}

                        {!isCapturing && (
                          <button
                            className='btn-video btn-video-link'
                            onClick={() => setIsRecordOpen(false)}
                          >
                            Upload a video
                          </button>
                        )}
                      </div>
                    </>
                  )}

                  {!isRecordOpen && !videoBlob && (
                    <>
                      <h4 className='video-title'>Upload or take a video</h4>
                      <p className='video-text'>
                        Telehealth laws in your state require that for additional verification, you
                        record a video of yourself. All of your information will be kept
                        confidential.
                      </p>

                      <div className='video-btn-block'>
                        <Form>
                          <UploadField
                            name='validVideo'
                            classes='btn-video-upload'
                            accept='.mp4,.webm,.mov'
                            onChange={async (blobs) => {
                              const blob = blobs[blobs.length - 1];

                              if (!isFileValid(blob)) {
                                showErrorNotification(videoProblemMessage, true);
                              } else {
                                setVideoBlob(blob);
                              }
                            }}
                          />
                        </Form>

                        {hasCameraAccess && (
                          <button
                            className='btn-video btn-video-link'
                            onClick={() => setIsRecordOpen(true)}
                          >
                            Record a video
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
