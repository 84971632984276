import './ProductList.scss';

import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { getProductsToBeBundledWith } from 'modules/shared/lib/product';

import BundleViewableProduct from 'modules/shared/ui/blocks/BundleViewableProduct';
import ViewableProduct from 'modules/shared/ui/blocks/ViewableProduct';

interface Props {
  products: GroupedContentfulProduct[][];
}

export default function ProductListBlock({ products }: Props) {
  return (
    <div className='manage-product-list-block'>
      {products.map((gcpList, listIndex) =>
        gcpList.map((gcp, index) => {
          const isBundled = gcpList.length > 1 && !!gcp.contentfulProduct.fields.bundledPrice;
          const parents = getProductsToBeBundledWith(gcp, products);

          return isBundled ? (
            <BundleViewableProduct
              key={`${listIndex}-${index}`}
              groupedProduct={gcp}
              hideProductInformation
              hideDetails
              showDosage
              isParentSelected={!!parents.length}
              parents={parents}
            />
          ) : (
            <ViewableProduct
              key={`${listIndex}-${index}`}
              groupedProduct={gcp}
              hideProductInformation
              hideDetails
              showDosage
            />
          );
        }),
      )}
    </div>
  );
}
