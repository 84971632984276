import { bindActionCreators } from '@reduxjs/toolkit';
import { uniq, xor } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ExperienceCategory } from 'common/dist/models/experience';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { useExperience } from 'modules/shared/context/experience';

import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';

import { updateRequestingProducts } from 'modules/shared/store/requesting-products';

import { useAppSelector } from 'shared/store/reducers';

import useUpsellProducts from './useUpsellProducts';

export default function useAddOnPreferencesState() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { onNext } = useExperience();
  const { isLoading, products } = useUpsellProducts();

  const categories = retrieveCategoriesFromUrl(location);

  const prevRequestedDeepProductIds = useAppSelector(
    (state) => state.experience.requestingDeepProductIds,
  );

  const [requestedDeepProductIds, setRequestedDeepProductIds] = useState(
    prevRequestedDeepProductIds,
  );

  const dispatchUpdateRequesting = bindActionCreators(updateRequestingProducts, dispatch);

  useEffect(() => {
    if (isLoading) return;

    const categoriesDeepProductIds = products
      .filter((gcp) =>
        categories.some((category) =>
          gcp.alloyProduct.parent.every((pf) => pf.category === category),
        ),
      )
      .flatMap((gcp) => gcp.alloyProduct.parent.map((pf) => pf.id));

    const preselected = uniq([...prevRequestedDeepProductIds, ...categoriesDeepProductIds]);

    setRequestedDeepProductIds(preselected);
  }, [isLoading]);

  const onSelect = (groupedProduct?: GroupedContentfulProduct) => {
    if (groupedProduct) {
      setRequestedDeepProductIds(
        xor(
          requestedDeepProductIds,
          groupedProduct.alloyProduct.parent.map((pf) => pf.id),
        ),
      );
    } else {
      setRequestedDeepProductIds([]);
    }
  };

  const onSubmit = () => {
    dispatchUpdateRequesting(requestedDeepProductIds);

    const requestingProducts = products.filter((gcp) =>
      requestedDeepProductIds.some((pid) => gcp.alloyProduct.parent.every((pf) => pf.id === pid)),
    );

    const additionalCategories = uniq(
      requestingProducts.flatMap((gcp) =>
        gcp.alloyProduct.parent.map((pf) => pf.category),
      ) as ExperienceCategory[],
    );

    const collatedCategories: ExperienceCategory[] = ['mht', ...additionalCategories];

    onNext(collatedCategories);
  };

  return {
    requestedDeepProductIds,

    onSelect,
    onSubmit,
  };
}
