import CheckoutExperienceContextProvider from 'modules/checkout-experience/context/checkout-experience';
import ExperienceContextProvider from 'modules/shared/context/experience';

import CheckoutExperienceRoutesWrapper from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceRoutes';

export default function CheckoutExperience() {
  return (
    <CheckoutExperienceContextProvider>
      <ExperienceContextProvider>
        <CheckoutExperienceRoutesWrapper />
      </ExperienceContextProvider>
    </CheckoutExperienceContextProvider>
  );
}
