import './CardStatus.scss';

import {
  useGetAllOrders,
  useGetAllSubscriptionsForCustomer,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';

import { getAccountStatus } from 'modules/dashboard/lib/account-status';

import ApproveCardBlock from 'modules/dashboard/sub-modules/home/ui/blocks/ApproveCard';
import DoctorReviewCardBlock from 'modules/dashboard/sub-modules/home/ui/blocks/DoctorReviewCard';
import EmptyCardBlock from 'modules/dashboard/sub-modules/home/ui/blocks/EmptyCard';
import ExpiringCardBlock from 'modules/dashboard/sub-modules/home/ui/blocks/ExpiringCard';
import NextShipmentCardBlock from 'modules/dashboard/sub-modules/home/ui/blocks/NextShipmentCard';
import NoActiveSubsCardBlock from 'modules/dashboard/sub-modules/home/ui/blocks/NoActiveSubsCard';

import { useAppSelector } from 'shared/store/reducers';

import RecentOrderCardBlock from '../blocks/RecentOrderCard';
import SingleSupplyCardBlock from '../blocks/SingleSupplyCard';

export default function CardStatusWrapper() {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();
  const { data: orders = [] } = useGetAllOrders();
  const { data: treatmentPlan } = useGetTreatmentPlan();

  const accountStatus = getAccountStatus(customer.status, subscriptions, orders, treatmentPlan);

  switch (accountStatus) {
    case 'NO_PURCHASE':
      return <EmptyCardBlock />;

    case 'AWAITING_DOCTOR':
    case 'AWAITING_CX_OR_QUEUE':
      return <DoctorReviewCardBlock />;

    case 'AWAITING_CUSTOMER':
      return <ApproveCardBlock />;

    case 'SINGLE_SUPPLY':
      return <SingleSupplyCardBlock />;

    case 'PRESCRIPTIONS_EXPIRING':
      return <ExpiringCardBlock />;

    case 'RECENT_ORDER':
      return <RecentOrderCardBlock />;

    case 'INACTIVE':
      return <NoActiveSubsCardBlock />;

    default:
      return <NextShipmentCardBlock />;
  }
}
