import './AddOnPreferencesForm.scss';

import { useExperience } from 'modules/shared/context/experience';

import SelectableProduct from 'modules/shared/ui/blocks/SelectableProduct';

import CheckoutExperienceSection from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceSection';

import Loader from 'shared/components/content/Loader';
import BottomBar from 'shared/components/wrappers/BottomBar';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

import useAddOnPreferencesState from '../../hooks/useAddOnPreferencesState';
import useUpsellProducts from '../../hooks/useUpsellProducts';

export default function AddOnPreferencesFormWrapper() {
  const { isLoading, products } = useUpsellProducts();
  const { requestedDeepProductIds, onSelect, onSubmit } = useAddOnPreferencesState();
  const { onBack } = useExperience();

  return (
    <>
      <TopBannerWithProgress onBack={isLoading ? undefined : onBack} />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CheckoutExperienceSection>
            <h1 className='content-title'>
              Round out your routine. Anything else you'd like to add?
            </h1>
            <p className='content-text'></p>

            <div className='preferences-products-wrapper'>
              {products.map((gcp, index) => (
                <SelectableProduct
                  key={index}
                  groupedProduct={gcp}
                  isSelected={requestedDeepProductIds.some((id) =>
                    gcp.alloyProduct.parent.every((pf) => pf.id === id),
                  )}
                  onSelect={onSelect}
                  multiSelect
                  hideDetails
                  showProgDetails
                />
              ))}
            </div>
          </CheckoutExperienceSection>

          <BottomBar>
            <button className='primary-button' type='submit' onClick={onSubmit}>
              Next
            </button>
          </BottomBar>
        </>
      )}
    </>
  );
}
