import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import useCart from 'modules/shared/hooks/useCart';

import { useAppSelector } from 'shared/store/reducers';

export default function useOrderConfirmationState() {
  const navigate = useNavigate();
  const location = useLocation();

  const { clearCart } = useCart();

  const searchParams = new URLSearchParams(location.search);

  const isConsultCheckout = searchParams.get('consult-checkout');
  const isOtcCheckout = searchParams.get('otc-checkout');

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean>(false);

  const customer = useAppSelector((state) => state.alloy.customer!);
  const checkout = useAppSelector((state) => state.experience.checkout);
  const { cart } = useAppSelector((state) => state.experience);

  useEffect(() => {
    if (isOtcCheckout && cart.products.length === 0) {
      clearCart();

      navigate('/', { replace: true });
    }

    return () => clearCart();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isConsultCheckout) {
        setShowModal(true);
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  const onViewTreatmentPlan = () => {
    clearCart();

    navigate('/', { replace: true });
  };

  return {
    customer,
    cart,
    checkout,

    isOtcCheckout,
    isConsultCheckout,

    showModal,
    setShowModal,

    showVideo,
    setShowVideo,

    onViewTreatmentPlan,
  };
}
