import { useEffect, useState } from 'react';

import ProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

export default function useMhtProducts() {
  const [products, setProducts] = useState<GroupedContentfulProduct[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);

      const fetchedProducts = (
        await ProductRegistry.get().getIndividualGroupedContentfulProductsFor('mht')
      ).flat();

      setProducts(fetchedProducts);
      setIsLoading(false);
    };

    fetchProducts();
  }, []);

  return { isLoading, products };
}
