import { getPriceForCombinedProducts, getReadablePrice } from '../../../modules/shared/lib/product';

import { AlloyCart } from 'modules/shared/models/cart';

interface Totals {
  total: number;
  subtotal: number;
  discount: number;
  referralCreditDiscount: number;
  tax: number;
  shipping: number;
}

/**
 * Get the totals for the alloy cart - used in checkout experience
 *
 * @param cart: AlloyCart
 * @returns Totals
 */
export const getCartTotals = (cart: AlloyCart): Totals => {
  const productsPriceInCents = cart.products.map((gcp) => getPriceForCombinedProducts(gcp));

  return getTotals(cart, productsPriceInCents);
};

/**
 * Get the totals for the alloy grouped cart - used in treatment plan
 *
 * @param cart: AlloyCart
 * @returns Totals
 */
export const getGroupedCartTotals = (cart: AlloyCart): Totals => {
  const productsPriceInCents = cart.products
    .flatMap((gcp) => gcp.alloyProduct.parent.concat(gcp.alloyProduct.child ?? []))
    .map((pf) => pf.priceInCents);

  return getTotals(cart, productsPriceInCents);
};

const getTotals = (cart: AlloyCart, productsPriceInCents: number[]): Totals => {
  const subtotal = productsPriceInCents.reduce((sum, priceInCents) => {
    return sum + getReadablePrice(priceInCents);
  }, 0);

  const tax = cart.taxAmountToCollect;

  const discount = cart.promotionCode.discountAmount;

  const shipping = getReadablePrice(cart.shipping?.priceInCents ? cart.shipping.priceInCents : 0);

  // Take the absolute value here b/c we treat the balance as a discount below
  const referralCreditDiscount = Math.abs(getReadablePrice(cart.referralCreditBalance));

  const total = subtotal + tax + shipping - discount - referralCreditDiscount;

  return {
    total: parseFloat(total.toFixed(2)),
    subtotal: parseFloat(subtotal.toFixed(2)),
    discount: parseFloat(discount.toFixed(2)),
    referralCreditDiscount: parseFloat(referralCreditDiscount.toFixed(2)),
    tax: parseFloat(tax.toFixed(2)),
    shipping: parseFloat(shipping?.toFixed(2))
  };
};
