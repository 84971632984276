import { useGetTreatmentPlan } from 'client/dist/generated/alloy';

import { showNewProducts } from 'modules/dashboard/lib/show-new-products';

import NewProductsBlock from 'modules/dashboard/ui/blocks/NewProducts';

import DashboardContainer from 'modules/dashboard/ui/containers/DashboardContainer';

import InfoSection from 'modules/dashboard/ui/wrappers/InfoSection';
import RoundOutRoutineWrapper from 'modules/dashboard/ui/wrappers/RoundOutRoutine';
import TestimonialsSection from 'modules/dashboard/ui/wrappers/TestimonialsSection';

import SectionWrapper from 'shared/components/wrappers/Section';

import { useAppSelector } from 'shared/store/reducers';

import TopBlogBlock from '../../../../../../ui/blocks/TopBlog';
import HeaderBlock from '../../../../ui/blocks/Header';
import ReferralBlock from '../../../../ui/blocks/Referral';
import ActiveWrapper from '../wrappers/Active';
import AwaitingWrapper from '../wrappers/Awaiting';
import DoctorWrapper from '../wrappers/Doctor';
import InactiveWrapper from '../wrappers/Inactive';
import PendingWrapper from '../wrappers/Pending';

export default function ActivePlan() {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const { data: treatmentPlan } = useGetTreatmentPlan();

  const showNewProductsBanner = showNewProducts(treatmentPlan);

  return (
    <DashboardContainer title='Treatment Plan | Alloy' desc='' currentPage='treatment plan'>
      {customer.firstName && (
        <HeaderBlock title='Treatment plan for' fancyTitle={customer.firstName} />
      )}

      <SectionWrapper sectionClasses='dashboard-treatment-plan-section'>
        <div className='col-12 col-md-7'>
          <div className='treatment-plan-container'>
            <DoctorWrapper />

            <AwaitingWrapper />
            <PendingWrapper />

            <ActiveWrapper />

            <InactiveWrapper />

            {showNewProductsBanner && <NewProductsBlock theme='dark' />}

            <RoundOutRoutineWrapper />
          </div>
        </div>

        <div className='col-12 col-md-5'>
          <ReferralBlock />
          <TopBlogBlock />
        </div>
      </SectionWrapper>

      <InfoSection />
      <TestimonialsSection />
    </DashboardContainer>
  );
}
