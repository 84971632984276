import RenewalConfirmationInfoWrapper from 'modules/renewal/ui/wrappers/RenewalConfirmationInfo';
import Layout from 'shared/components/core/Layout';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

export default function RenewalConfirmationPage() {
  return (
    <Layout title='Confirmation | MyAlloy' desc='' noBars>
      <TopBannerWithProgress />

      <RenewalConfirmationInfoWrapper />
    </Layout>
  );
}
