import { Customer } from 'client/dist/generated/alloy';
import { Question } from 'common/dist/models/questionnaire';
import { SubmissionAnswer } from 'common/dist/models/submission-answer';

import { getBmi } from 'modules/checkout-experience/sub-modules/intake/lib/bmi';
import { isAvSyncRequired } from 'modules/shared/lib/av-sync-required';

import { getAge } from 'shared/lib/date';

import { LocalPreCustomer } from 'shared/store/reducers/ExperienceReducer';

import { getAnswerByUserKey } from './answer';
import { findQuestion } from './question';

export const collateHiddenSubmissionAnswers = (
  initialAnswers: SubmissionAnswer[],
  questions: Question[],
  localPreCustomer: LocalPreCustomer,
  customer: Customer | undefined,
): SubmissionAnswer[] => {
  const answers = initialAnswers;

  const ageQuestion = findQuestion(questions, 'age-as-of-completion');
  const ageQuestionPosition = initialAnswers.find(
    (ia) => ia.slug === 'age-as-of-completion',
  )?.position;

  if (ageQuestion) {
    const dateOfBirth =
      (customer && customer.dateOfBirth?.toISOString()) || localPreCustomer.dateOfBirth!;

    const dob = getAnswerByUserKey(questions, answers, 'dob', dateOfBirth);

    const age = getAge(dob);

    const { slug, title: questionContent, isImportant: important, mdiCaseQuestion } = ageQuestion;

    const ageAnswer: SubmissionAnswer = {
      slug,
      questionContent,
      answer: age.toString(),
      important,
      mdiCaseQuestion,
      position: ageQuestionPosition,
    };

    answers.push(ageAnswer);
  }

  const bmiQuestion = findQuestion(questions, 'bmi');
  const bmiQuestionPosition = initialAnswers.find((ia) => ia.slug === 'bmi')?.position;

  if (bmiQuestion) {
    const height = getAnswerByUserKey(questions, answers, 'height', '60');

    const heightSplitAnswerSlug = height.split('-');
    const heightInches =
      height === '60' ? '60' : heightSplitAnswerSlug[heightSplitAnswerSlug.length - 1];

    const weight = getAnswerByUserKey(questions, answers, 'weight', '150');

    const bmi = getBmi(heightInches, weight);

    const { slug, title: questionContent, isImportant: important, mdiCaseQuestion } = bmiQuestion;

    const bmiAnswer: SubmissionAnswer = {
      slug,
      questionContent,
      answer: bmi,
      important,
      mdiCaseQuestion,
      position: bmiQuestionPosition,
    };

    answers.push(bmiAnswer);
  }

  const avSyncQuestion = findQuestion(questions, 'patient-require-av-sync');
  const avSyncQuestionPosition = initialAnswers.find(
    (ia) => ia.slug === 'patient-require-av-sync',
  )?.position;

  const stateAbbr = (customer && customer.stateAbbr) || localPreCustomer.state!;

  // If a customer/patient is in a state that requires av, we need to let the doc know
  if (isAvSyncRequired(stateAbbr ?? '') && avSyncQuestion) {
    const {
      slug,
      title: questionContent,
      isImportant: important,
      mdiCaseQuestion,
    } = avSyncQuestion;

    const stateAnswer: SubmissionAnswer = {
      slug,
      questionContent,
      answer: 'Yes',
      important,
      mdiCaseQuestion,
      position: avSyncQuestionPosition,
    };

    answers.push(stateAnswer);
  }

  return answers;
};
