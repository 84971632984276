import './OrderSummary.scss';

import CalloutTagBlock from 'modules/dashboard/sub-modules/treatment-plan/sub-modules/home/ui/blocks/CalloutTag';

import TotalPriceContent from 'modules/dashboard/ui/content/TotalPrice';

import CircleCheck from 'shared/assets/svg/common/circle-check-icon-orange.svg';

import useOrderSummaryData from '../../hooks/useOrderSummaryData';
import { ProductCleanNameAndPrice } from '../wrappers/ProductCleanNameAndPrice';

interface Props {
  onlySingleSupplySelected: boolean;
}

export default function OrderSummaryBlock({ onlySingleSupplySelected }: Props) {
  const {
    disableContinueButton,
    showEligibilityTag,

    unbundledTotal,
    bundledTotal,

    products,

    onContinueCheckout,
  } = useOrderSummaryData();

  return (
    <div className='order-summary-block'>
      <p className='summary-title'>Order Summary</p>

      {showEligibilityTag && <CalloutTagBlock text='FSA/HSA ELIGIBLE' icon={CircleCheck} />}

      <div className='summary-products'>
        {products.map((pcp, i) => (
          <ProductCleanNameAndPrice key={i} product={pcp} showBadge={showEligibilityTag} />
        ))}

        <div className='summary-products-wrapper'>
          <p className='summary-product-text'>
            {onlySingleSupplySelected ? 'Continued' : 'Unlimited'} doctor messaging
          </p>

          <p className='summary-product-price-text'>FREE</p>
        </div>

        {unbundledTotal - bundledTotal !== 0 && (
          <div className='summary-products-wrapper'>
            <p className='summary-product-text summary-text-orange'>Bundle savings</p>

            <p className='summary-product-price-text summary-text-orange'>
              -${(unbundledTotal - bundledTotal).toFixed(2)}
            </p>
          </div>
        )}
      </div>

      <p className='messaging-info-banner'>
        Every prescription treatment plan purchase unlocks{' '}
        {onlySingleSupplySelected ? 'continued' : 'unlimited'} access to a menopause-trained
        physician.
      </p>

      <div className='summary-text bottom-padding'>
        <div className='tp-total-wrapper'>
          <p className='total-title'>Order Subtotal</p>

          <TotalPriceContent unbundledTotal={unbundledTotal} bundledTotal={bundledTotal} />
        </div>

        <button
          className='primary-button full-width-button'
          onClick={onContinueCheckout}
          disabled={disableContinueButton}
        >
          Continue to checkout
        </button>
      </div>
    </div>
  );
}
