import { Dispatch } from '@reduxjs/toolkit';

const updateTest = (type: string, isOn: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type,
      payload: isOn,
    });
  };
};

export { updateTest };
