import emptyPlanMobile from 'modules/dashboard/sub-modules/home/assets/images/empty-plan-home-mobile.png';
import emptyPlan from 'modules/dashboard/sub-modules/home/assets/images/empty-plan-home.png';

import useInitExperience from 'modules/shared/hooks/useInitExperience';

export default function EmptyCardBlock() {
  const { initCheckoutExperience } = useInitExperience();

  const onStartConsult = async () => await initCheckoutExperience(['mht']);

  return (
    <div className='header-state-block'>
      <div className='state-content-wrapper'>
        <img src={emptyPlan} alt='alloy' className='content-full-img d-none d-sm-block' />
        <img src={emptyPlanMobile} alt='alloy' className='content-full-img d-block d-sm-none' />

        <h3 className='content-title'>Get prescription menopause relief today</h3>

        <p className='content-subtitle'>$49</p>

        <ul className='content-list-wrapper'>
          <li className='list-text'>Take a quick assessment & select products</li>
          <li className='list-text'>
            A menopause-trained doctor reviews your history and answers your questions
          </li>
          <li className='list-text'>Get your prescription products delivered</li>
        </ul>

        <button className='primary-button' onClick={onStartConsult}>
          Start your intake
        </button>
      </div>
    </div>
  );
}
