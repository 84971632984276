import classNames from 'classnames';
import { useState } from 'react';

import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { PROGESTERONE_PRODUCT_ID } from 'common/dist/products/productRegistry';

import useProductPrice from 'modules/shared/hooks/useProductPrice';

import { isSynbiotic } from 'modules/shared/lib/contentful';
import { getProductNameWithDosage } from 'modules/shared/lib/product/name';

import ProgressBlock from 'modules/dashboard/sub-modules/treatment-plan/ui/blocks/Progress';

import ProductInfoDrawer from 'modules/shared/ui/drawers/ProductInfoDrawer';

import checkedBox from 'shared/assets/svg/common/checked-box-icon.svg';
import uncheckedBox from 'shared/assets/svg/common/unchecked-box-icon.svg';

import { EmailUs } from 'shared/components/content/Buttons';

import isNarrowScreen from 'shared/lib/narrow-screen';

import ProductNameDosage from './ProductNameDosage';

// TODO: these product cards need a refactor soon in one of the prs, too much to do at this moment,
// but quite a bit can be done
interface Props {
  groupedProduct: GroupedContentfulProduct;
  isSelected: boolean;
  onSelect: (gcp: GroupedContentfulProduct) => void;
  multiSelect?: boolean;
  hideProductInformation?: boolean;
  hideDetails?: boolean;
  isBesFit?: boolean;
  showDosage?: boolean;
  showProgDetails?: boolean;
  showRetentionProgress?: boolean;
}

export default function SelectableProduct({
  groupedProduct,
  isSelected,
  onSelect,
  multiSelect = false,
  hideProductInformation = false,
  hideDetails = false,
  isBesFit = false,
  showDosage = false,
  showProgDetails = false,
  showRetentionProgress,
}: Props) {
  const { unbundledPrice } = useProductPrice({ groupedProduct });

  const [showProdInfo, setShowProdInfo] = useState<boolean>(false);

  const { contentfulProduct } = groupedProduct;

  const productsCleanNameAndDosage = showDosage
    ? getProductNameWithDosage(groupedProduct)
    : [{ cleanName: contentfulProduct.fields.title }];

  // TODO: if there is a way to access parent pf, change that logic to be: if price is 0, show Free with ${parent.name}*
  const productPrice =
    showProgDetails &&
    groupedProduct.alloyProduct.parent.every((pf) => pf.productId === PROGESTERONE_PRODUCT_ID)
      ? 'Free with Estradiol*'
      : `$${unbundledPrice.toFixed(2)}`;

  const isProductSynbiotic = isSynbiotic(contentfulProduct);

  const onSelectProduct = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onSelect(groupedProduct);
  };

  const onOpenProdInfo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setShowProdInfo(true);
  };

  return (
    <>
      <div
        className={classNames('selectable-product', isSelected && 'selected')}
        onClick={onSelectProduct}
      >
        {!isProductSynbiotic && <span className='product-rx-tag'>Rx</span>}

        <div className={classNames('product-header', hideDetails && 'no-margin')}>
          <div className='header-content'>
            <img
              src={contentfulProduct.fields.photo.fields.file.url}
              alt={contentfulProduct.fields.photo.fields.title}
              className='header-img'
            />

            <div className='header-text-wrapper'>
              {isNarrowScreen() && isBesFit && !hideDetails && (
                <span className='header-tag'>Your best fit</span>
              )}

              {productsCleanNameAndDosage.map(({ cleanName, dosage }, index) => (
                <ProductNameDosage cleanName={cleanName} dosage={dosage} key={index} />
              ))}

              <p className='header-text'>{productPrice} (3-month supply)</p>

              {hideDetails && !hideProductInformation && (
                <button onClick={onOpenProdInfo} type='button' className='product-info-btn-link'>
                  Product information
                </button>
              )}
            </div>
          </div>

          {multiSelect ? (
            <img
              src={isSelected ? checkedBox : uncheckedBox}
              alt='selectable box'
              className='header-box-btn'
            />
          ) : (
            <div>
              <div className='header-outer-circle'></div>
            </div>
          )}
        </div>

        {showRetentionProgress && <span className='product-hug'>new treatment</span>}

        {!hideDetails && contentfulProduct.fields.detailsList && (
          <ul className='product-details-list'>
            {contentfulProduct.fields.detailsList.map((text, index) => (
              <li className='list-text' key={index}>
                {text}
              </li>
            ))}
          </ul>
        )}

        {!hideDetails && !hideProductInformation && (
          <button onClick={onOpenProdInfo} type='button' className='product-info-btn-link'>
            Product information
          </button>
        )}

        {showRetentionProgress && (
          <>
            <ProgressBlock
              barTitles={['Request', 'Doctor review', 'Approve treatment']}
              currentIndex={1.75}
              hideButton
            />

            <p className='product-text'>
              Any questions about your order at all, email us at <EmailUs />. We're here for you!
            </p>
          </>
        )}
      </div>

      <ProductInfoDrawer
        open={showProdInfo}
        setOpen={setShowProdInfo}
        product={contentfulProduct}
      />
    </>
  );
}
