import { ExperienceValidationKey } from 'modules/checkout-experience/lib/validation';
import { ExperienceFlowStep } from 'modules/shared/models/experience';

import CheckoutPage from 'pages/checkout-experience/Checkout';
import NamePage from 'pages/checkout-experience/Name';
import ReliefTypePage from 'pages/checkout-experience/ReliefType';
import RegisterPage from 'pages/authentication/Register';
import VerificationPage from 'pages/authentication/Verification';

export const otcSteps: ExperienceFlowStep[] = [
  {
    validationKey: ExperienceValidationKey.name,
    component: NamePage,
    path: 'name',
    routeType: 'PUBLIC',
  },
  {
    validationKey: ExperienceValidationKey.reliefType,
    component: ReliefTypePage,
    path: 'relief-type',
    routeType: 'PUBLIC',
  },

  {
    validationKey: ExperienceValidationKey.register,
    component: RegisterPage,
    path: 'register',
    routeType: 'AUTH',
  },
  {
    validationKey: ExperienceValidationKey.verification,
    component: VerificationPage,
    path: 'verification',
    routeType: 'AUTH',
  },

  {
    validationKey: ExperienceValidationKey.checkout,
    component: CheckoutPage,
    path: 'checkout',
    routeType: 'PRIVATE',
  },
];
