import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';
import Layout from 'shared/components/core/Layout';
import LoginFormWrapper from 'modules/authentication/sub-modules/login/ui/wrappers/LoginForm';

export default function LoginPage() {
  return (
    <Layout title='Login - Alloy' desc='Log into your Alloy account' noBars>
      <TopBannerWithProgress />

      <LoginFormWrapper />
    </Layout>
  );
}
