import './HomeInfo.scss';

import classNames from 'classnames';

import EmptyCardBlock from 'modules/dashboard/sub-modules/home/ui/blocks/EmptyCard';

import StatsBlock from 'modules/dashboard/ui/blocks/Stats';
import TopBlogBlock from 'modules/dashboard/ui/blocks/TopBlog';

import DashboardContainer from 'modules/dashboard/ui/containers/DashboardContainer';

import InfoSection from 'modules/dashboard/ui/wrappers/InfoSection';
import RoundOutRoutineWrapper from 'modules/dashboard/ui/wrappers/RoundOutRoutine';

import Loader from 'shared/components/content/Loader';
import SectionWrapper from 'shared/components/wrappers/Section';

import HeaderSectionWrapper from './HeaderSection';
import ReviewsSectionWrapper from './ReviewsSection';

import useHomeState from '../../hooks/useHomeState';

/**
 * Two main states at the top level: INACTIVE AND ACTIVE
 * - inactive implies no purchase, waiting on doctor, or waiting on customer approval
 * - active implies has at least 1 active subscription
 *
 * these high level states are mainly for the content below the hero, the hero
 * will have its own various states to display
 */
export default function HomeInfoWrapper() {
  const { isLoading, notActiveSubscriber, hasConsultPurchase } = useHomeState();

  /**
   * Stuff is loading
   */
  if (isLoading) {
    return (
      <DashboardContainer title='Home | Alloy' currentPage='home'>
        <Loader />
      </DashboardContainer>
    );
  }

  return (
    <DashboardContainer title='Home | Alloy' desc='' currentPage='home'>
      <HeaderSectionWrapper />

      <SectionWrapper
        sectionClasses={classNames(
          'dashboard-treatment-plan-section',
          notActiveSubscriber && 'remove-md-top-padding',
        )}
      >
        {/* customers in these states, are not able yet to view the round out screen */}
        {notActiveSubscriber ? (
          <>
            <div className='col-12 col-md-6 stats-block-col'>
              <StatsBlock
                hugText='Did you know?'
                content={
                  <>
                    95% of women have symptom <span className='text-italic'>relief</span> within 4
                    weeks of working with Alloy
                  </>
                }
              />
            </div>

            <div className='col-12 col-md-6'>
              <TopBlogBlock smallerImg />
            </div>
          </>
        ) : (
          <>
            <div className={classNames('col-12 col-md-7', !hasConsultPurchase && 'mb-3 mb-md-0')}>
              {hasConsultPurchase ? <RoundOutRoutineWrapper /> : <EmptyCardBlock />}
            </div>

            <div className='col-12 col-md-5'>
              <TopBlogBlock smallerImg />
            </div>
          </>
        )}
      </SectionWrapper>

      {notActiveSubscriber && <ReviewsSectionWrapper />}

      <InfoSection />
    </DashboardContainer>
  );
}
