import { Skeleton } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';

import ContentBlock from 'modules/dashboard/sub-modules/account/sub-modules/profile/ui/blocks/Content';
import HelpBlock from 'modules/dashboard/sub-modules/account/sub-modules/profile/ui/blocks/Help';
import ProfileDrawer from 'modules/dashboard/sub-modules/account/sub-modules/profile/ui/drawers/ProfileDrawer';

import AddressDrawer from 'modules/shared/ui/drawers/AddressDrawer';
import PaymentDrawer from 'modules/shared/ui/drawers/PaymentDrawer';

import { stripePromise } from 'shared/lib/stripe-promise';

import useProfileState from '../../hooks/useProfileState';

export default function ProfileInfoWrapper() {
  const {
    isLoading,

    setupIntent,
    profileDict,

    isProfileOpen,
    setIsProfileOpen,

    isShippingOpen,
    setIsShippingOpen,

    isPaymentOpen,
    setIsPaymentOpen,

    onUpdate,
  } = useProfileState();

  if (isLoading || !setupIntent) {
    return (
      <>
        <Skeleton variant='rectangular' height={250} className='mb-3' />
        <Skeleton variant='rectangular' height={250} />
      </>
    );
  }

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          {Object.keys(profileDict.profile).map((key, index) => (
            <ContentBlock
              title={profileDict.titles[index]}
              onUpdate={() => onUpdate(key)}
              profileRows={profileDict.profile[key]}
              key={index}
            />
          ))}

          <HelpBlock />
        </div>
      </div>

      {/* DRAWERS */}
      <ProfileDrawer open={isProfileOpen} setOpen={setIsProfileOpen} />

      <AddressDrawer open={isShippingOpen} setOpen={setIsShippingOpen} />

      <Elements stripe={stripePromise} options={{ clientSecret: setupIntent.client_secret }}>
        <PaymentDrawer open={isPaymentOpen} setOpen={setIsPaymentOpen} />
      </Elements>
    </>
  );
}
