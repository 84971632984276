import { useState } from 'react';

import getIntakesByCategory from 'common/dist/intake/getIntakesByCategory';

import { useCancelContext } from 'modules/dashboard/sub-modules/manage-subscription/context/cancel';

import { QuestionnaireAnswers } from 'modules/questionnaire/models/questionnaire-answers';

import DrawerQuestionnaireWrapper from 'modules/dashboard/ui/wrappers/DrawerQuestionnaire';

import { useQueryParams } from 'shared/context/url/query';

export default function NotFeelingBetterContent() {
  const { onCancel, onRetentionSave, onSaveSubmission } = useCancelContext();
  const { getParam, setParam } = useQueryParams();

  const [answers, setAnswers] = useState<QuestionnaireAnswers>({});

  const questions = getIntakesByCategory(['cancel']);

  const onContinue = async (qAnswers: QuestionnaireAnswers) => {
    setAnswers(qAnswers);

    setParam('section', 'confirm');
  };

  const onSubmitRetention = async () => {
    const id = await onSaveSubmission(answers);

    await onRetentionSave(id);
  };

  const onSubmitCancel = async () => {
    await onSaveSubmission(answers);

    await onCancel();
  };

  if (getParam('section') === 'confirm') {
    return (
      <div className='cancel-content-wrapper'>
        <div className='content-block'>
          <h4 className='content-title'>Your doctor can help you find a better fit</h4>
          <p className='content-text'>
            We can send your answers to your doctor to review and find the right medication for your
            needs.
          </p>
        </div>

        <div className='btns-stack-block'>
          <button className='primary-button' onClick={onSubmitRetention}>
            Send to my doctor
          </button>

          <button className='primary-link' onClick={onSubmitCancel}>
            I want to cancel instead
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <DrawerQuestionnaireWrapper
        questions={questions}
        previousAnswers={answers}
        onContinue={onContinue}
      />
    );
  }
}
