import { Skeleton } from '@mui/material';

import useRoundOutRoutineState from 'modules/dashboard/hooks/useRoundOutRoutineState';

import { translateCategories } from 'modules/shared/lib/category';
import { getProductsToBeBundledWith } from 'modules/shared/lib/product';
import { sortProducts } from 'modules/shared/lib/product/sort';

import BundleViewableProduct from 'modules/shared/ui/blocks/BundleViewableProduct';
import ViewableProduct from 'modules/shared/ui/blocks/ViewableProduct';

import ReviewDrawer from 'modules/dashboard/ui/drawers/ReviewDrawer';

import chevronDown from 'shared/assets/svg/common/chevron-down.svg';

import { toggleClass } from 'shared/lib/dom';

// moved this code into a reusable component
// TODO: there is much to clean up in here ESPECIALLY the way we fetch upsell products, more to come later!!
export default function RoundOutRoutineWrapper() {
  const {
    isLoading,

    shouldAddToPlan,

    roundOutRoutineProducts,
    roundOutRoutineActiveSubProducts,

    isReviewOpen,
    setIsReviewOpen,

    onAddToPlanOrRequest,
  } = useRoundOutRoutineState();

  if (isLoading) {
    return (
      <>
        <Skeleton variant='rectangular' height={65} className='mb-3' />
        <Skeleton variant='rectangular' height={400} className='mb-3' />
      </>
    );
  }

  if (roundOutRoutineProducts.length === 0) {
    return <></>;
  }

  return (
    <>
      <div className='round-out-routine-wrapper'>
        <div className='tp-products-list-wrapper active' id='non-active-products-list'>
          <div className='tp-header'>
            <p className='tp-subtitle'>
              Round out your <span>routine</span>
            </p>

            <div className='tp-btn-wrapper'>
              <button
                className='tp-chevron-btn'
                onClick={() => toggleClass('non-active-products-list')}
              >
                <img src={chevronDown} alt='chevron-down' />
              </button>
            </div>
          </div>

          <div className='products-list'>
            {roundOutRoutineProducts.map(({ categories, products }, categoryIndex) => (
              <div key={categories + '-' + categoryIndex}>
                {products.length !== 0 && (
                  <div className='category-banner'>
                    <span>{translateCategories(categories)}</span>
                  </div>
                )}

                {sortProducts(products).map((gcp, index) => {
                  const parents = getProductsToBeBundledWith(gcp, roundOutRoutineActiveSubProducts);

                  return !!parents.length && !categories.includes('skin-health') ? (
                    <BundleViewableProduct
                      key={gcp.contentfulProduct.sys.id + '-' + index}
                      groupedProduct={gcp}
                      isParentSelected
                      parents={parents}
                    >
                      <button
                        className='product-action-btn'
                        onClick={() => onAddToPlanOrRequest(gcp, categories)}
                      >
                        {shouldAddToPlan(gcp) ? 'Add to plan' : 'Request from doctor'}
                      </button>
                    </BundleViewableProduct>
                  ) : (
                    <ViewableProduct
                      key={gcp.contentfulProduct.sys.id + '-' + index}
                      groupedProduct={gcp}
                      showNewTag
                    >
                      <button
                        className='product-action-btn'
                        onClick={() => onAddToPlanOrRequest(gcp, categories)}
                      >
                        {categories.every((c) => c === 'skin-health')
                          ? 'Add to your routine'
                          : shouldAddToPlan(gcp)
                            ? 'Add to plan'
                            : 'Request from doctor'}
                      </button>
                    </ViewableProduct>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/*
       * Review drawer handles opening a drawer for products that user is eligible to purchase (prescribed but not bought and active) + cart.
       * We also add synbiotic (if it exists in upsell as a add on since there is no intake) to the list of products that they can buy
       */}

      <ReviewDrawer
        open={isReviewOpen}
        setOpen={setIsReviewOpen}
        notPurchasedEligibleProducts={roundOutRoutineProducts
          .map((nu) => nu.products)
          .flat()
          .filter((p) => shouldAddToPlan(p))}
        activeSubProducts={roundOutRoutineActiveSubProducts}
      />
    </>
  );
}
