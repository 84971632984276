import { Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';

import ProductRegistry from 'client/dist/product/productRegistry';

import useInactiveData from 'modules/dashboard/sub-modules/treatment-plan/sub-modules/home/hooks/useInactiveData';

import { isMht, isPrescription } from 'modules/shared/lib/contentful';

import chevronDown from 'shared/assets/svg/common/chevron-down.svg';

import { toggleClass } from 'shared/lib/dom';

import DividerWrapper from './Divider';

import ActiveProduct from '../content/ActiveProduct';

export default function InactiveWrapper() {
  const { products, isLoading, prescriptionProducts } = useInactiveData();

  if (isLoading) {
    return (
      <div className='tp-products-list-wrapper'>
        <Skeleton variant='rectangular' height={200} />
      </div>
    );
  }

  if (products.length === 0) return <></>;

  return (
    <>
      <div className='tp-products-list-wrapper active' id='inactive-products-list'>
        <div className='tp-header'>
          <p className='tp-subtitle'>Inactive treatments</p>

          <div className='tp-btn-wrapper'>
            <Link to='/subscriptions' className='secondary-border-button'>
              Manage
            </Link>

            <button
              className='tp-chevron-btn'
              onClick={() => toggleClass('inactive-products-list')}
            >
              <img src={chevronDown} alt='chevron-down' />
            </button>
          </div>
        </div>

        <div className='products-list'>
          {/* Purchased products that are either in sub or not on sub (one time) */}
          {products
            .flat()
            .sort(
              (a, b) =>
                Number(isMht(b.contentfulProduct)) - Number(isMht(a.contentfulProduct)) ||
                Number(isPrescription(b.contentfulProduct)) -
                  Number(isPrescription(a.contentfulProduct)),
            )
            .map((gcp, i) => {
              const foundPrescriptionProduct = prescriptionProducts.find((pp) =>
                ProductRegistry.get().areProductsEquivalent([
                  pp.product,
                  ...gcp.alloyProduct.parent,
                ]),
              );

              return (
                <ActiveProduct
                  key={i}
                  product={{
                    alloyProduct: gcp.alloyProduct,
                    contentfulProduct: gcp.contentfulProduct,
                    prescription: foundPrescriptionProduct?.prescription,
                    renewal: foundPrescriptionProduct?.renewal,
                  }}
                />
              );
            })}
        </div>
      </div>

      <DividerWrapper />
    </>
  );
}
