import './CardIcon.scss';

import americanExpressCard from 'shared/assets/svg/cards/americanexpress-card.svg';
import defaultCard from 'shared/assets/svg/cards/default-card.svg';
import dinerClub from 'shared/assets/svg/cards/dinerclub-card.svg';
import discoverCard from 'shared/assets/svg/cards/discover-card.svg';
import jcbCard from 'shared/assets/svg/cards/jcb-card.svg';
import masterCard from 'shared/assets/svg/cards/mastercard-card.svg';
import visaCard from 'shared/assets/svg/cards/visa-card.svg';

interface Props {
  brand: string;
}

export default function CardIcon({ brand }: Props) {
  switch (brand) {
    case 'visa': {
      return <img src={visaCard} alt='visa icon' className='card-icon' />;
    }
    case 'mastercard': {
      return <img src={masterCard} alt='mastercard icon' className='card-icon' />;
    }
    case 'amex': {
      return <img src={americanExpressCard} alt='amex icon' className='card-icon' />;
    }
    case 'diners': {
      return <img src={dinerClub} alt='diners icon' className='card-icon' />;
    }
    case 'discover': {
      return <img src={discoverCard} alt='discover icon' className='card-icon' />;
    }
    case 'jcb': {
      return <img src={jcbCard} alt='jcb icon' className='card-icon' />;
    }
    default: {
      return <img src={defaultCard} alt='credit card icon' className='card-icon' />;
    }
  }
}
