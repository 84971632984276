import { useCancelContext } from 'modules/dashboard/sub-modules/manage-subscription/context/cancel';
import { useProductContext } from 'modules/dashboard/sub-modules/manage-subscription/context/product';

import { CANCEL_SAVE } from 'modules/dashboard/sub-modules/manage-subscription/data/reason';

import { getDeepProductIdsFrom } from 'modules/shared/lib/product';

import { useQueryParams } from 'shared/context/url/query';

import useSwitchable from 'shared/hooks/product/useSwitchable';

export default function DifferentProductContent() {
  const { navigateWithParams } = useQueryParams();

  const { product } = useProductContext();
  const { onCancel, onSaveSubmission } = useCancelContext();
  const { isSwitchable } = useSwitchable({ product });

  const onChangeTreatment = async () => {
    await onSaveSubmission({ 'cancel-saved': CANCEL_SAVE.CHANGE_TREATMENT });

    navigateWithParams('/treatment-plan', {
      manage: 'change',
      'deepProductIds[]': getDeepProductIdsFrom(product).join(',')
    });
  };

  const onMessage = async () => {
    await onSaveSubmission({ 'cancel-saved': CANCEL_SAVE.MESSAGE });

    navigateWithParams('/messages');
  };

  const onSubmitCancel = async () => {
    await onSaveSubmission();

    await onCancel();
  };

  return (
    <div className='cancel-content-wrapper'>
      <div className='content-block'>
        <h4 className='content-title'>
          {isSwitchable
            ? 'Did you know you can easily change your treatment?'
            : 'Did you know you can change your treatment?'}
        </h4>
        <p className='content-text'>
          {isSwitchable
            ? "If you aren't happy with your Menopause Hormone Therapy, you can easily switch your dose or form factor."
            : "If you aren't happy with your treatment and want to explore other options, your doctor is here to help! Feel free to message them to ask about your options."}
        </p>
      </div>

      <div className='btns-stack-block'>
        {isSwitchable ? (
          <button className='primary-button' onClick={onChangeTreatment}>
            Change my treatment
          </button>
        ) : (
          <button className='primary-button' onClick={onMessage}>
            Message my doctor
          </button>
        )}

        <button className='primary-link' onClick={onSubmitCancel}>
          I want to cancel
        </button>
      </div>
    </div>
  );
}
