import * as Sentry from '@sentry/react';
import { replayIntegration } from '@sentry/react';

import { Customer } from 'client/dist/generated/alloy';
import { appVersion } from 'common/dist/version';

const wildcardApi = [
  window.location.origin,
  /^\//,
  /^\/\//,
  /^https:\/\/api-devnext\.myalloy\.com/,
  /^https:\/\/api-dev\.myalloy\.com/,
  /^https:\/\/api\.myalloy\.com/,
];

let isSentryInitialized = false;

export function sentryInit() {
  if (!isSentryInitialized) {
    Sentry.init({
      dsn: 'https://e23ab74c22fc72342799d0667935201e@o4506792469331968.ingest.sentry.io/4506793493856256',
      normalizeDepth: 10,
      integrations: [
        replayIntegration({
          networkDetailAllowUrls: wildcardApi,
          networkCaptureBodies: true,
          maskAllInputs: false,
          maskAllText: false,
          blockAllMedia: false,
        }),
        Sentry.browserTracingIntegration({
          traceFetch: true,
          traceXHR: true,
        }),
      ],
      tracesSampleRate: 0.4,
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,

      tracePropagationTargets: wildcardApi,
      environment: process.env.REACT_APP_NODE_ENV,
      release: appVersion,
      // don't send sentry events during local dev
      beforeSend(event) {
        return process.env.REACT_APP_SUPPRESS_SENTRY ? null : event;
      },
    });

    isSentryInitialized = true;
  }
}

export function identifySentry(customer: Customer) {
  Sentry.setUser({
    id: customer.patientId,
    ...(process.env.REACT_APP_NODE_ENV !== 'production' ? { email: customer.email } : {}),
  });
}

export function sendExceptionToSentry(e: Error) {
  Sentry.captureException(e);
}

export function sendMessageToSentry(m: string) {
  Sentry.captureMessage(m);
}

export function sendTag(key: string, value: string) {
  Sentry.setTag(key, value);
}

export function sendServerErrorToSentry(e: Error) {
  const { message, name } = e;

  const formatted = `${name ? name + ':' : ''} ${message ? message : 'Unknown Error'}`;

  Sentry.captureException(formatted);
}

export default {
  sendServerErrorToSentry,
};
