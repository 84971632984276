import { useExperience } from 'modules/shared/context/experience';

import useVerifyIdentityState from 'modules/shared/sub-modules/verify-identity/hooks/useVerifyIdentityState';

import VerifyIdentityFormWrapper from 'modules/shared/sub-modules/verify-identity/ui/wrappers/VerifyIdentityForm';

import Layout from 'shared/components/core/Layout';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

export default function VerifyIdentityPage() {
  const { onBack } = useExperience();
  const { isLoading } = useVerifyIdentityState();

  return (
    <Layout title='Verify Identity - Alloy' desc='' noBars>
      <TopBannerWithProgress onBack={isLoading ? undefined : onBack} />

      <VerifyIdentityFormWrapper />
    </Layout>
  );
}
