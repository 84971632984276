import { capitalize, xor } from 'lodash';
import { useState } from 'react';

import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { useExperience } from 'modules/shared/context/experience';

import useCart from 'modules/shared/hooks/useCart';
import useTotalProductPrices from 'modules/shared/hooks/useTotalProductPrices';

import { cleanPurchasableProducts } from 'modules/request-experience/lib/flow';
import { getDeepProductsFromGroupedProducts } from 'modules/shared/lib/product';
import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';
import { sendExceptionToSentry } from 'modules/tracking/lib/sentry';

import { useAppSelector } from 'shared/store/reducers';

export default function useSkincareState() {
  const { onNext } = useExperience();
  const { cart, updateCart } = useCart();

  const { isAuthenticated, customer } = useAppSelector((state) => state.alloy);
  const { localPreCustomer } = useAppSelector((state) => state.experience);

  const firstName = capitalize(customer ? customer.firstName : localPreCustomer.firstName) ?? '';

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer({
    swr: {
      enabled: isAuthenticated,
    },
  });

  const { activeSubs } = getSubscriptionsWithStatus(subscriptions);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedDeepProductIds, setSelectedDeepProductIds] = useState<number[]>(
    getDeepProductsFromGroupedProducts(cart.products).map((pf) => pf.id),
  );

  const { unbundledTotal, bundledTotal } = useTotalProductPrices({
    products: cart.products
      .flatMap((gcp) => gcp.alloyProduct.parent)
      .filter((dp) => selectedDeepProductIds.includes(dp.id)),
    dependencies: [JSON.stringify(selectedDeepProductIds)],
  });

  const canBeDiscounted = unbundledTotal - bundledTotal !== 0;

  const onSelect = async (groupedProduct: GroupedContentfulProduct) => {
    const selectedDeepIds = xor(
      selectedDeepProductIds,
      groupedProduct.alloyProduct.parent.map((pf) => pf.id),
    );

    setSelectedDeepProductIds(selectedDeepIds);
  };

  const onContinue = async () => {
    try {
      setIsLoading(true);

      const products = (
        await ProductRegistry.get().getDeepProductsFromIds(selectedDeepProductIds)
      ).flat();

      const activeSubscriptionProducts = activeSubs.flatMap((sub) =>
        sub.products.map((pfr) => pfr.product),
      );

      const cleanedProducts = await cleanPurchasableProducts(products, activeSubscriptionProducts);

      updateCart({ products: cleanedProducts });

      onNext();
    } catch (error) {
      setIsLoading(false);
      sendExceptionToSentry(error as Error);
    }
  };

  return {
    isLoading,

    firstName,
    canBeDiscounted,

    selectedDeepProductIds,

    unbundledTotal,
    bundledTotal,

    onSelect,
    onContinue,
  };
}
