import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getRecentSubmission, runSuggestions } from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';

import { useExperience } from 'modules/shared/context/experience';

import useCart from 'modules/shared/hooks/useCart';

import { retrieveIntakeCategoriesFromUrl } from 'modules/shared/lib/experience/experience';
import { getPriceForCombinedProducts } from 'modules/shared/lib/product';

import { convertCentsToDollars } from 'shared/lib/convert';

import { useAppSelector } from 'shared/store/reducers';

import ProductReviewCardBlock from '../blocks/ProductReviewCard';
import ReviewLayoutWrapper from '../wrappers/ReviewLayout';

export default function IndividualContent() {
  const location = useLocation();

  const { onBack, onNext } = useExperience();
  const { updateCart } = useCart();

  const [isLoading, setIsLoading] = useState(false);

  const { isAuthenticated } = useAppSelector((state) => state.alloy);
  const { cart, localPreCustomer } = useAppSelector((state) => state.experience);

  const onContinue = async () => {
    setIsLoading(true);

    let submissionId = localPreCustomer.alloySubmissionId || '';

    if (isAuthenticated) {
      const intakeCategories = retrieveIntakeCategoriesFromUrl(location);

      const recentSubmission = await getRecentSubmission({ categories: intakeCategories });

      submissionId = recentSubmission.id!;
    }

    const suggestions = await runSuggestions({ submissionId });

    const qualifiedProducts = suggestions.qualified.map((q) => q.product);

    const fetchedProducts = (
      await ProductRegistry.get().getRecurringProductsForV2(qualifiedProducts)
    ).flat();

    updateCart({ products: fetchedProducts });

    setIsLoading(false);
    onNext();
  };

  return (
    <ReviewLayoutWrapper
      title={cart.products.length === 1 ? 'Your treatment' : 'Your treatments'}
      isLoading={isLoading}
      onBack={onBack}
      isContinueDisabled={cart.products.length === 0}
      onContinue={onContinue}
    >
      {cart.products.map((gcp, index) => {
        const priceInCents = getPriceForCombinedProducts(gcp);

        return (
          <ProductReviewCardBlock
            key={index}
            topImg={
              gcp.contentfulProduct.fields.productHeader?.fields.file.url ??
              gcp.contentfulProduct.fields.photo.fields.file.url
            }
            title={gcp.contentfulProduct.fields.title}
            subtitle={`$${convertCentsToDollars(priceInCents)} (3-month supply)`}
            description={gcp.contentfulProduct.fields.detail}
            benefitsList={gcp.contentfulProduct.fields.reviewBenefits ?? []}
            showRx
          />
        );
      })}
    </ReviewLayoutWrapper>
  );
}
