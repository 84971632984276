import './NotificationDrawer.scss';

import { Loader } from 'rsuite';

import {
  EventType,
  markNotificationsAsReadByEventType,
  useGetAllUnreadNotifications,
} from 'client/dist/generated/alloy';

import {
  TransformedNotification,
  processNotifications,
} from 'modules/notifications/lib/transform-notifs';

import AlloyDrawer from 'shared/components/core/AlloyDrawer';

import NotificationRow, { EmptyOrErrorNotificationRow } from '../content/NotificationRow';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function NotificationDrawer({ open, setOpen }: Props) {
  const {
    data: notifications = [],
    isLoading: isLoadingNotifications,
    error: hasNotificationsError,
    mutate,
  } = useGetAllUnreadNotifications();

  const transformedNotifications = processNotifications(notifications, false);

  const onMarkRead = async (eventType: EventType) => {
    await markNotificationsAsReadByEventType({ eventTypes: [eventType] });
    await mutate();
    setOpen(false);
  };

  const renderNotifications = () => {
    if (hasNotificationsError) {
      return <EmptyOrErrorNotificationRow />;
    }

    if (isLoadingNotifications) {
      return <Loader center size='lg' />;
    }

    if (!!hasNotificationsError || notifications.length === 0) {
      return <EmptyOrErrorNotificationRow />;
    }

    return transformedNotifications.map((notification: TransformedNotification, idx: number) => (
      <NotificationRow key={idx} notification={notification} onMarkRead={onMarkRead} />
    ));
  };

  return (
    <>
      <AlloyDrawer
        open={open}
        drawerClass='notification-drawer'
        onClose={() => setOpen(false)}
        title='Notifications'
      >
        <div className='drawer-body'>
          <p className='content-title d-block d-md-none'>Notifications</p>

          <div className='notifications-container'>{renderNotifications()}</div>
        </div>
      </AlloyDrawer>
    </>
  );
}
