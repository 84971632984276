import { useLocation } from 'react-router-dom';

import useCart from 'modules/shared/hooks/useCart';

import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';
import { formatProductNames } from 'modules/shared/lib/product/name';

interface Props {
  isAddOn?: boolean;
}

export default function BlurbWrapper({ isAddOn = false }: Props) {
  const location = useLocation();

  const { cart } = useCart();

  const categories = retrieveCategoriesFromUrl(location);

  // in the instance a customer only checks out with synbiotic, we do not need to show
  // mht / doc copy since they will be able to purchase right away
  const isSynbioticOnly = categories.every((c) => c === 'gut-health');

  const isRequestExperience = location.pathname.includes('request-experience');

  if (isAddOn) {
    <div className='ce-header-wrapper'>
      <p className='header-blurb'>
        {formatProductNames(cart.products)}{' '}
        {cart.shipNow ? 'will ship immediately.' : 'will arrive with your next shipment.'}
      </p>
    </div>;
  }

  if (isSynbioticOnly) return <></>;

  return (
    <div className='ce-header-wrapper'>
      {!isSynbioticOnly && !isRequestExperience && (
        <p className='header-blurb'>
          Your doctor will create your treatment plan. Usually within 1-3 days.
        </p>
      )}

      {isRequestExperience && (
        <p className='header-blurb'>
          {cart.shipNow
            ? `Your ${formatProductNames(cart.products)} will ship once approved by the doctor.`
            : `Once approved by your doctor, your ${formatProductNames(
                cart.products,
              )} will arrive with your next shipment.`}
        </p>
      )}
    </div>
  );
}
