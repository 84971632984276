import './ConsentForm.scss';

import { upperCase } from 'lodash';

import { markdown_description } from 'modules/dsar/data/content';
import useConsentFormState from 'modules/dsar/data/hooks/useConsentFormState';

import CheckoutExperienceSection from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceSection';
import QuestionLayoutWrapper from 'modules/questionnaire/ui/wrappers/QuestionLayout';

import Loader from 'shared/components/content/Loader';
import Form from 'shared/components/core/Form';
import CheckboxField from 'shared/components/fields/CheckboxField';
import { EmailField } from 'shared/components/fields/EmailField';
import { PhoneField } from 'shared/components/fields/PhoneField';
import { SelectField } from 'shared/components/fields/SelectField';
import { TextAreaField } from 'shared/components/fields/TextAreaField';
import { TextField } from 'shared/components/fields/TextField';
import { ZipCodeField } from 'shared/components/fields/ZipCodeField';
import BottomBar from 'shared/components/wrappers/BottomBar';

import privacyStates from './../../data/privacy-states.json';

export default function ContactFormWrapper() {
  const {
    isLoading,
    isSuccess,
    isFormIncomplete,

    isStateSelected,
    isStateSelectedCalifornia,

    consentForm,
    setConsentForm,

    onSubmit,
  } = useConsentFormState();

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess) {
    return (
      <>
        <CheckoutExperienceSection colClass='col-md-7'>
          <h1 className='content-title'>Thank you!</h1>
          <p className='content-text'>
            Your request has been received. Our support team will be in touch via email soon.
          </p>
        </CheckoutExperienceSection>

        <BottomBar>
          <a href={process.env.REACT_APP_MARKETING_URL} className='primary-button'>
            Back to myalloy.com
          </a>
        </BottomBar>
      </>
    );
  }

  return (
    <Form onSubmit={onSubmit}>
      <div className='questionnaire-wrapper fade-in-wrapper consent-form-wrapper'>
        <CheckoutExperienceSection>
          <QuestionLayoutWrapper
            wrapperClasses='content-markdown-wrapper'
            title='Alloy Health U.S. State Consumer Privacy Request Form'
            description={markdown_description}
          >
            <SelectField
              name='stateAbbr'
              label='State'
              value={consentForm.stateAbbr}
              placeholder='State'
              data={privacyStates.States}
              onChange={(value) =>
                setConsentForm({ ...consentForm, stateAbbr: upperCase(value || '') })
              }
            />
            {consentForm.stateAbbr === 'OTHER' && (
              <p className='question-text'>
                At this time, we are only required to respond to requests from residents of
                California, Washington, and Nevada.
              </p>
            )}
            {isStateSelected && (
              <>
                <CheckboxField
                  content='Request my personal information'
                  isPrevSelected={consentForm.rightToRequest}
                  onChange={(isSelected) =>
                    setConsentForm({ ...consentForm, rightToRequest: isSelected })
                  }
                />

                <CheckboxField
                  content='Correct my personal information'
                  isPrevSelected={consentForm.rightToCorrect}
                  onChange={(isSelected) =>
                    setConsentForm({ ...consentForm, rightToCorrect: isSelected })
                  }
                />

                <CheckboxField
                  content='Delete my personal information'
                  isPrevSelected={consentForm.rightToDelete}
                  onChange={(isSelected) =>
                    setConsentForm({ ...consentForm, rightToDelete: isSelected })
                  }
                />
                {!isStateSelectedCalifornia && (
                  <>
                    <CheckboxField
                      content='Confirm whether my personal information is collected, shared, or sold'
                      isPrevSelected={consentForm.rightToConfirm}
                      onChange={(isSelected) =>
                        setConsentForm({ ...consentForm, rightToConfirm: isSelected })
                      }
                    />

                    <CheckboxField
                      content='Appeal prior rights request'
                      isPrevSelected={consentForm.rightToAppeal}
                      onChange={(isSelected) =>
                        setConsentForm({ ...consentForm, rightToAppeal: isSelected })
                      }
                    />
                  </>
                )}

                <TextField
                  name='fullName'
                  label='Full Name'
                  value={consentForm.fullName}
                  placeholder='Full name'
                  onChange={(value) =>
                    setConsentForm({
                      ...consentForm,
                      fullName: value,
                    })
                  }
                />

                <TextField
                  name='address'
                  label='Address'
                  value={consentForm.customerAddress}
                  placeholder='Street address'
                  onChange={(value) =>
                    setConsentForm({
                      ...consentForm,
                      customerAddress: value,
                    })
                  }
                />

                <TextField
                  name='city'
                  label='City'
                  value={consentForm.customerCity}
                  placeholder='City'
                  onChange={(value) =>
                    setConsentForm({
                      ...consentForm,
                      customerCity: value,
                    })
                  }
                />

                <ZipCodeField
                  name='zip'
                  label='Zip Code'
                  placeholder='Zip code'
                  value={consentForm.customerZip}
                  onChange={(value) => setConsentForm({ ...consentForm, customerZip: value })}
                />

                <EmailField
                  label='Email'
                  name='email'
                  value={consentForm.customerEmail}
                  placeholder='Email address'
                  onChange={(value) => setConsentForm({ ...consentForm, customerEmail: value })}
                />

                <PhoneField
                  name='phone'
                  label='Phone Number'
                  placeholder='Phone number'
                  value={consentForm.customerPhone ?? consentForm.customerPhone ?? ''}
                  onChange={(phoneText) =>
                    setConsentForm({ ...consentForm, customerPhone: phoneText })
                  }
                />

                <TextAreaField
                  name='interaction'
                  rows={6}
                  label='Please describe your interactions with Alloy:'
                  value={consentForm.interaction}
                  placeholder='Type here'
                  onChange={(value) =>
                    setConsentForm({
                      ...consentForm,
                      interaction: value,
                    })
                  }
                />
              </>
            )}
          </QuestionLayoutWrapper>
        </CheckoutExperienceSection>
        <BottomBar>
          <button
            className='primary-button'
            type='submit'
            onClick={onSubmit}
            disabled={isFormIncomplete || isLoading || !isStateSelected}
          >
            Submit
          </button>
        </BottomBar>
      </div>
    </Form>
  );
}
