import { SharedLocation } from 'common/dist/models/shared/location';

import { getCurrentStepIndex } from 'modules/shared/lib/experience/experience';
import { retrieveFlowFromUrl } from 'modules/shared/lib/experience/experience-flow';

import { getQuestionSlugs } from '../../questionnaire/lib/question';

/**
 * This function takes in the base urls and the intake urls (if they exist)
 * and finds the percent the customer is on in the checkout experience. It is a little
 * more targeted at the moment but this is just to get out the door and then we can
 * look at improving it!
 *
 * We are using page and not index because we don't want to deal with a 0 and would rather
 * move forward in the page and go the whole length (ie on checkout progress bar full, at start
 * there is some progress, etc)
 *
 * @param location SharedLocation
 * @returns number
 */
export const getPercentComplete = (location: SharedLocation): number => {
  const flow = retrieveFlowFromUrl(location);
  const currentBasePage = getCurrentStepIndex(location.pathname, flow) + 1;

  const totalBasePages = flow.steps.length;
  const totalIntakePages = flow.intakeQuestions
    ? flow.intakeQuestions.filter((q) => q.type !== 'Hidden').length
    : 0;

  const totalPages = totalBasePages + totalIntakePages;

  const intakeStepIndex = flow.steps.findIndex((s) => s.path === 'intake');

  // Flow that has an intake
  if (intakeStepIndex >= 0) {
    const intakeSlugs = getQuestionSlugs(flow.intakeQuestions ?? []);

    const intakeStepPage = intakeStepIndex + 1;

    if (currentBasePage < intakeStepPage) {
      // Customer is before the intake page

      return (currentBasePage / totalPages) * 100;
    } else if (currentBasePage === intakeStepPage) {
      // Customer is on the intake page

      const foundCurrentIntakePage =
        (intakeSlugs.findIndex((s) => {
          const splitUrl = location.pathname.split('/');

          const endOfUrl = splitUrl[splitUrl.length - 1];

          return s === endOfUrl;
        }) ?? 0) + 1;

      const currentPage = currentBasePage + foundCurrentIntakePage;

      return (currentPage / totalPages) * 100;
    } else {
      // Customer is after the intake
      const currentPage = currentBasePage + totalIntakePages;

      return (currentPage / totalPages) * 100;
    }
  }
  // Flow that does not have an intake
  else {
    return (currentBasePage / totalPages) * 100;
  }
};
