import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useInitExperience from 'modules/shared/hooks/useInitExperience';

import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';

interface Props {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;

  BASE_PATH: string;
}

export const RequestExperienceContext = createContext<Props>({
  isLoading: true,
  setIsLoading: () => {},

  BASE_PATH: '',
});

export const useRequestExperience = () => useContext(RequestExperienceContext);

export default function RequestExperienceContextProvider({ children }: { children: ReactNode }) {
  const location = useLocation();

  const { initRequestExperience } = useInitExperience();

  const BASE_PATH = '/request-experience';

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    initiateExperience();
  }, []);

  const initiateExperience = async () => {
    setIsLoading(true);

    const retrievedCategories = retrieveCategoriesFromUrl(location);

    await initRequestExperience(retrievedCategories);

    setIsLoading(false);
  };

  return (
    <RequestExperienceContext.Provider
      value={{
        isLoading,
        setIsLoading,

        BASE_PATH,
      }}
    >
      {children}
    </RequestExperienceContext.Provider>
  );
}
