import './Payment.scss';

import { bindActionCreators } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { StripePaymentMethod, useGetPaymentMethods } from 'client/dist/generated/alloy';

import { updateBillingAddress } from 'modules/shared/sub-modules/checkout/store/actions';

import CheckboxField from 'shared/components/fields/CheckboxField';

import { Address } from 'shared/models/customer-address';

import { useAppSelector } from 'shared/store/reducers';

import ErrorReviewWrapper from './ErrorReview';

import AddressBlock from '../blocks/Address';
import PaymentOptionsBlock from '../blocks/PaymentOptions';
import SelectedPaymentBlock from '../blocks/SelectedPayment';

interface Props {
  onSelectPaymentMethod: (pm: StripePaymentMethod | null, allInputsComplete: boolean) => void;
  isError: boolean;
}

export default function PaymentWrapper({ onSelectPaymentMethod, isError }: Props) {
  const { data: paymentMethods = [] } = useGetPaymentMethods();
  const dispatch = useDispatch();

  const [allInputsComplete, setAllInputsComplete] = useState(false);
  const [isBillingDifferent, setIsBillingDifferent] = useState(false);
  const dispatchUpdateBilling = bindActionCreators(updateBillingAddress, dispatch);
  const customer = useAppSelector((state) => state.alloy.customer!);
  const { billingAddress, isEditingShipping } = useAppSelector((state) => state.experience);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<StripePaymentMethod | null>(
    paymentMethods.find((pm) => pm.is_primary) ?? null,
  );

  useEffect(() => {
    if (!isBillingDifferent) {
      dispatchUpdateBilling({
        firstName: customer.firstName ?? '',
        lastName: customer.lastName ?? '',
        shippingAddressLineOne: customer.shippingAddressLineOne ?? '',
        shippingAddressLineTwo: customer.shippingAddressLineTwo ?? '',
        city: customer.city ?? '',
        stateAbbr: customer.stateAbbr ?? '',
        zip: customer.zip ?? '',
      });
    }
  }, [isEditingShipping]);

  const onChangeBilling = (updatedForm: Address) => {
    dispatchUpdateBilling(updatedForm);
  };

  const onSelectOrRemove = (pm: StripePaymentMethod | null) => {
    setSelectedPaymentMethod(pm);

    onSelectPaymentMethod(pm, allInputsComplete);
  };

  const onUpdateInputsComplete = (isAllInputsComplete: boolean) => {
    setAllInputsComplete(isAllInputsComplete);

    onSelectPaymentMethod(selectedPaymentMethod, isAllInputsComplete);
  };

  if (isEditingShipping) {
    return (
      <div className='ce-payment-wrapper'>
        <div className={classNames('ce-block-header', 'no-padding')}>
          <p className='header-title'>
            <span className='header-number'>2</span>
            Payment method
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div className='ce-payment-wrapper'>
        <div className='ce-block-header'>
          <p className='header-title'>
            <span className='header-number'>2</span>
            Payment method{' '}
            {isError && (
              <span className={classNames('required-field', 'required-field-error')}>
                * Required
              </span>
            )}
          </p>
        </div>

        {isError && (
          <ErrorReviewWrapper
            reviewItems={['Complete all card fields below or select a payment method.']}
          />
        )}

        {selectedPaymentMethod ? (
          <SelectedPaymentBlock
            paymentMethod={selectedPaymentMethod}
            setRemovePaymentMethod={() => onSelectOrRemove(null)}
          />
        ) : (
          <PaymentOptionsBlock
            setAllInputsComplete={onUpdateInputsComplete}
            paymentMethods={paymentMethods}
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={onSelectOrRemove}
          />
        )}

        <div className='ce-payment-billing-block'>
          <CheckboxField
            content='Billing address is the same as shipping address'
            isPrevSelected={!isBillingDifferent}
            onChange={(isSelected) => setIsBillingDifferent(!isSelected)}
          />

          {isBillingDifferent && (
            <AddressBlock addressForm={billingAddress} onChange={onChangeBilling} />
          )}
        </div>
      </div>
    );
  }
}
