import { isEmpty } from 'lodash';
import { useState } from 'react';

import { useCancelContext } from 'modules/dashboard/sub-modules/manage-subscription/context/cancel';
import { useProductContext } from 'modules/dashboard/sub-modules/manage-subscription/context/product';

import { CANCEL_SAVE } from 'modules/dashboard/sub-modules/manage-subscription/data/reason';

import { getDeepProductIdsFrom } from 'modules/shared/lib/product';

import { QuestionnaireAnswers } from 'modules/questionnaire/models/questionnaire-answers';

import { TextAreaField } from 'shared/components/fields/TextAreaField';

import { useQueryParams } from 'shared/context/url/query';

import useSwitchable from 'shared/hooks/product/useSwitchable';

export default function NoResultsContent() {
  const { product } = useProductContext();
  const { navigateWithParams } = useQueryParams();

  const [inputText, setInputText] = useState('');

  const { onCancel, onSaveSubmission } = useCancelContext();
  const { isSwitchable } = useSwitchable({ product });

  const onChangeDose = async () => {
    await onSaveSubmission({ 'cancel-saved': CANCEL_SAVE.CHANGE_TREATMENT });

    navigateWithParams('/treatment-plan', {
      manage: 'change',
      'deepProductIds[]': getDeepProductIdsFrom(product).join(',')
    });
  };

  const onSubmitCancel = async (answers: QuestionnaireAnswers = {}) => {
    await onSaveSubmission(answers);

    await onCancel();
  };

  if (isSwitchable) {
    return (
      <div className='cancel-content-wrapper'>
        <div className='content-block'>
          <h4 className='content-title'>Did you know you can change your dosage?</h4>
          <p className='content-text'>
            If you aren't happy with your treatment, you can easily switch your dose.
          </p>
        </div>

        <div className='btns-stack-block'>
          <button className='primary-button' onClick={onChangeDose}>
            Change my dose
          </button>

          <button className='primary-link' onClick={() => onSubmitCancel()}>
            I want to cancel
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className='cancel-content-wrapper'>
        <div className='content-block'>
          <h4 className='content-title'>Tell us more about what you're experiencing:</h4>
        </div>

        <form>
          <TextAreaField
            name='no-results-field'
            value={inputText}
            onChange={setInputText}
            placeholder='Type here'
          />
        </form>

        <div className='btns-stack-block'>
          <button
            className='primary-button'
            disabled={isEmpty(inputText)}
            onClick={() => onSubmitCancel({ 'cancel-explanation': inputText })}
          >
            Continue to cancel
          </button>
        </div>
      </div>
    );
  }
}
