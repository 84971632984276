import { Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';

import usePendingState from 'modules/dashboard/sub-modules/treatment-plan/sub-modules/home/hooks/usePendingState';

import ListSelectableProducts from 'modules/dashboard/sub-modules/treatment-plan/ui/content/ListSelectableProducts';

import chevronDown from 'shared/assets/svg/common/chevron-down.svg';

import { toggleClass } from 'shared/lib/dom';

import DividerWrapper from './Divider';

export default function PendingWrapper() {
  const {
    products,
    type,
    isLoading,
    selectedProducts,
    onSelectProduct,
    onSwitch,
    onContinueCheckout,
  } = usePendingState();

  if (isLoading) {
    return (
      <div className='tp-products-list-wrapper'>
        <Skeleton variant='rectangular' height={200} />
      </div>
    );
  }

  if (products.length === 0) return <></>;

  return (
    <>
      <DividerWrapper />

      <div className='tp-products-list-wrapper active' id='approve-products-list'>
        <div className='tp-header'>
          <p className='tp-subtitle'>Approve your treatments</p>

          <div className='tp-btn-wrapper'>
            <button className='tp-chevron-btn' onClick={() => toggleClass('approve-products-list')}>
              <img src={chevronDown} alt='chevron-down' />
            </button>
          </div>
        </div>

        <div className='products-list'>
          {/* Listing selectable products for pending selection */}
          <ListSelectableProducts
            products={products}
            selectedProducts={selectedProducts}
            onSelect={onSelectProduct}
            onSwitch={onSwitch}
            showRetentionProgress={type === 'RETENTION'}
          />

          <div className='btn-container'>
            <button
              className='primary-button'
              onClick={onContinueCheckout}
              disabled={selectedProducts.length === 0}
            >
              Continue to checkout
            </button>

            {type === 'RETENTION' && (
              <p className='products-text'>
                Have Questions?{' '}
                <Link to='/messages' className='text-link'>
                  message your doctor
                </Link>
              </p>
            )}
          </div>
        </div>
      </div>

      <DividerWrapper />
    </>
  );
}
