import './CalloutTag.scss';

interface Props {
  text: string;
  icon?: string;
}

export default function CalloutTagBlock({ text, icon }: Props) {
  const showIcon = icon && icon?.length > 0;
  return (
    <div className='callout-tag-block'>
      {showIcon && <img className='callout-tag-img' src={icon} alt='decorative icon' />}
      <p className='callout-tag-text'>{text}</p>
    </div>
  );
}
