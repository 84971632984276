import ProgressBlock from 'modules/dashboard/sub-modules/treatment-plan/ui/blocks/Progress';

export default function DoctorReviewCardBlock() {
  return (
    <div className='header-state-block'>
      <div className='state-content-wrapper'>
        <div className='content-hug-wrapper'>
          <span className='hug-text'>under review</span>
          <div className='hug-alert-icon' />
        </div>

        <h3 className='content-title'>Your intake is under doctor review</h3>

        <div className='content-inner-wrapper'>
          <p className='content-subtitle mb-0'>You've taken your first step. Here's what's next.</p>
          <p className='content-text'>
            Your doctor will review your health history and determine the product right for you,
            usually within 1-3 days.
          </p>

          <ProgressBlock
            barTitles={['Complete Intake', 'Doctor review', 'Confirm your plan', 'Ship treatments']}
            currentIndex={1}
            hideButton
          />
        </div>
      </div>
    </div>
  );
}
