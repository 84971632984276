import './Header.scss';

import classNames from 'classnames';
import { format } from 'date-fns';
import { useState } from 'react';

import useCustomerSymptoms from 'modules/dashboard/sub-modules/treatment-plan/hooks/useCustomerSymptoms';

import SectionWrapper from 'shared/components/wrappers/Section';

import { useAppSelector } from 'shared/store/reducers';

interface Props {
  title: string;
  fancyTitle: string;
  showActive?: boolean;
  backgroundType?: 'primary' | 'secondary';
}

export default function HeaderBlock({
  title,
  fancyTitle,
  showActive = false,
  backgroundType = 'primary',
}: Props) {
  const [showAllSymptoms, setShowAllSymptoms] = useState<boolean>(false);

  const { symptoms, isLoading } = useCustomerSymptoms();

  const customer = useAppSelector((state) => state.alloy.customer!);

  const onShowSymptoms = () => {
    setShowAllSymptoms(!showAllSymptoms);
  };

  const filteredSymptoms = showAllSymptoms ? symptoms : symptoms.slice(0, 3);

  return (
    <SectionWrapper
      sectionClasses={classNames('dashboard-treatment-plan-header-section', backgroundType)}
    >
      <div className='col-12 col-md-7'>
        <h1 className='header-title'>
          {title}
          <br />
          <span>{fancyTitle}</span>
        </h1>

        {customer.status === 'ACTIVE' && showActive && (
          <p className='header-text'>Member since {format(new Date('12/12/2020'), 'MMM, yyyy')}</p>
        )}
      </div>

      {customer.status !== 'NEW' && symptoms.length !== 0 && !isLoading && (
        <div className='col-12 col-md-5 mt-3 mt-md-0'>
          <div className='header-symptoms-block'>
            <p className='symptoms-title'>Symptom relief from</p>

            <ul className='symptoms-list'>
              {filteredSymptoms.map((symptom, index) => (
                <li className='symptom-item' key={index}>
                  <img
                    src={symptom.fields.icon.fields.file.url}
                    alt={symptom.fields.name}
                    className='symptom-icon'
                  />
                  <span className='symptom-text'>{symptom.fields.name}</span>
                </li>
              ))}
            </ul>

            {symptoms.length > 3 && (
              <button className='symptoms-link' onClick={onShowSymptoms}>
                View {showAllSymptoms ? 'less' : 'more'}
              </button>
            )}
          </div>
        </div>
      )}
    </SectionWrapper>
  );
}
