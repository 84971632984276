import doctors from 'modules/shared/assets/images/doctors.png';
import { HowItWorksItem } from '../../relief-type/models/how-it-works';

export const howItWorks: HowItWorksItem[] = [
  {
    title: 'Fill out the intake form',
    text: 'Tell us about your symptoms',
  },
  {
    title: 'Message with your doctor',
    image: doctors,
    text: 'Your doctor will review your assessment and answer your questions to ensure you get the products most effective for you.',
  },
  {
    title: 'Get treatments delivered',
    text: 'Your prescription medications will be delivered directly to your door.',
  },
];
