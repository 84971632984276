import { BrazeCustomer } from '../models/braze';

/**
 * from a customer with all the key/values related to shipping address
 * build an object of a shippingAddress with the key/values related.
 *
 * @param customer
 * @returns
 */
export const getShippingFrom = (customer: BrazeCustomer) => {
  return {
    city: customer.city,
    shippingAddressLineOne: customer.shippingAddressLineOne,
    shippingAddressLineTwo: customer.shippingAddressLineTwo,
    stateAbbr: customer.stateAbbr,
    zip: customer.zip,
  };
};
