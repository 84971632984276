import './Calendar.scss';

import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';

import { ShippingMethod } from 'client/dist/generated/alloy';

import { useSubscriptionContext } from 'modules/dashboard/sub-modules/manage-subscription/context/manage';

import { getMaxDateForRescheduling } from 'modules/dashboard/lib/prescription';
import { getFormattedDateRange } from 'modules/dashboard/sub-modules/subscriptions/lib/day';
import {
  maxDaysFromShipping,
  minDaysFromShipping,
} from 'modules/shared/sub-modules/checkout/lib/shipping';

interface Props {
  shippingMethod?: ShippingMethod;
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
}

export default function CalendarBlock({ shippingMethod, selectedDate, setSelectedDate }: Props) {
  const { subscription } = useSubscriptionContext();

  const maxDate = getMaxDateForRescheduling(subscription);

  return (
    <div className='manage-calendar-block'>
      <p className='content-title'>
        Next bill date: {selectedDate ? format(selectedDate, 'M/d') : '-'}
      </p>

      <p className='content-text'>
        Expected shipment delivery:{' '}
        {selectedDate
          ? getFormattedDateRange(
              selectedDate,
              minDaysFromShipping(shippingMethod),
              maxDaysFromShipping(shippingMethod),
            )
          : '-'}
      </p>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateCalendar
          showDaysOutsideCurrentMonth
          disablePast
          maxDate={maxDate}
          onChange={setSelectedDate}
          focusedView='day'
          views={['day']}
          defaultValue={subscription.nextRecurrenceOn}
        />
      </LocalizationProvider>
    </div>
  );
}
