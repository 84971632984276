import MhtPreferencesFormWrapper from 'modules/checkout-experience/sub-modules/mht-preferences/ui/wrappers/MhtPreferencesForm';

import Layout from 'shared/components/core/Layout';

export default function MhtPreferencesPage() {
  return (
    <Layout title='MHT Preferences - Alloy' desc='' noBars>
      <MhtPreferencesFormWrapper />
    </Layout>
  );
}
