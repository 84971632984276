import { ReactNode } from 'react';

interface Props {
  onSubmit: () => void;
  children: ReactNode;
}

export default function Form({ onSubmit, children }: Props) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {children}
    </form>
  );
}
