import './BundleDrawer.scss';

import classNames from 'classnames';
import { format, getUnixTime } from 'date-fns';
import { first, uniqBy } from 'lodash';
import { useEffect, useState } from 'react';

import {
  SubscriptionWithRenewal,
  bundleSubscriptions,
  useGetAllSubscriptionsForCustomer,
} from 'client/dist/generated/alloy';
import { SEVENTY_FIVE_DAY_RECURRING_ID } from 'common/dist/products/productRegistry';

import { getTotalsForSubscriptions } from 'modules/shared/lib/subscriptions';
import {
  buildDataForCalculation,
  calculateTaxes,
} from 'modules/shared/sub-modules/checkout/lib/tax';

import { Option } from 'modules/dashboard/models/options-select-list';

import OptionsSelectList from 'modules/dashboard/ui/blocks/OptionsSelectList';

import OptionsSelectListBlock from 'modules/dashboard/ui/wrappers/ProductsList';

import chevronDownIcon from 'shared/assets/svg/common/chevron-down.svg';

import Loader from 'shared/components/content/Loader';
import AlloyDrawer from 'shared/components/core/AlloyDrawer';

import { convertCentsToDollars } from 'shared/lib/convert';

import { SummaryContent } from 'shared/models/summary-content';

import { useAppSelector } from 'shared/store/reducers';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  bundableSubscriptions: SubscriptionWithRenewal[];
  setDidBundle: (didBundle: boolean) => void;
}

export default function BundleDrawer({
  open,
  setOpen,
  bundableSubscriptions,
  setDidBundle,
}: Props) {
  const [isLoading, setIsLoading] = useState(true);

  const customer = useAppSelector((state) => state.alloy.customer!);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const [selectedSubId, setSelectedSubId] = useState(
    first(bundableSubscriptions)?.stripeSubscriptionId ?? '',
  );
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [taxInCents, setTaxInCents] = useState(0);

  const { subtotal } = getTotalsForSubscriptions(bundableSubscriptions);

  const hasBirthControlSubscription = subscriptions.some((sub) =>
    sub.products.some((pfr) => pfr.product.frequencyId === SEVENTY_FIVE_DAY_RECURRING_ID),
  );

  useEffect(() => {
    const getTaxAmount = async () => {
      const data = buildDataForCalculation(
        0,
        0,
        bundableSubscriptions.flatMap((sub) => sub.products.map((pfr) => pfr.product.priceInCents)),
        customer,
      );

      const tax = await calculateTaxes(data);

      setTaxInCents(tax * 100);

      setIsLoading(false);
    };

    getTaxAmount();
  }, []);

  // construct options to display for shipping date
  const options: Option[] = uniqBy(
    bundableSubscriptions
      .map((sub) => ({
        id: sub.stripeSubscriptionId,
        title: format(new Date(sub.nextRecurrenceOn), 'MM/dd/yyyy'),
      }))
      .concat([
        {
          id: '',
          title: 'Ship now',
        },
      ]),
    (option) => option.title,
  );

  // construct summary content for summary section to view
  const summaryContent: SummaryContent[] = [
    {
      title: 'Subtotal',
      content: `$${convertCentsToDollars(subtotal)}`,
    },
    {
      title: 'Taxes',
      content: `$${convertCentsToDollars(taxInCents)}`,
    },
    {
      title: 'Shipping',
      content: `$0.00`,
    },
  ];

  const onConfirm = async () => {
    setIsLoading(true);

    const selectedStartDate = bundableSubscriptions.find(
      (sub) => sub.stripeSubscriptionId === selectedSubId,
    )?.nextRecurrenceOn;

    const startTimestamp = !!selectedStartDate
      ? getUnixTime(new Date(selectedStartDate))
      : undefined;

    await bundleSubscriptions({
      productFrequencyIds: bundableSubscriptions.flatMap((sub) =>
        sub.products.map((pfr) => pfr.product.id),
      ),
      stripeSubscriptionIds: bundableSubscriptions.map((sub) => sub.stripeSubscriptionId),
      startTimestamp,
    });

    setDidBundle(true);
    setOpen(false);
  };

  return (
    <AlloyDrawer
      title='Bundle your shipments'
      drawerClass='bundle-drawer'
      open={open}
      onClose={() => setOpen(false)}
      disableClosing={isLoading}
    >
      {isLoading ? (
        <Loader size='lg' />
      ) : (
        <>
          <div className='drawer-body'>
            <div className='date-options-content-wrapper'>
              <p className='content-title'>Select your ship date</p>

              <OptionsSelectList
                options={options}
                selectedId={selectedSubId}
                onSelect={(id) => setSelectedSubId(id)}
              />
            </div>

            <div className='products-summary-content-wrapper'>
              <OptionsSelectListBlock
                productsWithRenewal={bundableSubscriptions.flatMap((sub) => sub.products)}
                subscriptions={bundableSubscriptions}
                showDosage
              />

              <div className='content-divider' />

              <div className={classNames('summary-block', isSummaryOpen && 'is-open')}>
                <div
                  className='summary-header-wrapper'
                  onClick={() => setIsSummaryOpen(!isSummaryOpen)}
                >
                  <p className='summary-title'>Bundled Total</p>

                  <p className='summary-text'>
                    ${convertCentsToDollars(subtotal + taxInCents)}
                    <img src={chevronDownIcon} alt='chevron-down' className='chevron-icon' />
                  </p>
                </div>

                <div className='summary-content-wrapper'>
                  {summaryContent.map((summary, index) => (
                    <div className='content-row' key={index}>
                      <p className='content-title'>{summary.title}</p>
                      <p className='content-text'>{summary.content}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {hasBirthControlSubscription && (
              <div className='informational-content-wrapper'>
                <p className='content-title'>Why can't I bundle my low-dose birth control?</p>
                <p className='content-text'>
                  Low-dose birth control requires a unique shipping cadence to ensure you receive
                  your medication on time. To maintain this precise schedule, we must ship it
                  separately from other products.
                </p>
              </div>
            )}
          </div>

          <div className='drawer-footer'>
            <button className='primary-button full-width-button' onClick={onConfirm}>
              Confirm
            </button>
          </div>
        </>
      )}
    </AlloyDrawer>
  );
}
