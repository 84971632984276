import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';
import { retrieveFlowFromUrl } from 'modules/shared/lib/experience/experience-flow';
import { getGroupedQualifiedProducts } from 'modules/shared/lib/product';

interface ReturnProps {
  flowProducts: GroupedContentfulProduct[][];
  shouldShowEdit: boolean;
  isLoading: boolean;
}

export default function useFlowProducts(): ReturnProps {
  const location = useLocation();

  const [flowProducts, setFlowProducts] = useState<GroupedContentfulProduct[][]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const flow = retrieveFlowFromUrl(location);
  const categories = retrieveCategoriesFromUrl(location);

  const shouldShowEdit = !(
    categories.some((c) => c === 'mht') ||
    (categories.length === 1 && !categories.every((c) => c === 'skin-health'))
  );

  useEffect(() => {
    if (!shouldShowEdit) return;

    const fetchFlowProducts = async () => {
      setIsLoading(true);

      const flowProducts = await ProductRegistry.get().getDefaultProductsByIds(flow.productIds);

      const fetchedProducts = await getGroupedQualifiedProducts(flowProducts, categories);

      setFlowProducts(fetchedProducts);
      setIsLoading(false);
    };

    fetchFlowProducts();
  }, []);

  return { flowProducts, shouldShowEdit, isLoading };
}
