import { bindActionCreators } from '@reduxjs/toolkit';
import { flatten } from 'lodash';
import { useDispatch } from 'react-redux';

import { ExperienceCategory } from 'common/dist/models/experience';
import { DeepProduct } from 'common/dist/products/productFrequency';

import { getGroupedQualifiedProducts } from 'modules/shared/lib/product';

import { AlloyCart } from 'modules/shared/models/cart';

import { useAppSelector } from 'shared/store/reducers';

import { sortProducts } from '../lib/product/sort';
import { clear, create, update } from '../store/cart';

interface ReturnProps {
  cart: AlloyCart;

  createCart: (products: DeepProduct[], categories: ExperienceCategory[]) => Promise<void>;
  updateCart: (cartUpdate: Partial<AlloyCart>) => void;
  clearCart: () => void;
}

export default function useCart(): ReturnProps {
  const dispatch = useDispatch();

  const { cart } = useAppSelector((state) => state.experience);

  const dispatchCreateCart = bindActionCreators(create, dispatch);
  const dispatchUpdateCart = bindActionCreators(update, dispatch);
  const dispatchClearCart = bindActionCreators(clear, dispatch);

  const createCart = async (products: DeepProduct[], categories: ExperienceCategory[]) => {
    const fetchedProducts = flatten(await getGroupedQualifiedProducts(products, categories));

    dispatchCreateCart(sortProducts(fetchedProducts));
  };

  const updateCart = (cartUpdate: Partial<AlloyCart>) => dispatchUpdateCart(cartUpdate);

  const clearCart = () => dispatchClearCart();

  return {
    cart,

    createCart,
    updateCart,
    clearCart,
  };
}
