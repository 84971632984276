import './ConfirmChangeDrawer.scss';

import classNames from 'classnames';
import { format } from 'date-fns';
import { minBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  IntakeCategory,
  SubscriptionWithRenewal,
  processProductSwitch,
  submissionByCategoriesExists,
  useGetAllSubscriptionsForCustomer,
  useGetPendingSwitch,
} from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import { ExperienceCategory } from 'common/dist/models/experience';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import useCart from 'modules/shared/hooks/useCart';

import {
  getDeepProductIdsFrom,
  getDeepProductsFromGroupedProducts,
} from 'modules/shared/lib/product';
import { formatExperienceURL } from 'modules/shared/lib/url';

import ViewableProduct from 'modules/shared/ui/blocks/ViewableProduct';

import Loader from 'shared/components/content/Loader';
import AlloyDrawer from 'shared/components/core/AlloyDrawer';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  currentProduct: GroupedContentfulProduct;
  requestedProduct: GroupedContentfulProduct;
  selectedType: 'CUSTOMER_FORM_FACTOR_CHANGE' | 'CUSTOMER_DOSAGE_CHANGE';
  hasPrescription: boolean;
  subscription: SubscriptionWithRenewal;
  dosageSwitchReasonId: number | undefined;
}

export default function ConfirmChangeDrawer({
  open,
  setOpen,
  currentProduct,
  requestedProduct,
  selectedType,
  hasPrescription,
  subscription,
  dosageSwitchReasonId,
}: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const { data: subscriptions = [], isLoading: isLoadingPlan } =
    useGetAllSubscriptionsForCustomer();
  const { mutate: mutatePendingSwitch } = useGetPendingSwitch();

  const { updateCart } = useCart();

  const nextShipment = minBy(subscriptions, (sub) => new Date(sub.nextRecurrenceOn));

  const [isLoading, setIsLoading] = useState(true);
  const [shipmentProducts, setShipmentProducts] = useState<GroupedContentfulProduct[]>([]);
  const [hasRecentSubmission, setHasRecentSubmission] = useState<boolean>(false);

  const isLoadingData = isLoading || isLoadingPlan;

  const [selectedProduct] = getDeepProductsFromGroupedProducts([requestedProduct]);
  const selectedProductCategory = selectedProduct.category as IntakeCategory;

  useEffect(() => {
    const fetchProducts = async () => {
      const products =
        nextShipment?.products
          // filter per product id here, this way if the shipment has [pill 1mg, prog, m4, tretinoin]
          // and the switch is for another form factor / dosage for pill 1mg (customer selected patch for example)
          // we filter out estradiol from the next shipment products and only show [prog, m4, tretinoin]
          .filter((pfr) => pfr.product.productId !== selectedProduct.productId)
          .map((pfr) => pfr.product) ?? [];

      const groupedContentful = (
        await ProductRegistry.get().getRecurringProductsForV2(products)
      ).flat();

      setShipmentProducts(groupedContentful);
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const checkRecent = async () => {
      const exists = await submissionByCategoriesExists({
        categories: [selectedProductCategory],
        timeAmount: 6,
        timeUnit: 'month',
        checkAllCategories: false,
      });

      setHasRecentSubmission(exists);
      setIsLoading(false);
    };

    checkRecent();
  }, []);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      updateCart({
        products: [requestedProduct],
        checkoutType: selectedType,
      });

      const currentPfIds = getDeepProductIdsFrom(currentProduct);
      const requestedPfIds = getDeepProductIdsFrom(requestedProduct);

      if (hasRecentSubmission) {
        await processProductSwitch({
          checkoutType: selectedType,
          currentPfIds,
          requestedPfIds,
          subscriptionId: subscription.stripeSubscriptionId,
          categoryType: requestedProduct.alloyProduct.parent[0].category, // get the category from the parent
          dosageSwitchReasonId: dosageSwitchReasonId,
        });

        await mutatePendingSwitch();

        const searchParams = new URLSearchParams(location.search);

        searchParams.append('hasPrescription', hasPrescription.toString());
        searchParams.append('checkoutType', selectedType);
        searchParams.append('subscriptionId', subscription.stripeSubscriptionId);
        searchParams.append('type', 'switch');

        navigate({
          pathname: '/request-confirmation',
          search: searchParams.toString(),
        });
      } else {
        // as we do anywhere, just send the customer to the basic url and from there, the request experience
        // will handle where to place them and check and restrictions
        const experienceUrl = formatExperienceURL(
          '/request-experience',
          location,
          [selectedProductCategory as ExperienceCategory],
          [
            ['currentPfIds[]', currentPfIds.join(',')],
            ['requestedPfIds[]', requestedPfIds.join(',')],
            ['hasPrescription', hasPrescription.toString()],
            ['checkoutType', selectedType],
            ['subscriptionId', subscription.stripeSubscriptionId],
            ['type', 'switch'],
          ],
        );

        navigate(experienceUrl);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getButtonCopy = () => {
    switch (true) {
      case hasPrescription:
        return 'Continue';
      case hasRecentSubmission:
        return 'Confirm request';
      default:
        return 'Start intake';
    }
  };

  return (
    <AlloyDrawer
      title='Confirm change'
      drawerClass={classNames('confirm-change-drawer', !isLoadingData && 'show-footer')}
      open={open}
      onClose={() => setOpen(false)}
      disableClosing={isLoadingData}
    >
      {isLoadingData ? (
        <Loader size='lg' />
      ) : (
        <>
          <div className='drawer-body'>
            <div className='request-content-wrapper'>
              <p className='content-blurb'>
                {hasRecentSubmission && selectedType === 'CUSTOMER_FORM_FACTOR_CHANGE'
                  ? 'Your new treatment will be included in your next shipment'
                  : `If your doctor approves your new ${
                      selectedType === 'CUSTOMER_DOSAGE_CHANGE' ? 'dosage' : 'treatment'
                    } it will be included in your next shipment`}
                {nextShipment &&
                  `, scheduled for ${format(
                    new Date(nextShipment.nextRecurrenceOn),
                    'MM/dd/yyyy',
                  )}`}
                .
              </p>

              <div className='request-products-wrapper'>
                <ViewableProduct
                  groupedProduct={requestedProduct}
                  showDosage={selectedType === 'CUSTOMER_FORM_FACTOR_CHANGE'}
                  hideProductInformation
                  hideDetails
                  hidePrice={selectedType === 'CUSTOMER_DOSAGE_CHANGE'}
                >
                  <span className='product-hug'>
                    {selectedType === 'CUSTOMER_DOSAGE_CHANGE'
                      ? 'New dose requested'
                      : 'New treatment'}
                  </span>
                </ViewableProduct>

                {shipmentProducts.length !== 0 && (
                  <>
                    <p className='request-text'>Included in your next shipment</p>

                    {shipmentProducts.map((gcp, index) => (
                      <ViewableProduct
                        key={index}
                        groupedProduct={gcp}
                        showDosage
                        hideProductInformation
                        hideDetails
                        hidePrice={selectedType === 'CUSTOMER_DOSAGE_CHANGE'}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className='drawer-footer'>
            <button className='primary-button full-width-button' onClick={onSubmit}>
              {getButtonCopy()}
            </button>
          </div>
        </>
      )}
    </AlloyDrawer>
  );
}
