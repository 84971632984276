import { configureStore, isPlain } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';

import reducers from 'shared/store/reducers';

const sentryReduxEnhancer = createReduxEnhancer();

/**
 * simple check to follow existing patterns we have after updating to react v18
 * this just caused warnings before that Date is no serializable so in order to behave
 * how we had it, just need to disable and then later can explore other options
 * around either converting objects in redux (date => string), etc
 *
 * @param value any
 * @returns boolean
 */
const isSerializable = (value: any) => {
  // Treat Date objects as serializable
  if (value instanceof Date) {
    return true;
  }

  // Keep default behavior for other types
  return isPlain(value);
};

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        isSerializable,
      },
    }),
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
});
