import './FollowUp.scss';

import { useCancelContext } from 'modules/dashboard/sub-modules/manage-subscription/context/cancel';

import Loader from 'shared/components/content/Loader';

import BadExperienceContent from '../content/cancel/BadExperience';
import DifferentProductContent from '../content/cancel/DifferentProduct';
import DownloadInvoiceContent from '../content/cancel/DownloadInvoice';
import MovingContent from '../content/cancel/Moving';
import NoResultsContent from '../content/cancel/NoResults';
import NoSubscriptionContent from '../content/cancel/NoSubscription';
import NotFeelingBetterContent from '../content/cancel/NotFeelingBetter';
import OtherContent from '../content/cancel/Other';
import PersonalDoctorContent from '../content/cancel/PersonalDoctor';
import SideEffectsContent from '../content/cancel/SideEffects';
import TooMuchProductContent from '../content/cancel/TooMuchProduct';

const ReasonsContent = () => {
  const { reasonType } = useCancelContext();

  switch (reasonType) {
    case 'NOT_FEELING_BETTER':
      return <NotFeelingBetterContent />;

    case 'NOT_SEEING_RESULTS':
      return <NoResultsContent />;

    case 'EXPERIENCING_SIDE_EFFECTS':
      return <SideEffectsContent />;

    case 'TOO_EXPENSIVE':
    case 'USE_INSURANCE':
      return <DownloadInvoiceContent />;

    case 'DIFFERENT_PRODUCT':
      return <DifferentProductContent />;

    case 'TOO_MUCH_PRODUCT':
      return <TooMuchProductContent />;

    case 'BAD_EXPERIENCE':
      return <BadExperienceContent />;

    case 'MOVING':
      return <MovingContent />;

    case 'NO_SUBSCRIPTION':
      return <NoSubscriptionContent />;

    case 'USE_PERSONAL_DOCTOR':
      return <PersonalDoctorContent />;

    default:
      return <OtherContent />;
  }
};

export default function FollowUpContainer() {
  const { isLoading } = useCancelContext();

  if (isLoading) return <Loader />;

  return (
    <div className='cancel-follow-up-container'>
      <ReasonsContent />
    </div>
  );
}
