import classNames from 'classnames';
import { SelectPicker } from 'rsuite';

export interface ItemDataType {
  value: string;
  label: string;
  groupBy?: string;
}

interface Props {
  name: string;
  label?: string;
  value?: string;
  placeholder?: string;
  data: ItemDataType[];
  onChange: (value: string | null) => void;
  isError?: boolean;
}

export const SelectField = ({
  name,
  label,
  placeholder,
  value,
  data,
  onChange,
  isError,
}: Props) => (
  <div className={classNames('input-field-wrapper', isError && 'input-field-error')}>
    {label && <label className='field-label'>{label}</label>}

    <SelectPicker
      name={name}
      value={value}
      placeholder={placeholder}
      data={data}
      className='field-select'
      placement='autoVerticalStart'
      size='lg'
      preventOverflow
      onChange={onChange}
    />

    {isError && <p className='field-required-label'>Required</p>}
  </div>
);
