import './Start.scss';

import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';

import { useProductContext } from 'modules/dashboard/sub-modules/manage-subscription/context/product';

import {
  extractCategories,
  isLastGroupedProductIn,
} from 'modules/shared/lib/subscriptions/product';
import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';

import { CancelStep } from 'modules/dashboard/sub-modules/manage-subscription/models/cancel-step';

import SubscriberBenefitsBlock from '../content/cancel/SubscriberBenefits';

interface Props {
  onReschedule: () => void;
  onContinue: (step: CancelStep) => void;
}

export default function StartBlock({ onReschedule, onContinue }: Props) {
  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();
  const { product } = useProductContext();

  const { activeSubs } = getSubscriptionsWithStatus(subscriptions);

  // in the instance a customer only has otc product left, we don't need to display
  // the benefits info as it is meant for non otc subscribers (doctor access, etc)
  const isLastProduct =
    isLastGroupedProductIn(activeSubs, product) &&
    extractCategories(product).every((c) => c !== 'gut-health');

  return (
    <div className='cancel-start-block'>
      <div className='content-block'>
        {isLastProduct ? (
          <>
            <h4 className='content-title'>Don&apos;t lose your benefits!</h4>
            <p className='content-text'>Would you like to reschedule your next delivery instead?</p>
          </>
        ) : (
          <h4 className='content-title'>
            Would you like to reschedule your next shipment instead?
          </h4>
        )}
      </div>

      <div className='btns-stack-block'>
        <button className='primary-button' onClick={onReschedule}>
          {isLastProduct ? 'Reschedule' : 'Yes, reschedule'}
        </button>

        <button className='primary-link' onClick={() => onContinue('RETENTION')}>
          No, I want to cancel
        </button>
      </div>

      {isLastProduct && <SubscriberBenefitsBlock showDoctor />}
    </div>
  );
}
