import { uniq } from 'lodash';

import { DeepProduct } from 'common/dist/products/productFrequency';

import drMennImg from 'modules/shared/assets/images/dr-menn.png';

import { features } from 'modules/shared/data/features';

import useInitExperience from 'modules/shared/hooks/useInitExperience';

import ProductReviewCardBlock from 'modules/shared/sub-modules/review/ui/blocks/ProductReviewCard';

import FeaturesBlock from 'modules/checkout-experience/ui/blocks/Features';
import ImprovementBlock from 'modules/checkout-experience/ui/blocks/Improvement';

import CheckoutExperienceSection from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceSection';

import Layout from 'shared/components/core/Layout';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

interface Props {
  products: DeepProduct[];
}

export default function DisqualifiedWrapper({ products }: Props) {
  const { initCheckoutExperience } = useInitExperience();

  const productNames = uniq(products.map((pf) => pf.name));

  const onStartConsult = async () => await initCheckoutExperience(['mht']);

  return (
    <Layout title='Intake - Alloy' desc='' noBars>
      <TopBannerWithProgress />

      <CheckoutExperienceSection colClass='col-md-7'>
        <h1 className='content-title'>Our Recommendation</h1>
        <p className='content-text'>
          Based on your answers,{' '}
          {productNames.length === 1 ? productNames[0] : 'your requested treatments'} may not be the
          right fit, but there's a solution for everyone. We need to ask a few more questions to
          find your ideal treatment.
        </p>

        <ProductReviewCardBlock
          topImg={drMennImg}
          title='Doctor Consultation'
          subtitle='$49.00 (1-time cost)'
          description='A menopause specialized doctor will review your intake and work with you to find a treatment that is the best for you.'
        >
          <div className='product-button-wrapper'>
            <button className='primary-button' onClick={onStartConsult}>
              Start intake
            </button>

            <a
              className='primary-link-button'
              href={process.env.REACT_APP_MARKETING_URL + '/solutions'}
            >
              Explore other treatments
            </a>
          </div>
        </ProductReviewCardBlock>

        <ImprovementBlock
          title='95% of Alloy customers '
          text='taking MHT have symptom relief in 2 weeks or less'
        />

        <FeaturesBlock data={features} />
      </CheckoutExperienceSection>
    </Layout>
  );
}
