import './Features.scss';

import classNames from 'classnames';

import { IconText } from 'shared/models/icon-text';

interface Props {
  data: IconText[];
  customBg?: string;
}

export default function FeaturesBlock({ data, customBg }: Props) {
  return (
    <div className={classNames(customBg, 'features-block')}>
      {data.map(({ icon, text }, index) => (
        <div className='feature-wrapper' key={text + '-' + index}>
          <img src={icon} alt={text} className='feature-img' />

          <p className='feature-text'>{text}</p>
        </div>
      ))}
    </div>
  );
}
