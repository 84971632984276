import classNames from 'classnames';
import { first } from 'lodash';
import { useState } from 'react';

import ProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import useProductPrice from 'modules/shared/hooks/useProductPrice';

import { isSynbiotic } from 'modules/shared/lib/contentful';
import { formatProductNames, getProductNameWithDosage } from 'modules/shared/lib/product/name';

import ProgressBlock from 'modules/dashboard/sub-modules/treatment-plan/ui/blocks/Progress';

import checkedBox from 'shared/assets/svg/common/checked-box-icon.svg';
import uncheckedBox from 'shared/assets/svg/common/unchecked-box-icon.svg';

import { EmailUs } from 'shared/components/content/Buttons';

import ProductNameDosage from './ProductNameDosage';
import ProductPricingBlock from './ProductPricingBlock';

import ProductInfoDrawer from '../drawers/ProductInfoDrawer';

interface Props {
  groupedProduct: GroupedContentfulProduct;
  isSelected: boolean;
  onSelect: (gcp: GroupedContentfulProduct) => void;
  hideProductInformation?: boolean;
  hideDetails?: boolean;
  showDosage?: boolean;
  isParentSelected?: boolean;
  parents?: GroupedContentfulProduct[];
  showRetentionProgress?: boolean;
}

// This component is just about universal, eventually would need to throw in a discounted price and title for parent bundleded product
export default function BundleSelectableProduct({
  groupedProduct,
  isSelected,
  onSelect,
  hideProductInformation = false,
  hideDetails = false,
  showDosage = false,
  isParentSelected = false,
  parents = [],
  showRetentionProgress,
}: Props) {
  const { unbundledPrice, bundledPrice } = useProductPrice({
    groupedProduct,
    parents,
  });

  const { contentfulProduct } = groupedProduct;

  const productsCleanNameAndDosage = showDosage
    ? getProductNameWithDosage(groupedProduct)
    : [{ cleanName: contentfulProduct.fields.title }];

  const categories = contentfulProduct.fields.categories;

  const [showProdInfo, setShowProdInfo] = useState<boolean>(false);

  const isProductSynbiotic = isSynbiotic(contentfulProduct);
  const parentNames = formatProductNames(parents);

  const onSelectProduct = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onSelect(groupedProduct);
  };

  const onOpenProdInfo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setShowProdInfo(true);
  };

  return (
    <>
      <div
        className={classNames('selectable-product', isSelected && 'selected')}
        onClick={onSelectProduct}
      >
        {!isProductSynbiotic && <span className='product-rx-tag'>Rx</span>}

        <div className={classNames('product-header')}>
          <div className='header-content'>
            <img
              src={contentfulProduct.fields.photo.fields.file.url}
              alt={contentfulProduct.fields.photo.fields.title}
              className='header-img'
            />

            <div className='header-text-wrapper'>
              {productsCleanNameAndDosage.map(({ cleanName, dosage }, index) => (
                <ProductNameDosage key={index} cleanName={cleanName} dosage={dosage} />
              ))}

              <ProductPricingBlock
                unbundledPrice={unbundledPrice}
                bundledPrice={bundledPrice}
                canBeDiscounted={!!parents.length}
                isParentSelected={isParentSelected}
                discountedParentName={parentNames}
                categories={categories}
              />

              {hideDetails && !hideProductInformation && (
                <button onClick={onOpenProdInfo} type='button' className='product-info-btn-link'>
                  Product information
                </button>
              )}
            </div>
          </div>

          <img
            src={isSelected ? checkedBox : uncheckedBox}
            alt='selectable box'
            className='header-box-btn'
          />
        </div>

        {showRetentionProgress && <span className='product-hug'>new treatment</span>}

        {!hideDetails && contentfulProduct.fields.detailsList && (
          <ul className='product-details-list'>
            {(contentfulProduct.fields.detailsList ?? []).map((text, index) => (
              <li className='list-text' key={index}>
                {text}
              </li>
            ))}
          </ul>
        )}

        {!hideDetails && !hideProductInformation && (
          <button onClick={onOpenProdInfo} type='button' className='product-info-btn-link'>
            Product information
          </button>
        )}

        {showRetentionProgress && (
          <>
            <ProgressBlock
              barTitles={['Request', 'Doctor review', 'Approve treatment']}
              currentIndex={1.75}
              hideButton
            />

            <p className='product-text'>
              Any questions about your order at all, email us at <EmailUs />. We're here for you!
            </p>
          </>
        )}
      </div>

      <ProductInfoDrawer
        open={showProdInfo}
        setOpen={setShowProdInfo}
        product={contentfulProduct}
      />
    </>
  );
}
