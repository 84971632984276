import { ReactNode } from 'react';

import { features } from 'modules/shared/data/features';

import StatReviewWrapper from 'modules/ab-tests/sub-modules/checkout-review/ui/wrappers/StartReview';

import FeaturesBlock from 'modules/checkout-experience/ui/blocks/Features';

import CheckoutExperienceSection from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceSection';

import Loader from 'shared/components/content/Loader';
import Form from 'shared/components/core/Form';
import BottomBar from 'shared/components/wrappers/BottomBar';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

interface Props {
  title: string;
  isLoading: boolean;
  onBack?: () => void;
  isContinueDisabled: boolean;
  onContinue: () => void;
  children: ReactNode;
}

export default function ReviewLayoutWrapper({
  title,
  isLoading,
  onBack,
  isContinueDisabled,
  onContinue,
  children,
}: Props) {
  return (
    <>
      <TopBannerWithProgress onBack={isLoading ? undefined : onBack} />

      {isLoading ? (
        <Loader />
      ) : (
        <Form onSubmit={onContinue}>
          <CheckoutExperienceSection colClass='col-md-7'>
            <div className='content-header-wrapper'>
              <h1 className='content-title'>{title}</h1>
            </div>

            {children}

            <StatReviewWrapper reviewPage />
            <FeaturesBlock data={features} />
          </CheckoutExperienceSection>

          <BottomBar>
            <button className='primary-button' type='submit' disabled={isContinueDisabled}>
              Continue to checkout
            </button>
          </BottomBar>
        </Form>
      )}
    </>
  );
}
