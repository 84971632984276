import ConsentFormWrapper from 'modules/dsar/ui/wrappers/ConsentForm';

import Layout from 'shared/components/core/Layout';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

export default function DataSubjectAccessRequestPage() {
  return (
    <Layout
      title='Alloy Health U.S. State Consumer Privacy Request Form'
      desc='Alloy Health U.S. State Consumer Privacy Request Form'
      noBars
    >
      <TopBannerWithProgress hideProgress />

      <ConsentFormWrapper />
    </Layout>
  );
}
