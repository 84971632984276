import { useExperience } from 'modules/shared/context/experience';

import { howItWorks } from 'modules/checkout-experience/sub-modules/expectations/data/expectations';
import { features } from 'modules/shared/data/features';

import HowItWorksBlock from 'modules/checkout-experience/sub-modules/relief-type/ui/blocks/HowItWorks';

import FeaturesBlock from 'modules/checkout-experience/ui/blocks/Features';
import ImprovementBlock from 'modules/checkout-experience/ui/blocks/Improvement';

import CheckoutExperienceSection from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceSection';

import BottomBar from 'shared/components/wrappers/BottomBar';

export default function ExpectationsInfoWrapper() {
  const { onNext } = useExperience();

  return (
    <>
      <CheckoutExperienceSection colClass='col-md-7'>
        <h1 className='content-title'>Here's how it works</h1>

        <HowItWorksBlock data={howItWorks} />

        <ImprovementBlock
          title='95% of Alloy customers '
          text='taking MHT have symptom relief in 2 weeks or less'
        />

        <FeaturesBlock data={features} />
      </CheckoutExperienceSection>

      <BottomBar>
        <button className='primary-button' type='submit' onClick={() => onNext()}>
          Continue with intake
        </button>
      </BottomBar>
    </>
  );
}
