import './OrderHistoryList.scss';

import { Skeleton } from '@mui/material';
import classNames from 'classnames';

import EmptyState from 'modules/shared/ui/content/EmptyState';

import useOrderHistoryData from '../../hooks/useOrderHistoryData';
import ContentBlock from '../blocks/Content';
import HeaderBlock from '../blocks/Header';

export default function OrderHistoryListWrapper() {
  const {
    isLoading,

    orders,
    customer,

    activeOrderId,

    toggleOrder,
  } = useOrderHistoryData();

  if (isLoading) {
    return (
      <>
        <Skeleton variant='rectangular' height={200} className='mb-3' />
        <Skeleton variant='rectangular' height={200} />
      </>
    );
  }

  if (orders.length === 0 && customer.status === 'NEW') {
    return <EmptyState type='NEW' />;
  }

  if (orders.length === 0 && customer.status === 'PENDING') {
    return <EmptyState type='PENDING_ORDER' />;
  }

  return (
    <div className='row'>
      <div className='col-12'>
        {orders.map((order, index) => (
          <div
            className={classNames(
              'order-wrapper',
              activeOrderId === order.fulfillmentCorrelationId && 'active',
            )}
            key={index}
          >
            <HeaderBlock order={order} setOpen={toggleOrder} />

            <ContentBlock order={order} />
          </div>
        ))}
      </div>
    </div>
  );
}
