import './Progress.scss';

import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  children?: ReactNode;
  title?: string;
  description?: string;
  barTitles: string[];
  currentIndex: number;
  hideButton?: boolean;
}

export default function ProgressBlock({
  children,
  title,
  description,
  barTitles,
  currentIndex,
  hideButton = false,
}: Props) {
  return (
    <div className='progress-block'>
      {title && <p className='progress-title'>{title}</p>}
      {description && <p className='progress-text'>{description}</p>}

      <div className='progress-bar-outer'>
        <div
          className='progress-bar-inner'
          style={{ width: `${(currentIndex / (barTitles.length - 1)) * 100}%` }}
        >
          <div className='progress-bar-circle' />
        </div>
      </div>

      <div className='progress-bar-text-wrapper'>
        {barTitles.map((title, index) => (
          <p className='bar-text' key={index}>
            {title}
          </p>
        ))}
      </div>

      {children && <div>{children}</div>}

      {!hideButton && (
        <div className='text-center'>
          <Link to='/messages' className='secondary-border-button'>
            Message your doctor
          </Link>
        </div>
      )}
    </div>
  );
}
