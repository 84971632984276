import { useFeatureFlagEnabled, useFeatureFlagVariantKey } from 'posthog-js/react';
import { useEffect } from 'react';

const usePosthog = (key: string) => {
  const isEnabled = useFeatureFlagEnabled(key);
  const variantKey = useFeatureFlagVariantKey(key);

  useEffect(() => {
    console.log(`Retrieved result of ${key} is:`, variantKey); // this is where to use ` since condition is inside btw!
  }, [isEnabled]);

  return { isEnabled, variantKey };
};

export default usePosthog;
