import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';
import { getDeepProductsFromGroupedProducts } from 'modules/shared/lib/product';
import sendAbandonEvent from 'modules/tracking/lib/sendAbandonEvent';

import { useAppSelector } from 'shared/store/reducers';

export default function useInitCheckout() {
  const location = useLocation();

  const { cart } = useAppSelector((state) => state.experience);

  useEffect(() => {
    window.scrollTo(0, 0);

    const products = getDeepProductsFromGroupedProducts(cart.products);
    const categories = retrieveCategoriesFromUrl(location);

    sendAbandonEvent({
      event: 'CHECKOUT_SHOWN',
      categories,
      experience: 'checkout',
      products
    });
  }, []);
}
