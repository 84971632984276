/**
 * TODO replace `window.localStorage` with `localforage`.
 *
 * localforage chooses the right storage location automatically
 * based on what browser features exist (seems unimportant until you
 * see the logrocket issues with phone+localstorage!)
 *
 * It also parses json by default which is nice.
 *
 * Problem is that localforage exposes an async api so we have to change
 * the call sites as well.
 *
 * @param id
 * @param shouldParse
 * @returns {string}
 */
export const getItem = (id, shouldParse) => {
    const localStorage = window.localStorage;
    const item = localStorage.getItem(id);
    if (shouldParse && item) {
        return JSON.parse(item);
    }
    return item;
};
export const setItem = (id, data, shouldParse) => {
    const localStorage = window.localStorage;
    let item = data;
    if (shouldParse) {
        item = JSON.stringify(data);
    }
    localStorage.setItem(id, item);
};
export const removeItem = (id) => {
    localStorage.removeItem(id);
};
