import DashboardContainer from 'modules/dashboard/ui/containers/DashboardContainer';

import SectionWrapper from 'shared/components/wrappers/Section';

import { useAppSelector } from 'shared/store/reducers';

import InfoSection from '../../../../../../ui/wrappers/InfoSection';
import TestimonialsSection from '../../../../../../ui/wrappers/TestimonialsSection';
import HeaderBlock from '../../../../ui/blocks/Header';
import ProgressBlock from '../../../../ui/blocks/Progress';

export default function DoctorReview() {
  const customer = useAppSelector((state) => state.alloy.customer!);

  return (
    <DashboardContainer title='Treatment Plan | Alloy' desc='' currentPage='treatment plan'>
      <HeaderBlock title='Treatment plan for' fancyTitle={customer.firstName ?? 'you'} />
      <SectionWrapper
        sectionClasses='dashboard-treatment-plan-section extra-bottom-padding'
        rowClasses='justify-content-center'
      >
        <div className='col-12 col-md-7'>
          <ProgressBlock
            title='Check back soon.'
            description='Your doctor will review your health history, messages, and requests to create a treatment plan for you. Check your inbox for updates on your treatment plan!'
            barTitles={['Intake', 'Doctor review', 'Treatment plan']}
            currentIndex={1}
          />
        </div>
      </SectionWrapper>

      <InfoSection />
      <TestimonialsSection />
    </DashboardContainer>
  );
}
