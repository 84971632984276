import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';

import formatReviewCards from 'modules/dashboard/sub-modules/home/lib/format-review-cards';
import { getReviews } from 'modules/shared/lib/contentful';

import ReviewCarouselWrapper from 'modules/dashboard/sub-modules/home/ui/wrappers/ReviewCarousel';

import SectionWrapper from 'shared/components/wrappers/Section';

import { ContentfulReview } from 'shared/models/contentful/reviews';

export default function ReviewsSectionWrapper() {
  const [reviews, setReviews] = useState<ContentfulReview[] | undefined>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getTrustpilotReviews();
  }, []);

  const getTrustpilotReviews = async () => {
    const reviews = await getReviews('home');

    setReviews(reviews);
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <SectionWrapper sectionClasses='dashboard-treatment-plan-section'>
        <div className='col-12'>
          <Skeleton variant='rectangular' height={300} />
        </div>
      </SectionWrapper>
    );
  }

  const formattedReviews = formatReviewCards(reviews ?? []);

  return (
    <ReviewCarouselWrapper title='What Alloy customers are saying' reviewCards={formattedReviews} />
  );
}
