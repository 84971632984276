"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatProductNameForBraze = void 0;
const productRegistry_1 = require("../products/productRegistry");
// This dictionary is used to translate product name to CRM naming convention
const BRAZE_PRODUCT_NAME_DICTIONARY = {
    'Tretinoin Face Cream': 'Alloy Tretinoin Cream',
    'Evamist Estradiol Spray': 'Evamist',
    'M4 Estriol Face Cream': 'M4',
    'O-mazing Cream': 'O-mazing',
    'O-mazing Cream (no mint)': 'O-mazing (no mint)',
    'Low-Dose Oral Minoxidil': 'Oral Minoxidil',
    'Alloy Synbiotic': 'The Alloy Synbiotic',
    'Norethindrone Acetate Pill': 'Norethindrone acetate',
    'Low-Dose Birth Control Pill': 'Low Dose Birth Control Pill',
    'Progestin-Only Birth Control Pill': 'Progestin-only Birth Control (Norethindrone)',
    'Extended Cycle Low-Dose Birth Control Pill': 'Extended Cycle Low Dose Birth Control',
    'Generic Yaz Pill': 'Generic Yaz',
};
// This array is used to get the correct dosage format in CRM naming convention
// Some dosages contains spaces between words or are in parenthesis
const PRODUCTS_WITH_CUSTOM_DOSE = [
    {
        productId: productRegistry_1.TRETINOIN_ID,
        formFactorId: productRegistry_1.FACE_CREAM_FORM_FACTOR_ID,
        dosageId: productRegistry_1.DOSAGE_0028_PCT_ID,
        customDose: '(0.028%)',
    },
    {
        productId: productRegistry_1.TRETINOIN_ID,
        formFactorId: productRegistry_1.FACE_CREAM_FORM_FACTOR_ID,
        dosageId: productRegistry_1.DOSAGE_0067_PCT_ID,
        customDose: '(0.067%)',
    },
    {
        productId: productRegistry_1.TRETINOIN_ID,
        formFactorId: productRegistry_1.FACE_CREAM_FORM_FACTOR_ID,
        dosageId: productRegistry_1.DOSAGE_0089_PCT_ID,
        customDose: '(0.089%)',
    },
    {
        productId: productRegistry_1.ESTRADIOL_PRODUCT_ID,
        formFactorId: productRegistry_1.GEL_FORM_FACTOR_ID,
        dosageId: productRegistry_1.DOSAGE_050MG_ID,
        customDose: '0.50mg',
    },
    {
        productId: productRegistry_1.ESTRADIOL_PRODUCT_ID,
        formFactorId: productRegistry_1.PATCH_FORM_FACTOR_ID,
        dosageId: productRegistry_1.DOSAGE_0075_MG_ID,
        customDose: '(0.075mg)',
    },
    {
        productId: productRegistry_1.ESTRADIOL_PRODUCT_ID,
        formFactorId: productRegistry_1.PILL_FORM_FACTOR_ID,
        dosageId: productRegistry_1.DOSAGE_050MG_ID,
        customDose: '(0.5 mg)',
    },
    {
        productId: productRegistry_1.ESTRADIOL_PRODUCT_ID,
        formFactorId: productRegistry_1.PILL_FORM_FACTOR_ID,
        dosageId: productRegistry_1.DOSAGE_1_MG_ID,
        customDose: '(1 mg)',
    },
    {
        productId: productRegistry_1.PROGESTERONE_PRODUCT_ID,
        formFactorId: productRegistry_1.PILL_FORM_FACTOR_ID,
        dosageId: productRegistry_1.DOSAGE_200_MG_ID,
        customDose: '(200mg)',
    },
];
/**
 * Due to differences between product names from our system and CRM and to
 * avoid having different names for the same product being recognized as new products,
 * we should translate the product names to how they are recognized in the CRM.
 *
 * @param product DeepProduct
 * @param showDosage boolean
 * @returns string
 */
const formatProductNameForBraze = (product, showDosage = true) => {
    const formFactor = product.formFactorType !== 'N/A' ? ` ${product.formFactorType}` : '';
    let name = product.displayName || product.name + formFactor;
    let translatedName = BRAZE_PRODUCT_NAME_DICTIONARY[name];
    if (showDosage) {
        const productWithCustomDose = PRODUCTS_WITH_CUSTOM_DOSE.find((p) => p.productId === product.productId &&
            p.dosageId === product.dosageId &&
            p.formFactorId === product.formFactorId);
        const dose = product.dose !== 'N/A' ? ` ${product.dose}` : '';
        const customDose = productWithCustomDose ? ` ${productWithCustomDose.customDose}` : '';
        if (translatedName) {
            translatedName += customDose || dose;
        }
        else {
            name += customDose || dose;
        }
    }
    return translatedName || name;
};
exports.formatProductNameForBraze = formatProductNameForBraze;
