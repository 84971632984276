import './TPWidget.scss';

import classNames from 'classnames';
import { useEffect, useRef } from 'react';

interface Props {
  withBackground?: boolean;
  title?: string;
  text?: string;
  theme?: 'dark' | 'light';
}

export default function TPWidgetBlock({ title, text, withBackground, theme = 'light' }: Props) {
  const ref = useRef(null);

  /**
   * Basically what this useEffect does is that it checks if the Trustpilot bootstrap script is loaded
   * Once it is loaded, it will load the Trustpilot widget from the element
   * This is a common practice when working with third-party scripts
   */
  useEffect(() => {
    if ((window as any).Trustpilot) {
      (window as any).Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div className={classNames(['tp-widget', withBackground && 'with-background'])}>
      {title && <h6 className='title'>{title}</h6>}
      {text && <p className='text'>{text}</p>}
      <div
        ref={ref}
        className='trustpilot-widget'
        data-locale='en-US'
        data-template-id='53aa8807dec7e10d38f59f32'
        data-businessunit-id='61b244919580764bc1d1b631'
        data-style-height='150px'
        data-style-width='100%'
        data-theme={theme}
      >
        <a href='https://www.trustpilot.com/review/myalloy.com' target='_blank' rel='noopener'>
          Trustpilot
        </a>
      </div>
    </div>
  );
}
