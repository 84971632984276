/**
 * Toggles the class on a DOM element by ID.
 *
 * @param id - The ID of the DOM element to toggle.
 */
export const toggleClass = (id: string, className: string = 'active'): void => {
  const element = document.getElementById(id);

  if (element) {
    element.classList.toggle(className);
  }
};
