import { Dispatch } from '@reduxjs/toolkit';

import { ContentfulPrescribingDoctor } from 'shared/models/contentful/doctor';

import { PRESCRIBING_DOCTOR } from 'shared/store/actions/GlobalTypes';

const updatePrescribingDoctor = (doctor: ContentfulPrescribingDoctor) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: PRESCRIBING_DOCTOR, payload: doctor });
  };
};

export { updatePrescribingDoctor };
