import useForgotState from 'modules/authentication/sub-modules/forgot/hooks/useForgotState';

import { cleanNonNumberValues } from 'modules/authentication/lib/clean-non-number-values';

import arrowRight from 'shared/assets/svg/arrows/arrow-right-white.svg';

import Form from 'shared/components/core/Form';
import { CodeField } from 'shared/components/fields/CodeField';
import { EmailField } from 'shared/components/fields/EmailField';
import PasswordFieldsGroup from 'shared/components/fields/PasswordFieldsGroup';

export default function FormBuilderContent() {
  const {
    sentCode,
    unconfirmedUser,
    forgotForm,
    setForgotForm,
    onSendVerificationCode,
    onConfirmAccount,
    onChangePassword,
  } = useForgotState();

  /**
   * A code has been sent to the email
   */
  if (!sentCode) {
    return (
      <Form onSubmit={onSendVerificationCode}>
        <EmailField
          label='Email'
          name='email'
          value={forgotForm.email}
          placeholder='Enter your email address'
          onChange={(value) => setForgotForm({ ...forgotForm, email: value })}
        />

        <button
          className='primary-button full-width-button'
          type='submit'
          disabled={forgotForm.email === ''}
        >
          Send Verification Code
          <img src={arrowRight} alt='arrow right' className='btn-arrow' />
        </button>
      </Form>
    );
  } else if (unconfirmedUser) {
    /**
     * A code has been sent to the email, but the user is not confirmed in cognito
     */
    return (
      <Form onSubmit={onConfirmAccount}>
        <p className='content-text'>
          Your account requires verification. We sent a new verification code to {forgotForm.email}
        </p>
        <p className='content-text'>Enter the code below to verify your account.</p>

        <CodeField
          name='code'
          label='Code'
          placeholder='Enter code here'
          value={forgotForm.code}
          onChange={(value) => {
            setForgotForm({
              ...forgotForm,
              code: cleanNonNumberValues(value, 6),
            });
          }}
        />

        <button
          className='primary-button full-width-button'
          type='submit'
          disabled={forgotForm.email === '' || forgotForm.code === ''}
        >
          Confirm account
          <img src={arrowRight} alt='arrow right' className='btn-arrow' />
        </button>
      </Form>
    );
  } else {
    /**
     * A code has been sent to the email and the user can reset their password
     */
    return (
      <Form onSubmit={onChangePassword}>
        <p className='content-text'>We sent a new verification code to {forgotForm.email}</p>
        <p className='content-text'>Enter the code below along with your new password.</p>

        <CodeField
          name='code'
          label='Code'
          placeholder='Enter code here'
          value={forgotForm.code}
          onChange={(value) => {
            setForgotForm({
              ...forgotForm,
              code: cleanNonNumberValues(value, 6),
            });
          }}
        />

        <PasswordFieldsGroup
          useConfirm
          onChange={(confirmedChecksSucceeded, password) =>
            setForgotForm({ ...forgotForm, confirmedChecksSucceeded, password })
          }
          passwordReset
        />

        <div className='content-resend-code-wrapper'>
          <p className='resend-code-title'>Didn't receive a code?</p>
          <p className='resend-code-text'>
            We sent the verification code to {forgotForm.email}. Be sure to check your promotions
            and spam folders in case the email is redirected!
          </p>

          <button type='button' onClick={onSendVerificationCode} className='primary-link-button'>
            Resend code
          </button>
        </div>

        <button
          className='primary-button full-width-button'
          type='submit'
          disabled={
            forgotForm.email === '' ||
            forgotForm.code === '' ||
            !forgotForm.confirmedChecksSucceeded
          }
        >
          Change password
          <img src={arrowRight} alt='arrow right' className='btn-arrow' />
        </button>
      </Form>
    );
  }
}
