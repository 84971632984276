import { first } from 'lodash';
import { Link } from 'react-router-dom';

import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import ViewableProduct from 'modules/shared/ui/blocks/ViewableProduct';

interface Props {
  hasAltFormFactors: boolean;
  groupedProducts: GroupedContentfulProduct[];
  hasPrescriptionFor: (pfId: number) => boolean;
  onOpenConfirm: (gcp: GroupedContentfulProduct) => void;
}

export default function TreatmentWrapper({
  hasAltFormFactors,
  groupedProducts,
  hasPrescriptionFor,
  onOpenConfirm
}: Props) {
  // customer selected change treatment but cannot for current product they do have
  if (!hasAltFormFactors) {
    return (
      <div className='request-content-wrapper'>
        <p className='content-title'>To change your treatment, message your doctor.</p>

        <p className='content-text'>
          If you'd like to talk about changing your treatment, message your doctor and they will
          provide guidance on the best treatment for you.
        </p>

        <Link to='/messages' className='secondary-border-button full-width-button'>
          Message your doctor
        </Link>
      </div>
    );
  }

  // customer has selected to do a form factor and try out a different mht
  return (
    <div className='request-content-wrapper'>
      <p className='content-title'>Select new treatment</p>

      <div className='request-products-wrapper'>
        {groupedProducts.map((gcp, index) => {
          const pfId = first(gcp.alloyProduct.parent)!.id;
          const foundPrescription = hasPrescriptionFor(pfId);

          return (
            <ViewableProduct key={index} groupedProduct={gcp} showDosage>
              <button
                className='secondary-border-button full-width-button mt-3'
                onClick={() => onOpenConfirm(gcp)}
              >
                {foundPrescription ? 'Select' : 'Request from doctor'}
              </button>
            </ViewableProduct>
          );
        })}
      </div>
    </div>
  );
}
