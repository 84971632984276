import { bindActionCreators } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { ReactNode, createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ExperienceCategory } from 'common/dist/models/experience';

import { useCheckoutExperience } from 'modules/checkout-experience/context/checkout-experience';

import useInitExperience from 'modules/shared/hooks/useInitExperience';

import { retrieveProductIdsFromUrl } from 'modules/request-experience/lib/flow';
import { isAvSyncRequired } from 'modules/shared/lib/av-sync-required';
import {
  getAllPathsFromFlow,
  getCurrentStepIndex,
  retrieveCategoriesFromUrl,
} from 'modules/shared/lib/experience/experience';
import {
  getCheckoutFlowFrom,
  retrieveFlowFromUrl,
} from 'modules/shared/lib/experience/experience-flow';
import { formatExperienceURL } from 'modules/shared/lib/url';

import { useAppSelector } from 'shared/store/reducers';

import { updateLocalPreCustomer } from '../store/local-pre-customer';

interface Props {
  onBack: () => void;
  onNext: (newFlowCategories?: ExperienceCategory[]) => Promise<void>;
}

export const ExperienceContext = createContext<Props>({
  onBack: () => {},
  onNext: () => Promise.resolve(),
});

export const useExperience = () => useContext(ExperienceContext);

export default function ExperienceContextProvider({ children }: { children: ReactNode }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { createCheckoutExperience } = useInitExperience();

  const { setIsLoading } = useCheckoutExperience();

  const { isAuthenticated, customer } = useAppSelector((state) => state.alloy);
  const {
    licenseExists,
    localPreCustomer: { experienceValidation },
  } = useAppSelector((state) => state.experience);

  const isRequestExperience = location.pathname.includes('request-experience');

  const dispatchUpdateLocalPreCustomer = bindActionCreators(updateLocalPreCustomer, dispatch);

  /**
   * steps forward in an experience, no cleaning yet just based on timing but would be nice to consolidate more
   * later so that experience is one entity while checkout and request are sub-entities!
   *
   * @param newFlowCategories ExperienceCategory[] | undefined
   */
  const onNext = async (newFlowCategories: ExperienceCategory[] | undefined = []) => {
    const retrievedCategories = retrieveCategoriesFromUrl(location);
    const retrievedFlow = retrieveFlowFromUrl(location);

    const urls = getAllPathsFromFlow(retrievedFlow);
    const currentIndex = getCurrentStepIndex(location.pathname, retrievedFlow);

    let nextStep = Math.min(currentIndex + 1, retrievedFlow.steps.length - 1);

    if (isRequestExperience) {
      const retrievedRequestedProductIds = retrieveProductIdsFromUrl(location);

      // add check if upload id exists and skip them if so
      if (retrievedFlow.steps[nextStep].path === 'verify-identity' && licenseExists) {
        nextStep += 1;
      }

      // this skips av sync
      if (
        retrievedFlow.steps[nextStep].path === 'upload-video' &&
        !isAvSyncRequired(customer?.stateAbbr ?? '')
      ) {
        nextStep += 1;
      }

      // in the instance we are going to a review page and only have 1 product in the url, then we skip that page
      // this only happens for skin health atm too but we can just check product length
      if (
        retrievedFlow.steps[nextStep].path === 'review' &&
        retrievedRequestedProductIds.length === 1
      ) {
        nextStep += 1;
      }

      navigate(
        formatExperienceURL(
          `/request-experience/${retrievedFlow.steps[nextStep].path}`,
          location,
          retrievedCategories,
        ),
      );
    } else {
      const key = retrievedFlow.steps[currentIndex].validationKey;

      const isCategoriesValidated = experienceValidation?.categories.every((c) =>
        retrievedCategories.includes(c),
      );

      const prevStepsViewed = isCategoriesValidated
        ? (experienceValidation?.stepsViewed ?? [])
        : [];

      const updatedStepsViewed = uniq([...prevStepsViewed, key]);

      // create copy for redux!
      const updatedCategories = !!newFlowCategories.length
        ? [...newFlowCategories]
        : [...retrievedCategories];

      dispatchUpdateLocalPreCustomer({
        experienceValidation: {
          date: new Date().toISOString(),
          categories: updatedCategories,
          stepsViewed: updatedStepsViewed,
        },
      });

      if (
        !!newFlowCategories.length &&
        !(
          newFlowCategories.every((c) => retrievedCategories.includes(c)) &&
          retrievedCategories.every((c) => newFlowCategories.includes(c))
        )
      ) {
        setIsLoading(true);

        const newFlow = getCheckoutFlowFrom(newFlowCategories);
        const currentIndex = getCurrentStepIndex(location.pathname, newFlow);

        const updatedNextStep = Math.min(currentIndex + 1, newFlow.steps.length - 1);

        await createCheckoutExperience(newFlowCategories, false, updatedNextStep);

        setIsLoading(false);
      } else {
        if (isAuthenticated) {
          // MARK: Not allow authenticated users to go to register or verification (unauth) pages
          // TODO authentication/identifying the customer should be the "step" - so the flow logic
          // gets to stay clean. that step could bypass or short circuit itself if unnecessary
          while (
            retrievedFlow.steps[nextStep].path === 'register' ||
            retrievedFlow.steps[nextStep].path === 'verification' ||
            (retrievedFlow.steps[nextStep].path === 'verify-identity' && licenseExists)
          ) {
            nextStep = Math.min(nextStep + 1, retrievedFlow.steps.length - 1);
          }

          // MARK: If a user logs in on the register page, we want to make sure when they land on verify-identity or whatever is next that the next step is incremented
          if (urls[currentIndex] === retrievedFlow.steps[nextStep].path) {
            nextStep = Math.min(nextStep + 1, retrievedFlow.steps.length - 1);
          }
        }

        // this skips av sync
        if (
          retrievedFlow.steps[nextStep].path === 'upload-video' &&
          !isAvSyncRequired(customer?.stateAbbr ?? '')
        ) {
          nextStep += 1;
        }

        navigate(
          formatExperienceURL(
            `/checkout-experience/${retrievedFlow.steps[nextStep].path}`,
            location,
            retrievedCategories,
          ),
        );
      }
    }
  };

  /**
   * used within the experience (either checkout or request) and allows for going ui back in,
   * the simplicity of combining allows for reusability and more shareable components
   */
  const onBack = () => {
    const BASE_PATH = isRequestExperience ? '/request-experience' : '/checkout-experience';

    const retrievedCategories = retrieveCategoriesFromUrl(location);
    const retrievedFlow = retrieveFlowFromUrl(location);
    const retrievedRequestedProductIds = retrieveProductIdsFromUrl(location);

    const currentIndex = getCurrentStepIndex(location.pathname, retrievedFlow);

    let prevStep = Math.max(currentIndex - 1, 0);

    // REQUEST EXPERIENCE ONLY
    // in the instance we are going to a review page and only have 1 product in the url, then we skip that page
    // this only happens for skin health atm too but we can just check product length
    if (
      isRequestExperience &&
      retrievedFlow.steps[prevStep].path === 'review' &&
      retrievedRequestedProductIds.length === 1
    ) {
      prevStep -= 1;
    }

    // this skips av sync
    if (
      retrievedFlow.steps[prevStep].path === 'upload-video' &&
      !isAvSyncRequired(customer?.stateAbbr ?? '')
    ) {
      prevStep -= 1;
    }

    if (isRequestExperience) {
      // add check if upload id exists and skip them if so
      if (retrievedFlow.steps[prevStep].path === 'verify-identity' && licenseExists) {
        prevStep -= 1;
      }
    } else {
      // skip any auth screens in CHECKOUT EXPERIENCE
      if (isAuthenticated) {
        while (
          retrievedFlow.steps[prevStep].path === 'register' ||
          retrievedFlow.steps[prevStep].path === 'verification' ||
          (retrievedFlow.steps[prevStep].path === 'verify-identity' && licenseExists)
        ) {
          prevStep = Math.max(prevStep - 1, 0);
        }
      }
    }

    // if the current index is greater than 0 (0 = first step of the flow), we want to take them back in
    // the flow otherwise take them back in history
    if (prevStep >= 0 && currentIndex > 0) {
      navigate(
        formatExperienceURL(
          `${BASE_PATH}/${retrievedFlow.steps[prevStep].path}`,
          location,
          retrievedCategories,
        ),
      );
    } else {
      navigate(-1);
    }
  };

  return (
    <ExperienceContext.Provider
      value={{
        onBack,
        onNext,
      }}
    >
      {children}
    </ExperienceContext.Provider>
  );
}
