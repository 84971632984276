import { DeepProduct } from 'common/dist/products/productFrequency';
import { IntakeCategory, SuggestionProduct } from 'client/dist/generated/alloy';
import suggestionsClient from './suggestions-client';
import { TRETINOIN_ID } from 'common/dist/products/productRegistry';

// TODO: Later with other versions of diff doses potentially of other products, will need to update this.
// for now since this is what is in prod, moved this into a func and added some tests to confirm working
// as expected

/**
 * for customers in consult, we need to clean some products such as tret and pass the right pf id to process
 * for checkout. this function checks recent submission and swaps out tret for the right dosage from
 * our suggestions
 *
 * @param intakeCategories IntakeCategory[]
 * @param requestingProducts GroupedContentfulProduct[]
 * @returns DeepProduct['id'][]
 */
export const updateRequestedDosageBasedOnSubmission = async (
  intakeCategories: IntakeCategory[],
  requestingProducts: DeepProduct[]
): Promise<DeepProduct[]> => {
  const foundRequestedTret = requestingProducts.find((pf) => pf.productId === TRETINOIN_ID);

  // if we are not in skin health for consult or no tret is found then we just get outta here right away
  if (!intakeCategories.includes('skin-health') || !foundRequestedTret) {
    return requestingProducts;
  }

  // send the user's submission to get recommended tret dosage based on submission answers
  const suggestedTret = await getRecommendedTretinoinDosage(intakeCategories);

  // if we found a recommended dosage, reset requesting products to exclude the original
  // otherwise, just use the original requesting products
  const requestingProductsWithoutTretinoin = suggestedTret?.product.id
    ? requestingProducts.filter((pf) => foundRequestedTret.id !== pf.id)
    : requestingProducts;

  return [
    ...requestingProductsWithoutTretinoin,
    // splice in the recommended dosage product id here - if it wasn't found, do nothing
    ...(suggestedTret ? [suggestedTret.product] : []),
  ];
};

const getRecommendedTretinoinDosage = async (
  categories: IntakeCategory[]
): Promise<SuggestionProduct | undefined> => {
  const suggestions = await suggestionsClient.runSuggestionsByRecentSubmission(categories);

  return suggestions.qualified.find((q) => q.product.productId === TRETINOIN_ID);
};
