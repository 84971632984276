import { Link, useLocation } from 'react-router-dom';

import { buildCheckoutExperienceLoginURL } from 'modules/shared/lib/url';

import { ExperienceComponentModel } from 'modules/shared/models/experience';

import CheckoutExperienceSection from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceSection';

import arrowRight from 'shared/assets/svg/arrows/arrow-right-white.svg';
import checkedBox from 'shared/assets/svg/common/checked-box-icon.svg';
import uncheckedBox from 'shared/assets/svg/common/unchecked-box-icon.svg';

import Loader from 'shared/components/content/Loader';
import Form from 'shared/components/core/Form';
import { EmailField } from 'shared/components/fields/EmailField';
import { PasswordField } from 'shared/components/fields/PasswordField';

import useRegisterState from '../../hooks/useRegisterState';

export default function RegisterFormWrapper({ onBack, onNext }: ExperienceComponentModel) {
  const location = useLocation();
  const { isLoading, content, registerForm, setRegisterForm, onSubmit } = useRegisterState({
    onNext,
    onBack,
  });

  if (isLoading) return <Loader />;

  return (
    <CheckoutExperienceSection>
      {content && <h1 className='content-title'>{content.title}</h1>}
      {content && <p className='content-text'>{content.text}</p>}

      <p className='content-text'>
        Already a member?{' '}
        <Link to={buildCheckoutExperienceLoginURL(location)} className='content-link'>
          Log in to continue
        </Link>
      </p>

      <Form onSubmit={onSubmit}>
        <EmailField
          label='Email'
          name='email'
          value={registerForm.email}
          placeholder='Enter your email address'
          onChange={(value) => setRegisterForm({ ...registerForm, email: value })}
        />

        <PasswordField
          label='Password'
          name='password'
          value={registerForm.password}
          placeholder='Set password'
          onChange={(checksSucceeded, password) =>
            setRegisterForm({ ...registerForm, checksSucceeded, password })
          }
        />

        <div className='content-consent-wrapper'>
          <div>
            <button
              className='consent-checkbox-btn'
              type='button'
              onClick={() =>
                setRegisterForm({
                  ...registerForm,
                  marketingEmailConsent: !registerForm.marketingEmailConsent,
                })
              }
            >
              <img
                src={registerForm.marketingEmailConsent ? checkedBox : uncheckedBox}
                alt='check box'
                className='checkbox-icon'
              />
            </button>
          </div>

          <p className='consent-text'>
            By signing up for Alloy emails, I agree to the{' '}
            <a
              href={process.env.REACT_APP_MARKETING_URL + '/terms-of-use'}
              target='_blank'
              className='consent-link'
            >
              Terms of Use
            </a>{' '}
            and{' '}
            <a
              href={process.env.REACT_APP_MARKETING_URL + '/privacy-policy'}
              target='_blank'
              className='consent-link'
            >
              Privacy Policy
            </a>
            . I consent to Alloy sending me Email and Text (SMS) messages that contain my protected
            health information (PHI) to keep me informed about my health consultation,
            prescriptions, & medications I receive through Alloy.
          </p>
        </div>

        <button
          className='primary-button full-width-button'
          type='submit'
          disabled={
            registerForm.email === '' ||
            registerForm.password === '' ||
            !registerForm.checksSucceeded ||
            !registerForm.marketingEmailConsent
          }
        >
          Send verification code
          <img src={arrowRight} alt='arrow right' className='btn-arrow' />
        </button>
      </Form>
    </CheckoutExperienceSection>
  );
}
