import DashboardContainer from 'modules/dashboard/ui/containers/DashboardContainer';

import SubscriptionContextProvider from 'modules/dashboard/sub-modules/manage-subscription/context/manage';

import ManageSubscriptionInfoWrapper from 'modules/dashboard/sub-modules/manage-subscription/ui/wrappers/ManageSubscriptionInfo';

import TitleTextWrapper from 'shared/components/wrappers/TitleText';

export default function ManageSubscription() {
  return (
    <DashboardContainer title='Upcoming Subscription | Alloy' desc='' currentPage='subscriptions'>
      <SubscriptionContextProvider>
        <section className='dashboard-section'>
          <div className='container'>
            <TitleTextWrapper title='Manage Subscription' />

            <ManageSubscriptionInfoWrapper />
          </div>
        </section>
      </SubscriptionContextProvider>
    </DashboardContainer>
  );
}
