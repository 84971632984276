import './Skincare.scss';

import classNames from 'classnames';
import React from 'react';

import { useExperience } from 'modules/shared/context/experience';

import useCart from 'modules/shared/hooks/useCart';

import { sortProducts } from 'modules/shared/lib/product/sort';

import SelectableProduct from 'modules/shared/ui/blocks/SelectableProduct';

import useSkincareState from '../../hooks/useSkincareState';
import ReviewLayoutWrapper from '../wrappers/ReviewLayout';

export default function SkincareContent() {
  const { onBack } = useExperience();
  const { cart } = useCart();

  const {
    isLoading,

    firstName,
    canBeDiscounted,

    selectedDeepProductIds,

    unbundledTotal,
    bundledTotal,

    onSelect,
    onContinue,
  } = useSkincareState();

  return (
    <ReviewLayoutWrapper
      title={cart.products.length === 1 ? 'Your treatment' : 'Your treatments'}
      isLoading={isLoading}
      onBack={onBack}
      isContinueDisabled={selectedDeepProductIds.length === 0}
      onContinue={onContinue}
    >
      <div className='skincare-content-wrapper'>
        <div className='content-inner-wrapper'>
          <div className='content-info-block'>
            <h2 className='info-title'>{firstName}'s skincare routine</h2>

            <p className='info-text'>
              {canBeDiscounted && <span className='text-discounted-price'>${bundledTotal} </span>}
              <span className={classNames('text-price', canBeDiscounted && 'price-strikethrough')}>
                ${unbundledTotal}
              </span>{' '}
              (3-month supply)
            </p>

            {canBeDiscounted && (
              <span className='info-tag'>
                ${(unbundledTotal - bundledTotal).toFixed(2)} savings
              </span>
            )}

            {!canBeDiscounted && cart.products.length !== 1 && (
              <p className='info-blurb'>Add more products to your routine to save!</p>
            )}
          </div>

          {sortProducts(cart.products).map((gcp, index) => {
            const isSelected = selectedDeepProductIds.some((pid) =>
              gcp.alloyProduct.parent.every((pf) => pf.id === pid),
            );

            return (
              <React.Fragment key={gcp.contentfulProduct.sys.id + '-' + index}>
                <div className='content-divider' />

                <SelectableProduct
                  groupedProduct={gcp}
                  isSelected={isSelected}
                  onSelect={onSelect}
                  multiSelect
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </ReviewLayoutWrapper>
  );
}
