import { useEffect, useState } from 'react';

import { DeepProduct } from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';

import { getProductPrice } from 'modules/shared/lib/product/pricing';

import CircleCheck from 'shared/assets/svg/common/circle-check-icon-orange.svg';

interface Props {
  product: DeepProduct;
  showBadge?: boolean;
}

export function ProductCleanNameAndPrice({ product, showBadge }: Props) {
  const prettyName = ProductRegistry.get().getProductFullName(product);

  const [price, setPrice] = useState(product.priceInCents / 100);

  useEffect(() => {
    getPrice();
  }, [JSON.stringify(product)]);

  /**
   * if the price is 0 (we know that is prog) so we don't need to get
   * the unbundled price while all other products need it (since we don't
   * display a "discount" for mht groupings)
   */
  const getPrice = async () => {
    if (product.priceInCents === 0) {
      setPrice(0);
      return;
    }

    const fetchedPrice = await getProductPrice(product);

    setPrice(fetchedPrice / 100);
  };

  const isRx = product.type === 'RX';

  return (
    <div className='summary-products-wrapper'>
      {showBadge && isRx ? (
        <div className='summary-product-with-badge'>
          <p className='summary-product-text'>{prettyName}</p>
          <img
            className='summary-product-img'
            src={CircleCheck}
            alt='decorative icon'
            height={16}
          />
        </div>
      ) : (
        <p className='summary-product-text'>{prettyName}</p>
      )}

      <p className='summary-product-price-text'>${price.toFixed(2)}</p>
    </div>
  );
}
