import './ListSelectableProducts.scss';

import { useEffect, useState } from 'react';

import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import {
  filterMhtProductsFrom,
  filterNonMhtProductsFrom,
  sortMhtProducts,
} from 'modules/dashboard/sub-modules/treatment-plan/lib/products';
import { getProductsToBeBundledWith } from 'modules/shared/lib/product';

import BundleSelectableProduct from 'modules/shared/ui/blocks/BundleSelectableProduct';
import SelectableProduct from 'modules/shared/ui/blocks/SelectableProduct';

import SelectableOptionProduct from '../../sub-modules/home/ui/content/SelectableOptionProduct';

interface ListSelectableProductsProps {
  products: GroupedContentfulProduct[][];
  selectedProducts: GroupedContentfulProduct[];
  onSelect: (p: GroupedContentfulProduct) => void;
  onSwitch: (selected: GroupedContentfulProduct, gcp: GroupedContentfulProduct) => void;
  showRetentionProgress?: boolean;
}

export default function ListSelectableProducts({
  products,
  selectedProducts,
  onSelect,
  onSwitch,
  showRetentionProgress = false,
}: ListSelectableProductsProps) {
  // Option products mean they can be used in the optional product select (mht select)
  const optionProducts = sortMhtProducts(filterMhtProductsFrom(products.flat()));

  // Non option products mean they are single card products that can be selected
  const nonOptionsProducts =
    optionProducts.length === 1 ? products : filterNonMhtProductsFrom(products);

  const sortedNonOptionProducts = nonOptionsProducts.sort(
    (a, b) => a[0].contentfulProduct.fields.order - b[0].contentfulProduct.fields.order,
  );

  // if the customer has any mht, they will have a 'previously selected' product which is just for the ui! otherwise we would always default to
  // the first element if they unselected the mht card and we don't want that
  const [prevSelectMht, setPrevSelectedMht] = useState<GroupedContentfulProduct | null>(
    optionProducts.length > 0 ? optionProducts[0] : null,
  );

  // if select products change then update the previously select product for display
  useEffect(() => {
    const foundMht = selectedProducts.find((gcp) =>
      gcp.alloyProduct.parent.some((pf) => pf.category === 'mht'),
    );

    if (foundMht) {
      setPrevSelectedMht(foundMht);
    }
  }, [JSON.stringify(selectedProducts)]);

  const areParentsSelected = (parents: GroupedContentfulProduct[]) => {
    return selectedProducts.some((gcp) =>
      gcp.alloyProduct.parent.every((pf) =>
        parents.some((parent) =>
          parent.alloyProduct.parent.every((parent) => parent.productId === pf.productId),
        ),
      ),
    );
  };

  const filterBySelectedParents = (parents: GroupedContentfulProduct[]) => {
    /**
     * if product has multiple parents, it should filter parents by
     * selected products to display correct bundled price.
     * (eg.: tretinoin has $21, $30, $60 prices and multiple parents)
     **/
    return parents.length > 1
      ? parents.filter((p) =>
          selectedProducts
            .map((sp) => sp.contentfulProduct.sys.id)
            .includes(p.contentfulProduct.sys.id),
        )
      : parents;
  };

  return (
    <div className='list-selectable-products-wrapper'>
      {optionProducts.length > 1 && prevSelectMht && (
        <SelectableOptionProduct
          groupedProducts={optionProducts}
          prevSelectedProduct={prevSelectMht}
          onSelect={onSelect}
          onSwitch={onSwitch}
          isSelected={selectedProducts.some((gcp) =>
            gcp.alloyProduct.parent.some((pf) => pf.category === 'mht'),
          )}
        />
      )}

      {sortedNonOptionProducts.map((gcpList) => {
        return gcpList.map((gcp) => {
          const isSelected = selectedProducts.some(
            (selected) => selected.contentfulProduct.sys.id === gcp.contentfulProduct.sys.id,
          );
          const parents = getProductsToBeBundledWith(gcp, sortedNonOptionProducts);
          const filteredParents = filterBySelectedParents(parents);

          return !!parents.length ? (
            <BundleSelectableProduct
              key={gcp.contentfulProduct.sys.id}
              groupedProduct={gcp}
              isSelected={isSelected}
              onSelect={() => onSelect(gcp)}
              showDosage
              isParentSelected={areParentsSelected(filteredParents)}
              parents={filteredParents}
              hideDetails={!showRetentionProgress}
              showRetentionProgress={showRetentionProgress}
            />
          ) : (
            <SelectableProduct
              key={gcp.contentfulProduct.sys.id}
              groupedProduct={gcp}
              isSelected={isSelected}
              onSelect={() => onSelect(gcp)}
              showDosage
              multiSelect
              hideDetails={!showRetentionProgress}
              showRetentionProgress={showRetentionProgress}
            />
          );
        });
      })}
    </div>
  );
}
