import './TestimonialsSection.scss';

import { useEffect, useState } from 'react';

import doubleQuoteIcon from 'modules/dashboard/assets/svg/double-quote.svg';

import { getTestimonialsByLimit } from 'modules/shared/lib/contentful';

import arrowLeftWithCircleIcon from 'shared/assets/svg/arrows/arrow-left-with-circle.svg';
import arrowRightWithCircleIcon from 'shared/assets/svg/arrows/arrow-right-with-circle.svg';

import SectionWrapper from 'shared/components/wrappers/Section';

import isNarrowScreen from 'shared/lib/narrow-screen';

import { ContentfulTestimonial } from 'shared/models/contentful/testimonial';

/**
 * Testimonials is used within the entirety of the treatment plan page as a carousel to showcase 3 testimonials!
 */
export default function TestimonialsSectionWrapper() {
  const [testimonials, setTestimonials] = useState<ContentfulTestimonial[]>([]);
  const [index, setIndex] = useState<number>(0);

  const [touchPosition, setTouchPosition] = useState(null);

  useEffect(() => {
    getTestimonials();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!document.hidden) {
        onNext();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const getTestimonials = async () => {
    const retrieved = await getTestimonialsByLimit(3);

    setTestimonials(retrieved);
  };

  const onPrev = () => {
    setIndex(index !== 0 ? index - 1 : 0);
  };

  const onNext = () => {
    setIndex(index !== testimonials.length - 1 ? index + 1 : 0);
  };

  const onSelect = (i: number) => {
    setIndex(i);
  };

  const handleMouseOrTouchStart = (event: any, type: string) => {
    const touchDown = type === 'touch' ? event.touches[0].clientX : event.clientX;

    setTouchPosition(touchDown);
  };

  const handleMouseOrTouchMove = (event: any, type: string) => {
    const touchDown = touchPosition;

    if (touchDown === null || !isNarrowScreen()) {
      return;
    }

    const currentTouch = type === 'touch' ? event.touches[0].clientX : event.clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      onNext();
    } else if (diff < -5) {
      onPrev();
    }

    setTouchPosition(null);
  };

  // If there are no testimonials, then do not return anything (could be loading too!)
  if (testimonials.length === 0) return <></>;

  return (
    <SectionWrapper
      sectionClasses='dashboard-tp-testimonials-section'
      rowClasses='justify-content-center text-center align-items-center'
    >
      <div className='col-1 d-none d-md-block'>
        <button className='btn-arrow' onClick={onPrev}>
          <img src={arrowLeftWithCircleIcon} alt='arrow left' className='testimonials-arrow-icon' />
        </button>
      </div>

      <div className='col-10 col-md-5'>
        <div
          className='testimonials-content-wrapper'
          onTouchStart={(e) => handleMouseOrTouchStart(e, 'touch')}
          onTouchMove={(e) => handleMouseOrTouchMove(e, 'touch')}
          onMouseDown={(e) => handleMouseOrTouchStart(e, 'mouse')}
          onMouseUp={(e) => handleMouseOrTouchMove(e, 'mouse')}
        >
          <p className='testimonials-title'>What others are saying</p>
          <img src={doubleQuoteIcon} alt='double quote' className='testimonials-quote-icon' />

          <p className='testimonials-text'>{testimonials[index].fields.message}</p>
          <p className='testimonials-author'>&mdash; {testimonials[index].fields.name}</p>
        </div>
      </div>

      <div className='col-1 d-none d-md-block'>
        <button className='btn-arrow' onClick={onNext}>
          <img
            src={arrowRightWithCircleIcon}
            alt='arrow right'
            className='testimonials-arrow-icon'
          />
        </button>
      </div>

      <div className='col-10'>
        <div className='testimonials-pagination-wrapper'>
          {testimonials.map((_, i) => (
            <div
              className={'pagination-dot' + (index === i ? ' active' : '')}
              onClick={() => onSelect(i)}
              key={i}
            />
          ))}
        </div>
      </div>
    </SectionWrapper>
  );
}
