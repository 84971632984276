import { ExperienceValidationKey } from 'modules/checkout-experience/lib/validation';
import { ExperienceFlowStep } from 'modules/shared/models/experience';

import RenewalPage from 'pages/renewal/Renewal';
import RenewalConfirmationPage from 'pages/renewal/RenewalConfirmation';

export const renewalSteps: ExperienceFlowStep[] = [
  {
    validationKey: ExperienceValidationKey.renewal,
    component: RenewalPage,
    path: 'renewal',
    routeType: 'PRIVATE',
    hasNested: true,
  },
  {
    validationKey: ExperienceValidationKey.renewalConfirmation,
    component: RenewalConfirmationPage,
    path: 'renewal-confirmation',
    routeType: 'PRIVATE',
  },
];
