import { useEffect } from 'react';

import {
  useGetAllOrders,
  useGetAllSubscriptionsForCustomer,
  useGetAllUnreadNotifications,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';

import { getAccountStatus } from 'modules/dashboard/lib/account-status';
import { sendTag } from 'modules/tracking/lib/sentry';

import { useAppSelector } from 'shared/store/reducers';

export default function useHomeState() {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const { data: subscriptions = [], isLoading: isLoadingSubscriptions } =
    useGetAllSubscriptionsForCustomer();
  const { data: orders = [], isLoading: isLoadingOrders } = useGetAllOrders();
  const { data: treatmentPlan, isLoading: isLoadingTreatmentPlan } = useGetTreatmentPlan();
  const { isLoading: isLoadingNotifications } = useGetAllUnreadNotifications();

  const accountStatus = getAccountStatus(customer.status, subscriptions, orders, treatmentPlan);
  const hasConsultPurchase = !!treatmentPlan?.hasConsult;
  const notActiveSubscriber = ['NO_PURCHASE', 'AWAITING_DOCTOR', 'AWAITING_CUSTOMER'].includes(
    accountStatus,
  );

  // See if anything is loading
  const isLoading =
    isLoadingTreatmentPlan || isLoadingSubscriptions || isLoadingOrders || isLoadingNotifications;

  useEffect(() => {
    if (!isLoading) {
      sendTag('account-status', accountStatus);
    }
  }, [isLoading]);

  return {
    isLoading,

    notActiveSubscriber,
    hasConsultPurchase,
  };
}
