import doctorAvatar from 'modules/shared/assets/images/dr-menn-video-thumbnail.jpg';
import hair from 'modules/shared/assets/images/hair-no-background.png';
import skincareBundleSmall from 'modules/shared/assets/images/skincare-bundle-no-background-small.png';
import skincareBundle from 'modules/shared/assets/images/skincare-bundle-no-background.png';
import skincareBundleOld from 'modules/shared/assets/images/skincare-old-bundle-no-background.png';
import synbiotic from 'modules/shared/assets/images/synbiotic-no-background.png';
import vaginalBundleSmall from 'modules/shared/assets/images/vaginal-bundle-small.png';
import vaginalBundle from 'modules/shared/assets/images/vaginal-bundle.png';

import { ReliefTypeSelection } from '../models/relief-type-selection';

const showNewSkincare = process.env.REACT_APP_SHOW_NEW_SKINCARE === 'true';

export const SELECTIONS: ReliefTypeSelection[] = [
  {
    id: '1',
    title: 'Dr. Consult for MHT',
    text: 'Get medication for perimenopause or menopause relief, ask questions with an expert.',
    categories: ['mht'],
    imageConfig: {
      alt: 'Doctor avatar',
      src: doctorAvatar,
      backgroundClassNames: 'background',
      imageClassNames: 'avatar',
    },
  },
  {
    id: '2',
    title: 'M4 or Tretinoin',
    text: 'I am here for skincare only',
    categories: ['skin-health'],
    imageConfig: {
      alt: 'Skincare bundle',
      src: showNewSkincare ? skincareBundle : skincareBundleOld,
      ...(showNewSkincare && { mobileSrc: skincareBundleSmall }),
      backgroundClassNames: 'background blue-white-gradient',
      imageClassNames: 'bottom',
      includeBadge: true,
    },
  },
  {
    id: '3',
    title: 'Vaginal Cream or O-mazing',
    text: 'I am here for vaginal & sexual health only',
    categories: ['vaginal-health', 'sexual-health'],
    imageConfig: {
      alt: 'Vaginal care rx bundle',
      src: vaginalBundle,
      mobileSrc: vaginalBundleSmall,
      backgroundClassNames: 'background',
      imageClassNames: 'fullWidth',
      includeBadge: true,
    },
  },
  {
    id: '4',
    title: 'Oral Minoxidil',
    text: 'I am here for hair health only',
    categories: ['hair-health'],
    imageConfig: {
      alt: 'hair care bottle',
      src: hair,
      backgroundClassNames: 'background blue-white-gradient',
      imageClassNames: 'extra-tall',
      includeBadge: true,
    },
  },
  {
    id: '5',
    title: 'Alloy Synbiotic',
    text: 'I am here for gut health only',
    categories: ['gut-health'],
    imageConfig: {
      alt: 'Synbiotic bottle',
      src: synbiotic,
      backgroundClassNames: 'background blue-white-gradient',
      imageClassNames: 'bottom',
    },
  },
];
