import classNames from 'classnames';
import { Input } from 'rsuite';

import { cleanWhitespace } from 'shared/lib/clean-whitespace';

interface Props {
  name: string;
  label?: string;
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  type?: string;
  cleanwhitespace?: boolean;
  autolower?: boolean;
  classes?: string;
  max?: number;
  disabled?: boolean;
  isError?: boolean;
  errorMessage?: string;
}

export const TextField = ({
  name,
  label,
  value,
  placeholder,
  onChange,
  type = 'text',
  cleanwhitespace,
  autolower,
  classes = 'field-input',
  max,
  disabled = false,
  isError = false,
  errorMessage = 'Required',
}: Props) => (
  <div className={classNames('input-field-wrapper', isError && 'input-field-error')}>
    {label && <p className='field-label'>{label}</p>}

    <Input
      name={name}
      type={type}
      placeholder={placeholder}
      className={classes}
      defaultValue={value}
      maxLength={max}
      onChange={(v) => {
        const lowered = autolower ? v.toLowerCase() : v;
        onChange(cleanwhitespace ? cleanWhitespace(lowered) : lowered);
      }}
      onBlur={(v) => {
        const trimmed = v.target.value.trim();
        onChange(trimmed);
      }}
      disabled={disabled}
    />

    {isError && <p className='field-required-label'>{errorMessage}</p>}
  </div>
);
