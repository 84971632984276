import './Summary.scss';

import DiscountBlock from '../blocks/Discount';
import TotalsBlock from '../blocks/Totals';

interface Props {
  onPlaceOrder: () => void;
}

export default function SummaryWrapper({ onPlaceOrder }: Props) {
  return (
    <div className='tp-checkout-summary-wrapper'>
      <p className='checkout-summary-title'>Order summary</p>

      <DiscountBlock />
      <TotalsBlock onPlaceOrder={onPlaceOrder} showOrderButton />
    </div>
  );
}
