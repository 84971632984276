import './Doctor.scss';

import usePrescribingDoctor from 'modules/shared/hooks/usePrescribingDoctor';

interface Props {
  showAbout?: boolean;
}

export default function DoctorCardBlock({ showAbout }: Props) {
  const { doctor } = usePrescribingDoctor();

  if (!!doctor) {
    return (
      <div className='doctor-card-block'>
        <img
          src={doctor.fields.photo?.fields.file.url}
          alt={doctor.fields.name}
          className='doctor-photo'
        />

        <div className='doctor-content'>
          <p className='content-title'>{doctor.fields.name}</p>
          <p className='content-subtitle'>{doctor.fields.degrees}</p>

          <p className='content-text'>
            {showAbout && doctor.fields.about
              ? doctor.fields.about
              : 'Free, unlimited messaging with your doctor is included in your subscription.'}
          </p>
        </div>
      </div>
    );
  }

  return <></>;
}
