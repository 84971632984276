import { Dispatch } from '@reduxjs/toolkit';

import { getTinuitiId } from 'client/dist/events/tinuiti';
import { PreidentifiedCustomer, signUpCustomer } from 'client/dist/generated/alloy';

import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';
import { trackFreshpaint } from 'modules/tracking/lib/freshpaint';
import sendAbandonEvent from 'modules/tracking/lib/sendAbandonEvent';
import { sendExceptionToSentry } from 'modules/tracking/lib/sentry';

import { postIdentification } from 'modules/shared/store/app-init';

import { signIn } from 'shared/lib/aws/aws-auth';
import { transformDateString } from 'shared/lib/date';

import { CUSTOMER, IS_AUTHENTICATED } from 'shared/store/actions/GlobalTypes';
import { RootState } from 'shared/store/reducers';

/**
 * What to do after a registration is confirmed?
 *
 * @param email
 * @param password
 * @param code
 * @param isNewUser - whether they used "standalone verification" (new code? forgot pass?)
 */
export const confirmRegistration = (
  email: string,
  password: string,
  code: string,
  isNewUser: boolean
) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { localPreCustomer } = getState().experience;
      const { tinuitiId } = getTinuitiId();

      // now create the customer and confirm cognito user at the same call for BE
      const unsavedCustomer: PreidentifiedCustomer = {
        email,
        ...(localPreCustomer.firstName && { firstName: localPreCustomer.firstName }),
        ...(localPreCustomer.state && { stateAbbr: localPreCustomer.state }),
        ...(localPreCustomer.dateOfBirth && {
          dateOfBirth: new Date(transformDateString(localPreCustomer.dateOfBirth, 'YYYY-MM-DD')!)
        })
      };

      const savedCustomer = await signUpCustomer({
        customer: unsavedCustomer,
        awsParams: { code },
        leadId: tinuitiId
      });

      await signIn(savedCustomer.email, password);

      await postIdentification(savedCustomer, localPreCustomer.alloySubmissionId);

      if (isNewUser) {
        trackFreshpaint('REGISTRATION_COMPLETE', savedCustomer);

        // when we send the abandon event, we should just send the actual base url (without any children paths)
        // that way it stays consistent and there is no worry about which step to send them to (the code handles that)
        const retrievedCategories = retrieveCategoriesFromUrl(window.location);

        sendAbandonEvent({
          event: 'REGISTRATION_COMPLETE',
          categories: retrievedCategories,
          experience: 'checkout'
        });
      }

      dispatch({ type: CUSTOMER, payload: savedCustomer });

      dispatch({ type: IS_AUTHENTICATED, payload: true });
    } catch (error) {
      sendExceptionToSentry(error as Error);
      throw error;
    }
  };
};
