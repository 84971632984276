import DashboardContainer from 'modules/dashboard/ui/containers/DashboardContainer';

import ReferralsWrapper from 'modules/dashboard/sub-modules/account/sub-modules/referrals/ui/wrappers/Referrals';
import AccountContainer from 'modules/dashboard/sub-modules/account/ui/containers/AccountContainer';

import useMarkNotificationsAsRead from 'shared/hooks/useMarkNotificationsAsRead';

export default function Referrals() {
  // mark notifications as read once on everytime going to page OR reloading
  useMarkNotificationsAsRead(['REFERRAL_REDEMPTION']);

  return (
    <DashboardContainer title='Referrals | Alloy' desc='' currentPage='account'>
      <AccountContainer title='Your Account' currentPage='referrals'>
        <ReferralsWrapper />
      </AccountContainer>
    </DashboardContainer>
  );
}
