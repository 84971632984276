import classNames from 'classnames';
import { first } from 'lodash';
import { ReactNode, useState } from 'react';

import ProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import useProductPrice from 'modules/shared/hooks/useProductPrice';

import { isSynbiotic } from 'modules/shared/lib/contentful';
import { formatProductNames, getProductNameWithDosage } from 'modules/shared/lib/product/name';

import ProductInfoDrawer from 'modules/shared/ui/drawers/ProductInfoDrawer';

import ProductNameDosage from './ProductNameDosage';
import ProductPricingBlock from './ProductPricingBlock';

interface Props {
  groupedProduct: GroupedContentfulProduct;
  isHighlighted?: boolean;
  onEdit?: () => void;
  children?: ReactNode;
  hideProductInformation?: boolean;
  hideDetails?: boolean;
  showDosage?: boolean;
  isParentSelected?: boolean;
  parents?: GroupedContentfulProduct[];
}

export default function BundleViewableProduct({
  groupedProduct,
  isHighlighted = false,
  onEdit,
  children,
  hideProductInformation = false,
  hideDetails = false,
  showDosage = false,
  isParentSelected = false,
  parents = [],
}: Props) {
  const { unbundledPrice, bundledPrice } = useProductPrice({ groupedProduct, parents });

  const { contentfulProduct } = groupedProduct;

  const productsCleanNameAndDosage = showDosage
    ? getProductNameWithDosage(groupedProduct)
    : [{ cleanName: contentfulProduct.fields.title }];

  const categories = contentfulProduct.fields.categories;

  const [showProdInfo, setShowProdInfo] = useState<boolean>(false);

  const isProductSynbiotic = isSynbiotic(contentfulProduct);
  const parentNames = formatProductNames(parents);

  const onOpenProdInfo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setShowProdInfo(true);
  };

  return (
    <>
      <div className={classNames('viewable-product', isHighlighted && 'highlighted-border')}>
        {!isProductSynbiotic && <span className='product-rx-tag'>Rx</span>}

        <div className={classNames('product-header', hideDetails ? 'no-margin' : '')}>
          <div className='header-content'>
            <img
              src={contentfulProduct.fields.photo.fields.file.url}
              alt={contentfulProduct.fields.photo.fields.title}
              className='header-img'
            />

            <div className='header-text-wrapper'>
              {productsCleanNameAndDosage.map(({ cleanName, dosage }, index) => (
                <ProductNameDosage key={index} cleanName={cleanName} dosage={dosage} />
              ))}

              <ProductPricingBlock
                unbundledPrice={unbundledPrice}
                bundledPrice={bundledPrice}
                canBeDiscounted={!!parents.length}
                isParentSelected={isParentSelected}
                discountedParentName={parentNames}
                categories={categories}
              />

              {hideDetails && !hideProductInformation && (
                <button onClick={onOpenProdInfo} type='button' className='product-info-btn-link'>
                  Product information
                </button>
              )}
            </div>
          </div>

          {onEdit && (
            <div className='header-btn-wrapper'>
              <button className='header-btn' type='button' onClick={onEdit}>
                Edit
              </button>
            </div>
          )}
        </div>

        {!hideDetails && <p className='product-text'>{contentfulProduct.fields.detail}</p>}

        {!hideDetails && !hideProductInformation && (
          <button onClick={onOpenProdInfo} type='button' className='product-info-btn-link'>
            Product information
          </button>
        )}

        <div>{children}</div>
      </div>

      <ProductInfoDrawer
        open={showProdInfo}
        setOpen={setShowProdInfo}
        product={contentfulProduct}
      />
    </>
  );
}
