import { bindActionCreators } from '@reduxjs/toolkit';
import { xor } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { useExperience } from 'modules/shared/context/experience';

import { updateRequestingProducts } from 'modules/shared/store/requesting-products';

import { useAppSelector } from 'shared/store/reducers';

export default function useMhtPreferencesState() {
  const dispatch = useDispatch();

  const { onNext } = useExperience();

  const prevRequestedProductIds = useAppSelector(
    (state) => state.experience.requestingDeepProductIds,
  );

  const [requestedDeepProductIds, setRequestedDeepProductIds] = useState(prevRequestedProductIds);

  const dispatchUpdateRequesting = bindActionCreators(updateRequestingProducts, dispatch);

  const onSelect = (groupedProduct?: GroupedContentfulProduct) => {
    if (groupedProduct) {
      setRequestedDeepProductIds(
        xor(
          requestedDeepProductIds,
          groupedProduct.alloyProduct.parent.map((pf) => pf.id),
        ),
      );
    } else {
      setRequestedDeepProductIds([]);
    }
  };

  const onSubmit = () => {
    dispatchUpdateRequesting(requestedDeepProductIds);

    onNext();
  };

  return {
    requestedDeepProductIds,

    onSelect,
    onSubmit,
  };
}
