import { uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  useGetAllSubscriptionsForCustomer,
  useGetNonCustomerUpsellProducts,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import { ExperienceCategory } from 'common/dist/models/experience';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { ProductCategory } from 'common/dist/products/product';

import useCart from 'modules/shared/hooks/useCart';

import { getNonPurchasedProducts } from 'modules/dashboard/sub-modules/treatment-plan/lib/products';
import { isSynbiotic } from 'modules/shared/lib/contentful';
import {
  getDeepProductsFromGroupedProducts,
  getNewUpsellContentfulProducts,
  isGroupedProductInProductsList,
} from 'modules/shared/lib/product';
import { getUpsellGroupedContentfulProducts } from 'modules/shared/lib/product/contentful';
import { formatExperienceURL } from 'modules/shared/lib/url';

import { NewUpsell } from 'modules/shared/models/new-upsell';

import { filterRequestingProducts } from '../lib/requesting-products';
import { CategoryGroupedProducts } from '../models/category-grouped-products';

const INIT_CATEGORY_GROUPED_PRODUCTS: CategoryGroupedProducts = {
  notPurchased: [],
  upsell: [],
};

export default function useRoundOutRoutineState() {
  const navigate = useNavigate();
  const location = useLocation();

  const { updateCart } = useCart();

  const [isReviewOpen, setIsReviewOpen] = useState(false);

  // Grouped products used to display content
  const [isLoadingGrouped, setIsLoadingGrouped] = useState<boolean>(true);
  const [categoryGroupedProducts, setCategoryGroupedProducts] = useState<CategoryGroupedProducts>(
    INIT_CATEGORY_GROUPED_PRODUCTS,
  );
  // Has only not purchased round out your routine products
  const [roundOutRoutineProducts, setRoundOutRoutineProducts] = useState<NewUpsell[]>([]);
  // Has all round out your routine products with discount bundles if available
  const [roundOutRoutineActiveSubProducts, setRoundOutRoutineActiveSubProducts] = useState<
    GroupedContentfulProduct[][]
  >([]);

  /**
   * Data loading for treatment plan, subscriptions, and upsell products
   */
  const { data: treatmentPlan, isLoading: isLoadingTreatmentPlan } = useGetTreatmentPlan();
  const { data: subscriptions = [], isLoading: isLoadingSubscriptions } =
    useGetAllSubscriptionsForCustomer();
  const { data: upsellProducts = [], isLoading: isLoadingUpsellProducts } =
    useGetNonCustomerUpsellProducts();

  const isLoading =
    isLoadingGrouped || isLoadingTreatmentPlan || isLoadingSubscriptions || isLoadingUpsellProducts;

  // Filter 1: One time products that are in our prescription products
  // Filter 2: One time products by product id that are not in our subscriptions ie (pill [one time] is not in sub [recurring])
  const oneTimePurchasedPrescriptionProducts = treatmentPlan!.prescriptionProducts
    .filter((p) => p.product.recurrenceType === 'ONE_TIME' && p.prescription?.fillsRemaining === 0)
    .filter(
      (p) =>
        !subscriptions
          .flatMap((sub) => sub.products.map((pfr) => pfr.product.productId))
          .includes(p.product.productId),
    );

  const nonSubscriptionProducts = getNonPurchasedProducts(
    subscriptions,
    oneTimePurchasedPrescriptionProducts,
    treatmentPlan!.prescriptionProducts,
    treatmentPlan!.pendingCharges.consultApproval.flatMap((pc) => pc.products),
  );

  useEffect(() => {
    retrieveGroupedAndContentfulProducts();
  }, [JSON.stringify(treatmentPlan), isLoadingSubscriptions, isLoadingUpsellProducts]);

  /**
   * using the subscription products and upsell products, we need to fetch the contentful and grouped products.
   * this will allow us to use these products for display grouped products as well as handle passing pf
   * to BE when needed
   *
   * purchased products are split into active subscription and invoice only products (one time)
   * not purchased products are products prescribed but not purchased
   * upsell products are products that the customer can request
   */
  const retrieveGroupedAndContentfulProducts = async () => {
    if (
      treatmentPlan?.pendingCharges.consultApproval.length !== 0 ||
      subscriptions.length !== 0 ||
      upsellProducts.length !== 0
    ) {
      setIsLoadingGrouped(true);

      // leaving for now cuz of the upsell bit BUT TODO: this needs some major cleaning into its own sections
      // + upsell should just come from BE so we don't have to do any magic here...
      const subscriptionsProducts = subscriptions.flatMap((sub) =>
        sub.products.map((p) => p.product),
      );

      const oneTimePurchasedProducts = oneTimePurchasedPrescriptionProducts.map(
        (nsp) => nsp.product,
      );

      const purchasedProducts = [...subscriptionsProducts, ...oneTimePurchasedProducts];

      const notPurchasedProducts = nonSubscriptionProducts
        .filter((pp) => !pp.renewal?.hasExpired)
        .map((pp) => pp.product);

      // TODO: for future mikhail, would love to adjust the upsell/round out code a lot more dynamically by just using the two arrays
      // below since we have the data we don't need to do anything fancy for bundles and whatnot

      // since we know hey you have there prescriptions BUT have NOT purchased and then you have these upsells that DONT HAVE any prescriptions
      // lets render these as so in each specified custom category, we shouldn't be doing all the jumps to understand which product to show
      // and filter if the BE already gives that
      const notPurchased = (
        await ProductRegistry.get().getRecurringProductsForV2(notPurchasedProducts)
      ).flat();
      const upsell = await getUpsellGroupedContentfulProducts(upsellProducts);

      // get the split upsells, so we don't bundle anything, but still get the bundled price
      const upsellsCategoryContentfulProduct = await getNewUpsellContentfulProducts(
        purchasedProducts,
        notPurchasedProducts,
        upsellProducts,
      );

      const upsellActiveSubProducts = await ProductRegistry.get().getRecurringProductsForV2([
        ...purchasedProducts,
        ...getDeepProductsFromGroupedProducts(
          upsellsCategoryContentfulProduct.flatMap((up) => up.products),
        ),
      ]);

      setCategoryGroupedProducts({
        notPurchased,
        upsell,
      });

      setRoundOutRoutineProducts(upsellsCategoryContentfulProduct);
      setRoundOutRoutineActiveSubProducts(upsellActiveSubProducts);

      setIsLoadingGrouped(false);
    }
  };

  const onAddToPlan = (product: GroupedContentfulProduct) => {
    updateCart({
      products: [product],
    });

    setIsReviewOpen(true);
  };

  const shouldAddToPlan = (gcp: GroupedContentfulProduct) => {
    const allNotPurchasedProducts = getDeepProductsFromGroupedProducts(
      categoryGroupedProducts.notPurchased,
    );

    return (
      isSynbiotic(gcp.contentfulProduct) ||
      isGroupedProductInProductsList(allNotPurchasedProducts, gcp)
    );
  };

  const onAddToPlanOrRequest = (
    product: GroupedContentfulProduct,
    categories: ProductCategory[],
  ) => {
    if (shouldAddToPlan(product)) {
      onAddToPlan(product);
    } else {
      const requestingProducts = filterRequestingProducts(
        roundOutRoutineProducts
          .filter((upsell) => categories.every((c) => upsell.categories.includes(c)))
          .map((upsell) => upsell.products)
          .flat(),
        nonSubscriptionProducts,
      );

      const productIds = requestingProducts.map((pf) => pf.productId);

      const urlCategories: ExperienceCategory[] = requestingProducts.map(
        (pf) => pf.category as ExperienceCategory,
      );

      // as we do anywhere, just send the customer to the basic url and from there, the request experience
      // will handle where to place them and check and restrictions
      const experienceUrl = formatExperienceURL(
        '/request-experience',
        location,
        uniq(urlCategories),
        [['productIds[]', uniq(productIds).join(',')]],
      );

      navigate(experienceUrl);
    }
  };

  return {
    isLoading,

    shouldAddToPlan,

    roundOutRoutineProducts,
    roundOutRoutineActiveSubProducts,

    isReviewOpen,
    setIsReviewOpen,
    onAddToPlanOrRequest,
  };
}
