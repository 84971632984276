import { Link } from 'react-router-dom';

import useForgotState from 'modules/authentication/sub-modules/forgot/hooks/useForgotState';

import CheckoutExperienceSection from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceSection';

import Loader from 'shared/components/content/Loader';

import { useQueryParams } from 'shared/context/url/query';

import FormBuilderContent from '../content/FormBuilder';

export default function ForgotFormWrapper() {
  const { buildPathWith } = useQueryParams();
  const { isLoading, sentCode } = useForgotState();

  if (isLoading) return <Loader />;

  return (
    <CheckoutExperienceSection>
      <h1 className='content-title'>Forgot password</h1>

      {!sentCode && (
        <p className='content-text'>
          Back to{' '}
          <Link to={buildPathWith('/login')} className='content-link'>
            Login
          </Link>
        </p>
      )}

      <FormBuilderContent />
    </CheckoutExperienceSection>
  );
}
