import classNames from 'classnames';

import checkedBox from 'shared/assets/svg/common/checked-box-icon.svg';
import uncheckedBox from 'shared/assets/svg/common/unchecked-box-icon.svg';

import SelectionImage from 'modules/shared/ui/content/SelectionImage';

import { MultiSelectOption } from '../../models/multi-select-option';

interface Props {
  option: MultiSelectOption;
  selectedIds: string[];
  onSelect: () => void;
}

/**
 * This is a styled checkbox select field with text
 * and optional image
 */

export default function MultiSelectImageField({
  option: { text, id, imageConfig },
  selectedIds,
  onSelect,
}: Props) {
  const isSelected = selectedIds.includes(id);

  return (
    <div className='field-checkbox select-image-field' onClick={onSelect}>
      <div className={classNames('select-image-inner-wrapper', isSelected && 'selected')}>
        <div className={classNames('inner-content-wrapper', 'short')}>
          <img
            src={isSelected ? checkedBox : uncheckedBox}
            alt='selectable checkbox'
            className='checkbox-select-icon'
          />

          <div className={classNames('content-block', !imageConfig && 'short')}>
            <div className='content-title'>{text}</div>
          </div>
        </div>

        {imageConfig && <SelectionImage {...imageConfig} />}
      </div>
    </div>
  );
}
