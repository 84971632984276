import { ExperienceComponentModel } from 'modules/shared/models/experience';

import VerificationFormWrapper from 'modules/authentication/sub-modules/verification/ui/wrappers/VerificationForm';

import Layout from 'shared/components/core/Layout';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

export default function Verification({
  onNext,
  onBack,
  standaloneVerificationParams,
}: ExperienceComponentModel) {
  return (
    <Layout title='Verification - Alloy' desc='' noBars>
      <TopBannerWithProgress />

      <VerificationFormWrapper
        onNext={onNext}
        onBack={onBack}
        standaloneVerificationParams={standaloneVerificationParams}
      />
    </Layout>
  );
}
