import { useExperience } from 'modules/shared/context/experience';

import QuestionnaireWrapper from 'modules/questionnaire/ui/wrappers/Questionnaire';
import Disqualified from 'modules/request-experience/ui/wrappers/Disqualified';

import Loader from 'shared/components/content/Loader';
import Layout from 'shared/components/core/Layout';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

import useIntakeState from '../../hooks/useIntakeState';

export default function IntakeFormRoutesWrapper() {
  const { onBack } = useExperience();
  const { isLoading, disqualifiedProducts, intakeQuestions, previousResults, onSubmit } =
    useIntakeState();

  if (isLoading) {
    return (
      <Layout title='Intake - Alloy' desc='' noBars>
        <TopBannerWithProgress />

        <Loader />
      </Layout>
    );
  }

  if (disqualifiedProducts.length !== 0) return <Disqualified products={disqualifiedProducts} />;

  return (
    <QuestionnaireWrapper
      questions={intakeQuestions}
      previousResults={previousResults}
      onSubmit={onSubmit}
      onBack={onBack}
      basePath='/request-experience/intake'
    />
  );
}
