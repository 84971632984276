import { useExperience } from 'modules/shared/context/experience';

import UploadVideoInfoWrapper from 'modules/shared/sub-modules/upload-video/ui/wrappers/UploadVideoInfo';

import Layout from 'shared/components/core/Layout';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

export default function UploadVideoPage() {
  const { onBack } = useExperience();

  return (
    <Layout title='Upload Video - Alloy' desc='' noBars>
      <TopBannerWithProgress onBack={onBack} />

      <UploadVideoInfoWrapper />
    </Layout>
  );
}
