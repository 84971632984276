import './Doctor.scss';

import { Link } from 'react-router-dom';

import usePrescribingDoctor from 'modules/shared/hooks/usePrescribingDoctor';

export default function DoctorWrapper() {
  const { doctor, isLoading } = usePrescribingDoctor();

  if (!doctor || isLoading) return <></>;

  return (
    <div className='doctor-wrapper'>
      <div className='doctor-info-block'>
        <img
          src={doctor.fields.photo?.fields.file.url}
          alt={doctor.fields.name}
          className='doctor-photo'
        />

        <div className='doctor-content'>
          <p className='doctor-title'>Prescribed by</p>
          <p className='doctor-name'>{doctor.fields.name}</p>
          <p className='doctor-text'>{doctor.fields.degrees}</p>

          <Link to='/messages' className='primary-link-button d-flex d-md-none'>
            Message your doctor
          </Link>
        </div>
      </div>

      <div className='d-none d-md-block'>
        <Link to='/messages' className='btn-message'>
          Message your doctor
        </Link>
      </div>
    </div>
  );
}
