import { capitalize, isEmpty } from 'lodash';

import isNameValid from 'common/dist/customer/isNameValid';

import Form from 'shared/components/core/Form';
import { SelectField } from 'shared/components/fields/SelectField';
import { TextField } from 'shared/components/fields/TextField';
import BottomBar from 'shared/components/wrappers/BottomBar';
import SectionWrapper from 'shared/components/wrappers/Section';

import states from 'shared/data/core/states.json';

import { HOW_IT_WORKS_THUMBNAIL_URL, HOW_IT_WORKS_VIDEO_URL } from '../../data/how-it-works';
import useNameState from '../../hooks/useNameState';

export default function NameFormWrapper() {
  const {
    content,
    skipCategorySelect,

    customerForm,
    setCustomerForm,
    onSubmit,
  } = useNameState();

  return (
    <Form onSubmit={onSubmit}>
      <SectionWrapper
        sectionClasses='checkout-experience-section'
        rowClasses='justify-content-center'
      >
        <div className='col-12 col-md-6'>
          <div className='checkout-experience-content-wrapper'>
            {content && <h1 className='content-title'>{content.title}</h1>}
            {content && <p className='content-text'>{content.text}</p>}

            <TextField
              label='First name'
              name='firstName'
              placeholder='First name'
              value={customerForm.firstName}
              onChange={(value) =>
                setCustomerForm({
                  ...customerForm,
                  firstName: capitalize(value.trim()),
                })
              }
            />

            <SelectField
              label='Your state'
              name='state'
              placeholder='Select state'
              value={customerForm.state}
              data={states.States}
              onChange={(value) => setCustomerForm({ ...customerForm, state: value ?? '' })}
            />
          </div>
        </div>

        {!skipCategorySelect && (
          <div className='col-12 col-md-4'>
            <div className='content-video-wrapper'>
              <video controls preload='metadata' poster={HOW_IT_WORKS_THUMBNAIL_URL}>
                <source src={HOW_IT_WORKS_VIDEO_URL} type='video/mp4' />
                Your browser does not support videos
              </video>
            </div>
          </div>
        )}
      </SectionWrapper>

      <BottomBar>
        <button
          className='primary-button'
          type='submit'
          disabled={isEmpty(customerForm.state) || !isNameValid(customerForm.firstName)}
          onClick={onSubmit}
        >
          Next
        </button>
      </BottomBar>
    </Form>
  );
}
