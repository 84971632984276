import { formatPhone } from 'shared/lib/phone';
import { MaskField } from './MaskField';

interface Props {
  name: string;
  label?: string;
  classes?: string;
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  isError?: boolean;
}

export const PhoneField = ({
  name,
  label,
  placeholder,
  value,
  classes,
  onChange,
  isError,
}: Props) => (
  <MaskField
    name={name}
    label={label}
    mask={['(', /[2-9]/, /[0-8]/, /\d/, ')', ' ', /[2-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    placeholder={placeholder}
    value={value}
    classes={classes}
    onChange={(v) => onChange(formatPhone(v))}
    isError={isError}
  />
);
