import classNames from 'classnames';
import { useState } from 'react';

import {
  GetShippingMethods200Item,
  ShippingMethodType,
  useGetShippingMethods,
} from 'client/dist/generated/alloy';

import useCart from 'modules/shared/hooks/useCart';

import { cleanShippingTitle } from 'modules/shared/sub-modules/checkout/lib/shipping';

import chevronDownIcon from 'shared/assets/svg/common/chevron-down.svg';

import { convertCentsToDollars } from 'shared/lib/convert';

interface Props {
  stateAbbr: string;
  hideBorder?: boolean;
}

export default function ShippingMethodBlock({ stateAbbr, hideBorder = false }: Props) {
  const { updateCart } = useCart();

  const [showShippingDetails, setShowShippingDetails] = useState<boolean>(false);

  const { data: shippingMethods = [] } = useGetShippingMethods(stateAbbr);

  const [selectedShippingType, setSelectedShippingType] = useState<ShippingMethodType>('STANDARD');

  const selectedShippingMethod = shippingMethods.find((sm) => sm.method === selectedShippingType);

  const handleSelectingShipping = (sm: GetShippingMethods200Item) => {
    updateCart({
      shipping: sm,
    });

    setSelectedShippingType(sm.method);
  };

  return (
    <div
      className={classNames(
        'tp-shipping-method-block',
        showShippingDetails && ' tp-active-shipping',
      )}
    >
      <div
        className={classNames('tp-shipping-method-wrapper', hideBorder && 'tp-hide-border')}
        onClick={() => setShowShippingDetails(!showShippingDetails)}
      >
        <div className='shipping-content-header'>
          <p className='shipping-title'>Shipping Speed</p>

          <img src={chevronDownIcon} alt='chevron right' className='shipping-chevron' />
        </div>

        <div className='shipping-content'>
          {showShippingDetails ? (
            <p className='shipping-text'>Processing time for orders can take 1-3 business days.</p>
          ) : (
            <>
              <p className='shipping-text'>
                {selectedShippingMethod
                  ? cleanShippingTitle(selectedShippingMethod)
                  : 'Standard Shipping (3-5 business days)'}
              </p>

              <p className='shipping-text-bold'>
                {selectedShippingMethod
                  ? selectedShippingMethod.method === 'STANDARD'
                    ? 'FREE'
                    : `$${convertCentsToDollars(selectedShippingMethod.priceInCents)}`
                  : 'FREE'}
              </p>
            </>
          )}
        </div>
      </div>

      <div className='tp-shipping-options-wrapper'>
        {shippingMethods
          .sort((a, b) => a.priceInCents - b.priceInCents)
          .map((sm, index) => (
            <div
              key={index}
              className={`shipping-option ${sm.method === selectedShippingType ? 'selected' : ''}`}
              onClick={() => handleSelectingShipping(sm)}
            >
              <div className='option-outer-circle'></div>

              <div className='option-content'>
                <p className='option-title'>{cleanShippingTitle(sm)}</p>
                <p className='option-price'>
                  {sm.method === 'STANDARD' ? 'FREE' : `$${convertCentsToDollars(sm.priceInCents)}`}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
