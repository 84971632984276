import { format, getTime, isValid, parse, parseISO } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { sendExceptionToSentry } from 'modules/tracking/lib/sentry';
import { isArray, transform } from 'lodash';

const ISO_DATE_FORMAT = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;
const MAX_DEPTH = 10;

export type ValidDob = {
  dob?: string;
  error?: string;
};

export const getAge = (dob: string, now: number = Date.now()): number => {
  const ageDifMs = (now ?? Date.now()) - new Date(dob).getTime();
  const ageDate = new Date(ageDifMs);

  return Math.abs(ageDate.getFullYear() - 1970);
};

export const validDob = (date: string): ValidDob => {
  const newDate = new Date(date);

  if (!isValid(newDate)) {
    return {
      error: 'Please enter a valid date of birth.',
    };
  }

  const dateOnly = new Date(newDate.valueOf() + newDate.getTimezoneOffset() * 60 * 1000);
  const formattedDate = format(dateOnly, 'yyyy-MM-dd');

  const age = getAge(formattedDate);

  if (age >= 18 && age <= 99) {
    return {
      dob: formattedDate,
    };
  } else {
    return {
      error: 'You must be at least 18 years old to purchase.',
    };
  }
};

/**
 * maps supported formats to their parsers.
 */
const formatParsers: { regex: RegExp; parse: (date: string) => Date | null }[] = [
  { regex: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/, parse: parseISO }, // ISO 8601 with Z
  {
    regex: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?([+-]\d{2}(:\d{2})?)$/,
    parse: parseISO,
  }, // ISO 8601 with offset
  { regex: /^\d{4}-\d{2}-\d{2}$/, parse: parseISO }, // YYYY-MM-DD
  { regex: /^\d{2}\/\d{2}\/\d{4}$/, parse: (date) => parse(date, 'MM/dd/yyyy', new Date()) }, // MM/DD/YYYY
];

/**
 * detects and parses a date string based on predefined formats.
 * from `formatParsers` array
 *
 * @param date date string to parse.
 * @returns parsed Date object or null if the format is unsupported.
 */
export const detectAndParseDate = (date: string): Date | null => {
  for (const { regex, parse } of formatParsers) {
    if (regex.test(date)) {
      const parsedDate = parse(date);

      return isValid(parsedDate) ? parsedDate : null;
    }
  }
  return null; // if no matching format, return null here
};

/**
 * transforms a date string into the desired format.
 * this only allows transforming into [`YYYY-MM-DD`] and [`MM/DD/YYYY`]
 *
 * it returns undefined if the date string passed is not in the following formats:
 * `ISO 8601` or `YYYY-MM-DD` or `MM/DD/YYYY`
 *
 * @param date date string passed
 * @param formatType
 * @returns The transformed date string or `undefined` if the input is invalid.
 */
export const transformDateString = (
  date: string,
  formatType: 'YYYY-MM-DD' | 'MM/DD/YYYY'
): string | undefined => {
  if (!date || date.trim() === '') return undefined;

  try {
    const parsedDate = detectAndParseDate(date);

    if (!parsedDate) return undefined;

    const includesTimezone = /[+-]\d{2}(:\d{2})?$|Z$/.test(date);

    // Convert to UTC if the input includes a timezone
    const utcDate = includesTimezone ? utcToZonedTime(parsedDate, 'UTC') : parsedDate;

    // Format the date as required
    return format(utcDate, formatType === 'YYYY-MM-DD' ? 'yyyy-MM-dd' : 'MM/dd/yyyy');
  } catch (error) {
    sendExceptionToSentry(error as Error);
    throw error;
  }
};

const randomInteger = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getMillisWithRandomTime = (date: Date): number => {
  const millisMomentEastern = getTime(zonedTimeToUtc(date, 'America/New_York'));

  const oneHourOfMillis = 1000 * 60 * 60;
  const randomTimeOffsetMillis = randomInteger(oneHourOfMillis * 8, oneHourOfMillis * 18);

  return millisMomentEastern + randomTimeOffsetMillis;
};

const isIsoDateString = (value: any): boolean => {
  return value && typeof value === 'string' && ISO_DATE_FORMAT.test(value);
};

/**
 * This functions reads the body of an axios response and update it so every
 * date string field is converted to Date object type.
 *
 * @param body body to convert ISO date string fields to Date
 * @param depth depth to start - don't pass this, only used on the recursive step
 * @returns body with string date fields converted to Date object
 */
const datesHandler = (body: any, depth: number = 0) => {
  if (body === null || body === undefined || typeof body !== 'object') {
    return body;
  }

  const initialValue = isArray(body) ? [] : {};

  return transform(
    body,
    (updatedBody: any, value, key) => {
      let updatedValue = value;
      if (isIsoDateString(value)) {
        updatedValue = parseISO(value);
      } else if (typeof value === 'object' && depth <= MAX_DEPTH) {
        updatedValue = datesHandler(value, depth + 1);
      }
      updatedBody[key] = updatedValue;
    },
    initialValue
  );
};

export default {
  datesHandler,
};
