import { addDays, isWithinInterval, startOfToday } from 'date-fns';

import { SubscriptionWithRenewal, TreatmentPlan } from 'client/dist/generated/alloy';

import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';

export const getSingleSupplyProducts = (
  subscriptions: SubscriptionWithRenewal[],
  treatmentPlan: TreatmentPlan | undefined
) => {
  if (!treatmentPlan) return [];

  const { activeSubs } = getSubscriptionsWithStatus(subscriptions);

  // Filter 1: One time products that are in our prescription products
  // Filter 2: One time products by product id that are not in our subscriptions ie (pill [one time] is not in sub [recurring])
  return treatmentPlan!.prescriptionProducts
    .filter((p) => p.product.recurrenceType === 'ONE_TIME' && p.prescription?.fillsRemaining === 0)
    .filter(
      (p) =>
        !activeSubs
          .flatMap((sub) => sub.products.map((pfr) => pfr.product.productId))
          .includes(p.product.productId)
    );
};

export const filterSingleSupplyProducts = (
  subscriptions: SubscriptionWithRenewal[],
  treatmentPlan: TreatmentPlan | undefined,
  filter: 'EXPIRING_SOON' | 'EXPIRED'
) => {
  const singleSupply = getSingleSupplyProducts(subscriptions, treatmentPlan);

  return singleSupply.filter((pfr) => {
    const createdAt = pfr.prescription?.createdAt ? pfr.prescription.createdAt : startOfToday();
    const daysSupply = pfr.prescription?.daysSupply || 0;
    const expirationDate = addDays(createdAt, daysSupply);
    const today = startOfToday();

    if (filter === 'EXPIRING_SOON') {
      // Check if today falls between createdAt (inclusive) and expirationDate (inclusive)
      return isWithinInterval(today, { start: createdAt, end: expirationDate });
    } else {
      // Check if today falls outside the range of createdAt and expirationDate
      return !isWithinInterval(today, { start: createdAt, end: expirationDate });
    }
  });
};
