import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';
import { useState } from 'react';
import { Input, InputGroup } from 'rsuite';

import completeIcon from 'shared/assets/images/complete-icon.png';

import { cleanWhitespace } from 'shared/lib/clean-whitespace';

interface Props {
  name: string;
  label?: string;
  value?: string;
  placeholder?: string;
  hideChecksSucceeded?: boolean;
  hideVisibleIcon?: boolean;
  onChange: (checksSucceeded: boolean, password: string) => void;
}

const check = (p: string) => {
  return checks.map(({ test, message }) => [test(p), message]);
};

const checks = [
  {
    test: (p: string) => p.length >= 8,
    message: 'At least 8 characters long',
  },
  {
    test: (p: string) => /[a-z]/.test(p),
    message: '1 lowercase letter',
  },
  {
    test: (p: string) => /[A-Z]/.test(p),
    message: '1 uppercase letter',
  },
  {
    test: (p: string) => /\d/.test(p),
    message: '1 number',
  },
  {
    test: (p: string) => /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(p),
    message: '1 special character',
  },
];

export const PasswordField = ({
  name,
  label,
  value,
  placeholder,
  hideChecksSucceeded,
  hideVisibleIcon,
  onChange,
}: Props) => {
  const [visible, setVisible] = useState(false);

  const passwordChecks = check(value ?? '');

  const handleChange = () => {
    setVisible(!visible);
  };

  return (
    <div className='input-field-wrapper'>
      {label && <p className='field-label'>{label}</p>}

      <InputGroup>
        <Input
          name={name}
          type={visible ? 'text' : 'password'}
          placeholder={placeholder}
          autoComplete='off'
          className='field-input'
          value={value}
          onChange={(password) => {
            const cleanedPassword = cleanWhitespace(password);
            const confirmedpasswordCheck = check(cleanedPassword);
            const checksSucceeded = confirmedpasswordCheck.every(([checkPassed]) => checkPassed);

            onChange(checksSucceeded, cleanedPassword);
          }}
        />

        {!hideVisibleIcon && (
          <InputGroup.Button onClick={handleChange}>
            {visible ? <EyeIcon /> : <EyeSlashIcon />}
          </InputGroup.Button>
        )}
      </InputGroup>

      {!hideChecksSucceeded && (
        <ul className='field-password-check'>
          {passwordChecks.map(([passed, message], row) => (
            <li key={row}>
              {passed ? <img src={completeIcon} alt={'Complete icon'} /> : '• '}
              {message}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
