import useRegisterState from 'modules/authentication/sub-modules/register/hooks/useRegisterState';

import { ExperienceComponentModel } from 'modules/shared/models/experience';

import RegisterFormWrapper from 'modules/authentication/sub-modules/register/ui/wrappers/RegisterForm';

import Layout from 'shared/components/core/Layout';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

export default function Register({ onBack, onNext }: ExperienceComponentModel) {
  const { isLoading } = useRegisterState({ onNext, onBack });

  return (
    <Layout title='Register - Alloy' desc='' noBars>
      <TopBannerWithProgress onBack={isLoading ? undefined : onBack} />

      <RegisterFormWrapper onBack={onBack} onNext={onNext} />
    </Layout>
  );
}
