import { SubscriptionWithRenewal } from 'client/dist/generated/alloy';
import { add, min, startOfToday } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

/**
 *
 * Get the max date for rescheduling. The normal max number of days is 90 but if the expiration date comes before
 * then that is the date to use
 *
 * @param subscription SubscriptionWithRenewal
 * @returns Date - the max date for rescheduling either the expiration date or the 90s out from today()
 */
export const getMaxDateForRescheduling = (subscription: SubscriptionWithRenewal): Date => {
  const MAX_DAYS = 90;
  const date90DaysFromNextRecurrence = add(
    zonedTimeToUtc(subscription.nextRecurrenceOn, 'America/New_York'),
    { days: MAX_DAYS }
  );

  const earliestExpDate = getEarliestExpirationDate(subscription);

  if (earliestExpDate) {
    return min([earliestExpDate, date90DaysFromNextRecurrence]);
  } else {
    return date90DaysFromNextRecurrence;
  }
};

/**
 *
 * Get the earliest expiration date that or get no date at all
 *
 * @param subscription SubscriptionWithRenewal
 * @returns Date | null - earliest exp date or none
 */
export const getEarliestExpirationDate = (subscription: SubscriptionWithRenewal): Date | null => {
  // Filter out only dates with expiration ones
  const renewalExpDates = subscription.products
    .filter((p) => p.renewal && p.renewal.expirationDate)
    .map((p) => p.renewal!! && p.renewal.expirationDate!);

  // If no expiration dates exist, then we don't need a date
  if (renewalExpDates.length === 0) {
    return null;
  }

  // Sort the dates and get the one closest to today()
  const dates = renewalExpDates
    .map((item) => zonedTimeToUtc(item, 'America/New_York'))
    .sort((a, b) => b.getTime() - a.getTime());

  return dates[0];
};

export const isAllPrescriptionsExpired = (subscription: SubscriptionWithRenewal): boolean => {
  const today = startOfToday();

  return subscription.products.every((p) => {
    const expDate = p.renewal?.expirationDate;

    if (expDate) {
      return today > new Date(expDate);
    } else {
      return false;
    }
  });
};

/**
 *
 * Checks to see if all the prescriptions in a given subscription are active and have not passed the expiration date
 *
 * @param subscription SubscriptionWithRenewal
 * @returns boolean - all prescriptions are active
 */
export const isAllPrescriptionsActive = (subscription: SubscriptionWithRenewal): boolean => {
  const today = startOfToday();

  return subscription.products.every((p) => {
    const expDate = p.renewal?.expirationDate;

    return !!expDate ? new Date(expDate) > today : true;
  });
};

/**
 *
 * Check to see if any prescriptions need renewal
 *
 * @param subscription SubscriptionWithRenewal
 * @returns boolean - if some prescriptions need renewal
 */
export const isSomeNeedRenew = (subscription: SubscriptionWithRenewal): boolean =>
  subscription.products.some(
    (p) => (p.renewal?.needsRenewed ?? false) && !p.renewal?.completedDate
  );

/**
 *
 * Check to see if every prescription exists
 *
 * @param subscription SubscriptionWithRenewal
 * @returns boolean - if all prescriptions exist
 */
export const isAllPrescriptionsExist = (subscription: SubscriptionWithRenewal): boolean =>
  subscription.products.every((p) => p.prescription);

/**
 *
 * allows to get various status' of prescriptions from a subscription that way certain bits can be rendered in the FE
 *
 * @param subscription SubscriptionWithRenewal
 */
export const getPrescriptionsStatusFrom = (subscription: SubscriptionWithRenewal) => {
  return {
    allPrescriptionsExist: isAllPrescriptionsExist(subscription),
    allPrescriptionsActive: isAllPrescriptionsActive(subscription),
    allPrescriptionsExpired: isAllPrescriptionsExpired(subscription),
    prescriptionsNeedRenewal: isSomeNeedRenew(subscription),
  };
};
