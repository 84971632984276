import { Link } from 'react-router-dom';
import { Form } from 'rsuite';

import checkedBox from 'shared/assets/svg/common/checked-box-icon.svg';
import uncheckedBox from 'shared/assets/svg/common/unchecked-box-icon.svg';

import { TextAreaField } from 'shared/components/fields/TextAreaField';

import { DoseForm } from '../../models/dose-form';

interface Props {
  hasAltDoses: boolean;
  doseForm: DoseForm;
  setDoseForm: (form: DoseForm) => void;
}

export default function DosageWrapper({ hasAltDoses, doseForm, setDoseForm }: Props) {
  // customer selected change dose but cannot for current product they do have
  if (!hasAltDoses) {
    return (
      <div className='request-content-wrapper'>
        <p className='content-title'>To change your dose, message your doctor.</p>

        <p className='content-text'>
          If you'd like to talk about changing your dose, message your doctor and they will provide
          guidance on the best treatment for you.
        </p>

        <Link to='/messages' className='secondary-border-button full-width-button'>
          Message your doctor
        </Link>
      </div>
    );
  }

  // customer product dose can be changed
  return (
    <Form>
      <div className='request-content-wrapper'>
        <p className='content-title'>Tell us more</p>

        <p className='content-text'>
          To help your doctor recommend the optimal dosage, please share any relevant symptoms,
          dosage preferences, or other factors.
        </p>

        <TextAreaField
          classes='dosage-textarea'
          name='reason'
          value={doseForm.reason}
          placeholder='Type here'
          onChange={(value) =>
            setDoseForm({
              ...doseForm,
              reason: value,
            })
          }
          rows={6}
        />

        <div
          className='content-consent-wrapper'
          onClick={() =>
            setDoseForm({
              ...doseForm,
              agreedTerms: !doseForm.agreedTerms,
            })
          }
        >
          <img
            src={doseForm.agreedTerms ? checkedBox : uncheckedBox}
            alt='check box'
            className='checkbox-icon'
          />

          <p className='consent-text'>
            Dosage changes may result in a price change by up to $15. Check the box to acknowledge.
          </p>
        </div>
      </div>
    </Form>
  );
}
