import { useExperience } from 'modules/shared/context/experience';

import TreatmentInterestsFormWrapper from 'modules/ab-tests/sub-modules/treatment-interests/ui/wrappers/TreatmentInterestsForm';

import Layout from 'shared/components/core/Layout';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

// AB_TEST
export default function TreatmentInterestsPage() {
  const { onBack } = useExperience();

  return (
    <Layout title='Treatment Interests - Alloy' desc='' noBars>
      <TopBannerWithProgress onBack={onBack} />

      <TreatmentInterestsFormWrapper />
    </Layout>
  );
}
