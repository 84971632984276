import { treatmentInterestsSelections } from 'modules/ab-tests/sub-modules/treatment-interests/data/treatment-interests';

import { SelectableCategory } from 'modules/ab-tests/sub-modules/known-preferences/models/preferences';

import CheckoutExperienceSection from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceSection';

import Form from 'shared/components/core/Form';
import BottomBar from 'shared/components/wrappers/BottomBar';

import useTreatmentInterests from '../../hooks/useTreatmentInterests';
import MultiSelectImageField from '../content/MultiSelectImageField';

export default function TreatmentInterestsFormWrapper() {
  const {
    selectionWrapper,

    selectedCategories,
    onSelectCategory,

    onSubmit,
  } = useTreatmentInterests();

  return (
    <Form onSubmit={onSubmit}>
      <div className='questionnaire-wrapper' ref={selectionWrapper}>
        <CheckoutExperienceSection>
          <div className='enhancers-wrapper'>
            <h1 className='content-title'>
              Your doctor will help you find the best menopause hormone treatment. Are you
              interested in any other categories?
            </h1>

            {treatmentInterestsSelections.map((s) => (
              <MultiSelectImageField
                option={s}
                key={s.id}
                selectedIds={selectedCategories ?? []}
                onSelect={() => onSelectCategory(s.id as SelectableCategory)}
              />
            ))}
          </div>
        </CheckoutExperienceSection>
      </div>

      <BottomBar>
        <button className='primary-button' type='submit' disabled={!selectedCategories?.length}>
          Next
        </button>
      </BottomBar>
    </Form>
  );
}
