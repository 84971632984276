import { useNavigate } from 'react-router-dom';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';
import Layout from 'shared/components/core/Layout';
import useForgotState from 'modules/authentication/sub-modules/forgot/hooks/useForgotState';
import ForgotFormWrapper from 'modules/authentication/sub-modules/forgot/ui/wrappers/ForgotForm';

export default function ForgotPage() {
  const navigate = useNavigate();
  const { sentCode } = useForgotState();

  return (
    <Layout
      title='Forgot Password - Alloy'
      desc='Reset your password for your Alloy account'
      noBars
    >
      <TopBannerWithProgress onBack={!sentCode ? () => navigate(-1) : undefined} />

      <ForgotFormWrapper />
    </Layout>
  );
}
