import { ProductCategory } from 'client/dist/generated/alloy';

import { NewUpsell } from 'modules/shared/models/new-upsell';

export const categories: ProductCategory[] = [
  'mht',
  'non-hormonal-treatment',
  'birth-control',
  'vaginal-health',
  'skin-health',
  'sexual-health',
  'gut-health',
  'hair-health',
];

export const excludeCategories = (removeCategories: ProductCategory[]): ProductCategory[] =>
  categories.filter((c) => !removeCategories.includes(c));

/**
 * bundle sexual and vaginal health into a single category
 * and sort it to show in a custom order in the list
 *
 * @param newUpsell: newUpsell[]
 * @returns newUpsell[]
 */
export const getBundledUpdatedCategory = (newUpsell: NewUpsell[]) => {
  const newUpsellProducts = [...newUpsell];
  const sexualHealth = newUpsellProducts.find((i) =>
    i.categories.every((c) => ['sexual-health'].includes(c)),
  );
  const vaginalHealth = newUpsellProducts.find((i) =>
    i.categories.every((c) => ['vaginal-health'].includes(c)),
  );

  const newGroupedCategory = {
    categories: ['sexual-health', 'vaginal-health'] as ProductCategory[],
    products: [
      ...(sexualHealth ? sexualHealth.products : []),
      ...(vaginalHealth ? vaginalHealth.products : []),
    ],
  };

  newUpsellProducts.push(newGroupedCategory);

  return newUpsellProducts
    .filter(
      (gccp) =>
        !gccp.categories.every((c) => c === 'sexual-health') &&
        !gccp.categories.every((c) => c === 'vaginal-health'),
    )
    .sort((a, b) => {
      const customOrder: ProductCategory[] = [
        'skin-health',
        'sexual-health',
        'vaginal-health',
        'hair-health',
        'gut-health',
      ];

      // sorting by categories allows for specific order for ourselves within round out.
      // there is potential room for improvements here, see:
      // https://myalloy.slack.com/archives/C03465ESVQT/p1736443323571959
      const getCategoryOrderIndex = (categories: ProductCategory[]) => {
        const indexes = categories
          .map((category) => customOrder.indexOf(category))
          .filter((index) => index !== -1);

        return indexes.length > 0 ? Math.min(...indexes) : customOrder.length;
      };

      const aIndex = getCategoryOrderIndex(a.categories);
      const bIndex = getCategoryOrderIndex(b.categories);

      return aIndex - bIndex;
    });
};

const categoryMap: Record<string, string> = {
  'gut-health': 'Gut Health',
  'sexual-health,vaginal-health': 'Sexual & Vaginal Health',
  'skin-health': 'Skincare',
  'hair-health': 'Hair Health',
};

export const translateCategories = (categories: ProductCategory[]) => {
  const key = categories.sort().join(',');

  return categoryMap[key] || '';
};
