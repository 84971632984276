import { Dispatch } from '@reduxjs/toolkit';

import { getTinuitiId } from 'client/dist/events/tinuiti';
import { IntakeCategory, getCustomer, processCheckoutV2 } from 'client/dist/generated/alloy';
import { removeItem } from 'client/dist/localstorage';
import ProductRegistry from 'client/dist/product/productRegistry';
import { ExperienceCategory } from 'common/dist/models/experience';

import { getDeepProductIdsFrom } from 'modules/shared/lib/product';
import { getCheckoutType, isConsultCart } from 'modules/shared/sub-modules/checkout/lib/cart';
import { updateRequestedDosageBasedOnSubmission } from 'modules/shared/sub-modules/checkout/lib/product';
import { brazeSetPartial } from 'modules/tracking/lib/braze';
import { getParamsFromFreshpaintCookie, trackFreshpaint } from 'modules/tracking/lib/freshpaint';

import { Address } from 'shared/models/customer-address';

import {
  ALLOY_CART,
  BILLING_ADDRESS,
  CHECKOUT,
  CHECKOUT_EDITING_SHIPPING,
  CUSTOMER
} from 'shared/store/actions/GlobalTypes';
import { RootState } from 'shared/store/reducers';

/**
 *
 * Handles the ability to checkout and fetch recent orders and updates to the user
 *
 * @param checkoutCategories ExperienceCategory[]
 * @param experienceType - checkout is new customer, request is upsell
 *
 * @returns Promise<void>
 */
const processCheckout = (
  checkoutCategories: ExperienceCategory[],
  experienceType: 'checkout' | 'request'
) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { cart } = getState().experience;
    const { requestingDeepProductIds, billingAddress } = getState().experience;

    let deepProductIds: number[] = [];

    const isConsult = isConsultCart(cart);

    // TODO: Discussion around this below CE category (gut health) | intake category (no gut health)
    const intakeCategories = checkoutCategories.filter(
      (c) => c !== 'gut-health'
    ) as IntakeCategory[];

    // just setup a simple way of understanding which flow the customer is in by throwing
    // in this flag
    const checkoutType =
      experienceType === 'request'
        ? 'CUSTOMER_ADDON_REQUEST'
        : getCheckoutType(cart, intakeCategories);

    // set the product frequencies to send to BE from cart
    // ie consult in cart we would add the consult pf id here,
    // omazing in cart we would add the omazing pf id here, etc
    deepProductIds = cart.products.flatMap((gcp) => getDeepProductIdsFrom(gcp));

    if (isConsult) {
      const products = (
        await ProductRegistry.get().getDeepProductsFromIds(requestingDeepProductIds)
      ).flat();

      // when a customer has some products selected that they want to request, we might need to swap out the dose
      // for tret because they might need a higher one than the default one we have. for this, we just grab the requested
      // products and then pass along into a func that can handle checking whether we should swap the dose out for
      // tret there! if we don't need to then nothing happens basically :)
      const filteredProducts = await updateRequestedDosageBasedOnSubmission(
        intakeCategories,
        products
      );

      // grab the right pf to send in our payload for requesting products ONLY for consult!
      const requestedProducts = (
        await ProductRegistry.get().getPricesFor(filteredProducts)
      ).flatMap((pfs) => pfs.flatMap((pf) => pf.id));

      deepProductIds = deepProductIds.concat(requestedProducts);
    }

    const billingCleaned = {
      line1: billingAddress.shippingAddressLineOne!,
      line2: billingAddress.shippingAddressLineTwo,
      city: billingAddress.city!,
      state: billingAddress.stateAbbr!,
      zip: billingAddress.zip!
    };

    const fpParams = getParamsFromFreshpaintCookie();
    const { tinuitiId } = getTinuitiId();

    const checkout = await processCheckoutV2({
      billingAddress: billingCleaned,
      checkoutType,
      productFrequencyIds: deepProductIds,
      promotionCodeId: cart.promotionCode.id !== '' ? cart.promotionCode.id : undefined,
      intakeCategories: intakeCategories,
      freshpaintParameters: fpParams,
      shippingMethodId: cart?.shipping?.id,
      ...(experienceType === 'checkout' && {
        crmConsent: {
          marketing: cart.agreement.isOptedSmsMarketing
        }
      }),
      ...(experienceType === 'request' && {
        shipNow: cart.shipNow
      }),
      leadId: tinuitiId
    });

    dispatch({ type: CHECKOUT, payload: checkout });
    dispatch({ type: ALLOY_CART, payload: { ...cart, checkoutType } });

    const customer = await getCustomer();

    await brazeSetPartial(customer);

    trackFreshpaint(
      experienceType === 'request' ? 'REQUEST_CONFIRMATION' : 'CHECKOUT_CONFIRMATION',
      customer,
      checkout,
      checkoutCategories
    );

    removeItem('localPreCustomer');
    removeItem('requestingDeepProductIds');
    removeItem('recentIntakeAnswers');

    dispatch({ type: CUSTOMER, payload: customer });
    dispatch({ type: ALLOY_CART, payload: { ...cart, isPurchased: true } });
  };
};

const updateIsEditingShipping = (isEditing: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: CHECKOUT_EDITING_SHIPPING, payload: isEditing });
  };
};

const updateBillingAddress = (updatedBilling: Partial<Address>) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const { billingAddress } = getState().experience;

    const collated = {
      ...billingAddress,
      ...updatedBilling
    };

    dispatch({ type: BILLING_ADDRESS, payload: collated });
  };
};

export { processCheckout, updateBillingAddress, updateIsEditingShipping };
