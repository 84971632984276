import { Dispatch } from '@reduxjs/toolkit';

import { ContentfulSymptom } from 'shared/models/contentful/symptom';

import { SYMPTOMS } from 'shared/store/actions/GlobalTypes';

const updateSymptoms = (symptoms: ContentfulSymptom[]) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: SYMPTOMS, payload: symptoms });
  };
};

export { updateSymptoms };
