import './Carousel.scss';

import classNames from 'classnames';
import { useEffect, useState } from 'react';

import {
  EventType,
  markNotificationsAsReadByEventType,
  useGetAllOrders,
  useGetAllSubscriptionsForCustomer,
  useGetAllUnreadNotifications,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';

import { getAccountStatus } from 'modules/dashboard/lib/account-status';
import {
  getBuiltNotificationCards,
  sortCardsWithStatus,
} from 'modules/dashboard/sub-modules/home/lib/cards';

import arrowLeftIcon from 'shared/assets/svg/arrows/arrow-left.svg';
import arrowRightIcon from 'shared/assets/svg/arrows/arrow-right.svg';

import isNarrowScreen from 'shared/lib/narrow-screen';

import { useAppSelector } from 'shared/store/reducers';

import CardStatusWrapper from './CardStatus';

export default function CarouselWrapper() {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const [index, setIndex] = useState(0);

  const { data: notifications = [], mutate } = useGetAllUnreadNotifications();

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();
  const { data: orders = [] } = useGetAllOrders();
  const { data: treatmentPlan } = useGetTreatmentPlan();

  const accountStatus = getAccountStatus(customer.status, subscriptions, orders, treatmentPlan);

  const filteredNotifications = notifications.filter((n) =>
    ['TREATMENT_PLAN_UPDATED', 'NEW_DOCTOR_MESSAGE'].includes(n.eventType),
  );

  useEffect(() => {
    if (isNarrowScreen()) {
      setIndex(0);
    }
  }, [isNarrowScreen()]);

  if (filteredNotifications.length === 0) {
    return <CardStatusWrapper />;
  }

  const markNotificationRead = async (eventType: EventType) => {
    await markNotificationsAsReadByEventType({
      eventTypes: [eventType],
    });

    await mutate();
  };

  const notificationCards = getBuiltNotificationCards(filteredNotifications, markNotificationRead);

  const cards = sortCardsWithStatus(notificationCards, accountStatus);

  return (
    <div className='header-carousel-wrapper'>
      <div className='carousel-card-block'>
        <button
          className={classNames('carousel-action', 'action-left', index === 0 && 'disable')}
          onClick={() => setIndex(index - 1)}
          disabled={index === 0}
        >
          <img src={arrowLeftIcon} alt='arrow left' />
        </button>

        <div className='carousel-cards-list'>
          {cards.map((card, i) => (
            <div className={classNames('card-wrapper', index === i && 'active-card')} key={i}>
              {card}
            </div>
          ))}
        </div>

        <button
          className={classNames(
            'carousel-action',
            'action-right',
            index === cards.length - 1 && 'disable',
          )}
          onClick={() => setIndex(index + 1)}
          disabled={index === cards.length - 1}
        >
          <img src={arrowRightIcon} alt='arrow right' />
        </button>
      </div>

      <div className='carousel-dot-block'>
        {cards.map((_, key) => (
          <span
            className={classNames('dot-icon', index === key && 'active')}
            key={key}
            onClick={() => setIndex(key)}
          />
        ))}
      </div>
    </div>
  );
}
