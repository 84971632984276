import { useGetPaymentMethods } from 'client/dist/generated/alloy';

import useCart from 'modules/shared/hooks/useCart';
import useTotalProductPrices from 'modules/shared/hooks/useTotalProductPrices';

import { getDeepProductsFromGroupedProducts } from 'modules/shared/lib/product';

import usePosthog from 'shared/hooks/usePosthog';

import { useAppSelector } from 'shared/store/reducers';

import useTreatmentPlanState from '../../../hooks/useTreatmentPlanState';

export default function useOrderSummaryData() {
  const { onContinueCheckout } = useTreatmentPlanState();
  const { isLoadingTotals } = useAppSelector((state) => state.treatmentPlan);

  const { data: paymentMethods = [] } = useGetPaymentMethods();
  const { cart } = useCart();

  const { isLoading, unbundledTotal, bundledTotal } = useTotalProductPrices({
    products: cart.products.flatMap((gcp) => gcp.alloyProduct.parent),
    dependencies: [JSON.stringify(cart.products)],
  });

  const products = getDeepProductsFromGroupedProducts(cart.products);
  const disableContinueButton =
    cart.products.length === 0 || paymentMethods.length === 0 || isLoadingTotals;

  //  "HSA/FSA Eligibility in Treatment Plan" CRO Test
  //  Highlighting items in the user's treatment plan
  //  that are FSA/HSA Eligible
  const { variantKey: tpEligibilityFlag } = usePosthog('tp-eligibility');
  const showEligibilityTag = tpEligibilityFlag === 'test' && products.length > 0;

  return {
    onContinueCheckout,

    disableContinueButton,
    showEligibilityTag,

    unbundledTotal: isLoadingTotals || isLoading ? 0 : unbundledTotal,
    bundledTotal: isLoadingTotals || isLoading ? 0 : bundledTotal,

    products,
  };
}
