import { isEmpty } from 'lodash';
import Webcam from 'react-webcam';

import AlloyModal from 'shared/components/core/AlloyModal';

import usePhotoModalState from '../../hooks/usePhotoModalState';

const videoConstraints = {
  width: 400,
  height: 300,
  facingMode: 'user',
};

interface Props {
  open: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onNext: (photo: string) => void;
}

export default function PhotoModal({ open, setIsOpen, onNext }: Props) {
  const { webcamRef, photo, setPhoto, hasAccess, setHasAccess, onCapture } = usePhotoModalState();

  return (
    <AlloyModal isOpen={open} onClose={() => setIsOpen(false)}>
      <div className='modal-body'>
        <p className='modal-title'>Take a photo of yourself</p>

        <div className='modal-photo-wrapper'>
          {isEmpty(photo) && hasAccess ? (
            <Webcam
              className='bg-light-blue'
              audio={false}
              width={400}
              height={300}
              ref={webcamRef}
              screenshotFormat='image/jpeg'
              videoConstraints={videoConstraints}
              screenshotQuality={1}
              mirrored
              onUserMediaError={(error) => {
                if (error) {
                  setHasAccess(false);
                }
              }}
            />
          ) : (
            <>
              {photo !== '' ? (
                <img src={photo} />
              ) : (
                <p className='modal-text'>
                  You have not authorized your webcam to take a photo on Alloy. Authorize your
                  webcam for {window.location.host} in browser settings.
                </p>
              )}
            </>
          )}
        </div>

        <div className='modal-btn-wrapper'>
          {hasAccess && (
            <>
              {!isEmpty(photo) ? (
                <button onClick={() => setPhoto('')} className='btn-cancel'>
                  Retake Photo
                </button>
              ) : (
                <button className='btn-confirm' onClick={onCapture}>
                  Take photo
                </button>
              )}

              {!isEmpty(photo) && (
                <button className='btn-confirm' onClick={() => onNext(photo)}>
                  Next
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </AlloyModal>
  );
}
