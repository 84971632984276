import { useState } from 'react';

import { useGenerateSetupIntent, useGetPaymentMethods } from 'client/dist/generated/alloy';

import { StripePaymentMethod } from 'modules/shared/models/stripe';

import { useAppSelector } from 'shared/store/reducers';

import { generateProfile } from '../lib/generate-profile';

export default function useProfileState() {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const [isProfileOpen, setIsProfileOpen] = useState<boolean>(false);
  const [isShippingOpen, setIsShippingOpen] = useState<boolean>(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState<boolean>(false);

  const { data: paymentMethods = [], isLoading: isLoadingPaymentMethods } = useGetPaymentMethods();
  const { data: setupIntent, isLoading: isLoadingSetupIntent } = useGenerateSetupIntent();

  const isLoading = isLoadingPaymentMethods || isLoadingSetupIntent;

  const onUpdate = (settingsId: string) => {
    switch (settingsId) {
      case 'information': {
        setIsProfileOpen(true);
        break;
      }

      case 'shippingAddress': {
        setIsShippingOpen(true);
        break;
      }

      case 'paymentMethod': {
        setIsPaymentOpen(true);
        break;
      }

      default: {
        break;
      }
    }
  };

  const profileDict = generateProfile(customer, paymentMethods as StripePaymentMethod[]);

  return {
    isLoading,

    setupIntent,
    profileDict,

    isProfileOpen,
    setIsProfileOpen,

    isShippingOpen,
    setIsShippingOpen,

    isPaymentOpen,
    setIsPaymentOpen,

    onUpdate
  };
}
