import { IconText } from 'shared/models/icon-text';
import {
  doctorIcon,
  doctorLightIcon,
  medicineIcon,
  medicineLightIcon,
  ongoingIcon,
  ongoingLightIcon,
  truckIcon,
  truckLightIcon,
} from '../assets/svg';

export const features: IconText[] = [
  {
    icon: doctorIcon,
    text: 'Menopause-specialized doctors',
  },
  {
    icon: ongoingIcon,
    text: 'Ongoing care and education',
  },
  {
    icon: medicineIcon,
    text: 'Medicine delivered to your door',
  },
  {
    icon: truckIcon,
    text: 'Free shipping • Cancel anytime',
  },
];

export const featuresWithLightIcons: IconText[] = features.map((item) => ({
  ...item,
  icon: {
    [doctorIcon]: doctorLightIcon,
    [ongoingIcon]: ongoingLightIcon,
    [medicineIcon]: medicineLightIcon,
    [truckIcon]: truckLightIcon,
  }[item.icon],
}));
