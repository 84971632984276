"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Rationale being - matching mdi's validation here. See ticket sc-23178
 * @param name
 */
const isNameValid = (name) => {
    if (!name) {
        return false;
    }
    const trimmed = name.replace(/[^a-zA-Z]+/g, '').trim();
    return trimmed.length > 1 && trimmed.length < 35;
};
exports.default = isNameValid;
