import './ShipNow.scss';

import { sumBy } from 'lodash';
import { useEffect, useState } from 'react';

import {
  ShippingMethodType,
  applyPromoCode,
  removePromoCode,
  useGetAllSubscriptionsForCustomer,
  useGetShippingMethods,
} from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { useSubscriptionContext } from 'modules/dashboard/sub-modules/manage-subscription/context/manage';

import { getTotalsByInvoice } from 'modules/shared/lib/invoice/calculations';
import { formatProductNames } from 'modules/shared/lib/product/name';
import { getPromotionCodeForCart } from 'modules/shared/sub-modules/checkout/lib/promotion-code';

import { ManageLevel } from 'modules/dashboard/sub-modules/manage-subscription/models/manage-type';
import { AlloyCartPromotionCode } from 'modules/shared/models/cart';

import { showSuccessNotification } from 'shared/components/core/Notification';

import { useAppSelector } from 'shared/store/reducers';

import DiscountBlock from '../blocks/Discount';
import ProductListBlock from '../blocks/ProductList';
import ShippingBlock from '../blocks/Shipping';
import TotalsBlock from '../blocks/Totals';

interface Props {
  products: GroupedContentfulProduct[][];
  manageLevel: ManageLevel;
  onConfirm: (shippingMethodId?: number, promotionCodeId?: string) => void;
  parentProducts: GroupedContentfulProduct[];
}

const INIT_PROMOTION: AlloyCartPromotionCode = {
  id: '',
  name: '',
  discountAmount: 0,
  isReferral: false,
};

export default function ShipNowWrapper({
  products,
  manageLevel,
  onConfirm,
  parentProducts,
}: Props) {
  const { subscription } = useSubscriptionContext();

  const [promotion, setPromotion] = useState<AlloyCartPromotionCode>(INIT_PROMOTION);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedShippingType, setSelectedShippingType] = useState<ShippingMethodType>('STANDARD');

  const customer = useAppSelector((state) => state.alloy.customer!);

  const { mutate } = useGetAllSubscriptionsForCustomer();
  const { data: shippingMethods = [], isLoading: isLoadingShippingMethods } = useGetShippingMethods(
    customer.stateAbbr!.toUpperCase(),
  );

  const foundSelectedShippingMethod = shippingMethods.find(
    (sm) => sm.method === selectedShippingType,
  );

  useEffect(() => {
    const upcomingInvoice = subscription.upcomingInvoice;

    if (!!upcomingInvoice) {
      const { promotionCode } = upcomingInvoice;

      const foundShippingMethod = shippingMethods.find(
        (sm) => sm.priceInCents === upcomingInvoice.shipping,
      );

      if (!!foundShippingMethod && selectedShippingType !== 'STANDARD') {
        setSelectedShippingType(foundShippingMethod.method);
      }

      if (manageLevel === 'SUBSCRIPTION' && !!promotionCode) {
        const { discount } = getTotalsByInvoice(upcomingInvoice);

        setPromotion({
          id: promotionCode.id,
          name: promotionCode.code,
          discountAmount: discount / 100,
          // TODO - is it possible to get here with a first-time order?
          isReferral: false,
        });
      }
    }
  }, [
    shippingMethods.length,
    isLoadingShippingMethods,
    JSON.stringify(subscription.upcomingInvoice),
  ]);

  const onApplyDiscount = async (code: string) => {
    try {
      setIsLoading(true);

      if (manageLevel === 'SUBSCRIPTION') {
        await applyPromoCode(subscription.stripeSubscriptionId, code);
        await mutate();
      } else {
        const subtotal =
          sumBy(
            products.flatMap((gcpList) =>
              gcpList.flatMap((gcp) => [
                ...gcp.alloyProduct.parent,
                ...(gcp.alloyProduct.child || []),
              ]),
            ),
            (pf) => pf.priceInCents,
          ) / 100;

        const stripeProductIds = products.flatMap((gcpList) =>
          gcpList.flatMap((gcp) => gcp.alloyProduct.parent.map((pf) => pf.stripeProductId)),
        );

        const promotionCode = await getPromotionCodeForCart(code, subtotal, stripeProductIds);

        setPromotion(promotionCode);
      }

      showSuccessNotification('Promo code successfully applied');

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onRemoveDiscount = async () => {
    try {
      setIsLoading(true);

      if (manageLevel === 'SUBSCRIPTION') {
        await removePromoCode(subscription.stripeSubscriptionId);
        await mutate();
      }

      setPromotion(INIT_PROMOTION);

      showSuccessNotification('Promo code successfully removed');

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className='ship-now-wrapper'>
      {!!parentProducts.length && (
        <p className='content-text'>
          This treatment is only eligible for a discount when shipped together with{' '}
          {formatProductNames(parentProducts)}.
        </p>
      )}

      <ProductListBlock products={products} />

      <div className='content-divider' />

      <DiscountBlock
        activeDiscountName={promotion.name}
        isLoading={isLoading}
        onApply={onApplyDiscount}
        onRemove={onRemoveDiscount}
      />

      <ShippingBlock
        previousType={foundSelectedShippingMethod?.method}
        shippingMethods={shippingMethods}
        onSelect={(sm) => setSelectedShippingType(sm.method)}
      />

      <TotalsBlock
        products={products}
        promotion={promotion}
        shippingMethod={foundSelectedShippingMethod}
      />

      <button
        className='primary-button'
        onClick={() =>
          onConfirm(
            selectedShippingType !== 'STANDARD' ? foundSelectedShippingMethod?.id : undefined,
            promotion.id !== '' ? promotion.id : undefined,
          )
        }
      >
        Confirm
      </button>
    </div>
  );
}
