import './Totals.scss';

import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { ShippingMethod } from 'common/dist/models/shipping';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { getSubtotalInCentsFrom } from 'modules/shared/lib/product/pricing';
import {
  buildDataForCalculation,
  calculateTaxes,
} from 'modules/shared/sub-modules/checkout/lib/tax';

import { AlloyCartPromotionCode } from 'modules/shared/models/cart';

import chevronDownIcon from 'shared/assets/svg/common/chevron-down.svg';

import { convertCentsToDollars } from 'shared/lib/convert';

import { SummaryContent } from 'shared/models/summary-content';

import { useAppSelector } from 'shared/store/reducers';

interface Props {
  products: GroupedContentfulProduct[][];
  promotion: AlloyCartPromotionCode;
  shippingMethod?: ShippingMethod & { priceInCents: number };
}

export default function TotalsBlock({ products, promotion, shippingMethod }: Props) {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const [showSummaryDetails, setShowSummaryDetails] = useState<boolean>(false);
  const [taxInCents, setTaxInCents] = useState(0);

  const subtotalInCents = getSubtotalInCentsFrom(products);

  const subtotalToDollars = convertCentsToDollars(subtotalInCents);
  const taxToDollars = convertCentsToDollars(taxInCents);

  const shippingPrice = shippingMethod?.priceInCents || 0;
  const shippingToDollars = convertCentsToDollars(shippingPrice);

  const totalToDollars = convertCentsToDollars(
    subtotalInCents + taxInCents + shippingPrice - promotion.discountAmount * 100,
  );

  useEffect(() => {
    const getTaxAmount = async () => {
      const data = buildDataForCalculation(
        0,
        0,
        products.flatMap((gcpList) =>
          gcpList.flatMap((gcp) => [
            ...gcp.alloyProduct.parent.map((pf) => pf.priceInCents),
            ...(gcp.alloyProduct.child || []).map((pf) => pf.priceInCents),
          ]),
        ),
        customer,
      );

      const tax = await calculateTaxes(data);

      setTaxInCents(tax * 100);
    };

    getTaxAmount();
  }, []);

  const summaryContent: SummaryContent[] = [
    {
      title: 'Subtotal',
      content: `$${subtotalToDollars}`,
    },
    {
      title: 'Taxes',
      content: `$${taxToDollars}`,
    },
    {
      title: 'Shipping',
      content: `$${shippingToDollars}`,
    },
  ];

  if (!!promotion.discountAmount) {
    // Insert at index 1 the discount row since it should come before taxes
    summaryContent.splice(1, 0, {
      title: `Discounts (${promotion.name})`,
      content: `-$${promotion.discountAmount.toFixed(2)}`,
    });
  }

  return (
    <div className={classNames('manage-totals-block', showSummaryDetails && 'active-summary')}>
      <div className='total-wrapper' onClick={() => setShowSummaryDetails(!showSummaryDetails)}>
        <p className='total-title'>Order Total</p>

        <p className='total-amount'>
          ${totalToDollars}
          <img src={chevronDownIcon} alt='chevron-down' />
        </p>
      </div>

      <div className='total-summary-wrapper'>
        {summaryContent.map((summary, index) => (
          <div className='summary-row' key={index}>
            <p className='summary-title'>{summary.title}</p>
            <p className='summary-content'>{summary.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
