import { useLocation } from 'react-router-dom';

import { SubscriptionWithRenewal } from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import useCart from 'modules/shared/hooks/useCart';

import {
  getDeepProductsFromGroupedProducts,
  getProductsToBeBundledWith,
} from 'modules/shared/lib/product';
import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';

import useGroupedProducts from 'shared/hooks/product/useGroupedProducts';

import BundleViewableProduct from './BundleViewableProduct';
import BundledSavingsBlock from './BundledSavings';
import ViewableProduct from './ViewableProduct';

interface ListViewableProductsProps {
  products: GroupedContentfulProduct[][];
  subscriptions?: SubscriptionWithRenewal[];
  showDosage?: boolean;
  hideDetails?: boolean;
}

export default function ListViewableProducts({
  products,
  subscriptions = [],
  showDosage,
  hideDetails,
}: ListViewableProductsProps) {
  const location = useLocation();

  const { cart } = useCart();

  const { activeSubs, pausedSubs } = getSubscriptionsWithStatus(subscriptions);

  const deepProducts = getDeepProductsFromGroupedProducts(products.flat());
  const subProducts = [...activeSubs, ...pausedSubs].flatMap((sub) =>
    sub.products.map((p) => p.product),
  );

  const { products: activeSubProducts } = useGroupedProducts({
    deepProducts: [...deepProducts, ...subProducts],
    cachedProducts: [...activeSubs, ...pausedSubs].length === 0 ? products : undefined,
    dependencies: [JSON.stringify(products)],
  });

  const isCheckoutExperience = location.pathname.includes('checkout-experience');
  const isRequestExperience = location.pathname.includes('request-experience');
  const isTreatmentPlan = location.pathname.includes('treatment-plan');

  /**
   * needed to add this because we've (for now) three scenarios to display in the checkout
   * - for checkout experience -> ship now defaults to true BUT we don't care about it, so we
   * should always send the `activeSubProducts` to be consider when showing the bundled/not bundled price in the UI
   * - for request experience / add-on TP -> we care about it!
   * if ship now is selected we DON'T consider the `activeSubProducts` and the opposite happens if they select to ship later (next shipment)
   * todo: mikhail says he'll think of a way of clean this whole component in the future
   */
  const shipNowSelected = cart.shipNow && (isRequestExperience || isTreatmentPlan);

  return (
    <>
      <BundledSavingsBlock products={products} />

      {products.map((gcpList, listIndex) => {
        return gcpList.map((gcp, index) => {
          const parents = getProductsToBeBundledWith(
            gcp,
            shipNowSelected && !isCheckoutExperience && gcpList.length === 1
              ? []
              : activeSubProducts,
          );

          return !!parents.length ? (
            <BundleViewableProduct
              key={`${listIndex}-${index}`}
              groupedProduct={gcp}
              isParentSelected
              parents={parents}
              showDosage={showDosage}
              hideDetails={hideDetails}
            />
          ) : (
            <ViewableProduct
              key={`${listIndex}-${index}`}
              groupedProduct={gcp}
              showDosage={showDosage}
              hideDetails={hideDetails}
            />
          );
        });
      })}
    </>
  );
}
