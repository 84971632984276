import { useExperience } from 'modules/shared/context/experience';

import KnownPreferencesFormWrapper from 'modules/ab-tests/sub-modules/known-preferences/ui/wrappers/KnownPreferencesForm';

import Layout from 'shared/components/core/Layout';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

// AB_TEST
export default function KnownPreferencesPage() {
  const { onBack } = useExperience();

  return (
    <Layout title='Known Preferences - Alloy' desc='' noBars>
      <TopBannerWithProgress onBack={onBack} />

      <KnownPreferencesFormWrapper />
    </Layout>
  );
}
