import { useCallback, useRef, useState } from 'react';

export default function usePhotoModalState() {
  const [hasAccess, setHasAccess] = useState(true);

  const [photo, setPhoto] = useState<string>('');
  const webcamRef = useRef<any>(null);

  const onCapture = useCallback(() => {
    if (webcamRef) {
      const photoSrc = webcamRef.current.getScreenshot();
      setPhoto(photoSrc);
    }
  }, []);

  return { webcamRef, photo, setPhoto, hasAccess, setHasAccess, onCapture };
}
