import classNames from 'classnames';

import { isConsultCart } from 'modules/shared/sub-modules/checkout/lib/cart';

import { useAppSelector } from 'shared/store/reducers';

export default function InfoBlock() {
  const { cart } = useAppSelector((state) => state.experience);
  const customer = useAppSelector((state) => state.alloy.customer!);

  const isConsult = isConsultCart(cart);

  return (
    <div className={classNames('ce-shipping-info-block', !isConsult && 'show-shipping-method')}>
      <p className='shipping-info-text'>
        {customer.firstName} {customer.lastName}
      </p>

      <p className='shipping-info-text'>
        {customer.shippingAddressLineOne} {customer.shippingAddressLineTwo}
      </p>

      <p className='shipping-info-text'>
        {customer.city}, {customer.stateAbbr} {customer.zip}
      </p>
    </div>
  );
}
