import { rxIconNoBorder } from 'modules/shared/assets/svg';
import { ReliefImageConfig } from 'modules/shared/models/relief-image-config';

/**
 * This is a reusable selection card image with background,
 * allowing for position and background style configuration
 */

export default function SelectionImage({
  src,
  alt,
  backgroundClassNames,
  imageClassNames,
  includeBadge,
}: ReliefImageConfig) {
  return (
    <div className={backgroundClassNames}>
      <img src={src} alt={alt} className={imageClassNames} />
      {includeBadge && <img alt='Prescription Included' src={rxIconNoBorder} className='rx-icon' />}
    </div>
  );
}
