import { useCancelContext } from 'modules/dashboard/sub-modules/manage-subscription/context/cancel';

import { AllBenefits } from 'modules/dashboard/sub-modules/manage-subscription/data/all-benefits';
import { CANCEL_SAVE } from 'modules/dashboard/sub-modules/manage-subscription/data/reason';

import CheckmarkListBlock from 'modules/dashboard/ui/blocks/CheckmarkList';

import { useQueryParams } from 'shared/context/url/query';

import DoctorCardBlock from './Doctor';

export default function PersonalDoctorContent() {
  const { onCancel, onSaveSubmission } = useCancelContext();
  const { setParam } = useQueryParams();

  const onKeepSubscription = async () => {
    await onSaveSubmission({ 'cancel-saved': CANCEL_SAVE.KEEP_SUBSCRIPTION });

    setParam('outcome', 'keep');
  };

  const onSubmitCancel = async () => {
    await onSaveSubmission();

    await onCancel();
  };

  return (
    <div className='cancel-content-wrapper'>
      <div className='content-block'>
        <h4 className='content-title'>
          Alloy provides unparalleled access to menopause-specialized doctors.
        </h4>
      </div>

      <DoctorCardBlock showAbout />
      <CheckmarkListBlock list={AllBenefits} />

      <div className='btns-stack-block'>
        <button className='primary-button' onClick={onKeepSubscription}>
          Keep my subscription
        </button>

        <button className='primary-link' onClick={onSubmitCancel}>
          Continue to cancel
        </button>
      </div>
    </div>
  );
}
