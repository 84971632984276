import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  DeepProduct,
  PreSubmission,
  SubmissionAnswer,
  useGetAllSubscriptionsForCustomer,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';
import { getItem } from 'client/dist/localstorage';

import { useExperience } from 'modules/shared/context/experience';

import { getExpiringPrescriptionsFromAll } from 'modules/dashboard/lib/subscription';
import { completeRenewal } from 'modules/renewal/lib/complete-renewal';
import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';
import { retrieveFlowFromUrl } from 'modules/shared/lib/experience/experience-flow';

import { PreviousAnswersLocalStorage } from 'modules/questionnaire/models/questionnaire-answers';

import useMarkNotificationsAsRead from 'shared/hooks/useMarkNotificationsAsRead';

export default function useRenewalState() {
  // mark notifications as read once on everytime going to page OR reloading
  useMarkNotificationsAsRead(['RENEWAL_KICKOFF']);

  const navigate = useNavigate();
  const location = useLocation();

  const { onNext } = useExperience();

  const dateStarted = new Date();

  const [isLoading, setIsLoading] = useState(true);
  const [previousResults, setPreviousResults] = useState({});
  const [expiringProductFrequencies, setExpiringProductFrequencies] = useState<DeepProduct[]>([]);

  const flow = retrieveFlowFromUrl(location);
  const categories = retrieveCategoriesFromUrl(location);
  const intakeQuestions = flow.intakeQuestions!;

  const {
    data: subscriptions = [],
    isLoading: isLoadingSubscriptions,
    mutate: mutateSubscriptions,
  } = useGetAllSubscriptionsForCustomer();

  const { mutate: mutateTreatmentPlan } = useGetTreatmentPlan();

  useEffect(() => {
    if (isLoadingSubscriptions) return;

    const expiring = getExpiringPrescriptionsFromAll(subscriptions);

    if (expiring.length === 0) {
      navigate('/');
      return;
    }

    let recentIntakeAnswers: PreviousAnswersLocalStorage | undefined = getItem(
      'recentIntakeAnswers',
      true,
    );

    if (
      recentIntakeAnswers &&
      recentIntakeAnswers.categories &&
      recentIntakeAnswers.categories.sort().toString() === categories.sort().toString()
    ) {
      setPreviousResults(recentIntakeAnswers.answers);
    }

    setExpiringProductFrequencies(expiring);
    setIsLoading(false);
  }, [subscriptions.length, isLoadingSubscriptions]);

  const onSubmit = async (answers: SubmissionAnswer[]) => {
    try {
      setIsLoading(true);

      const submission: PreSubmission = {
        categories: ['renewal'],
        started: dateStarted,
        answers: answers,
      };

      await completeRenewal(submission, expiringProductFrequencies);

      await Promise.all([mutateTreatmentPlan, mutateSubscriptions]);

      onNext();
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isLoadingSubscriptions,

    intakeQuestions,
    previousResults,
    expiringProductFrequencies,

    onSubmit,
  };
}
