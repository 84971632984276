import DashboardContainer from 'modules/dashboard/ui/containers/DashboardContainer';

import SectionWrapper from 'shared/components/wrappers/Section';

import { useAppSelector } from 'shared/store/reducers';

import InfoSection from '../../../../../../ui/wrappers/InfoSection';
import TestimonialsSection from '../../../../../../ui/wrappers/TestimonialsSection';
import EmptyStateBlock from '../../../../ui/blocks/EmptyPlan';
import HeaderBlock from '../../../../ui/blocks/Header';

export default function EmptyPlan() {
  const customer = useAppSelector((state) => state.alloy.customer!);

  return (
    <DashboardContainer title='Treatment Plan | Alloy' desc='' currentPage='treatment plan'>
      <HeaderBlock
        title={
          customer.status === 'NEW'
            ? `Create a treatment plan${customer.firstName ? ' for' : ''}`
            : 'Treatment plan for'
        }
        fancyTitle={customer.firstName ?? ''}
      />

      <SectionWrapper
        sectionClasses='dashboard-treatment-plan-section extra-bottom-padding'
        rowClasses='justify-content-center'
      >
        <div className='col-12 col-md-7'>
          <EmptyStateBlock />
        </div>
      </SectionWrapper>

      <InfoSection />
      <TestimonialsSection />
    </DashboardContainer>
  );
}
