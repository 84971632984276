import './Message.scss';

interface Props {
  title?: string;
  text?: string | JSX.Element;
}

export default function MessageBlock({
  title = 'Watch your inbox',
  text = "We'll send you a notification by email and text once the doctor has approved your order.",
}: Props) {
  return (
    <div className='message-block'>
      <p className='message-title'>{title}</p>
      <p className='message-text'>{text}</p>
    </div>
  );
}
