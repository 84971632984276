import DoctorSurveyConfirmationWrapper from 'modules/surveys/doctor/ui/wrappers/DoctorSurveyConfirmation';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';
import Layout from 'shared/components/core/Layout';

export default function DoctorSurveyConfirmationPage() {
  return (
    <Layout title='Confirmation | MyAlloy' desc='' noBars>
      <TopBannerWithProgress />

      <DoctorSurveyConfirmationWrapper />
    </Layout>
  );
}
