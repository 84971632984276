import posthog from 'posthog-js';
import Stripe from 'stripe';

import promoCodeClient from './promo-code-client';

import { decemberFlag } from 'shared/lib/posthog';

export const getPromotionCodeForCart = async (
  promotionCode: string,
  subtotal: number,
  productIds: Stripe.Product['id'][]
) => {
  const validPromotionCode = await promoCodeClient.checkValidity(promotionCode, {
    stripeProductIds: productIds
  });

  let discountAmount = 0;

  if (validPromotionCode.coupon.amount_off) {
    discountAmount = validPromotionCode.coupon.amount_off / 100;
  } else if (validPromotionCode.coupon.percent_off) {
    discountAmount = parseFloat(
      (subtotal * (validPromotionCode.coupon.percent_off / 100)).toFixed(2)
    );
  }

  // ##DEC2024PROMO
  // If the promotion is live, swap the value and name here so that:
  // * the holiday referral coupon name shows up in checkout
  // * the discount amount reflects the HOLIDAYREFERRAL coupon value, which we will apply on the backend
  const swapReferralValueAndName =
    posthog.isFeatureEnabled(decemberFlag) && validPromotionCode.isReferral;

  discountAmount = swapReferralValueAndName ? 49 : discountAmount;
  const discountName = swapReferralValueAndName ? 'HOLIDAYREFERRAL' : validPromotionCode.code;

  return {
    id: validPromotionCode.id,
    name: discountName,
    discountAmount,
    isReferral: validPromotionCode.isReferral
  };
};
