import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useGetCustomerDoctor } from 'client/dist/generated/alloy';

import { getPrescribingDoctorByNpiId } from 'modules/shared/lib/contentful';

import { ContentfulPrescribingDoctor } from 'shared/models/contentful/doctor';

import { useAppSelector } from 'shared/store/reducers';

import { updatePrescribingDoctor } from '../store/prescribing-doctor';

interface ReturnProps {
  doctor: ContentfulPrescribingDoctor | undefined;
  isLoading: boolean;
}

export default function usePrescribingDoctor(): ReturnProps {
  const dispatch = useDispatch();

  const { data: doctorNpiId, isLoading } = useGetCustomerDoctor();

  const doctor = useAppSelector((state) => state.treatmentPlan.prescribingDoctor);

  const dispatchUpdatePrescribingDoctor = bindActionCreators(updatePrescribingDoctor, dispatch);

  useEffect(() => {
    retrieveDoctor();
  }, [isLoading]);

  const retrieveDoctor = async () => {
    if (!!doctorNpiId && !doctor) {
      const fetchedDoctor = await getPrescribingDoctorByNpiId(doctorNpiId);

      if (fetchedDoctor) {
        dispatchUpdatePrescribingDoctor(fetchedDoctor);
      }
    }
  };

  return {
    doctor,
    isLoading
  };
}
