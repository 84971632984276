import { Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';

import useActiveState from 'modules/dashboard/sub-modules/treatment-plan/sub-modules/home/hooks/useActiveState';

import ProgressBlock from 'modules/dashboard/sub-modules/treatment-plan/ui/blocks/Progress';

import chevronDown from 'shared/assets/svg/common/chevron-down.svg';

import { EmailUs } from 'shared/components/content/Buttons';

import { toggleClass } from 'shared/lib/dom';

import DividerWrapper from './Divider';

import ActiveProduct from '../content/ActiveProduct';

export default function ActiveWrapper() {
  const {
    activeProducts,
    requestedProduct,
    pendingSwitch,
    isLoading: isLoadingGrouped,
  } = useActiveState();

  if (isLoadingGrouped) {
    return (
      <div className='tp-products-list-wrapper'>
        <Skeleton variant='rectangular' height={200} />
      </div>
    );
  }

  if (activeProducts.length === 0) return <></>;

  return (
    <>
      <div className='tp-products-list-wrapper active' id='active-products-list'>
        <div className='tp-header'>
          <p className='tp-subtitle'>Active treatments</p>

          <div className='tp-btn-wrapper'>
            <Link to='/subscriptions' className='secondary-border-button'>
              Manage
            </Link>

            <button className='tp-chevron-btn' onClick={() => toggleClass('active-products-list')}>
              <img src={chevronDown} alt='chevron-down' />
            </button>
          </div>
        </div>

        <div className='products-list'>
          {/* Purchased products that are either in sub or not on sub (one time) */}
          {activeProducts.map(
            (
              { gcp, subscription, productInSubscription, prescriptionProduct, foundSwitch },
              index,
            ) => {
              if (foundSwitch && requestedProduct) {
                return (
                  <ActiveProduct
                    key={gcp.contentfulProduct.sys.id + '-active-' + index}
                    product={requestedProduct}
                    isProcessing
                    showDosage={pendingSwitch?.isSwitchFormFactor}
                  >
                    <span className='product-hug'>
                      {pendingSwitch?.isSwitchDose ? 'New dose requested' : 'Requested from doctor'}
                    </span>

                    <ProgressBlock
                      description='Your doctor will review your request and, once approved, your new dosage will be included with the rest of your normal shipment.'
                      barTitles={['Request', 'Doctor review', 'Approved']}
                      currentIndex={1}
                      hideButton
                    />

                    <p className='product-text'>
                      Any questions about your order at all, email us at <EmailUs />. We're here for
                      you!
                    </p>
                  </ActiveProduct>
                );
              }
              // the product below is shown for any recurring product that would be in a subscription
              else if (subscription) {
                return (
                  <ActiveProduct
                    key={gcp.contentfulProduct.sys.id + '-active-subscription-' + index}
                    product={{
                      alloyProduct: gcp.alloyProduct,
                      contentfulProduct: gcp.contentfulProduct,
                      renewal: productInSubscription?.renewal,
                      prescription: productInSubscription?.prescription,
                    }}
                    nextRecurrenceOn={subscription.nextRecurrenceOn.toISOString()}
                    subscription={subscription}
                  />
                );
              }
              // the product below is shown when we have a one time product that would not have a subscription
              else {
                return (
                  <ActiveProduct
                    key={gcp.contentfulProduct.sys.id + '-active-one-time-' + index}
                    product={{
                      alloyProduct: gcp.alloyProduct,
                      contentfulProduct: gcp.contentfulProduct,
                      prescription: prescriptionProduct?.prescription,
                      renewal: prescriptionProduct?.renewal,
                    }}
                  />
                );
              }
            },
          )}
        </div>
      </div>

      <DividerWrapper />
    </>
  );
}
