import {
  ProductFrequencyRenewal,
  useGetAllSubscriptionsForCustomer,
  useGetTreatmentPlan
} from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { filterSingleSupplyProducts } from 'modules/shared/lib/product/single-supply';
import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';

import useGroupedProducts from 'shared/hooks/product/useGroupedProducts';

interface ReturnProps {
  products: GroupedContentfulProduct[][];
  isLoading: boolean;
  prescriptionProducts: ProductFrequencyRenewal[];
}

export default function useInactiveData(): ReturnProps {
  const { data: treatmentPlan, isLoading: isLoadingTreatmentPlan } = useGetTreatmentPlan();
  const { data: subscriptions = [], isLoading: isLoadingSubscriptions } =
    useGetAllSubscriptionsForCustomer();

  const { pausedSubs, cancelledSubs } = getSubscriptionsWithStatus(subscriptions);

  const singleSupplyProducts = filterSingleSupplyProducts(subscriptions, treatmentPlan, 'EXPIRED');

  const filteredProducts = [...pausedSubs, ...cancelledSubs]
    .flatMap((sub) => sub.products.map((pfr) => pfr.product))
    .filter((pf) =>
      treatmentPlan?.currentRentionProducts.every((retentionPf) => pf.id !== retentionPf.id)
    );

  const purchased = [...filteredProducts, ...singleSupplyProducts.map((pfr) => pfr.product)];

  const { products, isLoading } = useGroupedProducts({
    deepProducts: purchased,
    dependencies: [isLoadingTreatmentPlan, isLoadingSubscriptions]
  });

  return {
    products,
    isLoading,
    prescriptionProducts: treatmentPlan?.prescriptionProducts || []
  };
}
