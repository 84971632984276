import './MessagesInfo.scss';

import { Skeleton } from '@mui/material';
import classNames from 'classnames';

import { isOtcOnlyCustomer } from 'modules/dashboard/lib/otc-only-customer';

import DashboardContainer from 'modules/dashboard/ui/containers/DashboardContainer';

import EmptyState from 'modules/shared/ui/content/EmptyState';

import Loader from 'shared/components/content/Loader';
import TitleTextWrapper from 'shared/components/wrappers/TitleText';

import useMessagesState from '../../hooks/useMessagesState';

export default function MessagesInfoWrapper() {
  const {
    isLoading,
    isLoadingValidation,
    setIsLoadingIframe,
    isError,
    mdiWidget,
    customer,
    subscriptions,
    iframeRef,
  } = useMessagesState();

  if (isLoading)
    return (
      <DashboardContainer title='Messages | Alloy' desc='' currentPage='messages'>
        <Loader />
      </DashboardContainer>
    );

  let content = <></>;

  if (isError) {
    content = <EmptyState type='VALIDATION_ERROR' />;
  } else if (mdiWidget.status === 'ACTIVE' && mdiWidget.url) {
    content = (
      <div className='row mt-2'>
        <div className='col-12'>
          <iframe
            allow='microphone'
            className={classNames('messages-iframe', isLoadingValidation && 'hide-iframe')}
            src={mdiWidget.url}
            title='mdi'
            ref={iframeRef}
            onLoad={() => setIsLoadingIframe(false)}
          />

          {isLoadingValidation && <Skeleton variant='rectangular' height={350} />}
        </div>
      </div>
    );
  } else if (
    customer.status === 'NEW' ||
    (mdiWidget && mdiWidget.status === 'INACTIVE') ||
    isOtcOnlyCustomer(subscriptions)
  ) {
    content = <EmptyState type='NEW_CONSULT' />;
  } else if (customer.status === 'PENDING') {
    content = <EmptyState type='PENDING' />;
  } else {
    content = <></>;
  }

  return (
    <DashboardContainer title='Messages | Alloy' desc='' currentPage='messages'>
      <section className='dashboard-section'>
        <div className='container'>
          <TitleTextWrapper
            title='Messages'
            description={
              <>
                If you have questions about your symptoms, medical history, or prescription, please
                message your doctor here. Your doctor will respond within 24-48 hours during normal
                business hours. For non-medical questions (shipping, billing, refills, etc.) please
                email{' '}
                <a href='mailto:support@myalloy.com' className='anchor-link'>
                  support@myalloy.com
                </a>
                .
              </>
            }
          />

          {content}
        </div>
      </section>
    </DashboardContainer>
  );
}
