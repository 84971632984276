import DashboardContainer from 'modules/dashboard/ui/containers/DashboardContainer';

import useCart from 'modules/shared/hooks/useCart';

import ViewableProduct from 'modules/shared/ui/blocks/ViewableProduct';

import SectionWrapper from 'shared/components/wrappers/Section';

import { useAppSelector } from 'shared/store/reducers';

import TopBlogBlock from '../../../../../../ui/blocks/TopBlog';
import InfoSection from '../../../../../../ui/wrappers/InfoSection';
import TestimonialsSection from '../../../../../../ui/wrappers/TestimonialsSection';
import HeaderBlock from '../../../../ui/blocks/Header';
import ReferralBlock from '../../../../ui/blocks/Referral';

interface Props {
  onViewTreatmentPlan: () => void;
}

export default function Confirmation({ onViewTreatmentPlan }: Props) {
  const customer = useAppSelector((state) => state.alloy.customer!);
  const { cart } = useCart();

  return (
    <DashboardContainer title='Treatment Plan | Alloy' desc='' currentPage='treatment plan'>
      <HeaderBlock title='Your order is' fancyTitle='confirmed' backgroundType='secondary' />

      <SectionWrapper sectionClasses='dashboard-treatment-plan-section'>
        <div className='col-12 col-md-7'>
          {cart.products.map((gcp, index) => (
            <ViewableProduct key={index} groupedProduct={gcp} showDosage hideDetails />
          ))}

          <p>
            Thank you for your order! You'll receive an order confirmation email shortly at{' '}
            {customer.email}.
          </p>

          <p>
            Any questions about your order at all, email us at{' '}
            <a href='mailto:support@myalloy.com'>support@myalloy.com</a>. We're here for you!
          </p>

          <button className='primary-button' onClick={onViewTreatmentPlan}>
            View treatment plan
          </button>
        </div>

        <div className='col-12 col-md-5'>
          <ReferralBlock />
          <TopBlogBlock />
        </div>
      </SectionWrapper>

      <InfoSection />
      <TestimonialsSection />
    </DashboardContainer>
  );
}
