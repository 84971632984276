import { combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import ABTestingReducer, { ABTestingState } from './AbTestingReducer';
import AlloyReducer, { AlloyState } from './AlloyReducer';
import ExperienceReducer, { ExperienceState } from './ExperienceReducer';
import TreatmentPlanReducer, { TreatmentPlanState } from './TreatmentPlanReducer';

// TODO redux toolkit

export interface RootState {
  alloy: AlloyState;
  experience: ExperienceState;
  treatmentPlan: TreatmentPlanState;
  abTesting: ABTestingState;
}

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const rootReducer = combineReducers({
  alloy: AlloyReducer,
  experience: ExperienceReducer,
  treatmentPlan: TreatmentPlanReducer,
  abTesting: ABTestingReducer,
});

export default rootReducer;
