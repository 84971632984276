import { useNavigate } from 'react-router-dom';

import CheckoutExperienceSection from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceSection';

export default function DoctorSurveyConfirmationWrapper() {
  const navigate = useNavigate();

  const onComplete = () => navigate('/');

  return (
    <>
      <CheckoutExperienceSection colClass='col-md-7'>
        <h1 className='content-title'>Thank you for taking the time to complete the survey!</h1>

        <p className='content-text'>
          Your feedback helps us improve future doctor interactions through Alloy.
        </p>

        <button className='primary-button full-width-button' onClick={onComplete}>
          Back to your dashboard
        </button>
      </CheckoutExperienceSection>
    </>
  );
}
