import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useInitExperience from 'modules/shared/hooks/useInitExperience';

import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';

interface Props {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;

  BASE_PATH: string;
}

export const CheckoutExperienceContext = createContext<Props>({
  isLoading: true,
  setIsLoading: () => {},

  BASE_PATH: '',
});

export const useCheckoutExperience = () => useContext(CheckoutExperienceContext);

export default function CheckoutExperienceContextProvider({ children }: { children: ReactNode }) {
  const location = useLocation();

  const { initCheckoutExperience } = useInitExperience();

  const BASE_PATH = '/checkout-experience';

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    initiateExperience();
  }, []);

  const initiateExperience = async () => {
    setIsLoading(true);

    const retrievedCategories = retrieveCategoriesFromUrl(location);

    await initCheckoutExperience(retrievedCategories);

    setIsLoading(false);
  };

  return (
    <CheckoutExperienceContext.Provider
      value={{
        isLoading,
        setIsLoading,

        BASE_PATH,
      }}
    >
      {children}
    </CheckoutExperienceContext.Provider>
  );
}
