import { getTime } from 'date-fns';
import { useEffect, useState } from 'react';

import { ApiOrder, useGetAllOrders } from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';

import { useCancelContext } from 'modules/dashboard/sub-modules/manage-subscription/context/cancel';
import { useProductContext } from 'modules/dashboard/sub-modules/manage-subscription/context/product';

import { CANCEL_SAVE } from 'modules/dashboard/sub-modules/manage-subscription/data/reason';

import DownloadInvoiceButton from 'modules/dashboard/ui/content/DownloadInvoiceButton';

import Loader from 'shared/components/content/Loader';

import { useQueryParams } from 'shared/context/url/query';

export default function DownloadInvoiceContent() {
  const { reasonType, onCancel, onSaveSubmission } = useCancelContext();
  const { product } = useProductContext();
  const { setParam } = useQueryParams();

  const [recentOrder, setRecentOrder] = useState<ApiOrder | null>(null);

  const { data: orders = [], isLoading: isLoadingOrders } = useGetAllOrders();

  useEffect(() => {
    // sorted orders with last one being most recent
    const sorted = orders
      .sort((a, b) => getTime(new Date(a.createdAt!)) - getTime(new Date(b.createdAt!)))
      .filter((o) =>
        o.productFrequencies.some((pf) =>
          ProductRegistry.get().areProductsEquivalent([pf, product.alloyProduct.parent[0]]),
        ),
      )
      .filter((o) => !o.isConsult);

    setRecentOrder(sorted[sorted.length - 1]);
  }, [isLoadingOrders]);

  const onInvoiceDownload = async () => {
    await onSaveSubmission({ 'cancel-saved': CANCEL_SAVE.INVOICE });

    setParam('outcome', 'invoice');
  };

  const onSubmitCancel = async () => {
    await onSaveSubmission();

    await onCancel();
  };

  if (isLoadingOrders || !recentOrder) {
    return <Loader />;
  }

  return (
    <div className='cancel-content-wrapper'>
      <div className='content-block'>
        <h4 className='content-title'>
          {reasonType === 'TOO_EXPENSIVE'
            ? 'Did you know there are ways to reduce the cost of your prescriptions?'
            : 'Before you go, maybe we can help!'}
        </h4>

        <p className='content-text'>
          With Alloy, you can download an invoice to submit to your insurance which can help with
          the cost of your treatments.
        </p>
      </div>

      <div className='btns-stack-block'>
        <DownloadInvoiceButton order={recentOrder} onDownloaded={onInvoiceDownload} />

        <button className='primary-link' onClick={onSubmitCancel}>
          I want to cancel
        </button>
      </div>
    </div>
  );
}
