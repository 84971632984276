"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SPRAY_FORM_FACTOR_ID = exports.GEL_FORM_FACTOR_ID = exports.PATCH_FORM_FACTOR_ID = exports.PILL_FORM_FACTOR_ID = exports.NA_FORM_FACTOR_ID = exports.DOSAGE_1_PCT_ID = exports.DOSAGE_030_PCT_ID = exports.DOSAGE_0089_PCT_ID = exports.DOSAGE_0067_PCT_ID = exports.DOSAGE_0028_PCT_ID = exports.DOSAGE_200_MG_ID = exports.DOSAGE_100_MG_ID = exports.DOSAGE_5_MG_ID = exports.DOSAGE_2_MG_ID = exports.DOSAGE_1_25MG_ID = exports.DOSAGE_1_MG_ID = exports.DOSAGE_075MG_ID = exports.DOSAGE_050MG_ID = exports.DOSAGE_035MG_ID = exports.DOSAGE_025MG_ID = exports.DOSAGE_010_MG_ID = exports.DOSAGE_0075_MG_ID = exports.DOSAGE_005_MG_ID = exports.DOSAGE_00375_MG_ID = exports.DOSAGE_0025_MG_ID = exports.DOSAGE_NA = exports.M4_EYE_SERUM_PRODUCT_ID = exports.M4_FACE_SERUM_PRODUCT_ID = exports.DOCTOR_CONSULT_PRODUCT_ID = exports.VAGINAL_PRODUCT_ID = exports.PAROXETINE_PRODUCT_ID = exports.SYNBIOTIC_PRODUCT_ID = exports.OMAZING_NO_MINT_PRODUCT_ID = exports.OMAZING_PRODUCT_ID = exports.ORAL_MINOXIDIL_ID = exports.TRETINOIN_ID = exports.M4_PRODUCT_ID = exports.GENERIC_YAZ_ID = exports.PROGESTIN_ONLY_BC_PRODUCT_ID = exports.EXTEND_LDBC_PRODUCT_ID = exports.LDBC_PRODUCT_ID = exports.ESTRADIOL_PROGESTIN_COMBINATION_ID = exports.NORETHINDRONE_ACETATE_ID = exports.PROGESTERONE_PRODUCT_ID = exports.ESTRADIOL_PRODUCT_ID = exports.SIXTY_DAY_OFFSET_ID = exports.SEVENTY_THREE_DAY_OFFSET_ID = exports.SEVENTY_FIVE_DAY_RECURRING_ID = exports.SINGLE_SUPPLY_ID = exports.NINETY_DAY_RECURRING_ID = void 0;
exports.ProductRegistry = exports.PRODUCT_IDS_PER_TYPE = exports.SERUM_FORM_FACTOR_ID = exports.FACE_CREAM_FORM_FACTOR_ID = exports.CREAM_FORM_FACTOR_ID = void 0;
const lodash_1 = require("lodash");
exports.NINETY_DAY_RECURRING_ID = 1;
exports.SINGLE_SUPPLY_ID = 3;
exports.SEVENTY_FIVE_DAY_RECURRING_ID = 4;
exports.SEVENTY_THREE_DAY_OFFSET_ID = 1;
exports.SIXTY_DAY_OFFSET_ID = 2;
// PRODUCT IDS
exports.ESTRADIOL_PRODUCT_ID = 48;
exports.PROGESTERONE_PRODUCT_ID = 49;
exports.NORETHINDRONE_ACETATE_ID = 31;
exports.ESTRADIOL_PROGESTIN_COMBINATION_ID = 23;
exports.LDBC_PRODUCT_ID = 8;
exports.EXTEND_LDBC_PRODUCT_ID = 22;
exports.PROGESTIN_ONLY_BC_PRODUCT_ID = 33;
exports.GENERIC_YAZ_ID = 39;
exports.M4_PRODUCT_ID = 27;
exports.TRETINOIN_ID = 50;
exports.ORAL_MINOXIDIL_ID = 51;
exports.OMAZING_PRODUCT_ID = 26;
exports.OMAZING_NO_MINT_PRODUCT_ID = 44;
exports.SYNBIOTIC_PRODUCT_ID = 9;
exports.PAROXETINE_PRODUCT_ID = 10;
exports.VAGINAL_PRODUCT_ID = 7;
exports.DOCTOR_CONSULT_PRODUCT_ID = 15;
exports.M4_FACE_SERUM_PRODUCT_ID = 52;
exports.M4_EYE_SERUM_PRODUCT_ID = 53;
// DOSAGE IDS
exports.DOSAGE_NA = 1;
exports.DOSAGE_0025_MG_ID = 2;
exports.DOSAGE_00375_MG_ID = 3;
exports.DOSAGE_005_MG_ID = 4;
exports.DOSAGE_0075_MG_ID = 5;
exports.DOSAGE_010_MG_ID = 6;
exports.DOSAGE_025MG_ID = 7;
exports.DOSAGE_035MG_ID = 8;
exports.DOSAGE_050MG_ID = 9;
exports.DOSAGE_075MG_ID = 10;
exports.DOSAGE_1_MG_ID = 11;
exports.DOSAGE_1_25MG_ID = 12;
exports.DOSAGE_2_MG_ID = 13;
exports.DOSAGE_5_MG_ID = 14;
exports.DOSAGE_100_MG_ID = 15;
exports.DOSAGE_200_MG_ID = 16;
exports.DOSAGE_0028_PCT_ID = 17;
exports.DOSAGE_0067_PCT_ID = 18;
exports.DOSAGE_0089_PCT_ID = 19;
exports.DOSAGE_030_PCT_ID = 20;
exports.DOSAGE_1_PCT_ID = 21;
// FORM FACTOR IDS
exports.NA_FORM_FACTOR_ID = 1;
exports.PILL_FORM_FACTOR_ID = 2;
exports.PATCH_FORM_FACTOR_ID = 3;
exports.GEL_FORM_FACTOR_ID = 4;
exports.SPRAY_FORM_FACTOR_ID = 5;
exports.CREAM_FORM_FACTOR_ID = 6;
exports.FACE_CREAM_FORM_FACTOR_ID = 7;
exports.SERUM_FORM_FACTOR_ID = 8;
exports.PRODUCT_IDS_PER_TYPE = {
    mht: [exports.ESTRADIOL_PRODUCT_ID, exports.PROGESTERONE_PRODUCT_ID, exports.LDBC_PRODUCT_ID, exports.PAROXETINE_PRODUCT_ID],
    upsell: [
        exports.M4_PRODUCT_ID,
        exports.TRETINOIN_ID,
        exports.M4_FACE_SERUM_PRODUCT_ID,
        exports.M4_EYE_SERUM_PRODUCT_ID,
        exports.OMAZING_PRODUCT_ID,
        exports.SYNBIOTIC_PRODUCT_ID,
        exports.VAGINAL_PRODUCT_ID,
    ],
};
/**
 * Use this primarily, because it's way easier to mock with sinon
 */
const get = (fetcher) => ProductRegistry.getInstance(fetcher);
exports.default = { get };
/**
 * Holds product configuration in memory.
 *
 * This is the skeleton key across our integrations. Prefer grabbing and storing
 * this configuration in memory (vs re-fetching) - these are stable and won't change
 * over time (without a redeploy).
 *
 */
class ProductRegistry {
    constructor(fetcher) {
        this.shippingMethodPromise = undefined;
        this.shippingMethods = undefined;
        this.getDocConsult = () => __awaiter(this, void 0, void 0, function* () {
            return (yield this.alloyProductsActiveAndLegacy).filter((ap) => ap.productId === exports.DOCTOR_CONSULT_PRODUCT_ID);
        });
        /**
         * Give a product/frequency/dosage/formFactor and a optional bundledWith config - get the fully hydrated
         * DeepProduct object. Use this to get stripe price id, mdi id, etc.
         *
         * Note bundled config is only applicable to products that HAVE a bundled price (give a bundling discount).
         *
         * If only one product exist for the passed params, we do an early return to avoid
         * getPricesFor extra call (only necessary when trying to fetch the bundable version)
         * and also to make sure norethindrone can be correctly returned when `bundledWith` is empty,
         * since it only exists as bundled.
         *
         * Tests of this function are located in apps/api/productRegistry.test.ts
         *
         * @param product - product id to use (these should stay stable)
         * @param frequency - frequency id to use (1/[2]/3/4)
         * @param dosage - dosage id to use
         * @param formFactor - form factor id to use
         * @param bundledWith - optional pass the context of other products in the customers "cart" to return the correct price of the requested productFrequency
         */
        this.getProductFrequency = (product, frequency, dosage, formFactor, bundledWith = []) => __awaiter(this, void 0, void 0, function* () {
            const products = yield this.alloyProducts;
            const pfs = products.filter((pf) => this.sameProductDosageFormFactorFrequency(pf, product, frequency, dosage, formFactor));
            if (pfs.length === 1) {
                return (0, lodash_1.first)(pfs);
            }
            // fetch deep products that bundles with the one we are passing so we can fetch the correct price below
            const deepProductsToBundleWith = bundledWith
                .map((productId) => products.find((p) => p.productId === productId && p.frequencyId === frequency))
                /**
                 * filters out any product to bundle with that couldn't be found,
                 * for example when there is no product with the same frequency as product to fetch
                 *
                 * eg.: if LDBC that has 75 days recurrence has bundledWith products with 90 days recurrence,
                 * undefined would be returned here. which we need to filter out.
                 * */
                .filter((deepProduct) => !!deepProduct);
            const bundledPrices = yield this.getPricesFor([(0, lodash_1.first)(pfs), ...deepProductsToBundleWith]);
            return bundledPrices
                .flat()
                .find((bp) => this.sameProductDosageFormFactorFrequency(bp, product, frequency, dosage, formFactor));
        });
        /**
         * Given a set of valid mdi product ids (clearly ignoring synbiotic/consult here) -
         * group up products based a set of rules (see above)
         *
         * used when we need to fetch products regardless of being split or not.
         * for example: m4 and tretinoin would be together as parent and child using this method
         *
         * Tests of this function are located in apps/api/productRegistry.test.ts
         *
         * @param mdiIds
         */
        this.getGroupedProductsForMdiIds = (mdiIds) => __awaiter(this, void 0, void 0, function* () {
            const [deepProducts, bundles] = yield Promise.all([this.alloyProducts, this.discountBundles]);
            /**
             * matches the incoming MDI Product IDs to their corresponding DeepProduct
             * makes the array uniq by removing bundled/unbundled items that are equivalent except for their price configuration
             * later in the flow we identify bundled/unbundled based on their bundle parents being present or not.
             */
            const relevantProducts = deepProducts.filter((dp) => mdiIds.includes(dp.mdiProductId));
            const uniqueRelevantProducts = (0, lodash_1.uniqWith)(relevantProducts, (productA, productB) => this.areProductsEquivalent([productA, productB]));
            const products = (yield this.getPricesFor(uniqueRelevantProducts)).flatMap((bundle) => {
                const [child, parent] = (0, lodash_1.partition)(bundle, (b) => bundles[b.productId]);
                const hasParentAndChildren = parent.length && child.length;
                if (hasParentAndChildren) {
                    return parent.map((dp) => ({ parent: [dp], child }));
                }
                return bundle.map((dp) => ({ parent: [dp], child: [] }));
            });
            return products;
        });
        this.fetcher = fetcher;
        this.alloyProductsActiveAndLegacy = fetcher.fetch();
        this.alloyProducts = fetcher.fetch().then((f) => f.filter((f) => f.active));
        this.shippingFetcher = fetcher.fetchShippingMethods;
        this.discountBundles = fetcher.fetchDiscountBundles();
    }
    static getInstance(fetcher) {
        if (!ProductRegistry.instance) {
            ProductRegistry.instance = new ProductRegistry(fetcher);
        }
        return ProductRegistry.instance;
    }
    /**
     * @returns Promise<DeepShippingMethod[]>
     * Lazy Loads the Shipping Methods by hand
     */
    getShippingMethods() {
        if (this.shippingMethods) {
            return Promise.resolve(this.shippingMethods);
        }
        if (this.shippingMethodPromise) {
            return this.shippingMethodPromise;
        }
        const inFlight = this.shippingFetcher();
        this.shippingMethodPromise = inFlight;
        inFlight.then((dsm) => {
            this.shippingMethods = dsm;
            this.shippingMethodPromise = undefined;
        });
        return inFlight;
    }
    /**
     * For a given productId, it returns if the specified product is part of a bundle.
     * This will be true if the product is a discounted product or the parent of a discounted product.
     *
     * Tests of this function are located in apps/api/productRegistry.test.ts
     *
     * @param productId
     * @returns Promise<boolean>
     */
    canBeBundled(productId) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const bundles = yield this.discountBundles;
            const isDiscountedProduct = ((_a = bundles[productId]) === null || _a === void 0 ? void 0 : _a.length) > 0;
            const isBundleParent = Object.values(bundles).some((bundles) => bundles.some((bundle) => bundle.bundledParentProducts.parentIds.includes(productId)));
            return isDiscountedProduct || isBundleParent;
        });
    }
    /**
     * For a given list of deep products, this function returns available price options for
     * determined products, depending on if it is part of a bundle or not.
     *
     * Tests of this function are located in apps/api/productRegistry.test.ts
     *
     * @param products DeepProducts to get updated prices
     * @returns Promise<DeepProduct[][]>
     */
    getPricesFor(products) {
        return __awaiter(this, void 0, void 0, function* () {
            // Gets all bundles that contains parent and child products from params
            const availableBundles = yield this.getAvailableBundles(products);
            // Filter available bundles to get only best bundle options and remove the ones with less prodcuts in it
            const filteredBundles = yield this.filterByBestBundles(availableBundles);
            // Get bundled products
            const bundledProducts = yield this.getBundledProducts(filteredBundles, products);
            // Get unbundled products and parentless/childless bundled products with standalone price
            const unbundledProducts = yield this.getUnbundledProducts(products, bundledProducts);
            return [...bundledProducts, ...unbundledProducts];
        });
    }
    /**
     * TODO: Will work on that right after Skin Bundle Configuration is merged.
     *
     * getAvailableBundles, filterAvailableBundles, getBundledProducts and getUnbundledProducts
     * feels like they don't belong to ProductRegistry. I kept them here because of the need to use
     * areProductsEquivalent function, but if possible, we should move them out of the registry.
     */
    /**
     * Given a list of products to be bundled,
     * this function filters all bundles to get only the ones available
     * for the products passed as params and return them in an array.
     *
     * @param products products to get bundles from
     * @returns DiscountBundle[]
     */
    getAvailableBundles(products) {
        return __awaiter(this, void 0, void 0, function* () {
            const [alloyProducts, bundles] = yield Promise.all([this.alloyProducts, this.discountBundles]);
            const allBundledProducts = products.filter((deepProduct) => { var _a; return ((_a = bundles[deepProduct.productId]) === null || _a === void 0 ? void 0 : _a.length) > 0; });
            return (0, lodash_1.uniq)(allBundledProducts
                .flatMap((deepProduct) => bundles[deepProduct.productId])
                .filter((bundle) => {
                const discountedProduct = alloyProducts.find((ap) => ap.id === bundle.bundledDiscountProduct.productFrequencyId);
                // If bundle operator is 'AND', then use the EVERY array function to decide if parents are present in params
                // If bundle operator is 'OR', then use the SOME array function to decide if parents are present in params
                const operator = bundle.bundledParentProducts.operator === 'AND' ? 'every' : 'some';
                const paramsHasParent = bundle.bundledParentProducts.parentIds[operator]((parentId) => products.some((p) => p.productId === parentId &&
                    // make sure parent in param and children to be discounted are from same frequency
                    p.frequencyId === discountedProduct.frequencyId));
                const paramsHasChild = products.some((pf) => this.areProductsEquivalent([pf, discountedProduct]));
                return paramsHasParent && paramsHasChild;
            }));
        });
    }
    /**
     * Given a list of available discount bundles, this function filters it
     * so only the bundle with the best discount is available for a product.
     *
     * eg.: if available bundles are [m4, tret] and [m4, m4 face serum, m4 eye serum, tret]
     * then the [m4, tret] bundle will be filtered out because the bundle with 4 products has
     * a greater discount.
     *
     * Also, bundles that contains a product as a child should be filtered out if the same product
     * is a parent in another bundle.
     *
     * eg.: when [m4, m4 eye] and [m4, m4 eye, tret] are available bundles
     * the [m4, m4 eye] option should be filtered out
     * because m4 eye is a child in a smaller bundle but it is a parent in another better bundle
     *
     * @param availableBundles list of discount bundles to filter by best option
     * @returns DiscountBundle[]
     */
    filterByBestBundles(availableBundles) {
        return __awaiter(this, void 0, void 0, function* () {
            const alloyProducts = yield this.alloyProducts;
            // gets all child products from available bundles
            const discountedProducts = availableBundles.map((bundle) => alloyProducts.find((ap) => ap.id === bundle.bundledDiscountProduct.productFrequencyId));
            // group same deep products with different prices together
            const groupedDiscountedProducts = (0, lodash_1.groupBy)(discountedProducts, (p) => `${p.productId},${p.frequencyId},${p.dosageId},${p.formFactorId}`);
            // map grouped products to id list containing only lowest bundled price
            const lowestPriceDeepProductIds = (0, lodash_1.values)(groupedDiscountedProducts).map(
            // because some FE and client tests may have undefined priceInCents, default to first product in list so they don't break
            (products) => { var _a, _b; return ((_a = (0, lodash_1.minBy)(products, 'priceInCents')) === null || _a === void 0 ? void 0 : _a.id) || ((_b = (0, lodash_1.first)(products)) === null || _b === void 0 ? void 0 : _b.id); });
            // if a product is a parent in one bundle and a child in another, we should filter out
            // the bundles where this product is a child
            const discountedParentsDeepProductIds = discountedProducts
                .filter((product) => availableBundles.some((bundle) => bundle.bundledParentProducts.parentIds.includes(product.productId)))
                .map((product) => product.id);
            // filter available bundles to contain only best pricing bundle option
            // and also exclude bundles with products that are parent and child in different bundles
            return availableBundles.filter((bundle) => lowestPriceDeepProductIds.includes(bundle.bundledDiscountProduct.productFrequencyId) &&
                !discountedParentsDeepProductIds.includes(bundle.bundledDiscountProduct.productFrequencyId));
        });
    }
    /**
     * Given an array of available bundles for a list of products,
     * this function merges the parents of the bundles together and
     * gets the DeepProducts for both the parents and children, returning
     * them in a 2D array.
     *
     * @param availableBundles bundles that'll be considered when fetching the discounted price for a product
     * @param productsToBundle products to be bundled in a 2D array
     * @returns DeepProduct[][]
     */
    getBundledProducts(availableBundles, productsToBundle) {
        return __awaiter(this, void 0, void 0, function* () {
            const [alloyProducts, allBundles] = yield Promise.all([
                this.alloyProducts,
                this.discountBundles,
            ]);
            // Group bundles by parents
            const bundlesByParent = (0, lodash_1.groupBy)(availableBundles, (ab) => ab.bundledParentProducts.parentIds);
            /**
             * Now we need to merge bundles with same parents
             * eg: prog 100 and prog 200 has the same parent.
             * So, we need to change from [[parent: estradiol, child: prog 100],[parent: estradiol, child: prog 200]]
             * to [[parents: [estradiol], children: [prog 100, prog 200]]]
             */
            return (0, lodash_1.values)(bundlesByParent).flatMap((currentBundles) => {
                const bundleParentIds = (0, lodash_1.uniq)(currentBundles.flatMap((cb) => cb.bundledParentProducts.parentIds));
                const bundleChildIds = currentBundles.flatMap((cb) => cb.bundledDiscountProduct.productFrequencyId);
                const parents = alloyProducts.filter((deepProduct) => {
                    var _a;
                    return bundleParentIds.includes(deepProduct.productId) &&
                        productsToBundle.some((p) => this.areProductsEquivalent([deepProduct, p])) &&
                        // ensures that this is a deep product without discount
                        !((_a = allBundles[deepProduct.productId]) === null || _a === void 0 ? void 0 : _a.some((b) => b.bundledDiscountProduct.productFrequencyId === deepProduct.id));
                });
                const children = alloyProducts.filter((deepProduct) => bundleChildIds.includes(deepProduct.id) &&
                    productsToBundle.some((p) => this.areProductsEquivalent([p, deepProduct])));
                const currentBundledProducts = this.isBundleSplittableByParents([...parents, ...children])
                    ? parents.map((p) => [p, ...children])
                    : [[...parents, ...children]];
                return currentBundledProducts;
            });
        });
    }
    /**
     * Given a list of products and another list of already bundled products,
     * this function return all the products that were left unbundled (or that
     * didn't had a bundle at all) and return them in a 2D array.
     *
     * @param products all products that prices will be returned for
     * @param bundledProducts products that are already present in a bundle
     * @returns DeepProduct[][]
     */
    getUnbundledProducts(products, bundledProducts) {
        return __awaiter(this, void 0, void 0, function* () {
            const [alloyProducts, bundles] = yield Promise.all([this.alloyProducts, this.discountBundles]);
            return products
                .filter((pf) => {
                const isProductAlreadyBundled = bundledProducts.some((bp) => bp.some((p) => p.productId === pf.productId));
                return !isProductAlreadyBundled;
            })
                .map((pf) => [
                alloyProducts.find((ap) => {
                    var _a;
                    const isBundledPrice = (_a = bundles[pf.productId]) === null || _a === void 0 ? void 0 : _a.some((bp) => ap.id === bp.bundledDiscountProduct.productFrequencyId);
                    return !isBundledPrice && this.areProductsEquivalent([ap, pf]);
                }),
            ]);
        });
    }
    /**
     * Given a list of product frequencies, this function returns true if all pfs
     * have same product, frequency, dosage and form factor
     * this is usually useful to compare if the two products (patch 0.05mg bundle vs patch 0.05mg unbundled for example)
     * are the same product (ignoring the is the bundled one or not)
     *
     * Tests of this function are located in apps/api/productRegistry.test.ts
     *
     * @param pfs DeepProduct[]
     * @returns boolean
     */
    areProductsEquivalent(pfs, checkFrequency = true) {
        const firstPf = [...pfs].shift();
        if (!firstPf) {
            return true;
        }
        return pfs.every(({ productId, frequencyId, dosageId, formFactorId }) => this.sameProductDosageFormFactorFrequency(firstPf, productId, frequencyId, dosageId, formFactorId, checkFrequency));
    }
    /**
     * For a given list of productIds, it returns all products that are in the same bundle as them.
     * For example if [tretinoin, vaginal cream] is passed as param,
     * this function returns [m4, tretinoin, vaginal cream, omazing]
     *
     * Tests of this function are located in apps/api/productRegistry.test.ts
     *
     * @param productIds DeepProduct['productId'][]
     * @returns Promise<DeepProduct['productId'][]>
     */
    getBundleProductIds(productIds) {
        return __awaiter(this, void 0, void 0, function* () {
            const bundles = yield this.discountBundles;
            const allBundledProductIds = Object.keys(bundles).map((key) => Number(key));
            // get every product id from productIds that can be bundled
            let bundableProductIds = [];
            for (const productId of productIds) {
                if (yield this.canBeBundled(productId)) {
                    bundableProductIds.push(productId);
                }
            }
            // get parent ids for child id present in bundableProductIds
            const parentProductIds = (0, lodash_1.intersection)(allBundledProductIds, bundableProductIds).flatMap((productId) => bundles[productId].flatMap((b) => b.bundledParentProducts.parentIds));
            // get children ids for parent id present in bundableProductIds
            const childProductIds = allBundledProductIds.filter((productId) => {
                const parentExistsInBundle = bundles[productId].some((b) => (0, lodash_1.intersection)(b.bundledParentProducts.parentIds, bundableProductIds).length > 0);
                return parentExistsInBundle;
            });
            return (0, lodash_1.uniq)([...bundableProductIds, ...parentProductIds, ...childProductIds]);
        });
    }
    /**
     * from a `DeepProduct` we check formFactorType, dose and displayName props
     * to format and return the full name of it. if there's a display name,
     * we just append the dose, otherwise we format it with name + formFactor + dose.
     *
     * dose will only be considered if showDosage is true, otherwise it's ignored.
     *
     * @param p DeepProduct
     * @param showDosage boolean
     * @returns string
     */
    getProductFullName(p, showDosage = false) {
        const formFactor = p.formFactorType !== 'N/A' ? ` ${p.formFactorType}` : '';
        const dose = showDosage && p.dose !== 'N/A' ? ` ${p.dose}` : '';
        return p.displayName ? p.displayName + dose : p.name + formFactor + dose;
    }
    sameProductDosageFormFactorFrequency(pf, productId, frequencyId, dosageId, formFactor, checkFrequency = true) {
        return checkFrequency
            ? pf.productId === productId &&
                pf.frequencyId === frequencyId &&
                pf.dosageId === dosageId &&
                pf.formFactorId === formFactor
            : pf.productId === productId && pf.dosageId === dosageId && pf.formFactorId === formFactor;
    }
    /**
     * Returns true if there is more than one dose available
     * for the same product and form factor
     * as the given product frequency.
     *
     * @param productFrequency
     * @returns Promise<boolean>
     */
    hasAlternativeDoses(pf) {
        return __awaiter(this, void 0, void 0, function* () {
            const relatedProducts = (yield this.alloyProducts).filter((ap) => ap.productId === pf.productId &&
                ap.frequencyId === pf.frequencyId &&
                ap.formFactorId === pf.formFactorId);
            return relatedProducts.length > 1;
        });
    }
    /**
     * Returns true if there is more than one form factor available
     * for the same product as the given product frequency.
     *
     * @param productFrequency
     * @returns Promise<boolean>
     */
    hasAlternativeFormFactors(pf) {
        return __awaiter(this, void 0, void 0, function* () {
            const relatedProducts = (0, lodash_1.uniqBy)((yield this.alloyProducts).filter((ap) => ap.productId === pf.productId && ap.frequencyId === pf.frequencyId), 'formFactorId');
            return relatedProducts.length > 1;
        });
    }
    /**
     * Given one deep product, we check if it's a switchable products or not
     * (meaning: if it has another product with the same product id but different dosages AND/OR form factors)
     *
     * @param deepProduct
     * @returns
     */
    isSwitchableProduct(deepProduct) {
        return __awaiter(this, void 0, void 0, function* () {
            const allProducts = yield this.alloyProducts;
            return allProducts.some((ap) => ap.productId === deepProduct.productId &&
                (ap.dosageId !== deepProduct.dosageId || ap.formFactorId !== deepProduct.formFactorId));
        });
    }
    /**
     * Given a list of deep products from a bundle, it returns if the list
     * can be split in different bundle groups where every children has their own parent.
     *
     * Currently, the condition for it to be splittable is to be from the MHT category.
     *
     * eg.1:
     *  - [patch, prog] is a bundle and [pill, prog] is another bundle.
     *  - passing [patch, pill, prog] returns true because it turns into [[patch, prog], [pill, prog]]
     *
     * eg.2:
     * - [m4, tret] is a bundle and [m4 face serum, tret] is another bundle.
     * - passing [m4, m4 face serum, tret] returns false because it turns into [[m4, m4 face serum, tret]]
     *
     * Tests of this function are located in apps/api/productRegistry.test.ts
     *
     * @param deepProducts
     * @returns boolean
     */
    isBundleSplittableByParents(deepProducts) {
        return deepProducts.every((pf) => pf.category === 'mht');
    }
    /**
     * fetch all the switchable products we currently have in our database
     *
     * @returns
     */
    getSwitchableProducts() {
        return __awaiter(this, void 0, void 0, function* () {
            const allProducts = yield this.alloyProducts;
            const switchable = allProducts.filter((p) => {
                if (!!allProducts.some((dp) => dp.productId === p.productId &&
                    (p.dosageId !== dp.dosageId || p.formFactorId !== dp.formFactorId))) {
                    return p;
                }
            });
            return switchable;
        });
    }
    /**
     * Given a list of product ids, it returns the default product for each specified product id.
     * If there is only 1 instance of a product, it returns it as the default pf.
     * If there is more than 1 instance of a product, it returns all pfs where `isDefault` is true.
     * It throws an error if it fails to find the default pf for a product id.
     *
     * @param productIds
     * @returns Promise<DeepProduct>
     */
    getDefaultProductsByIds(productIds) {
        return __awaiter(this, void 0, void 0, function* () {
            const pfs = [];
            for (const productId of productIds) {
                const relatedPfs = (yield this.alloyProducts).filter((pf) => pf.productId === productId);
                if (relatedPfs.length === 1) {
                    pfs.push((0, lodash_1.first)(relatedPfs));
                    continue;
                }
                const defaultPfs = relatedPfs.filter((pf) => pf.isDefault);
                const defaultPfsByFormFactor = (0, lodash_1.uniqBy)(defaultPfs, 'formFactorId');
                if (defaultPfs.length !== defaultPfsByFormFactor.length) {
                    throw new Error(`More than one default form factor found for product id ${productId}`);
                }
                if (!defaultPfs.length) {
                    throw new Error(`Couldn't determine default pf for product id ${productId}`);
                }
                pfs.push(...defaultPfs);
            }
            return pfs;
        });
    }
    /**
     * Given a list of pf ids, it returns a list of pfs with correct bundling configuration
     *
     * @param deepProductIds DeepProduct['id'][]
     * @returns DeepProduct[][]
     */
    getDeepProductsFromIds(deepProductIds) {
        return __awaiter(this, void 0, void 0, function* () {
            const alloyProducts = yield this.alloyProducts;
            const pfs = alloyProducts.filter((pf) => deepProductIds.includes(pf.id));
            return this.getPricesFor(pfs);
        });
    }
}
exports.ProductRegistry = ProductRegistry;
