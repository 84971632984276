import { doctorIcon, moleculesIcon, ongoingIcon, truckIcon } from 'modules/shared/assets/svg';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';

const defaultPerks = [
  {
    icon: moleculesIcon,
    text: 'Science-backed ingredients'
  },
  {
    icon: truckIcon,
    text: 'Fast, Free Shipping'
  },
  {
    icon: ongoingIcon,
    text: 'Ongoing care and education'
  }
];

export default function SinglePageProductPerksWrapper() {
  const location = useLocation();

  const [perks, setPerks] = useState(defaultPerks);

  const categories = retrieveCategoriesFromUrl(location);
  const isSynbioticOnly = categories.every((c) => c === 'gut-health');

  useEffect(() => {
    if (!isSynbioticOnly) {
      setPerks([
        {
          icon: doctorIcon,
          text: 'Doctor-led care'
        },
        ...defaultPerks
      ]);
    }
  }, [isSynbioticOnly]);

  return (
    <div className='ce-single-page-product-perks-wrapper'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-8 offset-md-2'>
            <div className='perks-list'>
              {perks.map((perk, i) => (
                <div key={`perk-item-${i}`} className='product-perk'>
                  <img className='icon' src={perk.icon} alt={perk.text} />
                  <span className='perk-text'>{perk.text}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
