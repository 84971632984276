import './Discount.scss';

import { useEffect, useState } from 'react';

import { useGetTreatmentPlan } from 'client/dist/generated/alloy';

import useCart from 'modules/shared/hooks/useCart';

import { getGroupedCartTotals } from 'modules/shared/lib/cart';
import { getPromotionCodeForCart } from 'modules/shared/sub-modules/checkout/lib/promotion-code';

import closeIcon from 'shared/assets/svg/common/btn-close.svg';

import Form from 'shared/components/core/Form';
import { showSuccessNotification } from 'shared/components/core/Notification';
import { TextField } from 'shared/components/fields/TextField';

/**
 * Component that handles showing the promotion code, used within <OrderSummaryBlock />
 * Allows user to update their treatment plan cart with a promotion code
 */
export default function DiscountBlock() {
  const {
    data: treatmentPlan = {
      pendingCharges: {
        consultApproval: [],
        cancelRetentionApproval: [],
      },
    },
  } = useGetTreatmentPlan();

  const { cart, updateCart } = useCart();

  const [showDiscountInput, setShowDiscountInput] = useState<boolean>(false);
  const [promoCode, setPromoCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    try {
      initPromoCode();
    } catch (e) {
      setIsLoading(false);
    }
  }, []);

  // If there exists a promotion code in the pending charges then we need to grab it and apply to our cart appropriately
  const initPromoCode = async () => {
    const foundPromotionCode = treatmentPlan.pendingCharges.consultApproval.find(
      (pc) => pc.promotionCode,
    )?.promotionCode;

    if (foundPromotionCode) {
      setIsLoading(true);

      const { subtotal } = getGroupedCartTotals(cart);
      const cartStripeProductIds = cart.products.flatMap((p) =>
        p.alloyProduct.parent.map((par) => par.stripeProductId),
      );

      const promotionCode = await getPromotionCodeForCart(
        foundPromotionCode.code,
        subtotal,
        cartStripeProductIds,
      );

      updateCart({
        promotionCode,
      });

      setIsLoading(false);
    }
  };

  /**
   * Attempt to apply the promotion code to the treatment plan cart
   */
  const onApply = async () => {
    try {
      setIsLoading(true);

      const { subtotal } = getGroupedCartTotals(cart);
      const cartStripeProductIds = cart.products.flatMap((p) =>
        p.alloyProduct.parent.map((par) => par.stripeProductId),
      );

      const promotionCode = await getPromotionCodeForCart(
        promoCode,
        subtotal,
        cartStripeProductIds,
      );

      updateCart({
        promotionCode,
      });

      showSuccessNotification('Promo code successfully applied');

      setPromoCode('');
      setShowDiscountInput(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  /**
   * Remove the promotion code from the treatment plan cart
   */
  const onRemove = () => {
    updateCart({
      promotionCode: {
        id: '',
        name: '',
        discountAmount: 0,
        isReferral: false,
      },
    });

    showSuccessNotification('Promo code successfully removed');

    setPromoCode('');
    setShowDiscountInput(false);
  };

  /**
   * If a promotion code exists, show that to the user
   */
  if (cart.promotionCode.id !== '') {
    return (
      <div className='tp-discount-block'>
        <div className='discount-content'>
          <button onClick={onRemove} className='discount-close-btn'>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>

          <p className='discount-text'>Active Discount: {cart.promotionCode.name}</p>
        </div>
      </div>
    );
  }

  return (
    <div className='tp-discount-block'>
      {showDiscountInput ? (
        <div className='discount-content'>
          <button
            onClick={() => setShowDiscountInput(false)}
            className='discount-close-btn'
            disabled={isLoading}
          >
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>

          <div className='dicount-input-wrapper'>
            <Form onSubmit={onApply}>
              <TextField
                name='code'
                placeholder='Discount code'
                classes='discount-input'
                value={promoCode}
                onChange={(value) => setPromoCode(value.toUpperCase())}
                disabled={isLoading}
              />

              <button className='discount-btn' disabled={isLoading || promoCode === ''}>
                Apply
              </button>
            </Form>
          </div>
        </div>
      ) : (
        <button className='discount-link' onClick={() => setShowDiscountInput(true)}>
          Have a Promo Code?
        </button>
      )}
    </div>
  );
}
