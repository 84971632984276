import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  CheckoutType,
  DeepProduct,
  PreSubmission,
  ProductCategory,
  SubmissionAnswer,
  postSubmission,
  processProductSwitch,
  runSuggestions,
  useGetAllSubscriptionsForCustomer,
  useGetPendingSwitch,
} from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import { ExperienceCategory } from 'common/dist/models/experience';

import { useExperience } from 'modules/shared/context/experience';

import useCart from 'modules/shared/hooks/useCart';

import { collateHiddenSubmissionAnswers } from 'modules/questionnaire/lib/intake';
import { getPreviousResults } from 'modules/questionnaire/lib/results';
import { cleanPurchasableProducts } from 'modules/request-experience/lib/flow';
import {
  getIntakeCategories,
  retrieveCategoriesFromUrl,
} from 'modules/shared/lib/experience/experience';
import { retrieveFlowFromUrl } from 'modules/shared/lib/experience/experience-flow';
import { getGroupedQualifiedProducts } from 'modules/shared/lib/product';
import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';

import { QuestionnaireAnswers } from 'modules/questionnaire/models/questionnaire-answers';

import { useAppSelector } from 'shared/store/reducers';

export default function useIntakeState() {
  const location = useLocation();
  const navigate = useNavigate();

  const { onNext } = useExperience();
  const { updateCart } = useCart();

  const dateStarted = new Date();

  const flow = retrieveFlowFromUrl(location);
  const categories = retrieveCategoriesFromUrl(location);

  const intakeQuestions = flow.intakeQuestions!;

  const [previousResults, setPreviousResults] = useState<QuestionnaireAnswers>({});
  const [isLoading, setIsLoading] = useState(true);
  const [disqualifiedProducts, setDisqualifiedProducts] = useState<DeepProduct[]>([]);

  const customer = useAppSelector((state) => state.alloy.customer);
  const localPreCustomer = useAppSelector((state) => state.experience.localPreCustomer);
  const { cart } = useAppSelector((state) => state.experience);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();
  const { mutate: mutatePendingSwitch } = useGetPendingSwitch();

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const previousResults = getPreviousResults(intakeQuestions);

    setPreviousResults(previousResults);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (categories.every((c) => c === 'mht') && cart.products.length === 0) {
      navigate('/', { replace: true });
    }
  }, []);

  const onSubmit = async (answers: SubmissionAnswer[]) => {
    try {
      setIsLoading(true);

      const intakeCategories = getIntakeCategories(categories);
      const questions = flow.intakeQuestions!;

      const updatedAnswers = collateHiddenSubmissionAnswers(
        answers,
        questions,
        localPreCustomer,
        customer,
      );

      const submission: PreSubmission = {
        categories: intakeCategories,
        started: dateStarted,
        answers: updatedAnswers,
      };

      const { id } = await postSubmission(submission);

      // for switch flow, we need to handle the completion a little differently since it is a switch
      // process vs a direct request of a product
      if (searchParams.has('type') && searchParams.get('type') === 'switch') {
        await handleSwitchFlow(categories, id);
      } else {
        await handleNonSwitchFlow(id);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  // handles the product switch change processing
  const handleSwitchFlow = async (categories: ExperienceCategory[], submissionId: string) => {
    setIsLoading(true);

    const checkoutType = searchParams.get('checkoutType');
    const currentPfIds = searchParams.get('currentPfIds[]');
    const requestedPfIds = searchParams.get('requestedPfIds[]');
    const subscriptionId = searchParams.get('subscriptionId');

    // this shouldn't happen since we check for this to exist in url before even
    // getting into mht request experience
    if (!currentPfIds || !requestedPfIds || !checkoutType || !subscriptionId) {
      setIsLoading(false);
      return;
    }

    const convCurrentPfIds = currentPfIds.split(',').map((id) => Number(id));
    const convRequestedPfIds = requestedPfIds.split(',').map((id) => Number(id));

    await processProductSwitch({
      checkoutType: checkoutType as CheckoutType,
      currentPfIds: convCurrentPfIds,
      requestedPfIds: convRequestedPfIds,
      submissionId,
      subscriptionId: subscriptionId,
      categoryType: categories[0] as ProductCategory, // send the first in the array since switch is only for one category at a time
    });

    await mutatePendingSwitch();

    navigate({
      pathname: '/request-confirmation',
      search: `?${searchParams.toString()}`,
    });
  };

  // moved this out of the submit func for non mht

  /**
   * request-experience starts with 'default' products based on getEligibleUpsellProducts returned from productSerivce
   *
   * we pass different values to the cart between RE and CE because of the inital
   * product selection offered from each experience by which ensures appropriate dosages are selected
   */
  const handleNonSwitchFlow = async (submissionId?: string) => {
    // TODO: some suggestions for tret and others should be here
    if (submissionId) {
      const suggestions = await runSuggestions({ submissionId });

      const flowProducts = await ProductRegistry.get().getDefaultProductsByIds(flow.productIds);

      const fetchedProducts = await getGroupedQualifiedProducts(
        flowProducts,
        categories,
        submissionId,
      );

      if (fetchedProducts.flat().length === 0) {
        setDisqualifiedProducts(suggestions.disqualified.map((dq) => dq.product));
        setIsLoading(false);
        return;
      }

      updateCart({
        products: fetchedProducts.flat(),
      });
    }

    onNext();
  };

  return {
    isLoading,
    disqualifiedProducts,
    intakeQuestions,
    previousResults,
    onSubmit,
  };
}
