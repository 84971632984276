import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { useGetTreatmentPlan } from 'client/dist/generated/alloy';

import checkmarkIcon from 'modules/dashboard/sub-modules/home/assets/svg/checkmark-icon.svg';

import NotificationCardBlock from './NotificationCard';

export default function ApproveCardBlock() {
  const { data: treatmentPlan } = useGetTreatmentPlan();

  return (
    <NotificationCardBlock
      hugText='action required'
      isRequired
      title='Approve your treatment plan'
      description={
        treatmentPlan?.messagingExpiresAt
          ? `Your doctor has finished reviewing your intake. Approve your treatments by ${format(
              new Date(treatmentPlan?.messagingExpiresAt),
              'MMM d',
            )} in order to maintain access to your doctor.`
          : 'Your doctor has finished reviewing your intake. Approve your treatments in order to maintain access to your doctor.'
      }
      icon={checkmarkIcon}
    >
      <Link to='/treatment-plan' className='primary-button mt-3'>
        View your treatment plan
      </Link>
    </NotificationCardBlock>
  );
}
