import { doctorIcon, medicineIcon, ongoingIcon, truckIcon } from 'modules/shared/assets/svg';
import { IconText } from 'shared/models/icon-text';

export const subscriberBenefits: IconText[] = [
  {
    icon: doctorIcon,
    text: 'Menopause-specialized doctors',
  },
  {
    icon: medicineIcon,
    text: 'Medicine delivered to your door',
  },
  {
    icon: truckIcon,
    text: 'Free shipping • Cancel anytime',
  },
  {
    icon: ongoingIcon,
    text: 'Ongoing care and education',
  },
];
