import { ExperienceCategory } from 'common/dist/models/experience';
import { SharedLocation } from 'common/dist/models/shared/location';

/**
 * passing a base url string that you want to redirect someone
 * and the actual location this person is in
 * and a key, value list of the query params you want to add to it
 * it returns an URL formatted with the query params you want to
 *
 * @param newUrl new base url you want to redirect
 * @param location SharedLocation, so if someone passes `test` to it we can capture
 * @param params an key, value list of the params
 *
 * @returns the URL formatted with the query params
 */
export const formatURL = (
  newUrl: string,
  location: SharedLocation,
  params: string[][] = []
): string => {
  const searchParams = new URLSearchParams(location.search);

  params.forEach(([key, value]) => {
    // set the one that we're passing in the code to handle correctly
    searchParams.set(key, value);
  });

  /**
   * next path for checkout experience does not need to exist once the customer is logged in
   */
  if (searchParams.has('nextPath')) {
    searchParams.delete('nextPath');
  }

  /**
   * flow id for checkout experience does not need to exist anymore
   */
  if (searchParams.has('flowId')) {
    searchParams.delete('flowId');
  }

  // if no params, just return the url
  if (!Array.from(searchParams.values()).length) {
    return newUrl;
  }

  // return new url with query params
  return (newUrl += `?${searchParams.toString()}`);
};

/**
 * Formtting the checkout experience includes breaking down the categories for the search params.
 * The reason we pass 'categories[]' as so is because we need to show t the search params that it is
 * an array. we also join the categories into a string by ',' allowing it to work within the url.
 *
 * There was another route of passing individual 'category' (ie '?category=mht&category=skin-health') but this
 * could create an issue with potentially losing categories and makes the url messy
 *
 * Just having 'categories[]' keeps it clean and easy to understand for the developer
 *
 * Note: Once the url is formatted, you will see '%5B%5D' which means '[]' and also '%2' which is ','
 * That is the format that search params has
 *
 * @param endPath string
 * @param location SharedLocation
 * @param categories ExperienceCategory[]
 * @param params string[][]
 * @returns string
 */
export const formatExperienceURL = (
  url: string,
  location: SharedLocation,
  categories: ExperienceCategory[] = ['mht'],
  params: string[][] = []
): string => formatURL(url, location, [['categories[]', categories.join(',')], ...params]);

/**
 * Builds a login url that redirects to checkout experience for after logging in
 *
 * @param location SharedLocation
 * @returns string
 */
export const buildCheckoutExperienceLoginURL = (location: SharedLocation): string => {
  const searchParams = new URLSearchParams(location.search);

  searchParams.append('nextPath', 'checkout-experience');

  return '/login?' + searchParams.toString();
};

/**
 *
 *
 * @param location SharedLocation
 * @returns ExperienceCategory[]
 */
export const getCategoriesFromFlowId = (location: SharedLocation): ExperienceCategory[] => {
  const searchParams = new URLSearchParams(location.search);
  const potentialFlowId = searchParams.get('flowId');

  if (!potentialFlowId) {
    return [];
  }

  // all old flow ids and having them be converted to categories
  const flowIdsToCategories: { [key: string]: ExperienceCategory } = {
    recommendation: 'mht',
    consult: 'mht',
    m4: 'skin-health',
    skin: 'skin-health',
    omazing: 'sexual-health',
    vaginalcream: 'vaginal-health',
    otc: 'gut-health',
    renewal: 'renewal',
    hair: 'hair-health',
  };

  return Object.entries(flowIdsToCategories)
    .filter(([key]) => potentialFlowId === key)
    .map(([_, value]) => value);
};

export const shouldSkipCategorySelect = (location: SharedLocation): boolean => {
  const searchParams = new URLSearchParams(location.search);

  return (
    searchParams.has('skip-category-select') && searchParams.get('skip-category-select') === 'true'
  );
};
