import classNames from 'classnames';

import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import checkedBox from 'shared/assets/svg/common/checked-box-icon.svg';
import uncheckedBox from 'shared/assets/svg/common/unchecked-box-icon.svg';

interface Props {
  isSelected: boolean;
  onSelect: (groupedProduct?: GroupedContentfulProduct) => void;
  title?: string;
  text?: string;
}

export default function TextSelectableProduct({
  isSelected,
  onSelect,
  title = "I'm not sure",
  text = 'Your doctor will work with you to find the treatments right for you.',
}: Props) {
  return (
    <div
      className={classNames('selectable-product', isSelected ? 'selected' : '')}
      onClick={() => onSelect()}
    >
      <div className={classNames('product-header', 'no-margin')}>
        <div className='header-content'>
          <div className={classNames('header-text-wrapper', 'no-margin')}>
            <p className='header-title'>{title}</p>

            <p className='header-text'>{text}</p>
          </div>
        </div>

        <img
          src={isSelected ? checkedBox : uncheckedBox}
          alt='selectable box'
          className='header-box-btn'
        />
      </div>
    </div>
  );
}
