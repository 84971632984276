import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';
import Layout from 'shared/components/core/Layout';
import ResetRequiredFormWrapper from 'modules/authentication/sub-modules/reset-required/ui/wrappers/ResetRequiredForm';

export default function ResetRequiredPage() {
  return (
    <Layout title='Reset Password - Alloy' desc='Reset your password for your Alloy account' noBars>
      <TopBannerWithProgress />

      <ResetRequiredFormWrapper />
    </Layout>
  );
}
