import ProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { useEffect, useState } from 'react';

export default function useUpsellProducts() {
  const [products, setProducts] = useState<GroupedContentfulProduct[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);

      const fetchedProducts = (
        await ProductRegistry.get().getIndividualGroupedContentfulProductsFor('upsell')
      ).flat();

      setProducts(fetchedProducts);
      setIsLoading(false);
    };

    fetchProducts();
  }, []);

  return { isLoading, products };
}
