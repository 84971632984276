import './TPReview.scss';

import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import {
  getRelevantReview,
  getReviewTheme,
} from 'modules/ab-tests/sub-modules/checkout-review/lib/relevant-review';
import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';

export default function TPReviewBlock() {
  const ref = useRef(null);

  const location = useLocation();
  const categories = retrieveCategoriesFromUrl(location);
  const review = getRelevantReview(categories);
  const theme = getReviewTheme(categories);

  /**
   * Checks if the Trustpilot bootstrap script is loaded
   * Once it is loaded, it will load the Trustpilot widget from the element
   * This is a common practice when working with third-party scripts
   */
  useEffect(() => {
    if ((window as any).Trustpilot) {
      (window as any).Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  if (!review) {
    return <></>;
  }
  const { name, title, text, url } = review;

  const isLight = theme === 'light';

  return (
    <div className={classNames('tp-review', isLight && theme)}>
      <div
        ref={ref}
        className='trustpilot-widget'
        data-locale='en-US'
        data-template-id='53aa8807dec7e10d38f59f32'
        data-businessunit-id='61b244919580764bc1d1b631'
        data-style-height='120px'
        data-style-width='205px'
        data-theme={theme}
      >
        <a href='https://www.trustpilot.com/review/myalloy.com' target='_blank' rel='noopener'>
          Trustpilot
        </a>
      </div>
      {title && <h6 className='review-title'>{title}</h6>}
      {text && <p className='review-body'>{text}</p>}
      {name && url && (
        <div className='review-source'>
          <p>{name} on </p>
          <a href={url} target='_blank'>
            {' '}
            Trustpilot
          </a>
        </div>
      )}
    </div>
  );
}
