import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'shared/store/reducers';

interface Props {
  comp: () => JSX.Element;
  path?: string;
}

const AuthRoute = ({ comp: Component }: Props) => {
  const location = useLocation();

  const isAuthenticated = useAppSelector((state) => state.alloy.isAuthenticated);
  const customer = useAppSelector((state) => state.alloy.customer);

  if (isAuthenticated === false || typeof customer === null) {
    return <Component />;
  } else {
    return (
      <Navigate
        to={{
          pathname: '/',
          search: location.search,
        }}
      />
    );
  }
};

const PrivateRoute = ({ comp: Component, path }: Props) => {
  const location = useLocation();

  const isAuthenticated = useAppSelector((state) => state.alloy.isAuthenticated);
  const customer = useAppSelector((state) => state.alloy.customer);

  if (isAuthenticated === false || typeof customer === null) {
    const searchParams = new URLSearchParams(location.search);

    if (path !== '/') {
      searchParams.set('nextPath', location.pathname.replace('/', ''));
    }

    return <Navigate to={{ pathname: '/login', search: searchParams.toString() }} />;
  } else {
    return <Component />;
  }
};

export { AuthRoute, PrivateRoute };
