import { first } from 'lodash';
import { useEffect, useState } from 'react';

import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { getProductPrice } from 'modules/shared/lib/product/pricing';

import { getPriceForCombinedProducts } from '../lib/product';

interface Props {
  groupedProduct: GroupedContentfulProduct;
  parents?: GroupedContentfulProduct[];
}

export default function useProductPrice({ groupedProduct, parents = [] }: Props) {
  // price in regular format
  const [unbundledPrice, setUnbundledPrice] = useState(0);
  const [bundledPrice, setBundledPrice] = useState(0);

  useEffect(() => {
    getPricing();
  }, [parents]);

  const getPricing = async () => {
    const product = first(groupedProduct.alloyProduct.parent)!;

    if (product) {
      const parentProducts = parents.flatMap((p) => p.alloyProduct.parent);

      const [unbundledPriceInCents, bundledPriceInCents] = await Promise.all([
        getProductPrice(product),
        getProductPrice(product, parentProducts),
      ]);

      setUnbundledPrice(unbundledPriceInCents / 100);
      setBundledPrice(bundledPriceInCents / 100);
    } else {
      setUnbundledPrice(getPriceForCombinedProducts(groupedProduct));
    }
  };

  return {
    unbundledPrice,
    bundledPrice,
  };
}
