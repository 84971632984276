import { Dispatch } from '@reduxjs/toolkit';

import {
  Customer,
  getCustomer,
  licenseExistsByCustomer,
  updatePartialSubmission,
} from 'client/dist/generated/alloy';
import { getItem } from 'client/dist/localstorage';

import { getAlertBanner, getMessagesBanner } from 'modules/shared/lib/contentful';
import { brazeInit, brazeSetPartial, identifyBraze } from 'modules/tracking/lib/braze';
import { correlateFreshpaintToCustomer, identifyFreshpaint } from 'modules/tracking/lib/freshpaint';
import { identifyPosthog, posthogLoaded } from 'modules/tracking/lib/posthog';
import { identifySentry, sendExceptionToSentry, sentryInit } from 'modules/tracking/lib/sentry';

import { currentSession } from 'shared/lib/aws/aws-auth';

import {
  CUSTOMER,
  IS_APP_LOADED,
  IS_AUTHENTICATED,
  LICENSE_EXISTS,
  LOCAL_USER,
  MESSAGES_BANNER,
  REQUESTING_PRODUCTS,
  TOP_ALERT_BANNER,
} from 'shared/store/actions/GlobalTypes';

/**
 *
 * @returns {(function(*): Promise<any|{}|undefined>)|*}
 */
const initializeApp = () => {
  return async (dispatch: Dispatch) => {
    brazeInit();
    sentryInit();

    try {
      const [userCurrentSession, topAlertBanner, messagesBanner, _] = await Promise.all([
        currentSession(),
        getAlertBanner(),
        getMessagesBanner(),
        posthogLoaded(),
      ]);

      dispatch({ type: TOP_ALERT_BANNER, payload: topAlertBanner });
      dispatch({ type: MESSAGES_BANNER, payload: messagesBanner });

      if (!userCurrentSession?.isValid()) {
        dispatch({ type: IS_AUTHENTICATED, payload: false });
        dispatch({ type: IS_APP_LOADED });
        return;
      }

      const [alloyCustomer, licenseExists] = await Promise.all([
        getCustomer(),
        licenseExistsByCustomer(),
      ]);

      postIdentification(alloyCustomer);

      dispatch({ type: CUSTOMER, payload: alloyCustomer });

      dispatch({ type: LICENSE_EXISTS, payload: licenseExists });

      dispatch({ type: IS_AUTHENTICATED, payload: true });

      dispatch({ type: IS_APP_LOADED });

      return alloyCustomer;
    } catch (error) {
      dispatch({ type: IS_AUTHENTICATED, payload: false });

      dispatch({ type: IS_APP_LOADED });

      sendExceptionToSentry(error as Error);

      return {};
    }
  };
};

/**
 * Once we've "identified" the user we need to
 * * Update tracking vendors Logrocket, freshpaint, sentry, and braze
 * * Update our database so we can link preidentification med intake to alloy customer
 *
 * Leaving here for now with the initializeApp
 */
const postIdentification = async (customer: Customer, submissionId?: string) => {
  try {
    if (submissionId) {
      await updatePartialSubmission({ id: submissionId, customerId: customer.id });
    }

    identifyFreshpaint(customer);
    correlateFreshpaintToCustomer();

    identifyBraze(customer);

    brazeSetPartial(customer);

    identifySentry(customer);

    identifyPosthog(customer);
  } catch (error) {
    throw error;
  }
};

export { initializeApp, postIdentification };
