import { ContentfulPrescribingDoctor } from 'shared/models/contentful/doctor';
import { ContentfulSymptom } from 'shared/models/contentful/symptom';

import { LOADING_TOTALS, PRESCRIBING_DOCTOR, SYMPTOMS } from 'shared/store/actions/GlobalTypes';

export interface TreatmentPlanState {
  customerSymptoms: ContentfulSymptom[];
  prescribingDoctor?: ContentfulPrescribingDoctor;

  isLoadingTotals: boolean;
}

const INIT_STATE: TreatmentPlanState = {
  customerSymptoms: [],

  isLoadingTotals: false,
};

// Create reducer
export default function TreatmentPlanReducer(
  state = INIT_STATE,
  action: { payload: Partial<TreatmentPlanState>; type: string },
) {
  switch (action.type) {
    case SYMPTOMS: {
      return { ...state, customerSymptoms: action.payload };
    }

    case PRESCRIBING_DOCTOR: {
      return { ...state, prescribingDoctor: action.payload };
    }

    case LOADING_TOTALS: {
      return { ...state, isLoadingTotals: action.payload };
    }

    default:
      return state;
  }
}
