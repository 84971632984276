import { useExperience } from 'modules/shared/context/experience';

import ExpectationsInfoWrapper from 'modules/checkout-experience/sub-modules/expectations/ui/wrappers/ExpectationsInfo';

import Layout from 'shared/components/core/Layout';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

export default function ExpectationsPage() {
  const { onBack } = useExperience();

  return (
    <Layout title='Expectations - Alloy' desc='' noBars>
      <TopBannerWithProgress onBack={onBack} />

      <ExpectationsInfoWrapper />
    </Layout>
  );
}
