import './Bundled.scss';

import { xor } from 'lodash';
import { useState } from 'react';

import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { useExperience } from 'modules/shared/context/experience';

import useCart from 'modules/shared/hooks/useCart';

import { cleanPurchasableProducts } from 'modules/request-experience/lib/flow';
import {
  getDeepProductsFromGroupedProducts,
  getProductsToBeBundledWith,
} from 'modules/shared/lib/product';
import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';
import { sendExceptionToSentry } from 'modules/tracking/lib/sentry';

import BundleSelectableProduct from 'modules/shared/ui/blocks/BundleSelectableProduct';
import SelectableProduct from 'modules/shared/ui/blocks/SelectableProduct';

import { useAppSelector } from 'shared/store/reducers';

import './Bundled';

import ReviewLayoutWrapper from '../wrappers/ReviewLayout';

export default function BundledContent() {
  const { onBack, onNext } = useExperience();
  const { cart, updateCart } = useCart();

  const { isAuthenticated } = useAppSelector((state) => state.alloy);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer({
    swr: {
      enabled: isAuthenticated,
    },
  });

  const { activeSubs } = getSubscriptionsWithStatus(subscriptions);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedDeepProductIds, setSelectedDeepProductIds] = useState<number[]>(
    getDeepProductsFromGroupedProducts(cart.products).map((pf) => pf.id),
  );

  const onSelect = (groupedProduct: GroupedContentfulProduct) => {
    setSelectedDeepProductIds(
      xor(
        selectedDeepProductIds,
        groupedProduct.alloyProduct.parent.map((pf) => pf.id),
      ),
    );
  };

  const areParentsSelected = (parents: GroupedContentfulProduct[]) => {
    return selectedDeepProductIds.some((pfId) =>
      parents.some((gcp) => gcp.alloyProduct.parent.every((pf) => pf.id === pfId)),
    );
  };

  const onContinue = async () => {
    try {
      setIsLoading(true);

      const products = (
        await ProductRegistry.get().getDeepProductsFromIds(selectedDeepProductIds)
      ).flat();
      const activeSubscriptionProducts = activeSubs.flatMap((sub) =>
        sub.products.map((pfr) => pfr.product),
      );

      const cleanedProducts = await cleanPurchasableProducts(products, activeSubscriptionProducts);

      updateCart({ products: cleanedProducts });

      onNext();
    } catch (error) {
      setIsLoading(false);
      sendExceptionToSentry(error as Error);
    }
  };

  return (
    <ReviewLayoutWrapper
      title={cart.products.flat().length === 1 ? 'Your treatment' : 'Your treatments'}
      isLoading={isLoading}
      onBack={onBack}
      isContinueDisabled={selectedDeepProductIds.length === 0}
      onContinue={onContinue}
    >
      <div className='bundled-products-wrapper'>
        {cart.products.map((gcp, index) => {
          const isSelected = selectedDeepProductIds.some((pid) =>
            gcp.alloyProduct.parent.every((pf) => pf.id === pid),
          );
          const parents = getProductsToBeBundledWith(gcp, [cart.products]);

          return !!parents.length ? (
            <BundleSelectableProduct
              key={gcp.contentfulProduct.sys.id + '-' + index}
              groupedProduct={gcp}
              isSelected={isSelected}
              onSelect={onSelect}
              isParentSelected={areParentsSelected(parents)}
              parents={parents}
            />
          ) : (
            <SelectableProduct
              key={gcp.contentfulProduct.sys.id + '-' + index}
              groupedProduct={gcp}
              isSelected={isSelected}
              onSelect={onSelect}
              multiSelect
            />
          );
        })}
      </div>
    </ReviewLayoutWrapper>
  );
}
