import { ContentfulReview } from 'shared/models/contentful/reviews';

export default function formatReviewCards(reviews: ContentfulReview[]): ContentfulReview[] {
  return reviews.map((review) => {
    const { content, customerName, link } = review;
    return {
      content,
      customerName,
      link,
    };
  });
}
