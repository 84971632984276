import Loader from 'shared/components/content/Loader';
import QuestionnaireWrapper from 'modules/questionnaire/ui/wrappers/Questionnaire';
import { useMemo } from 'react';
import useDoctorSurveyState from '../../hooks/useDoctorSurveyState';

export default function DoctorQuestionnaireWrapper() {
  const {
    isLoading,

    doctor,
    questions,

    onBack,
    onSubmit,
  } = useDoctorSurveyState();

  const SurveyIntro = useMemo(() => {
    const doctorName = doctor?.fields.name;
    return (
      <>
        Thank you for taking the time to provide feedback on your most recent doctor interaction
        through Alloy! Please answer the following questions based on your experience with
        {doctorName ? <span className='doctor-name'> {doctorName}</span> : ' your doctor'}:
      </>
    );
  }, [doctor]);

  if (isLoading) return <Loader />;

  return (
    <QuestionnaireWrapper
      questions={questions}
      previousResults={{}}
      onSubmit={onSubmit}
      onBack={onBack}
      hideProgressBar
      basePath='/doctor-survey'
      metaTitle='Doctor Survey | Alloy'
      introduction={SurveyIntro}
    />
  );
}
