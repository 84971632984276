import { Modal } from 'rsuite';

import playIcon from 'modules/checkout-experience/sub-modules/order-confirmation/assets/images/play-icon.png';

import HeardAboutUsBlock from 'modules/checkout-experience/sub-modules/order-confirmation/ui/blocks/HeardAboutUs';
import HeaderBlock from 'modules/dashboard/sub-modules/treatment-plan/ui/blocks/Header';
import MessageBlock from 'modules/dashboard/sub-modules/treatment-plan/ui/blocks/Message';
import ProgressBlock from 'modules/dashboard/sub-modules/treatment-plan/ui/blocks/Progress';
import ReferralBlock from 'modules/dashboard/sub-modules/treatment-plan/ui/blocks/Referral';

import TopBlogBlock from 'modules/dashboard/ui/blocks/TopBlog';
import ViewableProduct from 'modules/shared/ui/blocks/ViewableProduct';

import InfoSection from 'modules/dashboard/ui/wrappers/InfoSection';
import TestimonialsSection from 'modules/dashboard/ui/wrappers/TestimonialsSection';

import { EmailUs } from 'shared/components/content/Buttons';
import SectionWrapper from 'shared/components/wrappers/Section';

import isNarrowScreen from 'shared/lib/narrow-screen';

import {
  CONSULT_CONFIRMATION_DESKTOP_THUMBNAIL,
  CONSULT_CONFIRMATION_DESKTOP_URL,
  CONSULT_CONFIRMATION_MOBILE_THUMBNAIL,
  CONSULT_CONFIRMATION_MOBILE_URL,
} from '../../data/confirmation';
import useOrderConfirmationState from '../../hooks/useOrderConfirmationState';

export default function OrderConfirmationInfoWrapper() {
  const {
    customer,
    cart,
    checkout,

    isOtcCheckout,
    isConsultCheckout,

    showModal,
    setShowModal,

    showVideo,
    setShowVideo,

    onViewTreatmentPlan,
  } = useOrderConfirmationState();

  return (
    <>
      <HeaderBlock
        title={isOtcCheckout ? 'Your order is' : 'Welcome to Alloy'}
        fancyTitle={isOtcCheckout ? 'confirmed' : (customer?.firstName ?? '')}
        backgroundType='secondary'
      />

      <SectionWrapper sectionClasses='order-confirmation-section'>
        {checkout && <div className='fp-checkout-id' data-checkout-id={checkout.id} />}

        <div className='col-12 col-md-7'>
          {isOtcCheckout ? (
            <>
              {cart.products.map((gcp, index) => (
                <ViewableProduct
                  key={index}
                  groupedProduct={gcp}
                  showDosage
                  hideDetails
                  hidePrice
                />
              ))}
            </>
          ) : (
            <>
              <ProgressBlock
                title="You've taken your first step. Here's what's next."
                description='Your doctor will review your health history, messages, and requests to create a treatment plan for you. Usually within 1-3 days.'
                barTitles={
                  isConsultCheckout
                    ? ['Complete intake', 'Doctor review', 'Confirm your plan', 'Ship treatments']
                    : ['Complete intake', 'Doctor review', 'Ship treatments']
                }
                currentIndex={1}
                hideButton
              />

              {isConsultCheckout ? (
                <MessageBlock text="Your doctor will be in touch within 2 business days about your treatment plan. We'll send you a notification by email and text." />
              ) : (
                <MessageBlock />
              )}
            </>
          )}

          <HeardAboutUsBlock />

          <p className='confirmation-text'>
            You'll receive an order confirmation email shortly at{' '}
            <span className='text-bold'>{customer.email}</span>.
          </p>

          <p className='confirmation-text'>
            Any questions about your order at all, email us at <EmailUs btnClass='text-bold' />.
            We're here for you!
          </p>

          {isOtcCheckout && (
            <button className='primary-button my-3 full-width-button' onClick={onViewTreatmentPlan}>
              View treatment plan
            </button>
          )}
        </div>

        <div className='col-12 col-md-5'>
          <ReferralBlock />
          <TopBlogBlock />
        </div>
      </SectionWrapper>

      <InfoSection />
      <TestimonialsSection />

      <Modal
        open={showModal}
        overflow={true}
        size='md'
        className='messages-video'
        onClose={() => setShowModal(false)}
      >
        <Modal.Header />
        <Modal.Body className='text-center'>
          <h4 className='title'>Meet Dr. Menn</h4>

          {showVideo ? (
            <video controls autoPlay>
              <source
                src={
                  isNarrowScreen()
                    ? CONSULT_CONFIRMATION_MOBILE_URL
                    : CONSULT_CONFIRMATION_DESKTOP_URL
                }
                type='video/mp4'
              />
              Your browser does not support videos
            </video>
          ) : (
            <div className='messages-video-thumbnail' onClick={() => setShowVideo(true)}>
              <div className='messages-video-block'>
                <img
                  src={
                    isNarrowScreen()
                      ? CONSULT_CONFIRMATION_MOBILE_THUMBNAIL
                      : CONSULT_CONFIRMATION_DESKTOP_THUMBNAIL
                  }
                  alt={`dr menn thumbnail`}
                  className='img-fluid messages-thumbnail-img'
                />
                <img src={playIcon} alt='Play icon' className='play-icon' />
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
