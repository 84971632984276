import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getMdiWidget } from 'client/dist/generated/alloy';

import { updateMdiWidget } from 'modules/shared/store/customer';

import BannerWrapper from 'shared/components/wrappers/Banner';

import { useAppSelector } from 'shared/store/reducers';

/**
 * only used in the messages tab for when we need to communicate certain copy to customers
 * via the messages tab when things could be down, some specific info, etc
 */
export default function MessagesBannerWrapper() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { messagesBanner } = useAppSelector((state) => state.alloy);

  const dispatchUpdateMdiWidget = bindActionCreators(updateMdiWidget, dispatch);

  useEffect(() => {
    fetchWidget();
  }, [pathname]);

  const fetchWidget = async () => {
    try {
      const widget = await getMdiWidget();
      dispatchUpdateMdiWidget(widget);
    } catch (e) {
      dispatchUpdateMdiWidget({ status: 'PENDING' });
    }
  };

  const mdiWidget = useAppSelector((state) => state.alloy.mdiWidget);

  if (
    mdiWidget.status === 'INACTIVE' ||
    !messagesBanner?.fields.isDashboardOn ||
    pathname.includes('-confirmation')
  ) {
    return <BannerWrapper />;
  }

  return <BannerWrapper overrideBanner={messagesBanner} />;
}
