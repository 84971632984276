import { Dispatch } from '@reduxjs/toolkit';

import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { AlloyCart } from 'modules/shared/models/cart';

import { ALLOY_CART } from 'shared/store/actions/GlobalTypes';
import { RootState } from 'shared/store/reducers';

const create = (products: GroupedContentfulProduct[]) => {
  return (dispatch: Dispatch) => {
    const cart = {
      products: [...products],
      promotionCode: {
        id: '',
        name: '',
        discountAmount: 0,
      },
      taxAmountToCollect: 0,
      referralCreditBalance: 0,
      agreement: {
        isAgreedTerms: false,
        isOptedSmsMarketing: true,
      },
      shipNow: true,
    };

    dispatch({ type: ALLOY_CART, payload: cart });
  };
};

const update = (updatingCart: Partial<AlloyCart>) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const updated = {
      ...getState().experience.cart,
      ...updatingCart,
    };

    dispatch({ type: ALLOY_CART, payload: updated });
  };
};

const clear = () => {
  return (dispatch: Dispatch) => {
    const cart = {
      products: [],
      promotionCode: {
        id: '',
        name: '',
        discountAmount: 0,
        isReferral: false,
      },
      taxAmountToCollect: 0,
      referralCreditBalance: 0,
      agreement: {
        isAgreedTerms: false,
        isOptedSmsMarketing: true,
      },
      shipNow: true,
    };

    dispatch({ type: ALLOY_CART, payload: cart });
  };
};

export { clear, create, update };
