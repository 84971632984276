import { Input } from 'rsuite';
import { cleanWhitespace } from 'shared/lib/clean-whitespace';

interface Props {
  name: string;
  label?: string;
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  classes?: string;
}

export const EmailField = ({
  name,
  label,
  value,
  placeholder,
  onChange,
  classes = 'field-input',
}: Props) => (
  <div className='input-field-wrapper'>
    {label && <p className='field-label'>{label}</p>}

    <Input
      name={name}
      placeholder={placeholder}
      className={classes}
      value={value}
      onChange={(v) => onChange(cleanWhitespace(v.toLowerCase()))}
      autoComplete='email'
    />
  </div>
);
