import { Dispatch } from '@reduxjs/toolkit';

import { MdiWidget, patchCustomer } from 'client/dist/generated/alloy';

import { brazeSetPartial } from 'modules/tracking/lib/braze';

import { transformDateString } from 'shared/lib/date';
import { formatPhone } from 'shared/lib/phone';

import { CUSTOMER, LICENSE_EXISTS, MDI_WIDGET } from 'shared/store/actions/GlobalTypes';

import { ProfileUpdates } from '../models/profile-updates';

/**
 *
 * Given the current alloy user and the selected profile attribute updates,
 * update the alloy user.
 *
 * this also sets some user properties on braze based in the updates made to the database
 *
 * @param profileUpdates ProfileUpdates - fields that are to be updated for customer
 * @returns Customer - updated customer
 */
const updateCustomer = (profileUpdates: ProfileUpdates) => {
  return async (dispatch: Dispatch) => {
    const saved = await patchCustomer({
      ...profileUpdates,
      dateOfBirth: profileUpdates.dateOfBirth
        ? new Date(transformDateString(profileUpdates.dateOfBirth, 'YYYY-MM-DD')!)
        : undefined,
      phoneNumber: profileUpdates.phoneNumber ? formatPhone(profileUpdates.phoneNumber) : undefined
    });

    // this only gets invoked if the previous await was successful
    brazeSetPartial(saved);

    dispatch({ type: CUSTOMER, payload: saved });

    return saved;
  };
};

const updateMdiWidget = (widget: MdiWidget) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: MDI_WIDGET, payload: widget });
  };
};

const updateLicenseExists = (exists: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: LICENSE_EXISTS, payload: exists });
  };
};

export { updateCustomer, updateLicenseExists, updateMdiWidget };
