import 'shared/styles/alloy.scss';

import { SnackbarProvider, SnackbarProviderProps } from 'notistack';
import { PostHogProvider } from 'posthog-js/react';

import AppRouter from 'modules/routes/ui/wrappers/AppRouter';

import { AlloySnackbar } from 'shared/components/core/AlloySnackbar';

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  ui_host: 'https://app.posthog.com',
};

/**
 * default config for snackbars when displayed, if an individ snackbar needs different
 * options such as persisting or positioning, pass options when calling enqueueSnackbar()
 */

const snackbarConfig: SnackbarProviderProps = {
  maxSnack: 5,
  autoHideDuration: 5000,
  anchorOrigin: { horizontal: 'right', vertical: 'top' },
  preventDuplicate: true,
  Components: {
    success: AlloySnackbar,
    warning: AlloySnackbar,
    info: AlloySnackbar,
    error: AlloySnackbar,
  },
};

const App = () => {
  return (
    <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
      <SnackbarProvider {...snackbarConfig}>
        <AppRouter />
      </SnackbarProvider>
    </PostHogProvider>
  );
};

export default App;
