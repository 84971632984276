import AddOnPreferencesFormWrapper from 'modules/checkout-experience/sub-modules/add-on-preferences/ui/wrappers/AddOnPreferencesForm';

import Layout from 'shared/components/core/Layout';

export default function AddOnPreferencesPage() {
  return (
    <Layout title='Add On Preferences - Alloy' desc='' noBars>
      <AddOnPreferencesFormWrapper />
    </Layout>
  );
}
