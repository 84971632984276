import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { register } from 'modules/authentication/sub-modules/register/lib/actions';
import { getPersonalizedContent } from 'modules/checkout-experience/lib/personalized-content';
import { retrieveCategoriesFromUrl } from 'modules/shared/lib/experience/experience';
import { buildCheckoutExperienceLoginURL } from 'modules/shared/lib/url';

import { ExperienceComponentModel } from 'modules/shared/models/experience';

import { showErrorNotification } from 'shared/components/core/Notification';

import { useAppSelector } from 'shared/store/reducers';

import { RegisterForm } from '../models/register-form';

const INIT_REGISTER_FORM: RegisterForm = {
  email: '',
  password: '',
  marketingEmailConsent: false,
  checksSucceeded: false
};

export default function useRegisterState({ onBack, onNext }: ExperienceComponentModel) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isAuthenticated = useAppSelector((state) => state.alloy.isAuthenticated);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [registerForm, setRegisterForm] = useState<RegisterForm>(INIT_REGISTER_FORM);

  const dispatchRegister = bindActionCreators(register, dispatch);

  useEffect(() => {
    if (isAuthenticated && onBack) {
      onBack();
    }
  }, []);

  const categories = retrieveCategoriesFromUrl(location);

  const content = getPersonalizedContent(categories, 'register');

  // Submit the login and if needed to change password, submit the new password changed
  const onSubmit = async () => {
    window.scrollTo(0, 0);

    try {
      const { email, password } = registerForm;

      setIsLoading(true);

      // TODO - AWS call, diff. error handling needed
      await dispatchRegister(email, password);

      onNext();
    } catch (error) {
      const errorMessage = (error as Error).message;

      if (
        errorMessage &&
        (errorMessage.includes('An account with the given email already exists.') ||
          errorMessage.includes('Customer already exists with email'))
      ) {
        navigate(buildCheckoutExperienceLoginURL(location));
        showErrorNotification('An account with the given email already exists, please login.');
      } else {
        setIsLoading(false);
        showErrorNotification(errorMessage);
      }
    }
  };

  return {
    isLoading,

    content,

    registerForm,
    setRegisterForm,

    onSubmit
  };
}
