export interface TrustpilotReview {
  title: string;
  text: string;
  name: string;
  url: string;
  categories: string[];
}

export const CheckoutReviews: TrustpilotReview[] = [
  {
    title: 'There for you at every step.',
    text: 'My doctor moved, my prescription lapsed, night sweats came back and I thought I was starting from square one. But then I found Alloy…There for me at every step. I could chat with a doctor, quick delivery, hot flashes gone. So very grateful.',
    name: 'Customer',
    url: 'https://www.trustpilot.com/reviews/66d0e38ef24f48a085ef740c',
    categories: ['mht'],
  },
  {
    title: 'First-Rate!',
    text: "My initial experience has been really great. The website is clear and easy to use. I really like my prescription vaginal cream and plan to reorder when I run low. I also love the fact I don't have to deal with driving to a physical office. Couldn't be more convenient!",
    name: 'Tina',
    url: 'https://www.trustpilot.com/reviews/66b0f5e42df588f94657c1ec',
    categories: ['sexual-health', 'vaginal-health'],
  },
  {
    title: 'Excellent experience all around',
    text: '"[...] I purchased the M4 face cream- saw results within a week. Again. so impressed. And grateful that there is a site out there to help us navigate the waters of peri-menopause/menopause in a way that has been hard for me to find in standard medical practices. Thank you SO much."',
    name: 'Erin',
    url: 'https://www.trustpilot.com/reviews/66848e51ff142fffd1b88178',
    categories: ['skin-health'],
  },
  {
    title: 'A probiotic & prebiotic that works!',
    text: `I have been taking 2 Daily Synbiotic daily for 2.5 months and I feel the positive effects: less gas, and bloating.

    Recently had oral surgery and took antibiotics for 5 days. I usually experience long term uncomfortable side effects, but the discomfort was greatly reduced I feel due to Daily Synbiotic.`,
    name: 'Susan',
    url: 'https://www.trustpilot.com/reviews/669da66add445a9b400f223e',
    categories: ['gut-health'],
  },
];
