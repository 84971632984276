import DashboardContainer from 'modules/dashboard/ui/containers/DashboardContainer';

import ProfileInfoWrapper from 'modules/dashboard/sub-modules/account/sub-modules/profile/ui/wrappers/ProfileInfo';
import AccountContainer from 'modules/dashboard/sub-modules/account/ui/containers/AccountContainer';

import useMarkNotificationsAsRead from 'shared/hooks/useMarkNotificationsAsRead';

export default function Profile() {
  // mark notifications as read once on everytime going to page OR reloading
  useMarkNotificationsAsRead(['FAILED_PAYMENT']);

  return (
    <DashboardContainer title='Profile | Alloy' desc='' currentPage='account'>
      <AccountContainer title='Your Account' currentPage='your profile'>
        <ProfileInfoWrapper />
      </AccountContainer>
    </DashboardContainer>
  );
}
