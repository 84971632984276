import './PaymentDrawer.scss';

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import { useState } from 'react';
import { Drawer } from 'rsuite';

import {
  StripePaymentMethod,
  attachPaymentMethod,
  useGetPaymentMethods,
} from 'client/dist/generated/alloy';

import {
  confirmSetupIntent,
  formatExpirationDate,
  paymentElementTabOptions,
} from 'modules/dashboard/lib/stripe';
import { sendExceptionToSentry } from 'modules/tracking/lib/sentry';

import closeIcon from 'shared/assets/svg/common/btn-close.svg';

import CardIcon from 'shared/components/content/CardIcon';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'shared/components/core/Notification';

import { useAppSelector } from 'shared/store/reducers';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function PaymentDrawer({ open, setOpen }: Props) {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState<boolean>(false);
  const [allInputsComplete, setAllInputsComplete] = useState<boolean>(false);
  const [addPaymentMethod, setAddPaymentMethod] = useState<boolean>(false);

  const customer = useAppSelector((state) => state.alloy.customer!);

  const { data: paymentMethods = [], mutate } = useGetPaymentMethods();

  const onChangeDefaultCard = async (paymentMethod: StripePaymentMethod) => {
    setLoading(true);

    try {
      await attachPaymentMethod({ paymentMethod: paymentMethod.id });
      await mutate();

      showSuccessNotification('Successfully updated default payment method');

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onSave = async () => {
    try {
      if (!stripe || !elements) {
        showErrorNotification('An unknown error occurred.');
        return;
      }

      setLoading(true);

      const { error, setupIntent } = await confirmSetupIntent(stripe, elements, customer);

      if (error) {
        !!error.message
          ? showErrorNotification(error.message)
          : showErrorNotification(
              'There was an issue processing your payment method. Please try again or reach out to support for assistance',
            );
        setLoading(false);
        return;
      }

      if (setupIntent) {
        let paymentMethodId = setupIntent.payment_method as string;

        await attachPaymentMethod({ paymentMethod: paymentMethodId });
        await mutate();

        showSuccessNotification('Successfully updated your payment method');

        setAddPaymentMethod(false);
        setLoading(false);
        setOpen(false);
      } else {
        showErrorNotification(
          'Payment method could not be created. Please try again or reach out to support for assistance.',
        );
        setLoading(false);
      }
    } catch (error) {
      sendExceptionToSentry(error as Error);
      setLoading(false);
    }
  };

  const onClose = () => {
    if (!loading) {
      setOpen(false);
    }
  };

  return (
    <Drawer
      open={open}
      size='sm'
      className={classNames('alloy-drawer payment-drawer', open ? 'drawer-open' : 'drawer-closed')}
      onClose={onClose}
    >
      <Drawer.Body>
        <div className='drawer-header'>
          <p className='header-title'>Payment Method</p>

          <button onClick={onClose} className='header-btn-close' disabled={loading}>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>
        </div>

        <div className='drawer-body'>
          {addPaymentMethod ? (
            <div className='payment-element-wrapper'>
              <PaymentElement
                id='payment-element'
                options={paymentElementTabOptions}
                onChange={(e) => setAllInputsComplete(e.complete)}
              />
            </div>
          ) : (
            (paymentMethods as StripePaymentMethod[]).map(
              (paymentMethod: StripePaymentMethod, index: number) => (
                <div className='payment-method-wrapper' key={index}>
                  <p className='payment-method-text'>
                    <CardIcon brand={paymentMethod.card!.brand} />
                    {paymentMethod.card!.brand.charAt(0).toUpperCase()}
                    {paymentMethod.card!.brand.slice(1)} ending in {paymentMethod.card!.last4}
                  </p>

                  <p className='payment-method-text'>
                    Expiration date: {formatExpirationDate(paymentMethod.card!)}
                  </p>

                  {paymentMethod.is_primary ? (
                    <p className='payment-method-text default'>Default card</p>
                  ) : (
                    <button
                      className='primary-link-button'
                      onClick={() => onChangeDefaultCard(paymentMethod)}
                    >
                      Make default
                    </button>
                  )}
                </div>
              ),
            )
          )}
        </div>

        <div className='drawer-footer'>
          {addPaymentMethod ? (
            <>
              <button
                className='primary-button full-width-button'
                onClick={onSave}
                disabled={!allInputsComplete || loading}
              >
                Save
              </button>

              <button
                className='primary-link-button secondary'
                onClick={() => setAddPaymentMethod(false)}
                disabled={loading}
              >
                Cancel
              </button>
            </>
          ) : (
            <button
              className='primary-link-button'
              onClick={() => setAddPaymentMethod(true)}
              disabled={loading}
            >
              Add credit or debit card
            </button>
          )}
        </div>
      </Drawer.Body>
    </Drawer>
  );
}
