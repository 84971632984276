import { Link } from 'react-router-dom';

import { cleanNonNumberValues } from 'modules/authentication/lib/clean-non-number-values';

import CheckoutExperienceSection from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceSection';

import arrowRight from 'shared/assets/svg/arrows/arrow-right-white.svg';

import Loader from 'shared/components/content/Loader';
import Form from 'shared/components/core/Form';
import { CodeField } from 'shared/components/fields/CodeField';
import { EmailField } from 'shared/components/fields/EmailField';
import PasswordFieldsGroup from 'shared/components/fields/PasswordFieldsGroup';

import { useQueryParams } from 'shared/context/url/query';

import useResetRequiredState from '../../hooks/useResetRequiredState';

export default function ResetRequiredFormWrapper() {
  const { buildPathWith } = useQueryParams();
  const {
    isLoading,

    sentCode,

    resetForm,
    setResetForm,

    onSendVerificationCode,
    onChangePassword,
  } = useResetRequiredState();

  if (isLoading) return <Loader />;

  return (
    <CheckoutExperienceSection>
      <h1 className='content-title'>Reset Password</h1>

      {sentCode && (
        <p className='content-text'>
          We recently updated our account authentication.
          <br />
          It makes things more secure on our end, but it also means you&apos;ll need to update your
          password.
          <br />
          We&apos;ve sent you a verification code by email to <b>{resetForm.email}</b>
          <br />
          Enter it below and then create your new password.
        </p>
      )}

      <p className='content-text'>
        Back to{' '}
        <Link to={buildPathWith('/login')} className='content-link'>
          Get started
        </Link>
      </p>

      <Form onSubmit={onChangePassword}>
        {(resetForm.email === '' || !sentCode) && (
          <EmailField
            label='Email'
            name='email'
            value={resetForm.email}
            placeholder='Enter your email address'
            onChange={(value) => setResetForm({ ...resetForm, email: value })}
          />
        )}

        <CodeField
          name='code'
          label='Code'
          placeholder='Enter code here'
          value={resetForm.code}
          onChange={(value) => {
            setResetForm({
              ...resetForm,
              code: cleanNonNumberValues(value, 6),
            });
          }}
        />

        <PasswordFieldsGroup
          useConfirm
          onChange={(confirmedChecksSucceeded, password) =>
            setResetForm({ ...resetForm, confirmedChecksSucceeded, password })
          }
          passwordReset
        />

        {(sentCode || (!sentCode && resetForm.email !== '')) && (
          <div className='content-resend-code-wrapper'>
            <p className='resend-code-title'>Didn't receive a code?</p>
            <p className='resend-code-text'>
              We sent the verification code to {resetForm.email}. Be sure to check your promotions
              and spam folders in case the email is redirected!
            </p>

            <button type='button' onClick={onSendVerificationCode} className='primary-link-button'>
              Resend code
            </button>
          </div>
        )}

        <button
          className='primary-button full-width-button'
          type='submit'
          disabled={
            !sentCode ||
            resetForm.email === '' ||
            resetForm.code === '' ||
            !resetForm.confirmedChecksSucceeded
          }
        >
          Change Password
          <img src={arrowRight} alt='arrow right' className='btn-arrow' />
        </button>
      </Form>
    </CheckoutExperienceSection>
  );
}
