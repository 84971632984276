import { Navigate, Route } from 'react-router-dom';

import { Customer } from 'client/dist/generated/alloy';

import { useAppSelector } from 'shared/store/reducers';

interface Props {
  component: any;
  basePath?: string;
  onNext: () => void;
  onBack?: () => void;
  location: any;
}

const isAuthenticatedAndUserExists = (isAuthenticated: boolean, customer?: Customer) => {
  return isAuthenticated && !!customer;
};

const AuthFlowRoute = ({ component: Component, onNext, onBack, location }: Props) => {
  const { isAuthenticated, customer } = useAppSelector((state) => state.alloy);

  const isAuthAndExists = isAuthenticatedAndUserExists(isAuthenticated, customer);

  if (!isAuthAndExists) {
    return <Component onNext={onNext} onBack={onBack} />;
  } else {
    return (
      <Navigate
        to={{
          pathname: '/',
          search: location.search,
        }}
      />
    );
  }
};

const PrivateFlowRoute = ({
  component: Component,
  basePath = '/checkout-experience',
  onNext,
  onBack,
  location,
}: Props) => {
  const { isAuthenticated, customer } = useAppSelector((state) => state.alloy);

  const isAuthAndExists = isAuthenticatedAndUserExists(isAuthenticated, customer);

  if (isAuthAndExists) {
    return <Component onNext={onNext} onBack={onBack} />;
  } else {
    const searchParams = new URLSearchParams(location.search);

    const path = basePath.replaceAll('/', '');
    const paramsArray: string[][] = [];

    const nextPath = ['nextPath', path];

    paramsArray.push(nextPath);

    searchParams.forEach((value, key) => paramsArray.push([key, value]));

    const nextPathSearchParams = new URLSearchParams(paramsArray);

    return (
      <Navigate
        to={{
          pathname: '/login',
          ...(searchParams.toString() !== 'nextPath=' && {
            search: nextPathSearchParams.toString(),
          }),
        }}
      />
    );
  }
};

export { AuthFlowRoute, PrivateFlowRoute, isAuthenticatedAndUserExists };
