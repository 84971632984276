import { uniqBy } from 'lodash';

import {
  DeepProduct,
  ProductFrequencyRenewal,
  SubscriptionWithRenewal,
} from 'client/dist/generated/alloy';

/**
 *
 * Retrieves all the **unique** expired prescriptions in all shipments and returns as pf[]
 *
 * @param shipments SubscriptionWithRenewal[]
 * @returns DeepProduct[] - all the expired prescriptions from all shipments
 */
export const getExpiringPrescriptionsFromAll = (
  shipments: SubscriptionWithRenewal[],
): DeepProduct[] => {
  const expiring = shipments.flatMap((ship) => getExpiringPrescriptionsFromSubscription(ship));

  // slight subtlety here - uniqify on mdiProductId rather than pf.id - this is an explicit
  // choice to ensure renewal encounters do not hit validation errors
  return uniqBy(expiring, (deepProduct) => deepProduct.mdiProductId);
};

/**
 *
 * Retrieves all the expired prescriptions in a shipment and returns as pf[]
 *
 * @param shipment SubscriptionWithRenewal
 * @returns DeepProduct[] - all expired prescriptions from shipments
 */
export const getExpiringPrescriptionsFromSubscription = (
  shipment: SubscriptionWithRenewal,
): DeepProduct[] => {
  let products: DeepProduct[] = [];

  shipment.products.forEach((p: ProductFrequencyRenewal) => {
    if (p.renewal && p.renewal.needsRenewed && !p.renewal.completedDate) {
      products.push(p.product);
    }
  });

  return products;
};
