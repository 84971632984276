import './Actions.scss';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import {
  useGetAllOrders,
  useGetAllSubscriptionsForCustomer,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';

import calendarIcon from 'modules/dashboard/sub-modules/home/assets/svg/calendar-icon.svg';
import clipboardIcon from 'modules/dashboard/sub-modules/home/assets/svg/clipboard-icon.svg';
import messageIcon from 'modules/dashboard/sub-modules/home/assets/svg/message-icon.svg';
import personIcon from 'modules/dashboard/sub-modules/home/assets/svg/person-icon.svg';
import questionMarkIcon from 'modules/dashboard/sub-modules/home/assets/svg/question-mark-icon.svg';

import { getAccountStatus } from 'modules/dashboard/lib/account-status';
import { isOtcOnlyCustomer } from 'modules/dashboard/lib/otc-only-customer';

import arrowRightIcon from 'shared/assets/svg/arrows/arrow-right.svg';

import { useAppSelector } from 'shared/store/reducers';

interface Action {
  icon: string;
  title: string;
  link: string;
  isExternal: boolean;
}

const DEFAULT_ACTIONS: Action[] = [
  {
    icon: clipboardIcon,
    title: 'View treatment plan',
    link: '/treatment-plan',
    isExternal: false,
  },
  {
    icon: calendarIcon,
    title: 'Manage subscriptions',
    link: '/subscriptions',
    isExternal: false,
  },
  {
    icon: messageIcon,
    title: 'Message your doctor',
    link: '/messages',
    isExternal: false,
  },
  {
    icon: personIcon,
    title: 'Refer friends & earn',
    link: '/account/referrals',
    isExternal: false,
  },
  {
    icon: questionMarkIcon,
    title: 'Need help? Contact us',
    link: 'https://myalloy.zendesk.com/',
    isExternal: true,
  },
];

const OTC_ACTIONS: Action[] = [
  {
    icon: calendarIcon,
    title: 'Manage subscriptions',
    link: '/subscriptions',
    isExternal: false,
  },
  {
    icon: personIcon,
    title: 'Refer friends & earn',
    link: '/account/referrals',
    isExternal: false,
  },
  {
    icon: questionMarkIcon,
    title: 'Need help? Contact us',
    link: 'https://myalloy.zendesk.com/',
    isExternal: true,
  },
];

export default function ActionsWrapper() {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();
  const { data: orders = [] } = useGetAllOrders();
  const { data: treatmentPlan } = useGetTreatmentPlan();

  const accountStatus = getAccountStatus(customer.status, subscriptions, orders, treatmentPlan);

  // No purchase means the customer doesn't have actions to view
  if (accountStatus === 'NO_PURCHASE') return <></>;

  const hideReferrals = !customer.referralStripePromotionCodeId;

  const isOtcOnly = isOtcOnlyCustomer(subscriptions);

  const updatedActions = isOtcOnly ? OTC_ACTIONS : DEFAULT_ACTIONS;
  const filteredActions = updatedActions.filter((action) =>
    hideReferrals ? action.link !== '/account/referrals' : true,
  );

  return (
    <div className={classNames('header-actions-wrapper')}>
      {filteredActions.map((action, index) =>
        action.isExternal ? (
          <a
            href={action.link}
            className='header-action-block'
            key={index}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={action.icon} alt={action.title} className='action-icon' />

            <p className='action-title'>
              {action.title}
              <img src={arrowRightIcon} alt='arrow right' className='title-arrow' />
            </p>
          </a>
        ) : (
          <Link to={{ pathname: action.link }} className='header-action-block' key={index}>
            <img src={action.icon} alt={action.title} className='action-icon' />

            <p className='action-title'>
              {action.title}
              <img src={arrowRightIcon} alt='arrow right' className='title-arrow' />
            </p>
          </Link>
        ),
      )}
    </div>
  );
}
