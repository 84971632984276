import { getTime } from 'date-fns';
import { useState } from 'react';

import { useGetAllOrders } from 'client/dist/generated/alloy';

import { useAppSelector } from 'shared/store/reducers';

export default function useOrderHistoryData() {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const [activeOrderId, setActiveOrderId] = useState('');

  const { data: orders = [], isLoading } = useGetAllOrders();

  orders.sort((a, b) => getTime(new Date(b.createdAt!)) - getTime(new Date(a.createdAt!)));

  const toggleOrder = (id: string) => setActiveOrderId(activeOrderId === id ? '' : id);

  return {
    isLoading,

    orders,
    customer,

    activeOrderId,

    toggleOrder
  };
}
