import { upperCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  DeepProduct,
  useGenerateSetupIntent,
  useGetPaymentMethods,
} from 'client/dist/generated/alloy';

import { useSubscriptionContext } from 'modules/dashboard/sub-modules/manage-subscription/context/manage';

import { isLastDeepProductsIn } from 'modules/shared/lib/subscriptions/product';

import {
  ManageLevel,
  ManageType,
  isValidManageType,
} from 'modules/dashboard/sub-modules/manage-subscription/models/manage-type';

import { useQueryParams } from 'shared/context/url/query';

export default function useManageSubscriptionState() {
  const location = useLocation();
  const navigate = useNavigate();

  const { getParam, navigateWithParams } = useQueryParams();

  const { subscription, isLoading } = useSubscriptionContext();

  const [isManageOpen, setIsManageOpen] = useState(false);

  const [selectedDeepProductIds, setSelectedDeepProductIds] = useState<number[]>([]);
  const [manageType, setManageType] = useState<ManageType>('EDIT');
  const [manageLevel, setManageLevel] = useState<ManageLevel>('SUBSCRIPTION');

  const { isLoading: isLoadingIntent } = useGenerateSetupIntent();
  const { isLoading: isPaymentMethodsLoading } = useGetPaymentMethods();

  const isLoadingData = isLoading || isLoadingIntent || isPaymentMethodsLoading;

  useEffect(() => {
    const productIdsString = getParam('deepProductIds[]');
    const manage = upperCase(getParam('manage') || '').replaceAll(' ', '_');

    if (isValidManageType(manage) && !isLoadingData) {
      const convertedProductIds = (productIdsString || '').split(',').map(Number);
      const subscriptionDeeProductIds = subscription.products.map((pfr) => pfr.product.id);

      if (
        convertedProductIds.length &&
        convertedProductIds.every((dpId) => subscriptionDeeProductIds.includes(dpId)) &&
        !isLastDeepProductsIn(subscription, convertedProductIds, true)
      ) {
        setSelectedDeepProductIds(
          subscription.products
            .map((pfr) => pfr.product.id)
            .filter((dpId) => convertedProductIds.includes(dpId)),
        );
        setManageLevel('PRODUCT');
      } else {
        setSelectedDeepProductIds(subscriptionDeeProductIds);
        setManageLevel('SUBSCRIPTION');
      }

      setManageType(manage);
      setIsManageOpen(true);
    }
  }, [location.search, isLoadingData]);

  const onEditProduct = (deepProductIds: DeepProduct['id'][]) =>
    navigateWithParams(`/subscriptions/${subscription.stripeSubscriptionId}`, {
      manage: 'edit',
      'deepProductIds[]': deepProductIds.join(','),
    });

  const onRescheduleSub = () =>
    navigateWithParams(`/subscriptions/${subscription.stripeSubscriptionId}`, {
      manage: 'reschedule',
    });

  const onShipNowSub = () =>
    navigateWithParams(`/subscriptions/${subscription.stripeSubscriptionId}`, {
      manage: 'ship_now',
    });

  const onClose = () => {
    setIsManageOpen(false);

    navigate(`/subscriptions/${subscription.stripeSubscriptionId}`);
  };

  return {
    isLoadingData,

    subscription,

    selectedDeepProductIds,

    manageType,
    manageLevel,
    isManageOpen,
    onClose,

    onRescheduleSub,
    onShipNowSub,

    onEditProduct,
  };
}
