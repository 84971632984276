import { IntakeCategory } from 'client/dist/generated/alloy';
import { ExperienceCategory } from 'common/dist/models/experience';

import { SharedLocation } from 'common/dist/models/shared/location';
import { validateCategories } from 'modules/checkout-experience/lib/validation';
import { getCategoriesFromFlowId } from 'modules/shared/lib/url';
import { uniq } from 'lodash';
import { ExperienceFlow } from 'modules/shared/models/experience';

// Moving functions into here that are shared amongst experiences/flows that way we don't have dupes

/**
 *
 */
export const getIntakeCategories = (categories: ExperienceCategory[]) =>
  categories.filter((c) => c !== 'gut-health') as IntakeCategory[];

/**
 * At the moment, intake categories are just checkout experience categories but they do not include gut health since that category
 * does not have an intake
 */
export const retrieveIntakeCategoriesFromUrl = (location: SharedLocation): IntakeCategory[] => {
  const categories = retrieveCategoriesFromUrl(location);

  return getIntakeCategories(categories);
};

/**
 *
 */
export const retrieveCategoriesFromUrl = (location: SharedLocation): ExperienceCategory[] => {
  if (!location.pathname.includes('-experience')) return [];

  const searchParams = new URLSearchParams(location.search);

  const categories: string | null = searchParams.get('categories[]');

  const parsedCategories = categories ? categories.split(',') : [];

  const categoriesFromFlowId = getCategoriesFromFlowId(location);
  const collatedCategories = uniq([...parsedCategories, ...categoriesFromFlowId]);

  return validateCategories(collatedCategories);
};

/**
 *
 */
export const getAllPathsFromFlow = (flow: ExperienceFlow): string[] => {
  return flow.steps.map((step) => step.path);
};

/**
 *
 */
export const getCurrentStepIndex = (url: string, flow: ExperienceFlow) => {
  const urls = getAllPathsFromFlow(flow);

  const splitUrl = url.split('/').filter((s) => s !== '');

  return urls.findIndex((url) => splitUrl.includes(url));
};
