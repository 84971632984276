import './Payment.scss';

import { useGetPaymentMethods } from 'client/dist/generated/alloy';

import PaymentBlock from '../blocks/Payment';

interface Props {
  stripeClientSecret: string;
}

export default function PaymentWrapper({ stripeClientSecret }: Props) {
  const { data: paymentMethods = [] } = useGetPaymentMethods();

  return (
    <div className='tp-checkout-payment-wrapper'>
      <p className='checkout-payment-title'>Payment method</p>

      <PaymentBlock paymentMethods={paymentMethods} stripeClientSecret={stripeClientSecret} />
    </div>
  );
}
