import { Button } from 'component-library';
import { useNavigate } from 'react-router-dom';

import {
  SubscriptionWithRenewal,
  cancel,
  reactivateSubscription,
  startRenewalAlert,
  unnpauseSubscription,
  useGetAllSubscriptionsForCustomer,
  useIsProcessing,
} from 'client/dist/generated/alloy';

import useInitExperience from 'modules/shared/hooks/useInitExperience';

import { getPrescriptionsStatusFrom } from 'modules/dashboard/lib/prescription';
import { getExpiringPrescriptionsFromAll } from 'modules/dashboard/lib/subscription';
import { sendExceptionToSentry } from 'modules/tracking/lib/sentry';

import { showSuccessNotification } from 'shared/components/core/Notification';

interface Props {
  subscription: SubscriptionWithRenewal;
  setLoading: (isLoading: boolean) => void;
}

export default function SubscriptionsAction({ subscription, setLoading }: Props) {
  const navigate = useNavigate();

  const { data: subscriptions = [], mutate: mutateSubscriptions } =
    useGetAllSubscriptionsForCustomer();
  const { mutate: mutateIsProcessing } = useIsProcessing(subscription.stripeSubscriptionId);

  const { initCheckoutExperience } = useInitExperience();

  const mutate = () => Promise.all([mutateSubscriptions, mutateIsProcessing]);

  const {
    allPrescriptionsExist,
    allPrescriptionsActive,
    allPrescriptionsExpired,
    prescriptionsNeedRenewal,
  } = getPrescriptionsStatusFrom(subscription);

  const isOtcOnlySubscription = subscription.products.every((pfr) => pfr.product.type === 'OTC');

  // Renewal Flag
  const needsRenewal = !allPrescriptionsActive || prescriptionsNeedRenewal;

  const onManageSubscription = () =>
    navigate(`/subscriptions/${subscription.stripeSubscriptionId}`);

  const onResume = async () => {
    try {
      window.scrollTo(0, 0);

      setLoading(true);

      const { stripeSubscriptionId } = subscription;

      await unnpauseSubscription(stripeSubscriptionId);
      await mutate();

      if (needsRenewal) {
        onRenew();
      } else {
        showSuccessNotification('Your subscription has successfully been resumed');
        setLoading(false);
      }
    } catch (error) {
      sendExceptionToSentry(error as Error);
      setLoading(false);
    }
  };

  const onReactivate = async () => {
    try {
      window.scrollTo(0, 0);

      setLoading(true);

      const { stripeSubscriptionId } = subscription;

      await reactivateSubscription(stripeSubscriptionId);
      await mutate();

      if (allPrescriptionsExpired || prescriptionsNeedRenewal) {
        onRenew();
      } else {
        showSuccessNotification('Your subscription has successfully been reactivated');
      }

      setLoading(false);
    } catch (error) {
      sendExceptionToSentry(error as Error);
      setLoading(false);
    }
  };

  const onRenew = async () => {
    const expiredProductFrequencies = getExpiringPrescriptionsFromAll(subscriptions);

    const expiredProductNames = expiredProductFrequencies.map((pf) => pf.name);

    startRenewalAlert({ productNames: expiredProductNames });

    await initCheckoutExperience(['renewal']);
  };

  const onCancel = async () => {
    try {
      window.scrollTo(0, 0);

      setLoading(true);

      const { stripeSubscriptionId, products } = subscription;

      const payload = {
        deepProductIds: products.map((pfr) => pfr.product.id),
        stripeSubscriptionId: stripeSubscriptionId,
      };

      await cancel(payload);
      await mutate();

      showSuccessNotification('Your subscription has successfully been cancelled');
      setLoading(false);
    } catch (error) {
      sendExceptionToSentry(error as Error);
      setLoading(false);
    }
  };

  switch (subscription.status) {
    case 'ACTIVE': {
      if (needsRenewal) {
        return (
          <Button theme='primary' onClick={onResume}>
            Renew
          </Button>
        );
      } else {
        return (
          <Button theme='secondary-border' onClick={onManageSubscription}>
            Manage subscription
          </Button>
        );
      }
    }

    case 'PAUSED': {
      return (
        <>
          <Button
            theme='secondary-border'
            onClick={onCancel}
            className='secondary-border-button mr-0 mr-md-3 mb-3 mb-md-0'
          >
            Cancel subscription
          </Button>

          <Button theme='primary' onClick={onResume}>
            {needsRenewal ? 'Renew' : 'Resume'}
          </Button>
        </>
      );
    }

    case 'INACTIVE': {
      if ((allPrescriptionsActive && allPrescriptionsExist) || isOtcOnlySubscription) {
        return (
          <Button theme='primary' onClick={onReactivate}>
            Reactivate
          </Button>
        );
      } else {
        return (
          <p className='m-0 p-0'>
            To reactivate, please reach out to{' '}
            <a href='mailto:support@myalloy.com' className='text-underline'>
              support@myalloy.com
            </a>
          </p>
        );
      }
    }
  }
}
