import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useGetCustomerSymptoms } from 'client/dist/generated/alloy';

import { getSymptoms } from 'modules/shared/lib/contentful';

import { updateSymptoms } from 'modules/dashboard/sub-modules/treatment-plan/store/actions';

import { ContentfulSymptom } from 'shared/models/contentful/symptom';

import { useAppSelector } from 'shared/store/reducers';

interface ReturnProps {
  symptoms: ContentfulSymptom[];
  isLoading: boolean;
}

export default function useCustomerSymptoms(): ReturnProps {
  const dispatch = useDispatch();

  const { customerSymptoms } = useAppSelector((state) => state.treatmentPlan);

  const { data: symptoms = [], isLoading } = useGetCustomerSymptoms();

  const dispatchUpdateSymptoms = bindActionCreators(updateSymptoms, dispatch);

  useEffect(() => {
    retrieveContentfulSymptoms();
  }, [isLoading]);

  const retrieveContentfulSymptoms = async () => {
    if (!isLoading && customerSymptoms.length === 0) {
      const retrieved = await getSymptoms(symptoms);

      dispatchUpdateSymptoms(retrieved);
    }
  };

  return { symptoms: customerSymptoms, isLoading };
}
