import { store } from 'shared/store';

import { Question } from 'common/dist/models/questionnaire';

import { QuestionnaireAnswers } from 'modules/questionnaire/models/questionnaire-answers';

import { transformDateString } from '../../../shared/lib/date';

import { RootState } from 'shared/store/reducers';

/**
 * Used for the object that stores question answers in the Intake page and prefills some fields
 * that way some fields are defaulted (new customer no height) or prefilled (current customer dob).
 * this helps speed up some steps for customers and having some data in there before a customer
 * gets to a specific question!
 *
 * @param intakeQuestions Question[]
 * @returns QuestionnaireAnswers
 */
export const getPreviousResults = (intakeQuestions: Question[]): QuestionnaireAnswers => {
  const state = store.getState() as RootState;

  const { customer } = state.alloy;
  const { localPreCustomer } = state.experience;

  let previousResults: QuestionnaireAnswers = {};

  intakeQuestions.forEach((question: Question) => {
    const potentialDateOfBirth =
      (customer && customer.dateOfBirth?.toISOString()) || localPreCustomer.dateOfBirth;

    if (question.type === 'Select' && question.defaultValue) {
      previousResults[question.slug] = question.defaultValue.toString();
    } else if (question.type === 'Number' && question.defaultValue) {
      previousResults[question.slug] = question.defaultValue.toString();
    } else if (question.userKey === 'dob' && potentialDateOfBirth) {
      previousResults[question.slug] = transformDateString(potentialDateOfBirth, 'MM/DD/YYYY')!;
    }
  });

  return previousResults;
};
