import './TotalPrice.scss';

import classNames from 'classnames';

interface Props {
  unbundledTotal: number;
  bundledTotal: number;
}

export default function TotalPriceContent({ unbundledTotal, bundledTotal }: Props) {
  const isBundled = unbundledTotal - bundledTotal !== 0;

  if (unbundledTotal === 0 && bundledTotal === 0) {
    return <p className='total-price-text'>-</p>;
  }

  return (
    <p className='total-price-text'>
      <span className={classNames('text-price', isBundled && 'price-strikethrough')}>
        ${unbundledTotal.toFixed(2)}
      </span>

      {isBundled && <span className='text-discounted-price'>${bundledTotal.toFixed(2)}</span>}
    </p>
  );
}
