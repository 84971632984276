/**
 * @param data - the string you want to clean
 * @param maxLength - the max length allow to the field
 * @returns the same string you passed but with only digit numbers with the length you specified
 */

export const cleanNonNumberValues = (data: string, maxLength: number): string => {
  let onlyNumbers = data.replace(/\D/g, '');
  if (onlyNumbers.length > maxLength) onlyNumbers = onlyNumbers.substring(0, maxLength);

  return onlyNumbers;
};
