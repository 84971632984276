import {
  ProductCategory,
  RecordTreatmentPlanPendingChargeTypeTreatmentPlanPendingChargeArray,
  useGetAllSubscriptionsForCustomer,
  useGetTreatmentPlan
} from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';

import useGroupedProducts from 'shared/hooks/product/useGroupedProducts';

interface ReturnProps {
  isAwaitingDoctor: boolean;
  isAwaitingQueueOrCX: boolean;
  hasMhtOrCompletedConsult: boolean;
  retentionProducts: GroupedContentfulProduct[][];
  pendingCharges: RecordTreatmentPlanPendingChargeTypeTreatmentPlanPendingChargeArray;
}

export default function useAwaitingData(): ReturnProps {
  const { data: treatmentPlan } = useGetTreatmentPlan();
  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const { activeSubs } = getSubscriptionsWithStatus(subscriptions);

  const { products: retentionProducts } = useGroupedProducts({
    deepProducts: treatmentPlan?.currentRentionProducts ?? []
  });

  const isAwaitingQueueOrCX =
    treatmentPlan!.status === 'CX_PROCESSING' || treatmentPlan!.status === 'PROCESSING';

  /**
   * Variables used from treatment plan to show various mini states inside each group (active prescriptions, add ons, etc)
   */
  const CATEGORIES: ProductCategory[] = ['mht', 'birth-control', 'non-hormonal-treatment'];
  const isAwaitingDoctor = treatmentPlan!.status === 'AWAITING_DOCTOR';

  const hasMhtOrCompletedConsult =
    activeSubs
      .flatMap((sub) => sub.products.map((p) => p.product.category))
      .some((category) => CATEGORIES.includes(category)) || treatmentPlan!.hasConsult;

  return {
    isAwaitingDoctor,
    isAwaitingQueueOrCX,
    hasMhtOrCompletedConsult,
    retentionProducts,
    pendingCharges: treatmentPlan!.pendingCharges
  };
}
