import { ExperienceCategory } from 'common/dist/models/experience';

import {
  defaultStat,
  defaultWidget,
  resultStats,
  resultWidgets,
} from 'modules/checkout-experience/data/content/result-stats';

type Props = {
  categories: ExperienceCategory[];
  isStat?: boolean;
};

export const getRelevantFact = ({ categories, isStat }: Props) => {
  if (isStat) {
    return resultStats.find((s) => s.categories.some((c) => categories.includes(c))) ?? defaultStat;
  }

  return (
    resultWidgets.find((w) => w.categories.some((c) => categories.includes(c))) ?? defaultWidget
  );
};
