import { useExperience } from 'modules/shared/context/experience';

import ReliefTypeFormWrapper from 'modules/checkout-experience/sub-modules/relief-type/ui/wrappers/ReliefTypeForm';

import Layout from 'shared/components/core/Layout';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

export default function ReliefTypePage() {
  const { onBack } = useExperience();

  return (
    <Layout title='Select relief - Alloy' desc='' noBars>
      <TopBannerWithProgress onBack={onBack} />

      <ReliefTypeFormWrapper />
    </Layout>
  );
}
