import { SubscriptionGroupConsentStatus, subscribeToNewsletter } from 'client/dist/generated/alloy';
import { cleanStringForBraze } from 'modules/tracking/lib/braze';

// TODO: Need some cleaning here
export const subscribeNewsletter = async (
  email: string,
  consentStatus: SubscriptionGroupConsentStatus | undefined
) => {
  await subscribeToNewsletter({
    email: cleanStringForBraze(email, true)!,
    consentStatus: consentStatus!,
  });
};
