import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';

import { useCancelContext } from 'modules/dashboard/sub-modules/manage-subscription/context/cancel';
import { useProductContext } from 'modules/dashboard/sub-modules/manage-subscription/context/product';

import { AllBenefits } from 'modules/dashboard/sub-modules/manage-subscription/data/all-benefits';
import { CANCEL_SAVE } from 'modules/dashboard/sub-modules/manage-subscription/data/reason';

import { isLastGroupedProductIn } from 'modules/shared/lib/subscriptions/product';
import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';

import CheckmarkListBlock from 'modules/dashboard/ui/blocks/CheckmarkList';

import { useQueryParams } from 'shared/context/url/query';

import DoctorCardBlock from './Doctor';

export default function NoSubscriptionContent() {
  const { setParam } = useQueryParams();
  const { onCancel, onSaveSubmission } = useCancelContext();
  const { product } = useProductContext();

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();
  const { activeSubs } = getSubscriptionsWithStatus(subscriptions);

  const isLastProduct = isLastGroupedProductIn(activeSubs, product);

  const onKeepSubscription = async () => {
    await onSaveSubmission({ 'cancel-saved': CANCEL_SAVE.KEEP_SUBSCRIPTION });

    setParam('outcome', 'keep');
  };

  const onReschedule = async () => {
    await onSaveSubmission({ 'cancel-saved': CANCEL_SAVE.RESCHEDULE });

    setParam('manage', 'reschedule');
  };

  const onSubmitCancel = async () => {
    await onSaveSubmission();

    await onCancel();
  };

  if (isLastProduct) {
    return (
      <div className='cancel-content-wrapper'>
        <div className='content-block'>
          <h4 className='content-title'>Alloy is more than just a subscription.</h4>
        </div>

        <DoctorCardBlock showAbout />
        <CheckmarkListBlock list={AllBenefits} />

        <div className='btns-stack-block'>
          <button className='primary-button' onClick={onKeepSubscription}>
            Stay with alloy
          </button>

          <button className='primary-link' onClick={onSubmitCancel}>
            Continue to cancel
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className='cancel-content-wrapper'>
        <div className='content-block'>
          <h4 className='content-title'>Before you go, maybe we can help!</h4>
          <p className='content-text'>
            With Alloy, you can easily reschedule your treatments and have them ship on a schedule
            that works for you. Would you like to reschedule instead?
          </p>
        </div>

        <div className='btns-stack-block'>
          <button className='primary-button' onClick={onReschedule}>
            Yes, reschedule
          </button>

          <button className='primary-link' onClick={onSubmitCancel}>
            No, I want to cancel
          </button>
        </div>
      </div>
    );
  }
}
