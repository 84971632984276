import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import getIntakesByCategory from 'common/dist/intake/getIntakesByCategory';

import { useCancelContext } from 'modules/dashboard/sub-modules/manage-subscription/context/cancel';

import { reasons } from 'modules/dashboard/sub-modules/manage-subscription/data/reason';

import { getPages } from 'modules/questionnaire/lib/question';

import { useQueryParams } from 'shared/context/url/query';

import FollowUpContainer from '../containers/FollowUp';
import ReasonContainer from '../containers/Reason';

type RetentionStep = 'REASON' | 'FOLLOW_UP';

export default function RetentionBlock() {
  const { setParams, getParam } = useQueryParams();
  const location = useLocation();
  const { reasonType, setReasonType } = useCancelContext();

  const [step, setStep] = useState<RetentionStep>('REASON');

  useEffect(() => {
    // in order to throw customers into the right component whether it be on refresh
    // or on changes to the navigation, we need to update the state appropriately
    const foundReasonSlug = getParam('reason');
    const reason = reasons.find((r) => r.slug === foundReasonSlug);

    if (!!reason) {
      setReasonType(reason.type);
      setStep('FOLLOW_UP');
    } else {
      setStep('REASON');
    }
  }, [location.search]);

  const onNext = () => {
    const reason = reasons.find((r) => r.type === reasonType);

    if (!!reason) {
      const questions = getIntakesByCategory(['cancel']);
      const pages = getPages(questions, []);

      const nextSection = pages[0][0].groupedSection || pages[0][0].slug;

      setParams({
        reason: reason.slug,
        ...(reasonType === 'NOT_FEELING_BETTER' && { section: nextSection })
      });
    }

    setStep('FOLLOW_UP');
  };

  return (
    <div className='cancel-retention-block'>
      {step === 'REASON' && <ReasonContainer onContinue={onNext} />}

      {step === 'FOLLOW_UP' && !!reasonType && <FollowUpContainer />}
    </div>
  );
}
