import { useEffect, useRef, useState } from 'react';

import { Question as AlloyQuestion } from 'common/dist/models/questionnaire';

import { hasUnansweredRequired } from 'modules/questionnaire/lib/validation';
import { fadeQuestions } from 'modules/shared/lib/fade-scroll';

import { QuestionnaireAnswers } from 'modules/questionnaire/models/questionnaire-answers';
import { RelevantProduct } from 'modules/shared/models/experience';

import CheckoutExperienceSection from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceSection';

import Form from 'shared/components/core/Form';
import Layout from 'shared/components/core/Layout';
import { showErrorNotification } from 'shared/components/core/Notification';
import BottomBar from 'shared/components/wrappers/BottomBar';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

import { validDob } from 'shared/lib/date';

import { QuestionBlock } from '../blocks/Question';

interface Props {
  questions: AlloyQuestion[];
  allAnswers: QuestionnaireAnswers;
  onNext: (newAnswers: QuestionnaireAnswers) => void;
  onBack?: () => void;
  hideProgressBar?: boolean;
  metaTitle?: string;
  relevantProducts?: RelevantProduct[];
  introduction?: JSX.Element;
  type?: 'survey' | 'intake';
  isLastQuestion?: boolean;
}

/**
 * This component handles one question at a time to allow one question per page
 */
export default function QuestionsWrapper({
  questions,
  allAnswers,
  onNext: rootOnNext,
  onBack,
  hideProgressBar,
  metaTitle = 'Intake - Alloy',
  relevantProducts = [],
  introduction,
  type = 'intake',
  isLastQuestion = false,
}: Props) {
  const [answers, setAnswers] = useState<QuestionnaireAnswers>(allAnswers);
  const [disableButton, setDisableButton] = useState<boolean>(true);

  const questionnaireWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fadeQuestions('fade-in-wrapper', questionnaireWrapper);
  }, [JSON.stringify(questions)]);

  const handleUpdateAnswers = (updatedAnswers: QuestionnaireAnswers) => {
    // TODO: Most likely in parent rootOnNext/onNext, take care of children answers related to changed parent answers,
    // this is deleting answers that are no longer necessary as the parent ones have been changed

    questions.forEach((question) => {
      if (updatedAnswers[question.slug] === '') {
        delete updatedAnswers[question.slug];
      }
    });

    setAnswers(updatedAnswers);

    if (
      questions.every(
        (question) => updatedAnswers[question.slug] && question.type === 'SingleChoice',
      )
    ) {
      fadeQuestions('fade-out-wrapper', questionnaireWrapper);

      setTimeout(() => rootOnNext(updatedAnswers), 400);
    }
  };

  const onNext = () => {
    const questionsValidated = questions.some((q) => {
      if (q.type === 'Date' && answers[q.slug]) {
        const { error } = validDob(answers[q.slug] as string);

        if (error) {
          showErrorNotification(error);
        }

        return !error;
      } else {
        return true;
      }
    });

    if (!questionsValidated) return;

    setDisableButton(true);

    fadeQuestions('fade-out-wrapper', questionnaireWrapper);

    setTimeout(() => rootOnNext(answers), 400);
  };

  return (
    <Layout title={metaTitle} desc='' noBars>
      <TopBannerWithProgress onBack={onBack} hideProgress={hideProgressBar} />

      <div className='questionnaire-wrapper' ref={questionnaireWrapper}>
        <Form onSubmit={onNext}>
          <CheckoutExperienceSection>
            {questions.map((question, index) => (
              <QuestionBlock
                key={index}
                question={question}
                answers={answers}
                setAnswers={handleUpdateAnswers}
                relevantProducts={relevantProducts}
                introduction={introduction}
              />
            ))}
          </CheckoutExperienceSection>

          <BottomBar>
            <button
              className='primary-button'
              type='submit'
              disabled={hasUnansweredRequired(questions, answers) && disableButton}
            >
              {type === 'survey' && isLastQuestion ? 'Submit' : 'Next'}
            </button>
          </BottomBar>
        </Form>
      </div>
    </Layout>
  );
}
