import './Reason.scss';

import { useCancelContext } from 'modules/dashboard/sub-modules/manage-subscription/context/cancel';
import { useProductContext } from 'modules/dashboard/sub-modules/manage-subscription/context/product';

import { ReasonType } from 'modules/dashboard/sub-modules/manage-subscription/data/reason';

import { getFilteredReasonsForOptions } from 'modules/dashboard/sub-modules/subscriptions/lib/filtered-reasons';
import { formatProductNames } from 'modules/shared/lib/product/name';

import { SelectFieldsGroup } from 'shared/components/fields/SelectFieldsGroup';

interface Props {
  onContinue: () => void;
}

export default function ReasonContainer({ onContinue }: Props) {
  const { product } = useProductContext();
  const { reasonType, setReasonType } = useCancelContext();

  return (
    <div className='cancel-reason-container'>
      <div className='content-block'>
        <h4 className='content-title'>We're sorry to see you go!</h4>
        <p className='content-text'>
          Why would you like to cancel your subscription of {formatProductNames([product])}?
        </p>
      </div>

      <SelectFieldsGroup
        selectedIds={reasonType ? [reasonType] : []}
        onChange={(checkboxAnswers) => {
          const singleChoiceAnswer: ReasonType =
            (checkboxAnswers[checkboxAnswers.length - 1] as ReasonType) ?? '';

          setReasonType(singleChoiceAnswer);
        }}
        options={getFilteredReasonsForOptions(product)}
        isSingleSelect
      />

      <button className='primary-button' disabled={!reasonType} onClick={onContinue}>
        Continue
      </button>
    </div>
  );
}
