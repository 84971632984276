import useAwaitingData from 'modules/dashboard/sub-modules/treatment-plan/sub-modules/home/hooks/useAwaitingData';

import EmptyStateBlock from 'modules/dashboard/sub-modules/treatment-plan/ui/blocks/EmptyPlan';
import MessageBlock from 'modules/dashboard/sub-modules/treatment-plan/ui/blocks/Message';
import ProgressBlock from 'modules/dashboard/sub-modules/treatment-plan/ui/blocks/Progress';

import { EmailUs } from 'shared/components/content/Buttons';

import DividerWrapper from './Divider';

import ActiveProduct from '../content/ActiveProduct';

export default function AwaitingWrapper() {
  const {
    isAwaitingDoctor,
    isAwaitingQueueOrCX,
    hasMhtOrCompletedConsult,
    retentionProducts,
    pendingCharges,
  } = useAwaitingData();

  if (
    !isAwaitingQueueOrCX &&
    !isAwaitingDoctor &&
    (retentionProducts.length === 0 || pendingCharges.cancelRetentionApproval.length)
  )
    return <></>;

  return (
    <>
      <DividerWrapper />

      <div className='tp-products-list-wrapper active'>
        {/* the encounter || pending charge is either in queue or in cx land for treatment plan approvals */}
        {isAwaitingQueueOrCX && (
          <MessageBlock
            title='Check back soon.'
            text={
              <>
                If your doctor has finalized your treatment plan, your recommended treatments should
                appear within 10 minutes. If it is taking longer, our support team is likely
                managing your order. Please reach out to <EmailUs /> if you have any questions.
              </>
            }
          />
        )}

        {/* waiting on doctor to complete the encounter for treatment plan approvals */}
        {isAwaitingDoctor && (
          <ProgressBlock
            title='Menopausal hormonal treatment plan'
            description='Your doctor will review your health history, messages, and requests, check back soon to confirm your treatment plan. Check your inbox for updates on your treatment plan!'
            barTitles={['Intake', 'Doctor review', 'Treatment plan']}
            currentIndex={1}
            hideButton
          />
        )}

        {/* encounter for treatment plan not in progress AND doesn't have a consult checkout */}
        {!isAwaitingDoctor && !hasMhtOrCompletedConsult && (
          <EmptyStateBlock
            title='Hormonal Menopause Treatment Plan'
            buttonTitle='Start your intake now'
          />
        )}

        {!!retentionProducts.length &&
          !pendingCharges.cancelRetentionApproval.length &&
          retentionProducts.flat().map((gcp, index) => (
            <ActiveProduct
              key={gcp.contentfulProduct.sys.id + '-retention-' + index}
              product={gcp}
              isProcessing
              showDosage={false}
              customHug='Paused'
            >
              <ProgressBlock
                description="Your treatment is paused while your doctor reviews your notes. They'll respond soon, usually within 1-3 days."
                barTitles={['Request', 'Doctor review', 'Approve treatment']}
                currentIndex={1}
                hideButton
              />

              <p className='product-text'>
                Any questions about your order at all, email us at{' '}
                <EmailUs btnClass='text-underline' />. We're here for you!
              </p>
            </ActiveProduct>
          ))}
      </div>

      <DividerWrapper />
    </>
  );
}
