import './ErrorReview.scss';

interface Props {
  reviewItems: string[];
}

export default function ErrorReviewWrapper({ reviewItems }: Props) {
  return (
    <div className='ce-error-wrapper'>
      <p className='error-text'>Please review your information.</p>

      <ul className='error-list'>
        {reviewItems.map((text, index) => (
          <li key={index} className='list-text'>
            {text}
          </li>
        ))}
      </ul>
    </div>
  );
}
