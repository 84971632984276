import './Reschedule.scss';

import { useEffect, useState } from 'react';

import { ShippingMethodType, useGetShippingMethods } from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { useSubscriptionContext } from 'modules/dashboard/sub-modules/manage-subscription/context/manage';

import { formatProductNames } from 'modules/shared/lib/product/name';

import { useAppSelector } from 'shared/store/reducers';

import CalendarBlock from '../blocks/Calendar';
import ProductListBlock from '../blocks/ProductList';
import ShippingBlock from '../blocks/Shipping';

interface Props {
  products: GroupedContentfulProduct[][];
  onConfirm: (date: Date, shippingMethodId?: number) => void;
  parentProducts: GroupedContentfulProduct[];
}

export default function RescheduleWrapper({ products, onConfirm, parentProducts }: Props) {
  const { subscription } = useSubscriptionContext();

  const [selectedDate, setSelectedDate] = useState(subscription.nextRecurrenceOn);

  const customer = useAppSelector((state) => state.alloy.customer!);

  const { data: shippingMethods = [], isLoading: isLoadingShippingMethods } = useGetShippingMethods(
    customer.stateAbbr!.toUpperCase(),
  );

  const [selectedShippingType, setSelectedShippingType] = useState<ShippingMethodType>('STANDARD');

  const foundSelectedShippingMethod = shippingMethods.find(
    (sm) => sm.method === selectedShippingType,
  );

  useEffect(() => {
    const upcomingInvoice = subscription?.upcomingInvoice;

    if (!!upcomingInvoice) {
      const foundShippingMethod = shippingMethods.find(
        (sm) => sm.priceInCents === upcomingInvoice.shipping,
      );

      if (foundShippingMethod) {
        setSelectedShippingType(foundShippingMethod.method);
      }
    }
  }, [shippingMethods.length, isLoadingShippingMethods]);

  return (
    <div className='reschedule-wrapper'>
      {!!parentProducts.length && (
        <p className='content-text'>
          This treatment is only eligible for a discount when shipped together with{' '}
          {formatProductNames(parentProducts)}.
        </p>
      )}

      <ProductListBlock products={products} />

      <CalendarBlock
        shippingMethod={foundSelectedShippingMethod}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />

      <ShippingBlock
        previousType={foundSelectedShippingMethod?.method}
        shippingMethods={shippingMethods}
        onSelect={(sm) => setSelectedShippingType(sm.method)}
      />

      <button
        onClick={() =>
          onConfirm(
            selectedDate!,
            selectedShippingType !== 'STANDARD' ? foundSelectedShippingMethod?.id : undefined,
          )
        }
        className='primary-button'
        disabled={!selectedDate}
      >
        Confirm
      </button>
    </div>
  );
}
