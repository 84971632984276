import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { EXPERIENCE_SHOW_KNOWN_PREFERENCES_TESTING } from 'shared/store/actions/GlobalTypes';

import { showKnownPreferencesPage } from '../lib/get-tests';
import { updateTest } from '../store/actions';

interface ReturnProps {
  initABTests: () => Promise<void>;
}

export default function useABTests(): ReturnProps {
  const dispatch = useDispatch();

  const dispatchUpdateTest = bindActionCreators(updateTest, dispatch);

  const initABTests = async () => {
    const knownPreferencesTesting = showKnownPreferencesPage();

    dispatchUpdateTest(EXPERIENCE_SHOW_KNOWN_PREFERENCES_TESTING, knownPreferencesTesting);
  };

  return { initABTests };
}
