import Layout from 'shared/components/core/Layout';

import useReviewData from '../../hooks/useReviewData';
import BundledContent from '../content/Bundled';
import DoctorConsultContent from '../content/DoctorConsult';
import IndividualContent from '../content/Individual';
import SkincareContent from '../content/Skincare';

export default function ReviewInfoWrapper() {
  const { isBundled, isConsult, isSkin } = useReviewData();

  switch (true) {
    case isConsult: {
      return (
        <Layout title='Review - Alloy' desc='' noBars>
          <DoctorConsultContent />
        </Layout>
      );
    }

    case isSkin: {
      return (
        <Layout title='Review - Alloy' desc='' noBars>
          <SkincareContent />
        </Layout>
      );
    }

    case isBundled: {
      return (
        <Layout title='Review - Alloy' desc='' noBars>
          <BundledContent />
        </Layout>
      );
    }

    default: {
      return (
        <Layout title='Review - Alloy' desc='' noBars>
          <IndividualContent />
        </Layout>
      );
    }
  }
}
