import './Header.scss';

import { useState } from 'react';

import { useGetCustomerReferralCode } from 'client/dist/generated/alloy';

import copiedCodeIcon from 'modules/dashboard/sub-modules/account/sub-modules/referrals/assets/svg/copied-code-icon.svg';
import copyCodeIcon from 'modules/dashboard/sub-modules/account/sub-modules/referrals/assets/svg/copy-code-icon.svg';

import usePosthog from 'shared/hooks/usePosthog';

import { decemberFlag } from 'shared/lib/posthog';

export default function HeaderBlock() {
  const { data: code = '' } = useGetCustomerReferralCode();

  const [isCopied, setIsCopied] = useState(false);
  const { isEnabled: isDecember } = usePosthog(decemberFlag);

  const onCopy = () => {
    navigator.clipboard.writeText(code);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className='referrals-header-block'>
      <div className='referrals-header-hero'>
        <h1 className='hero-title'>
          {isDecember ? 'Share the love (and health)!' : 'Help a friend'}
          <br />
          <span> {isDecember ? 'Gift a free consult, Get $50' : 'Give $25, Get $15'}</span>
        </h1>
      </div>

      <div className='referrals-header-content'>
        <p className='content-title'> {isDecember ? 'Black Friday Promo' : 'Refer a Friend'}</p>
        <p className='content-text'>
          {isDecember
            ? "Treat a friend to a free consult (worth $49) and you'll receive a $50 credit toward Alloy products. Win-win!"
            : 'Give your friends $25 off their first purchase and receive a $15 credit towards your next shipment.'}
        </p>

        <div className='content-code-wrapper'>
          <p className='code-text'>Enter code at checkout</p>
          <p className='code-input'>{code}</p>

          <button className='primary-button full-width-button' onClick={onCopy}>
            <img
              src={isCopied ? copiedCodeIcon : copyCodeIcon}
              alt='copy icon'
              className='button-icon'
            />
            {isCopied ? 'Copied' : 'Copy code'}
          </button>

          <a
            href={process.env.REACT_APP_MARKETING_URL + '/terms-of-use'}
            target='_blank'
            rel='noreferrer'
            className='code-link'
          >
            Terms & Conditions apply
          </a>
        </div>
      </div>
    </div>
  );
}
