import { uniq } from 'lodash';

import { useExperience } from 'modules/shared/context/experience';

import useRenewalState from 'modules/renewal/hooks/useRenewalState';

import QuestionnaireWrapper from 'modules/questionnaire/ui/wrappers/Questionnaire';

import Loader from 'shared/components/content/Loader';

export default function RenewalFormWrapper() {
  const { onBack } = useExperience();
  const {
    isLoading,
    isLoadingSubscriptions,

    intakeQuestions,
    previousResults,
    expiringProductFrequencies,

    onSubmit,
  } = useRenewalState();

  if (isLoading || isLoadingSubscriptions) return <Loader />;

  return (
    <QuestionnaireWrapper
      questions={intakeQuestions}
      previousResults={previousResults}
      onSubmit={onSubmit}
      onBack={onBack}
      hideProgressBar
      basePath='/checkout-experience/renewal'
      metaTitle='Renewal | Alloy'
      relevantProducts={uniq(
        expiringProductFrequencies.map((pf) => {
          return { name: pf.name, category: pf.category };
        }),
      )}
    />
  );
}
