import './ReviewDrawer.scss';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import useCart from 'modules/shared/hooks/useCart';

import { cleanPurchasableProducts } from 'modules/request-experience/lib/flow';
import {
  getDeepProductsFromGroupedProducts,
  getProductsToBeBundledWith,
} from 'modules/shared/lib/product';
import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';

import BundleViewableProduct from 'modules/shared/ui/blocks/BundleViewableProduct';
import ListViewableProducts from 'modules/shared/ui/blocks/ListViewableProducts';
import ViewableProduct from 'modules/shared/ui/blocks/ViewableProduct';

import AlloyDrawer from 'shared/components/core/AlloyDrawer';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  notPurchasedEligibleProducts: GroupedContentfulProduct[];
  activeSubProducts: GroupedContentfulProduct[][];
}

export default function ReviewDrawer({
  open,
  setOpen,
  notPurchasedEligibleProducts,
  activeSubProducts,
}: Props) {
  const navigate = useNavigate();

  const { updateCart } = useCart();

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const { activeSubs, pausedSubs } = getSubscriptionsWithStatus(subscriptions);

  const [products, setProducts] = useState<GroupedContentfulProduct[][]>([]);
  const [notPurchasedProducts, setNotPurchasedProducts] = useState<GroupedContentfulProduct[][]>(
    [],
  );

  const { cart } = useCart();

  const activeSubscriptions = [...activeSubs, ...pausedSubs];

  const filteredNotPurchasedProducts = notPurchasedEligibleProducts.filter(
    (groupedContentful) =>
      !cart.products
        .map((p) => p.contentfulProduct?.sys.id)
        .includes(groupedContentful.contentfulProduct.sys.id),
  );

  useEffect(() => {
    fetchProducts();

    // on unmount, reset!
    return () => {
      setProducts([]);
      setNotPurchasedProducts([]);
    };
  }, [JSON.stringify(cart)]);

  const fetchProducts = async () => {
    const cartProductIds = getDeepProductsFromGroupedProducts(cart.products);
    const notPurchasedProductIds = getDeepProductsFromGroupedProducts(filteredNotPurchasedProducts);

    const [cartProducts, notPurchasedProducts] = await Promise.all([
      ProductRegistry.get().getRecurringProductsForV2(cartProductIds),
      ProductRegistry.get().getRecurringProductsForV2(notPurchasedProductIds),
    ]);

    setProducts(cartProducts);
    setNotPurchasedProducts(notPurchasedProducts);
  };

  const onAddProduct = (product: GroupedContentfulProduct) => {
    updateCart({
      products: [...cart.products, product],
    });
  };

  const onContinue = async () => {
    // this allows to get the proper products to put in the cart, mainly for bundles:
    // ex: if m4 exists and select tret then get the right tret bundle pf
    // ex: if just tret, get the unbundled tret
    const products = getDeepProductsFromGroupedProducts(cart.products);

    const activeSubsProducts = activeSubscriptions.flatMap((sub) =>
      sub.products.map((pfr) => pfr.product),
    );

    const filteredProducts = await cleanPurchasableProducts(products, activeSubsProducts);

    updateCart({
      products: filteredProducts,
      shipNow: !activeSubs.length,
    });

    navigate('/treatment-plan/checkout');
  };

  return (
    <AlloyDrawer
      title='Review'
      drawerClass='review-drawer'
      open={open}
      onClose={() => setOpen(false)}
    >
      <div className='drawer-body'>
        <div className='review-products-wrapper'>
          {
            <ListViewableProducts
              products={products}
              subscriptions={subscriptions}
              showDosage
              hideDetails
            />
          }
        </div>

        <div className='drawer-divider' />

        {notPurchasedProducts.length !== 0 && (
          <div className='review-products-wrapper'>
            <p className='review-text'>Need anything else?</p>

            {notPurchasedProducts.map((gcpList, listIndex) => {
              return gcpList.map((gcp, index) => {
                const parents = getProductsToBeBundledWith(gcp, activeSubProducts);
                return !!parents.length ? (
                  <BundleViewableProduct
                    key={`${listIndex}-${index}`}
                    groupedProduct={gcp}
                    isParentSelected
                    showDosage
                    parents={parents}
                  >
                    <button className='product-action-btn' onClick={() => onAddProduct(gcp)}>
                      Add to plan
                    </button>
                  </BundleViewableProduct>
                ) : (
                  <ViewableProduct key={`${listIndex}-${index}`} groupedProduct={gcp} showDosage>
                    <button className='product-action-btn' onClick={() => onAddProduct(gcp)}>
                      Add to plan
                    </button>
                  </ViewableProduct>
                );
              });
            })}
          </div>
        )}
      </div>

      <div className='drawer-footer'>
        <button onClick={onContinue} className='btn-save' disabled={cart.products.length === 0}>
          Continue to checkout
        </button>
      </div>
    </AlloyDrawer>
  );
}
