import './EditProduct.scss';

import { lowerCase } from 'lodash';

import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { getDeepProductsFromGroupedProducts } from 'modules/shared/lib/product';
import { areBundlePriceProducts } from 'modules/shared/lib/product/bundle-pairing';
import { formatProductNames, getProductNameWithDosage } from 'modules/shared/lib/product/name';

import { ManageType } from 'modules/dashboard/sub-modules/manage-subscription/models/manage-type';

import alertIcon from 'shared/assets/svg/common/alert-info-icon.svg';

import { useQueryParams } from 'shared/context/url/query';

import ProductListBlock from '../blocks/ProductList';

interface Props {
  products: GroupedContentfulProduct[][];
  parentProducts: GroupedContentfulProduct[];
}

export default function EditProductWrapper({ products, parentProducts }: Props) {
  const { setParam } = useQueryParams();

  const onSelect = (manageType: ManageType) => {
    setParam('manage', lowerCase(manageType).replaceAll('_', '-'));
  };

  return (
    <div className='edit-product-wrapper'>
      <ProductListBlock products={products} />

      {!!parentProducts.length && areBundlePriceProducts(products) && (
        <div className='product-discount-block'>
          <p className='discount-title'>
            <img src={alertIcon} alt='alert info icon' className='title-icon' />
            Don't lose your discount
          </p>

          <p className='discount-text'>
            This treatment is eligible for a discount when shipped together with{' '}
            {formatProductNames(parentProducts)}. If shipped separately, you'll be charged the full
            price.
          </p>
        </div>
      )}

      <div className='product-btns-block'>
        <button className='secondary-border-button' onClick={() => onSelect('RESCHEDULE')}>
          Reschedule
        </button>

        <button className='primary-button' onClick={() => onSelect('SHIP_NOW')}>
          Ship now
        </button>
      </div>

      <button className='primary-link' onClick={() => onSelect('CANCEL')}>
        Cancel subscription
      </button>
    </div>
  );
}
