import './BottomBar.scss';

import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export default function BottomBar({ children }: Props) {
  return <section className='bottom-bar-section'>{children}</section>;
}
