import classNames from 'classnames';
import { useState } from 'react';
import { Drawer, Loader } from 'rsuite';

import { sendExceptionToSentry } from 'modules/tracking/lib/sentry';

import closeIcon from 'shared/assets/svg/common/btn-close.svg';

import { EmailUs } from 'shared/components/content/Buttons';
import Form from 'shared/components/core/Form';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'shared/components/core/Notification';
import PasswordFieldsGroup from 'shared/components/fields/PasswordFieldsGroup';
import { TextField } from 'shared/components/fields/TextField';

import { updatePassword } from 'shared/lib/aws/aws-auth';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

type PasswordForm = {
  currentPassword: string;
  newPassword: string;
  checksSucceeded: boolean;
};

const INIT_PASSWORD_FORM: PasswordForm = {
  currentPassword: '',
  newPassword: '',
  checksSucceeded: false,
};

export default function PasswordDrawer({ open, setOpen }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const [passwordForm, setPasswordForm] = useState<PasswordForm>(INIT_PASSWORD_FORM);

  const onSave = async () => {
    try {
      setLoading(true);

      const { currentPassword, newPassword } = passwordForm;

      await updatePassword(currentPassword, newPassword);

      setPasswordForm(INIT_PASSWORD_FORM);

      showSuccessNotification('Successfully updated your password');

      setLoading(false);
      setOpen(false);
    } catch (error) {
      showErrorNotification((error as Error).message);

      sendExceptionToSentry(error as Error);
      setLoading(false);
    }
  };

  const onClose = () => {
    if (!loading) {
      setOpen(false);
    }
  };

  return (
    <Drawer
      open={open}
      size='sm'
      className={classNames('alloy-drawer password-drawer', open ? 'drawer-open' : 'drawer-closed')}
      onClose={onClose}
    >
      <Drawer.Body>
        <div className='drawer-header'>
          <p className='header-title'>Change Password</p>

          <button onClick={onClose} type='button' className='header-btn-close'>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>
        </div>

        {loading ? (
          <Loader center size='lg' />
        ) : (
          <Form onSubmit={onSave}>
            <div className='drawer-body'>
              <div className='password-form-wrapper'>
                <TextField
                  name='currentPassword'
                  type='password'
                  label='Current Password'
                  value={passwordForm.currentPassword}
                  onChange={(value) => setPasswordForm({ ...passwordForm, currentPassword: value })}
                />

                <PasswordFieldsGroup
                  useConfirm
                  onChange={(checksSucceeded, newPassword) =>
                    setPasswordForm({
                      ...passwordForm,
                      checksSucceeded,
                      newPassword,
                    })
                  }
                  passwordReset
                  hideVisibleAndPlaceholder
                />
              </div>

              <div className='profile-help-block'>
                <p className='help-title'>Need Help?</p>
                <p className='help-text'>
                  To make changes to your email contact us.{' '}
                  <EmailUs btnClass='primary-link-button' />
                </p>
              </div>
            </div>

            <div className='drawer-footer'>
              <button
                type='submit'
                className='btn-save'
                disabled={
                  !passwordForm.currentPassword.trim() ||
                  !passwordForm.newPassword.trim() ||
                  !passwordForm.checksSucceeded
                }
              >
                Save
              </button>
            </div>
          </Form>
        )}
      </Drawer.Body>
    </Drawer>
  );
}
