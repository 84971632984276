import DashboardContainer from 'modules/dashboard/ui/containers/DashboardContainer';

import SubscriptionsListWrapper from 'modules/dashboard/sub-modules/subscriptions/ui/wrappers/SubscriptionsList';

import TitleTextWrapper from 'shared/components/wrappers/TitleText';

import useMarkNotificationsAsRead from 'shared/hooks/useMarkNotificationsAsRead';

export default function Subscriptions() {
  // mark notifications as read once on everytime going to page OR reloading
  useMarkNotificationsAsRead(['PRESCRIPTION_AUTOPAUSED']);

  return (
    <DashboardContainer title='Subscriptions | Alloy' desc='' currentPage='subscriptions'>
      <section className='dashboard-section'>
        <div className='container'>
          <TitleTextWrapper title='Subscriptions' />

          <SubscriptionsListWrapper />
        </div>
      </section>
    </DashboardContainer>
  );
}
