import { flatten } from 'lodash';
import { useEffect, useState } from 'react';

import { DeepProduct } from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

interface Props {
  deepProducts: DeepProduct[];
  cachedProducts?: GroupedContentfulProduct[][];
  dependencies?: (string | boolean)[]; // we only really care for strings or booleans and that is also a common pattern in useEffect
}

interface ReturnProps {
  isLoading: boolean;
  products: GroupedContentfulProduct[][];
  flattenedProducts: GroupedContentfulProduct[];
}

export default function useGroupedProducts({
  deepProducts,
  cachedProducts,
  dependencies = [],
}: Props): ReturnProps {
  const [products, setProducts] = useState<GroupedContentfulProduct[][]>(cachedProducts || []);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (cachedProducts?.length || !deepProducts.length) {
      return;
    }

    const fetchProducts = async () => {
      setIsLoading(true);

      const fetched = await ProductRegistry.get().getRecurringProductsForV2(deepProducts);

      setProducts(fetched);
      setIsLoading(false);
    };

    fetchProducts();
  }, dependencies);

  return { isLoading, products, flattenedProducts: flatten(products) };
}
