import { getDeepProductIdsFrom, getDeepProductsFromGroupedProducts } from '.';

import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

export const areBundlePriceProducts = (products: GroupedContentfulProduct[][]) =>
  getDeepProductsFromGroupedProducts(products.flat()).every((dp) => dp.isBundledPrice);

/**
 * used to grab the potential bundle parents from a 2D array of products, used when need to know what
 * other products are in a grouping
 *
 * @param selectedProducts
 * @param products
 * @returns
 */
export const getFilteredBundlePairings = (
  selectedProducts: GroupedContentfulProduct[][],
  products: GroupedContentfulProduct[][],
) => {
  if (!areBundlePriceProducts(selectedProducts)) return [];

  const selectedDeepProductIds = selectedProducts.flatMap((gcpList) =>
    gcpList.flatMap((gcp) => getDeepProductIdsFrom(gcp)),
  );

  const potentialParents =
    products.find((gcpList) =>
      gcpList.some((gcp) =>
        getDeepProductIdsFrom(gcp).every((dpId) => selectedDeepProductIds.includes(dpId)),
      ),
    ) || [];

  return potentialParents.filter((gcp) =>
    getDeepProductIdsFrom(gcp).every((dpId) => !selectedDeepProductIds.includes(dpId)),
  );
};
