import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'shared/store/reducers';

import {
  Customer,
  PreSubmission,
  SubmissionAnswer,
  postPreIdentifiedSubmission,
  postSubmission,
} from 'client/dist/generated/alloy';

export default function useSymptomSurveyState() {
  const navigate = useNavigate();

  const dateStarted = new Date();

  const [isLoading, setIsLoading] = useState(false);

  const customer: Customer | undefined = useAppSelector((state) => state.alloy.customer);

  const onBack = () => navigate(-1);

  const onSubmit = async (answers: SubmissionAnswer[]) => {
    try {
      setIsLoading(true);

      const submission: PreSubmission = {
        categories: ['symptom-survey'],
        started: dateStarted,
        answers: answers,
      };

      if (customer) {
        await postSubmission(submission);
      } else {
        await postPreIdentifiedSubmission(submission);
      }

      navigate('/');
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    isLoading,

    onBack,
    onSubmit,
  };
}
