import { sumBy, uniq } from 'lodash';

import ProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { DeepProduct } from 'common/dist/products/productFrequency';

/**
 * the FE mainly uses this to get the right product price, this allows for the cart/product cards to display the right bundled/unbundled price when needed.
 * mainly used for bundling since we have the other prices correctly
 *
 * @param product DeepProduct
 * @param parents parents of a possible discounted product. used to help fetch the correct bundled price of a product
 * @returns number
 */
export const getProductPrice = async (
  product: DeepProduct,
  parents: DeepProduct[] = [],
): Promise<number> => {
  const { productId, frequencyId, dosageId, formFactorId } = product;

  return (
    await ProductRegistry.get().getProductFrequency(
      productId,
      frequencyId,
      dosageId,
      formFactorId,
      uniq(parents.map((p) => p.productId)),
    )
  ).priceInCents;
};

export const getSubtotalInCentsFrom = (products: GroupedContentfulProduct[][]) =>
  sumBy(
    products.flatMap((gcpList) =>
      gcpList.flatMap((gcp) => [...gcp.alloyProduct.parent, ...(gcp.alloyProduct.child || [])]),
    ),
    (pf) => pf.priceInCents,
  );
