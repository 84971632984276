import { useExperience } from 'modules/shared/context/experience';

import useCheckoutState from 'modules/request-experience/sub-modules/checkout/hooks/useCheckoutState';

import CheckoutFormWrapper from 'modules/request-experience/sub-modules/checkout/ui/wrappers/CheckoutForm';

import Layout from 'shared/components/core/Layout';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

export default function CheckoutPage() {
  const { isAnyLoading } = useCheckoutState();
  const { onBack } = useExperience();

  return (
    <Layout title='Checkout - Alloy' desc='' noBars>
      <TopBannerWithProgress onBack={isAnyLoading ? undefined : onBack} />

      <CheckoutFormWrapper />
    </Layout>
  );
}
