import { upperCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SubscriptionWithRenewal } from 'client/dist/generated/alloy';

import useInitExperience from 'modules/shared/hooks/useInitExperience';

import { getDeepProductIdsFrom } from 'modules/shared/lib/product';
import { getProductNameWithDosage } from 'modules/shared/lib/product/name';

import { ProductRenewalPrescription } from 'modules/dashboard/sub-modules/treatment-plan/models/product-renewal-prescription';

import { useQueryParams } from 'shared/context/url/query';

import useSwitchable from 'shared/hooks/product/useSwitchable';

interface Props {
  product: ProductRenewalPrescription;
  subscription?: SubscriptionWithRenewal;
  isProcessing?: boolean;
  showDosage?: boolean;
}

export default function useActiveProductState({
  product,
  subscription,
  isProcessing,
  showDosage = false,
}: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  const { initCheckoutExperience } = useInitExperience();

  const { getParam, navigateWithParams } = useQueryParams();
  const { isSwitchable } = useSwitchable({ product });

  const { alloyProduct, contentfulProduct, renewal: renewalInfo } = product;

  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isSwitchOpen, setIsSwitchOpen] = useState(false);

  const onOpenInfo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setIsInfoOpen(true);
  };

  const onOpenSwitch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    navigateWithParams('/treatment-plan', {
      manage: 'change',
      'deepProductIds[]': getDeepProductIdsFrom(product).join(','),
    });
  };

  const onCloseSwitch = () => {
    setIsSwitchOpen(false);

    navigate('/treatment-plan');
  };

  const onRenew = async () => await initCheckoutExperience(['renewal']);

  const productsCleanNameAndDosage = showDosage
    ? getProductNameWithDosage(product)
    : [{ cleanName: contentfulProduct.fields.title }];
  /**
   * Variables used to identify items to show for renewal, one time product, and rx
   */
  const isSynbioticProduct = alloyProduct.parent.every((pf) => pf.category === 'gut-health');
  const needsRenewed = renewalInfo?.needsRenewed ?? false;
  const renewalCompletedDate = renewalInfo?.completedDate;
  const isOneTimeProduct = alloyProduct.parent.every((pf) => pf.recurrenceType === 'ONE_TIME');
  const isRx = alloyProduct.parent.every((pf) => pf.type === 'RX');

  const showSwitch =
    isSwitchable &&
    !needsRenewed &&
    !isProcessing &&
    subscription?.status === 'ACTIVE' &&
    !renewalCompletedDate;
  const showFills = !isSynbioticProduct && !isProcessing;
  const showNextShipment = subscription?.status === 'ACTIVE' && !needsRenewed && !isProcessing;

  const showExpiration =
    (needsRenewed || isOneTimeProduct) && !renewalInfo?.hasExpired && !renewalCompletedDate;

  useEffect(() => {
    const productIdsString = getParam('deepProductIds[]');
    const manage = upperCase(getParam('manage') || '').replaceAll(' ', '_');

    if (manage === 'CHANGE' && !needsRenewed && !isProcessing) {
      const convertedProductIds = (productIdsString || '').split(',').map(Number);

      if (
        convertedProductIds.length &&
        convertedProductIds.every((dpId) => getDeepProductIdsFrom(product).includes(dpId))
      ) {
        setIsSwitchOpen(true);
      }
    }
  }, [location.search]);

  return {
    isRx,
    productsCleanNameAndDosage,
    showFills,
    showNextShipment,
    isSynbioticProduct,
    showExpiration,
    showSwitch,
    renewalCompletedDate,
    isOneTimeProduct,
    needsRenewed,

    isInfoOpen,
    setIsInfoOpen,

    isSwitchOpen,

    onOpenInfo,
    onOpenSwitch,
    onCloseSwitch,
    onRenew,
  };
}
