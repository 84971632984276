import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { confirmRegistration } from 'modules/authentication/sub-modules/verification/lib/actions';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'shared/components/core/Notification';
import { resendVerificationCode } from 'shared/lib/aws/aws-auth';
import { buildCheckoutExperienceLoginURL } from 'modules/shared/lib/url';
import { posthogCapture } from 'modules/tracking/lib/posthog';
import { ExperienceComponentModel } from 'modules/shared/models/experience';
import { useAppSelector } from 'shared/store/reducers';
import { VerificationForm } from '../models/verification-form';

const INIT_VERIFICATION_FORM: VerificationForm = {
  email: '',
  password: '',
  code: '',
  confirmedChecksSucceeded: false,
};

export default function useVerificationState({
  onBack,
  onNext,
  standaloneVerificationParams,
}: ExperienceComponentModel) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [verificationForm, setVerificationForm] =
    useState<VerificationForm>(INIT_VERIFICATION_FORM);

  const { isAuthenticated } = useAppSelector((state) => state.alloy);
  const { cognitoUser } = useAppSelector((state) => state.experience);

  const dispatchConfirmRegistration = bindActionCreators(confirmRegistration, dispatch);

  useEffect(() => {
    if (isAuthenticated && onBack) {
      onBack();
    } else {
      setVerificationForm({
        ...INIT_VERIFICATION_FORM,
        email: cognitoUser?.email || standaloneVerificationParams?.email || '',
      });
    }
  }, []);

  // Submit the login and if needed to change password, submit the new password changed
  const onSubmit = async () => {
    window.scrollTo(0, 0);

    try {
      setIsLoading(true);

      const { email, code } = verificationForm;

      const password = cognitoUser ? cognitoUser.password : verificationForm.password;

      const isStandaloneVerification =
        standaloneVerificationParams?.email || standaloneVerificationParams?.password;

      await dispatchConfirmRegistration(email, password, code, !isStandaloneVerification);

      posthogCapture('accountVerified');

      onNext();
    } catch (error: any) {
      if (error.message && error.message.includes('Incorrect username or password')) {
        showErrorNotification(
          'Account verified but email or password was incorrect. Please login again.'
        );

        navigate(buildCheckoutExperienceLoginURL(location));
      } else {
        setIsLoading(false);
      }
    }
  };

  const onResendCode = async () => {
    try {
      const { email } = verificationForm;

      setIsLoading(true);

      await resendVerificationCode(email);

      setIsLoading(false);

      showSuccessNotification('Verification code resent successfully');
    } catch (error) {
      const errorMessage = (error as Error).message;

      setIsLoading(false);

      showErrorNotification(errorMessage);
    }
  };

  return {
    isLoading,

    cognitoUser,

    verificationForm,
    setVerificationForm,

    onResendCode,
    onSubmit,
  };
}
