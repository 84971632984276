import { sumBy } from 'lodash';

import { PendingSwitch, SubscriptionWithRenewal } from 'client/dist/generated/alloy';

import { TotalsInCents } from 'modules/shared/models/invoice';

import { getTotalsByInvoice } from '../invoice/calculations';

/**
 * isProcessing comes from the sub level here for when a sub could be in renewal / charging state.
 * this only tells us that bit and then the pending switch state focuses on whether a sub that
 * has mht and is in a switching state (form factor or dose) then that tells us the sub is still
 * processing atm
 *
 * TODO: isProcessing should come back on the actual subscription level for the FE, there is some
 * clean up to handle where this shouldn't really exist for FE
 *
 * @param isProcessing boolean
 * @param subscription SubscriptionWithRenewal
 * @param pendingSwitch PendingSwitch
 * @returns boolean
 */
export const isSubscriptionProcessing = (
  isProcessing: boolean,
  subscription: SubscriptionWithRenewal,
  switchableProductIds: number[],
  pendingSwitch?: PendingSwitch
): boolean => {
  const hasPendingSwitch =
    subscription.products.some((pfr) => switchableProductIds.includes(pfr.product.id)) &&
    !!pendingSwitch &&
    (pendingSwitch.isSwitchDose || pendingSwitch.isSwitchFormFactor);

  return subscription.status !== 'INACTIVE' && (isProcessing || hasPendingSwitch);
};

export const getTotalsForSubscriptions = (
  subscriptions: SubscriptionWithRenewal[]
): TotalsInCents => {
  const totals: TotalsInCents[] = subscriptions
    .filter((sub) => !!sub.upcomingInvoice)
    .map((sub) => getTotalsByInvoice(sub.upcomingInvoice!));

  return {
    subtotal: sumBy(totals, 'subtotal'),
    tax: sumBy(totals, 'tax'),
    shipping: sumBy(totals, 'shipping'),
    discount: sumBy(totals, 'discount'),
    total: sumBy(totals, 'total')
  };
};
