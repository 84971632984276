import { isEmpty } from 'lodash';
import { useState } from 'react';

import { useCancelContext } from 'modules/dashboard/sub-modules/manage-subscription/context/cancel';

import { TextAreaField } from 'shared/components/fields/TextAreaField';

export default function OtherContent() {
  const [inputText, setInputText] = useState('');

  const { onCancel, onSaveSubmission } = useCancelContext();

  const onSubmitCancel = async () => {
    await onSaveSubmission({ 'cancel-explanation': inputText });

    await onCancel();
  };

  return (
    <div className='cancel-content-wrapper'>
      <div className='content-block'>
        <h4 className='content-title'>Please let us know why you'd like to cancel:</h4>
      </div>

      <form>
        <TextAreaField
          name='other-field'
          value={inputText}
          onChange={setInputText}
          placeholder='Type here'
        />
      </form>

      <div className='btns-stack-block'>
        <button className='primary-button' disabled={isEmpty(inputText)} onClick={onSubmitCancel}>
          Cancel subscription
        </button>
      </div>
    </div>
  );
}
