export const NAVIGATION_TABS = [
  {
    title: 'home',
    link: '/home',
  },
  {
    title: 'treatment plan',
    link: '/treatment-plan',
  },
  {
    title: 'messages',
    link: '/messages',
  },
  {
    title: 'subscriptions',
    link: '/subscriptions',
  },
  {
    title: 'account',
    link: '/account/profile',
  },
];
