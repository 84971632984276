import NameFormWrapper from 'modules/checkout-experience/sub-modules/name/ui/wrappers/NameForm';

import Layout from 'shared/components/core/Layout';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

export default function NamePage() {
  return (
    <Layout title='Name - Alloy' desc='' noBars>
      <TopBannerWithProgress />

      <NameFormWrapper />
    </Layout>
  );
}
