import './Content.scss';

import classNames from 'classnames';
import { format } from 'date-fns';
import { capitalize } from 'lodash';

import { ApiOrder, Status } from 'client/dist/generated/alloy';

import useTotalProductPrices from 'modules/shared/hooks/useTotalProductPrices';

import { displayFriendlyOrderStatus } from 'modules/dashboard/lib/friendly-order-status';

import DownloadInvoiceButton from 'modules/dashboard/ui/content/DownloadInvoiceButton';

import CardIcon from 'shared/components/content/CardIcon';

import { convertCentsToDollars } from 'shared/lib/convert';
import { prettyPrintPhone } from 'shared/lib/phone';

import { useAppSelector } from 'shared/store/reducers';

interface Props {
  order: ApiOrder;
}

export default function ContentBlock({ order }: Props) {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const { unbundledTotal, bundledTotal } = useTotalProductPrices({
    products: order.productFrequencies,
  });

  const isBundled = unbundledTotal - bundledTotal !== 0;

  const showDownloadInvoice =
    order.internalStatus === Status.SENT_TO_FULFILLMENT ||
    order.internalStatus == Status.SHIPPED ||
    order.internalStatus === Status.DELIVERED;

  return (
    <div className='order-content-block'>
      <div className='order-content'>
        <div className='content-header'>
          <div className='row'>
            {!order.isConsult && (
              <div className='col-4'>
                <p className='header-title'>Shipping Address</p>
              </div>
            )}

            <div className='col-4'>
              <p className='header-title'>Payment Method</p>
            </div>

            <div className='col-4'>
              <p className='header-title'>{order.isConsult ? 'Payment' : 'Order'} Summary</p>
            </div>
          </div>
        </div>

        <div className='content-details'>
          <div className='row'>
            {!order.isConsult && (
              <div className='col-12 col-md-4'>
                <div className='content-wrapper'>
                  <p className='content-title hide-large'>Shipping Address</p>

                  <p className='content-text'>
                    {order.shippingAddress?.firstName} {order.shippingAddress?.lastName}
                  </p>

                  <p className='content-text'>{order.shippingAddress?.line1}</p>

                  <p className='content-text'>{order.shippingAddress?.line2}</p>

                  <p className='content-text'>
                    {order.shippingAddress?.city}, {order.shippingAddress?.state}
                  </p>

                  <p className='content-text'>
                    {order.shippingAddress?.zip},{' '}
                    {order.shippingAddress?.country ?? 'United States'}
                  </p>

                  <p className='content-text'>{prettyPrintPhone(customer.phoneNumber ?? '')}</p>
                </div>
              </div>
            )}

            <div className='col-12 col-md-4'>
              <div className='content-wrapper'>
                {order.paymentMethod && (
                  <>
                    <p className='content-title hide-large'>Payment Method</p>

                    <p className='content-text'>
                      <CardIcon brand={order.paymentMethod.card?.brand ?? ''} />
                      •••• •••• •••• {order.paymentMethod.card?.last4}
                    </p>
                    <p className='content-text'>{capitalize(order.paymentMethod.card?.brand)}</p>
                    <p className='content-text'>
                      Expires {order.paymentMethod.card?.exp_month}/
                      {order.paymentMethod.card?.exp_year}
                    </p>
                  </>
                )}
              </div>
            </div>

            <div className='col-8 col-md-3'>
              <div className='content-wrapper'>
                <p className='content-title hide-large'>Order Summary</p>

                <p className='content-text list-text'>
                  <span>Subtotal</span>

                  <span className='text-price-wrapper'>
                    <span className={classNames('text-price', isBundled && 'price-strikethrough')}>
                      ${unbundledTotal.toFixed(2)}
                    </span>

                    {isBundled && (
                      <span className='text-discounted-price'>${bundledTotal.toFixed(2)}</span>
                    )}
                  </span>
                </p>

                {order.discount &&
                  order.discount.map((discount, dIndex) => (
                    <p className='content-text list-text' key={dIndex}>
                      <span>Discount ({discount.name})</span>

                      <span>- ${convertCentsToDollars(discount.amount)}</span>
                    </p>
                  ))}

                <p className='content-text list-text'>
                  <span>Taxes</span>
                  <span>${convertCentsToDollars(order.taxInCents)}</span>
                </p>

                <p className='content-text list-text'>
                  <span>Shipping</span>
                  <span>${convertCentsToDollars(order.shippingMethod?.priceInCents ?? 0)}</span>
                </p>

                <p className='content-text text-bold mt-3'>
                  Order Total ${convertCentsToDollars(order.totalInCents)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='order-content'>
        <div className='content-details'>
          <div className='content-wrapper list-wrapper'>
            <div className='content-text-wrapper'>
              <p className='content-title'>{order.isConsult ? 'Date:' : 'Placed On:'}</p>
              <p className='content-text'>{format(new Date(order.createdAt!), 'MM/dd/yyyy')}</p>
            </div>

            <div className='content-text-wrapper'>
              <p className='content-title'>Status:</p>
              <p className='content-text'>{displayFriendlyOrderStatus(order)}</p>
            </div>

            <div className='content-text-wrapper'>
              <p className='content-title'>Total:</p>
              <p className='content-text'>${convertCentsToDollars(order.totalInCents)}</p>
            </div>
          </div>

          {showDownloadInvoice && (
            <div className='content-wrapper mt-3'>
              <DownloadInvoiceButton order={order} btnClass='content-text text-link' />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
