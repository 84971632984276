import './HeaderSection.scss';

import { format } from 'date-fns';
import { minBy } from 'lodash';

import {
  useGetAllSubscriptionsForCustomer,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';

import { showNewProducts } from 'modules/dashboard/lib/show-new-products';

import ActionsWrapper from 'modules/dashboard/sub-modules/home/ui/wrappers/Actions';
import CarouselWrapper from 'modules/dashboard/sub-modules/home/ui/wrappers/Carousel';

import NewProductsBlock from 'modules/dashboard/ui/blocks/NewProducts';

import SectionWrapper from 'shared/components/wrappers/Section';

import { useAppSelector } from 'shared/store/reducers';

export default function HeaderSectionWrapper() {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();
  const { data: treatmentPlan } = useGetTreatmentPlan();

  const showNewProductsBanner = showNewProducts(treatmentPlan);

  // no matter the status, the first subscription is when a customer became an actual member of alloy
  const firstSubscription = minBy(subscriptions, (sub) => new Date(sub.startedOn));

  return (
    <SectionWrapper sectionClasses='dashboard-home-header-section'>
      <div className='col-12'>
        {customer.firstName && <h1 className='header-title'>Hi, {customer.firstName}</h1>}

        {customer.status === 'ACTIVE' && !!firstSubscription && (
          <p className='header-text'>
            Member since {format(new Date(firstSubscription.startedOn), 'MMMM yyyy')}
          </p>
        )}
      </div>

      <div className='col-12'>
        <CarouselWrapper />

        {showNewProductsBanner && <NewProductsBlock theme='light' />}
        <ActionsWrapper />
      </div>
    </SectionWrapper>
  );
}
