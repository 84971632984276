import './CheckoutSection.scss';

import { useElements, useStripe } from '@stripe/react-stripe-js';
import { createRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  StripePaymentMethod,
  attachPaymentMethod,
  useGetPaymentMethods,
} from 'client/dist/generated/alloy';

import { confirmSetupIntent } from 'modules/dashboard/lib/stripe';
import { scrollToWrapper } from 'modules/shared/lib/fade-scroll';

import StatReviewWrapper from 'modules/ab-tests/sub-modules/checkout-review/ui/wrappers/StartReview';

import { showErrorNotification } from 'shared/components/core/Notification';
import SectionWrapper from 'shared/components/wrappers/Section';

import { useAppSelector } from 'shared/store/reducers';

import CartWrapper from './Cart';
import HeaderWrapper from './Header';

import MultiStepWrapper from '../content/MultiStep';
import SingleStepWrapper from '../content/SingleStep';

interface Props {
  checkoutStep?: 'shipping' | 'payment';
  onPlaceOrder: () => void;
  onNextStep?: () => void;
}

export default function CheckoutSectionWrapper({ checkoutStep, onPlaceOrder, onNextStep }: Props) {
  const elements = useElements();
  const location = useLocation();
  const stripe = useStripe();

  const paymentWrapperRef = createRef<HTMLDivElement>();

  const { data: paymentMethods = [] } = useGetPaymentMethods();

  const [isLoading, setIsLoading] = useState(false);
  const [allInputsComplete, setAllInputsComplete] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<StripePaymentMethod | null>(
    paymentMethods.find((pm) => pm.is_primary) ?? null,
  );

  const [isPaymentError, setIsPaymentError] = useState(false);
  const [isTermsError, setIsTermsError] = useState(false);

  const { cart } = useAppSelector((state) => state.experience);
  const { billingAddress } = useAppSelector((state) => state.experience);

  const { shippingAddressLineTwo, ...restBilling } = billingAddress;

  const isRequestExperience = location.pathname.includes('request-experience');

  /**
   * doesn't allow buttons to be enabled at a certain part (per payment bits and billing being incomplete)
   */
  const disableContinue = () =>
    isLoading ||
    !stripe ||
    !elements ||
    Object.values(restBilling).some((val) => val === '' || val.includes('_'));

  const onSelectPaymentMethod = (pm: StripePaymentMethod | null, isAllInputsComplete: boolean) => {
    setSelectedPaymentMethod(pm);
    setAllInputsComplete(isAllInputsComplete);

    if (!!pm || isAllInputsComplete) {
      setIsPaymentError(false);
    }
  };

  const onHandleAndPlaceOrder = async () => {
    if (!selectedPaymentMethod && !allInputsComplete) {
      scrollToWrapper(paymentWrapperRef.current);
      setIsPaymentError(true);

      return;
    }

    if (!cart.agreement.isAgreedTerms) {
      setIsTermsError(true);

      return;
    }

    setIsLoading(true);

    if (selectedPaymentMethod) {
      const paymentMethodId = selectedPaymentMethod.id;

      // TODO: Typing on the damn attach pm
      await attachPaymentMethod({ paymentMethod: paymentMethodId });

      onPlaceOrder();
    } else {
      if (!stripe || !elements) {
        setIsLoading(false);
        showErrorNotification('An unknown error occurred.');
        return;
      }

      const { error, setupIntent } = await confirmSetupIntent(stripe, elements, billingAddress);

      if (error) {
        !!error.message
          ? showErrorNotification(error.message)
          : showErrorNotification(
              'There was an issue processing your payment method. Please try again or reach out to support for assistance',
            );
        setIsLoading(false);
        return;
      }

      if (setupIntent) {
        let paymentMethodId = setupIntent.payment_method as string;

        await attachPaymentMethod({ paymentMethod: paymentMethodId });

        onPlaceOrder();
      } else {
        showErrorNotification(
          'Payment method could not be created. Please try again or reach out to support for assistance.',
        );
        setIsLoading(false);
      }
    }
  };

  return (
    <SectionWrapper sectionClasses='checkout-section'>
      <div className='col-12'>
        <HeaderWrapper />
      </div>

      <div className='col-12 col-md-7 order-2 order-md-1 checkout-col-container'>
        {/* mikhail isn't crazy about this... would love to redo + rethink some cart + flow stuff via potentially redux :thinking: but that is for later */}
        {isRequestExperience ? (
          <SingleStepWrapper
            onPlaceOrder={onHandleAndPlaceOrder}
            onSelectPaymentMethod={onSelectPaymentMethod}
            isPaymentError={isPaymentError}
            disableContinue={disableContinue()}
          />
        ) : (
          <MultiStepWrapper
            checkoutStep={checkoutStep}
            onPlaceOrder={onHandleAndPlaceOrder}
            onNextStep={onNextStep}
            onSelectPaymentMethod={onSelectPaymentMethod}
            isPaymentError={isPaymentError}
            setIsTermsError={setIsTermsError}
            isTermsError={isTermsError}
            disableContinue={disableContinue()}
            paymentWrapperRef={paymentWrapperRef}
          />
        )}
      </div>

      <div className='col-12 col-md-5 order-1 order-md-12'>
        <CartWrapper />

        <div className='d-none d-md-block'>
          <StatReviewWrapper />
        </div>
      </div>
    </SectionWrapper>
  );
}
