import { ExperienceValidationKey } from 'modules/checkout-experience/lib/validation';

import { ExperienceFlowStep } from 'modules/shared/models/experience';

import RegisterPage from 'pages/authentication/Register';
import VerificationPage from 'pages/authentication/Verification';
import CheckoutPage from 'pages/checkout-experience/Checkout';
import IntakePage from 'pages/checkout-experience/Intake';
import NamePage from 'pages/checkout-experience/Name';
import ReliefTypePage from 'pages/checkout-experience/ReliefType';
import ReviewPage from 'pages/shared/Review';
import UploadVideoPage from 'pages/shared/UploadVideo';
import VerifyIdentityPage from 'pages/shared/VerifyIdentity';

export const standardSteps: ExperienceFlowStep[] = [
  {
    validationKey: ExperienceValidationKey.name,
    component: NamePage,
    path: 'name',
    routeType: 'PUBLIC',
  },
  {
    validationKey: ExperienceValidationKey.reliefType,
    component: ReliefTypePage,
    path: 'relief-type',
    routeType: 'PUBLIC',
  },

  {
    validationKey: ExperienceValidationKey.intake,
    component: IntakePage,
    path: 'intake',
    routeType: 'PUBLIC',
    hasNested: true,
  },
  {
    validationKey: ExperienceValidationKey.review,
    component: ReviewPage,
    path: 'review',
    routeType: 'PUBLIC',
  },

  {
    validationKey: ExperienceValidationKey.register,
    component: RegisterPage,
    path: 'register',
    routeType: 'AUTH',
  },
  {
    validationKey: ExperienceValidationKey.verification,
    component: VerificationPage,
    path: 'verification',
    routeType: 'AUTH',
  },

  {
    validationKey: ExperienceValidationKey.uploadId,
    component: VerifyIdentityPage,
    path: 'verify-identity',
    routeType: 'PRIVATE',
  },
  {
    validationKey: ExperienceValidationKey.uploadVideo,
    component: UploadVideoPage,
    path: 'upload-video',
    routeType: 'PRIVATE',
  },

  {
    validationKey: ExperienceValidationKey.checkout,
    component: CheckoutPage,
    path: 'checkout',
    routeType: 'PRIVATE',
  },
];
