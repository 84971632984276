import { format } from 'date-fns';
import { minBy } from 'lodash';

import { startRenewalAlert, useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';

import useInitExperience from 'modules/shared/hooks/useInitExperience';

import { convertNumberToWords } from 'modules/dashboard/sub-modules/home/lib/numbers-to-words';
import { getSubscriptionsWithStatus } from 'modules/shared/lib/subscriptions/status';

import ProductCardBlock from './ProductCard';

export default function ExpiringCardBlock() {
  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const { activeSubs } = getSubscriptionsWithStatus(subscriptions);

  const { initCheckoutExperience } = useInitExperience();

  const expiringProducts = activeSubs
    .flatMap((sub) => sub.products)
    .filter((pfr) => !!pfr.renewal && pfr.renewal.needsRenewed && pfr.renewal.expirationDate);

  const nextExpiryRenewal = minBy(
    expiringProducts.map((pfr) => pfr.renewal),
    (renewal) => new Date(renewal?.expirationDate!),
  );

  const onRenew = async () => {
    startRenewalAlert({ productNames: expiringProducts.map((pfr) => pfr.product.name) });

    await initCheckoutExperience(['renewal']);
  };

  if (!nextExpiryRenewal) {
    return <></>;
  }

  return (
    <ProductCardBlock
      hugText='action required'
      isRequired
      title='Treatments expiring soon'
      description={`${convertNumberToWords(
        expiringProducts.length,
      )} of your treatments expire on ${format(
        new Date(nextExpiryRenewal.expirationDate!),
        'MMM d',
      )}. Renew to maintain access to your doctor.`}
      products={expiringProducts}
      maxProducts={2}
    >
      <button className='primary-button mt-3' onClick={onRenew}>
        Renew your treatment plan
      </button>
    </ProductCardBlock>
  );
}
