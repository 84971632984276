import { Elements } from '@stripe/react-stripe-js';

import CheckoutSectionWrapper from 'modules/shared/sub-modules/checkout/ui/wrappers/CheckoutSection';
import SinglePageProductPerksWrapper from 'modules/shared/sub-modules/checkout/ui/wrappers/SinglePageProductPerks';

import Loader from 'shared/components/content/Loader';
import Layout from 'shared/components/core/Layout';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

import { stripePromise } from 'shared/lib/stripe-promise';

import useCheckoutState from '../../hooks/useCheckoutState';
import useInitCheckout from '../../hooks/useInitCheckout';

export default function CheckoutFormWrapper() {
  // initiate the checkout
  useInitCheckout();

  const { isAnyLoading, setupIntent, checkoutStep, onNextStep, onBack, onPlaceOrder } =
    useCheckoutState();

  if (isAnyLoading || !setupIntent)
    return (
      <Layout title='Checkout - Alloy' desc='' noBars>
        <TopBannerWithProgress />

        <Loader />
      </Layout>
    );

  return (
    <Layout title='Checkout - Alloy' desc='' noBars>
      <TopBannerWithProgress onBack={onBack} />

      <Elements stripe={stripePromise} options={{ clientSecret: setupIntent.client_secret }}>
        <SinglePageProductPerksWrapper />

        <CheckoutSectionWrapper
          onPlaceOrder={onPlaceOrder}
          checkoutStep={checkoutStep}
          onNextStep={onNextStep}
        />
      </Elements>
    </Layout>
  );
}
