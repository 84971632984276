import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ExperienceCategory } from 'common/dist/models/experience';

import { useExperience } from 'modules/shared/context/experience';

import { fadeQuestions } from 'modules/shared/lib/fade-scroll';

import { SelectableCategory } from 'modules/ab-tests/sub-modules/known-preferences/models/preferences';

import { updateLocalPreCustomer } from 'modules/shared/store/local-pre-customer';

import { useAppSelector } from 'shared/store/reducers';

export default function useTreatmentInterests() {
  const dispatch = useDispatch();
  const { onNext } = useExperience();

  const {
    localPreCustomer: { treatmentInterests },
  } = useAppSelector((state) => state.experience);

  const dispatchUpdateLocalPreCustomer = bindActionCreators(updateLocalPreCustomer, dispatch);

  const [selectedCategories, setSelectedCategories] = useState(treatmentInterests);
  const selectionWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fadeQuestions('fade-in-wrapper', selectionWrapper);
  }, []);

  const removeCategory = (category: SelectableCategory, categories: SelectableCategory[]) =>
    setSelectedCategories(categories.filter((c) => c !== category));

  const addCategory = (
    category: SelectableCategory,
    categories: SelectableCategory[] | undefined,
  ) =>
    categories?.length
      ? setSelectedCategories([category, ...categories.filter((c) => c !== 'none')])
      : setSelectedCategories([category]);

  const handleNone = () => {
    if (selectedCategories && selectedCategories.includes('none')) {
      setSelectedCategories([]);
    } else if (selectedCategories) {
      setSelectedCategories(['none']);
    } else {
      addCategory('none', selectedCategories);
    }
  };

  const onSelectCategory = (category: SelectableCategory) => {
    if (category === 'none') {
      handleNone();
    } else if (selectedCategories && selectedCategories.includes(category)) {
      removeCategory(category, selectedCategories);
    } else {
      addCategory(category, selectedCategories);
    }
  };

  const onSubmit = () => {
    const filteredCategories = selectedCategories?.filter(
      (c) => c !== 'none',
    ) as ExperienceCategory[];
    const collatedCategories: ExperienceCategory[] = ['mht', ...filteredCategories];

    dispatchUpdateLocalPreCustomer({
      treatmentInterests: selectedCategories,
    });

    fadeQuestions('fade-out-wrapper', selectionWrapper);
    setTimeout(() => onNext(collatedCategories), 400);
  };

  return {
    selectionWrapper,

    selectedCategories,
    onSelectCategory,

    onSubmit,
  };
}
