import { DeepProduct, TreatmentPlan } from 'client/dist/generated/alloy';
import {
  M4_EYE_SERUM_PRODUCT_ID,
  M4_FACE_SERUM_PRODUCT_ID,
  M4_PRODUCT_ID,
  TRETINOIN_ID,
} from 'common/dist/products/productRegistry';

// checking if the customer has purchased all of the skin products we offer
// before showing them an ad-style banner encouraging them to check out our
// new skin products
export const showNewProducts = (treatmentPlan: TreatmentPlan | undefined) => {
  const DEFAULT_SKIN_PRODUCT_IDS =
    process.env.REACT_APP_SHOW_NEW_SKINCARE === 'true'
      ? [M4_PRODUCT_ID, TRETINOIN_ID, M4_FACE_SERUM_PRODUCT_ID, M4_EYE_SERUM_PRODUCT_ID]
      : [M4_PRODUCT_ID, TRETINOIN_ID];

  const skinProducts: DeepProduct[] =
    treatmentPlan?.prescriptionProducts
      .filter((p) => p.product.category === 'skin-health')
      .map((p) => p.product) || [];

  const purchasedAllSkin: boolean = DEFAULT_SKIN_PRODUCT_IDS.every((productId) =>
    skinProducts.some((skinProduct) => skinProduct.productId === productId),
  );

  return process.env.REACT_APP_SHOW_NEW_SKINCARE === 'true' && !purchasedAllSkin;
};
