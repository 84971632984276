import useTreatmentPlanState from 'modules/dashboard/sub-modules/treatment-plan/hooks/useTreatmentPlanState';

import { getAccountStatus } from 'modules/dashboard/lib/account-status';

import ActivePlanContainer from 'modules/dashboard/sub-modules/treatment-plan/sub-modules/home/ui/containers/ActivePlan';
import AwaitingQueueOrCX from 'modules/dashboard/sub-modules/treatment-plan/sub-modules/home/ui/containers/AwaitingQueueOrCX';
import ConfirmationContainer from 'modules/dashboard/sub-modules/treatment-plan/sub-modules/home/ui/containers/Confirmation';
import DoctorReviewContainer from 'modules/dashboard/sub-modules/treatment-plan/sub-modules/home/ui/containers/DoctorReview';
import EmptyPlanContainer from 'modules/dashboard/sub-modules/treatment-plan/sub-modules/home/ui/containers/EmptyPlan';
import PendingApprovalContainer from 'modules/dashboard/sub-modules/treatment-plan/sub-modules/home/ui/containers/PendingApproval';

import DashboardContainer from 'modules/dashboard/ui/containers/DashboardContainer';

import Loader from 'shared/components/content/Loader';

export default function TreatmentPlanHomeWrapper() {
  const {
    isLoading,

    isOrderPlaced,

    customer,
    treatmentPlan,
    subscriptions,
    orders,

    cart,

    onViewTreatmentPlan,
  } = useTreatmentPlanState();

  /**
   * Stuff is loading
   */
  if (isLoading) {
    return (
      <DashboardContainer title='Treatment Plan | Alloy' desc='' currentPage='treatment plan'>
        <Loader />
      </DashboardContainer>
    );
  }

  if (isOrderPlaced && cart.products.length !== 0) {
    return <ConfirmationContainer onViewTreatmentPlan={onViewTreatmentPlan} />;
  }

  const accountStatus = getAccountStatus(customer.status, subscriptions, orders, treatmentPlan);

  switch (accountStatus) {
    case 'NO_PURCHASE':
      return <EmptyPlanContainer />;

    case 'AWAITING_CX_OR_QUEUE':
      return <AwaitingQueueOrCX />;

    case 'AWAITING_DOCTOR':
      return <DoctorReviewContainer />;

    case 'AWAITING_CUSTOMER':
      return <PendingApprovalContainer />;

    default:
      return <ActivePlanContainer />;
  }
}
