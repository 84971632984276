import { useExperience } from 'modules/shared/context/experience';

import SelectableProductsGroup from 'modules/checkout-experience/sub-modules/mht-preferences/ui/content/SelectableProductsGroup';

import CheckoutExperienceSection from 'modules/checkout-experience/ui/wrappers/CheckoutExperienceSection';

import Loader from 'shared/components/content/Loader';
import BottomBar from 'shared/components/wrappers/BottomBar';
import TopBannerWithProgress from 'shared/components/wrappers/TopBannerWithProgress';

import useMhtPreferencesState from '../../hooks/useMhtPreferencesState';
import useMhtProducts from '../../hooks/useMhtProducts';

export default function MhtPreferencesFormWrapper() {
  const {
    requestedDeepProductIds,

    onSelect,
    onSubmit,
  } = useMhtPreferencesState();
  const { isLoading, products } = useMhtProducts();
  const { onBack } = useExperience();

  return (
    <>
      <TopBannerWithProgress onBack={isLoading ? undefined : onBack} />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CheckoutExperienceSection>
            <h1 className='content-title'>
              Do you have a specific treatment you are interested in including in your treatment
              plan?
            </h1>

            <p className='content-blurb'>
              If not, don't worry, your doctor will work with you to find the treatment that is best
              for you.
            </p>

            <SelectableProductsGroup
              products={products}
              requestedDeepProductIds={requestedDeepProductIds}
              onSelect={onSelect}
            />
          </CheckoutExperienceSection>

          <BottomBar>
            <button className='primary-button' type='submit' onClick={onSubmit}>
              Next
            </button>
          </BottomBar>
        </>
      )}
    </>
  );
}
